/* eslint-disable security/detect-object-injection */
/********************************************************************************
 * ResolvePipe
 *
 * Angular pipe to resolve nested attributes from an object
 *
 * parameters
 *   path : an array or '.' separated string for path to attributes to resolve
 *          to find specific nested values (ex. in an array of objects), the
 *          nested item can be selected using a {key:value} syntax.  For example
 *          "runResultDetails{_eligibleAttribute:3}.employee_value"
 *
 *          Note: the key must be an actual property of the object and the
 *          JSONAPI mapped attributes are really getter functions, so you need
 *          to use the original property name that starts with a '_'
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'resolve' })
export class ResolvePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const path = args[0];
    const separator = '.';
    const nestedSearch = /(.*?)\{(.*?)\}/;
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev: any, curr: any) => {
      const nested = curr.match(nestedSearch);
      if (nested) {
        const nestedScope = nested[1];
        const nestedItem = nested[2].split(':');
        return this.findObject(prev && prev[nestedScope], nestedItem[0], nestedItem[1]);
      } else {
        return prev && prev[curr];
      }
    }, value);
  }

  findObject(theObject: any, targetProp: string, targetValue: any) {
    let result = null;
    if (theObject instanceof Array) {
      for (const o of theObject) {
        result = this.findObject(o, targetProp, targetValue);
        if (result) {
          break;
        }
      }
    } else {
      for (const prop in theObject) {
        // eslint-disable-next-line no-prototype-builtins
        if (theObject.hasOwnProperty(prop)) {
          if (prop === targetProp) {
            // If the value is a boolean, convert a 0 or 1 target value to a boolean true/false
            if (typeof theObject[prop] === 'boolean' && theObject[prop] === Boolean(targetValue)) {
              result = theObject;
              break;
            // eslint-disable-next-line eqeqeq
            } else if (theObject[prop] == targetValue) {
              result = theObject;
              break;
            }
          }
          // if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
          //   result = this.findObject(theObject[prop], targetProp, targetValue);
          // }
        }
      }
    }
    return result;
  }

}
