import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { CustomerAccount } from './customer-account';


/*****************************************
 * CustomerAccountNote
 *****************************************/
@JsonApiModelConfig({
  type: 'customerAccountNotes',
})
export class CustomerAccountNote extends JsonApiModel {

  @Attribute({ serializedName: 'account_number', converter })
    accountNumber: string;

  @Attribute({ serializedName: 'note', converter })
    note: string;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @BelongsTo()
    customerAccount: CustomerAccount;

}
