import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';

/*****************************************
 * DamageDetail
 *****************************************/
@JsonApiModelConfig({
  type: 'damageDetails'
})
export class DamageDetail extends JsonApiModel {

  get damageId() {
    return this.id;
  }

  @Attribute({ serializedName: 'dd_notification_id', converter })
    notificationId: string;

  @Attribute({ serializedName: 'dd_district', converter })
    district: string;

  @Attribute({ serializedName: 'dd_address_1', converter })
    address1: string;

  @Attribute({ serializedName: 'dd_address_2', converter })
    address2: string;

  @Attribute({ serializedName: 'dd_city', converter })
    city: string;

  @Attribute({ serializedName: 'dd_state', converter })
    state: string;

  @Attribute({ serializedName: 'dd_zip_code', converter })
    zipCode: string;

  @Attribute({ serializedName: 'dd_orig_request_id', converter })
    origRequestId: string;

  @Attribute({ serializedName: 'dd_damage_date', converter: dateConverter })
    damageDate: Date;

  @Attribute({ serializedName: 'dd_date_reported', converter: dateConverter })
    dateReported: Date;

  @Attribute({ serializedName: 'dd_notified_by', converter })
    notifiedBy: string;

  @Attribute({ serializedName: 'dd_caller_company', converter })
    callerCompany: string;

  @Attribute({ serializedName: 'dd_damaging_excavator', converter })
    damagingExcavator: string;

  @Attribute({ serializedName: 'dd_cdi_emp_id', converter })
    cdiEmpId: number;

  @Attribute({ serializedName: 'dd_cdi_confirmation', converter })
    cdiConfirmation: string;

  @Attribute({ serializedName: 'dd_notes', converter })
    notes: string;

  @Attribute({ serializedName: 'dd_investigation_date_from', converter })
    investigationDateFrom: string;

  @Attribute({ serializedName: 'dd_investigation_date_to', converter })
    investigationDateTo: string;

  @Attribute({ serializedName: 'dd_type_of_excavation', converter })
    typeOfExcavation: string;

  @Attribute({ serializedName: 'dd_orig_locate_request', converter })
    origLocateRequest: string;

  @Attribute({ serializedName: 'dd_start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'dd_type_of_ticket', converter })
    typeOfTicket: string;

  @Attribute({ serializedName: 'dd_damage_request', converter })
    damageRequest: string;

  @Attribute({ serializedName: 'dd_further_investigation_needed', converter })
    furtherInvestigationNeeded: number;

  @Attribute({ serializedName: 'dd_contact_info', converter })
    contactInfo: number;

  @Attribute({ serializedName: 'dd_call_date', converter })
    callDate: number;

  @Attribute({ serializedName: 'dd_damaged_customer', converter })
    damagedCustomer: number;

  @Attribute({ serializedName: 'dd_pictures_taken_by', converter })
    picturesTakenBy: string;

}
