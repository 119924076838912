import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { EligibleAttribute } from './eligible-attribute';
import { RunResult } from './run-result';


/*****************************************
 * RunResultDetail
 *****************************************/
@JsonApiModelConfig({
  type: 'runResultDetails'
})
export class RunResultDetail extends JsonApiModel {

  @BelongsTo()
    runResult: RunResult;

  @Attribute({ serializedName: 'result_id', converter })
    resultId: number;

  @BelongsTo()
    eligibleAttribute: EligibleAttribute;

  @Attribute({ serializedName: 'attribute_id', converter })
    attributeId: number;

  @Attribute({ serializedName: 'qualification_value', converter })
    qualificationValue: string;

  @Attribute({ serializedName: 'employee_value', converter })
    employeeValue: string;

  @Attribute({ converter })
    result: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

}
