import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * CpDistrict
 *****************************************/
@JsonApiModelConfig({
  type: 'cpDistricts',
})
export class CpDistrict extends JsonApiModel {

  get cpDistrictId() {
    return +this.id; // (+) converts string 'id' to a number
  }

  @Attribute({ serializedName: 'cp_workday_id',  converter })
    workddayId: number;

  @Attribute({ serializedName: 'cp_district_code',  converter })
    districtCode: number;

  @Attribute({ serializedName: 'cp_district_abbr',  converter })
    districtAbbr: string;

  @Attribute({ serializedName: 'cp_district_name',  converter })
    districtName: string;

  @Attribute({ serializedName: 'cp_state',  converter })
    state: string;

  @Attribute({ serializedName: 'cp_region',  converter })
    region: string;

  @Attribute({ serializedName: 'cp_created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'cp_created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'cp_last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'cp_last_updated_by', converter })
    lastUpdatedBy: string;

}
