import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';

import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import { UserAccess } from './user-access';
import { CustomerAccount } from './customer-account';
import { CustomerContract } from './customer-contract';


/*****************************************
 * User
 *****************************************/
@JsonApiModelConfig({
  type: 'users'
})
export class User extends JsonApiModel {

  @Attribute({ serializedName: 'cp_user_name', converter })
    cpUserName: string;

  @Attribute({ serializedName: 'cp_password', converter })
    cpPassword: string;

  @Attribute({ serializedName: 'first_name', converter })
    firstName: string;

  @Attribute({ serializedName: 'last_name', converter })
    lastName: string;

  @Attribute({ serializedName: 'middle_name', converter })
    middleName: string;

  @Attribute({ serializedName: 'preferred_name', converter })
    preferredName: string;

  @Attribute({ converter })
    active: boolean;

  @Attribute({ serializedName: 'eff_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'eff_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ serializedName: 'email_address', converter })
    emailAddress: string;

  @Attribute({ serializedName: 'cp_admin', converter })
    cpAdmin: boolean;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'mobile_phone', converter })
    mobilePhone: string;

  @Attribute({ serializedName: 'receive_notifications', converter })
    receiveNotifications: boolean;

  @Attribute({ serializedName: 'last_logged_on', converter: dateConverter })
    lastLoggedOn: Date;

  @Attribute({ serializedName: 'logo_id', converter })
    logoId: number;

  @Attribute({ serializedName: 'show_damages', converter })
    showDamages: boolean;

  @Attribute({ serializedName: 'password_date', converter: dateConverter })
    passwordDate: Date;

  @Attribute({ serializedName: 'nda_signed', converter })
    ndaSigned: boolean;

  @Attribute({ serializedName: 'show_history', converter })
    showHistory: boolean;

  @Attribute({ serializedName: 'show_responses', converter })
    showResponses: boolean;

  @Attribute({ serializedName: 'show_screenshots', converter })
    showScreenshots: boolean;

  @Attribute({ serializedName: 'show_gps', converter })
    showGps: boolean;

  @Attribute({ serializedName: 'expiration_cycle', converter })
    expirationCycle: number;

  @Attribute({ serializedName: 'export_gps', converter })
    exportGps: boolean;

  @Attribute({ serializedName: 'attachments_with_notifications', converter })
    attachmentsWithNotifications: boolean;

  @HasMany()
    customerAccounts: CustomerAccount;

  @HasMany()
    customerContracts: CustomerContract;

  @HasMany()
    userAccesses: UserAccess[];

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

}
