import {
  JsonApiModelConfig,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { Employee } from './employee';

/*****************************************
 * UserLogin
 *****************************************/
@JsonApiModelConfig({
  type: 'userLogins'
})
export class UserLogin extends Employee {

  @Attribute({ serializedName: 'session_id', converter })
    sessionId: string;

  @Attribute({ serializedName: 'application_id', converter })
    applicationId: number;

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @Attribute({ serializedName: 'login_date', converter: dateConverter })
    loginDate: Date;

  @Attribute({ serializedName: 'browser_name', converter })
    browserName: string;

  @Attribute({ serializedName: 'major_version', converter })
    majorVersion: string;

  @Attribute({ serializedName: 'minor_version', converter })
    minorVersion: string;

  @Attribute({ serializedName: 'user_platform', converter })
    userPlatform: string;

  @Attribute({ serializedName: 'screen_height', converter })
    screenHeight: number;

  @Attribute({ serializedName: 'screen_width', converter })
    screenWidth: number;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ converter })
    attribute4: string;

  @Attribute({ converter })
    attribute5: string;

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
      attribute4: this.attribute4,
      attribute5: this.attribute5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
    this.attribute2 = v.attribute2;
    this.attribute3 = v.attribute3;
    this.attribute4 = v.attribute4;
    this.attribute5 = v.attribute5;
  }

  @BelongsTo()
    employee: Employee;
}
