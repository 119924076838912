import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { District } from './district';


/*****************************************
 * SupervisorGroup
 *****************************************/
@JsonApiModelConfig({
  type: 'supervisorGroups'
})
export class SupervisorGroup extends JsonApiModel {

  @Attribute({ serializedName: 'sup_group_code', converter })
    supervisorGroupCode: string;

  @Attribute({ serializedName: 'district_code', converter })
    districtCode: string;

  @BelongsTo({ key : 'dist' })
    district: District;

  @Attribute({ serializedName: 'district_name', converter })
    districtName: string;

  @Attribute({ converter })
    region: string;

  @Attribute({ serializedName: 'status', converter })
    status: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

}
