import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Employee } from '../shareddata';

/*****************************************
 * Vehicle
 *****************************************/
@JsonApiModelConfig({
  type: 'vehicles'
})
export class Vehicle extends JsonApiModel {

  // ID is the dvn (Donlen Vehicle Number)

  @Attribute({ serializedName: 'vin_number', converter })
    vin: string;

  @Attribute({ converter })
    cvn: string;

  @BelongsTo()
    employee: Employee;

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @Attribute({ serializedName: 'model_year', converter })
    modelYear: number;

  @Attribute({ converter })
    make: string;

  @Attribute({ serializedName: 'model_group', converter })
    modelGroup: string;

  @Attribute({ serializedName: 'book_value', converter })
    bookValue: string;

  @Attribute({ serializedName: 'license_plate_number', converter })
    licensePlateNumber: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

}
