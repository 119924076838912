import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';

/*****************************************
 * ReportDataType
 *****************************************/
@JsonApiModelConfig({
  type: 'reportDataTypes'
})
export class ReportDataType extends JsonApiModel {

  @Attribute({ serializedName: 'name', converter })
    name: string;

  @Attribute({ serializedName: 'description', converter })
    description: string;

  @Attribute({ serializedName: 'database_name', converter })
    databaseName: string;

  @Attribute({ serializedName: 'table_name', converter })
    tableName: string;

  @Attribute({ serializedName: 'value_attribute', converter })
    valueAttribute: string;

  @Attribute({ serializedName: 'qualifier', converter })
    qualifier: string;

  @Attribute({ serializedName: 'data_source', converter })
    dataSource: string;

  @Attribute({ serializedName: 'model_name', converter })
    modelName: string;

  @Attribute({ serializedName: 'filter_json', converter })
    filterJson: string;

  @Attribute({ serializedName: 'label', converter })
    label: string;

  @Attribute({ serializedName: 'ui_value', converter })
    uiValue: string;

  @Attribute({ serializedName: 'ui_option', converter })
    uiOption: string;

  @Attribute({ serializedName: 'ui_option_custom', converter })
    uiOptionCustom: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

}
