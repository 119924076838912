import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * CmsDistrict
 *****************************************/
@JsonApiModelConfig({
  type: 'cmsDistricts'
})
export class CmsDistrict extends JsonApiModel {

  get districtId() {
    return Number(this.id);
  }

  @Attribute({ serializedName: 'workday_id', converter })
    workdayId: number;

  @Attribute({ serializedName: 'district_code', converter })
    districtCode: number;

  @Attribute({ serializedName: 'district_abbr', converter })
    districtAbbr: string;

  @Attribute({ serializedName: 'district_name', converter })
    districtName: string;

  @Attribute({ converter })
    state: string;

  @Attribute({ converter })
    region: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

}
