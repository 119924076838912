import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * AttProductCatalogItem
 *****************************************/
@JsonApiModelConfig({
  type: 'attProductCatalogItems'
})
export class AttProductCatalogItem extends JsonApiModel {

  // ID is the SKU number
  get sku() {
    return this.id;
  }


  @Attribute({ serializedName: 'item_type', converter })
    itemType: string;

  @Attribute({ serializedName: 'description', converter })
    description: string;

  @Attribute({ serializedName: 'unit_price', converter })
    unitPrice: number;

  @Attribute({ serializedName: 'unit_measurement', converter })
    unitMeasurement: string;

  @Attribute({ serializedName: 'charge_type', converter })
    chargeType: string;

  @Attribute({ serializedName: 'soc_code', converter })
    socCode: string;

  @Attribute({ serializedName: 'effective_date', converter: dateConverter })
    effectiveDate: Date;

  @Attribute({ serializedName: 'expiration_date', converter: dateConverter })
    expirationDate: Date;

  @Attribute({ serializedName: 'long_description', converter })
    longDescription: string;

  @Attribute({ serializedName: 'plan_type', converter })
    planType: string;

  @Attribute({ serializedName: 'plan_subtype', converter })
    planSubtype: string;

  @Attribute({ serializedName: 'manufacturer', converter })
    manufacturer: string;

  @Attribute({ serializedName: 'color', converter })
    color: string;

  @Attribute({ serializedName: 'capacity', converter })
    capacity: string;

  @Attribute({ serializedName: 'fn5g_capable', converter })
    fn5gCapable: boolean;

  @Attribute({ serializedName: 'mutually_exclusive_features', converter })
    mutuallyExclusiveFeatures$: string;

  get mutuallyExclusiveFeatures(): string[] {
    return this.mutuallyExclusiveFeatures$ ? this.mutuallyExclusiveFeatures$
      .replace(/^\[/, '').replace(/\]$/, '')
      .replace(/featureSku:/g, '')
      .replace(/ /g, '')
      .split(',') : [];
  }

  @Attribute({ serializedName: 'incompatible_services', converter })
    incompatibleServices$: string;

  get incompatibleServices(): string[] {
    return this.incompatibleServices$ ? this.incompatibleServices$
      .replace(/^\[/, '').replace(/\]$/, '')
      .replace(/featureSku:/g, '')
      .replace(/ /g, '')
      .split(',') : [];
  }

  @Attribute({ serializedName: 'compatible_services', converter })
    compatibleServices$: string;

  get compatibleServices(): string[] {
    return this.compatibleServices$ ? this.compatibleServices$
      .replace(/^\[/, '').replace(/\]$/, '')
      .replace(/featureSku:/g, '')
      .replace(/ /g, '')
      .split(',') : [];
  }

  @Attribute({ serializedName: 'compatible_rateplans', converter })
    compatibleRateplans$: string;

  get compatibleRateplans(): string[] {
    return this.compatibleRateplans$ ? this.compatibleRateplans$
      .replace(/^\[/, '').replace(/\]$/, '')
      .replace(/rateplanSku:/g, '')
      .replace(/ /g, '')
      .split(',') : [];
  }

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

}
