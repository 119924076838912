import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * AttachmentCategorized
 *****************************************/
@JsonApiModelConfig({
  type: 'attachmentsCategorized'
})
export class AttachmentCategorized extends JsonApiModel {

  @Attribute({ serializedName: 'user_id', converter })
    userId: number;

  @Attribute({ serializedName: 'ticket_id', converter })
    ticketId: number;

  @Attribute({ serializedName: 'attachment_id', converter })
    attachmentId: number;

  @Attribute({ serializedName: 'file_name', converter })
    fileName: string;

  @Attribute({ serializedName: 'generated_at', converter: dateConverter })
    generatedAt: Date;

  @Attribute({ serializedName: 'received_at', converter: dateConverter })
    receivedAt: Date;

  @Attribute({ serializedName: 'photo_category', converter })
    photoCategory: string;

  @Attribute({ converter })
    lat: number;

  @Attribute({ converter })
    lon: number;

  @Attribute({ serializedName: 'passed', converter })
    passed: boolean;

  @Attribute({ serializedName: 'account_number', converter })
    accountNumber: string;

  @Attribute({ serializedName: 'passed_override_by_id', converter })
    passedOverrideById: number;

  @Attribute({ serializedName: 'passed_override_at', converter: dateConverter })
    passedOverrideAt: Date;

  @Attribute({ serializedName: 'purpose', converter })
    purpose: string;

  @Attribute({ serializedName: 'probability', converter })
    probability: number;

  @Attribute({ serializedName: 'manual_category', converter })
    manualCategory: string;

  @Attribute({ serializedName: 'manual_category_at', converter: dateConverter })
    manualCategoryAt: Date;

  @Attribute({ serializedName: 'manual_category_by_id', converter })
    manualCategoryById: number;

  @Attribute({ serializedName: 'customer_facility_type', converter })
    customerFacilityType: string;


  get url() {
    // Remove the https://attachments.usicllc.com part in order to
    // use proxy which allows export to work around CORS issue (CP-83)
    //    return `/attachment/${this.attachmentId}`;
    return `/attachment/${this.attachmentId}`;
  }

  // URLs to get thumnail versions of attachments
  //
  get url_sm() {
    return `/attachment-sm/${this.attachmentId}`;
  }

  get url_med() {
    return `/attachment-med/${this.attachmentId}`;
  }

  get url_lg() {
    return `/attachment-lg/${this.attachmentId}`;
  }

  get latLon() {
    return `${this.lat}, ${this.lon}`;
  }

  get whenCreated() {
    return this.generatedAt;
  }

  get createdDate() {
    return this.generatedAt;
  }

}
