import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * VerizonWirelessInventorySFO
 *****************************************/
@JsonApiModelConfig({
  type: 'verizonWirelessInventorySfos'
})
export class VerizonWirelessInventorySfo extends JsonApiModel {


  @Attribute({ serializedName: 'mdn', converter })
    mdn: string;

  @Attribute({ serializedName: 'sfo_code', converter })
    sfoCode: number;

  @Attribute({ serializedName: 'sfo_description', converter })
    sfoDescription: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

}
