import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { Utility } from './utility';


/*****************************************
 * TkpCustomer
 *****************************************/
@JsonApiModelConfig({
  type: 'tkp-customers'
})
export class TkpCustomer extends JsonApiModel {

  @Attribute({ serializedName: 'name', converter })
    name: string;

  @Attribute({ serializedName: 'account_name', converter })
    accountName: string;

  @Attribute({ serializedName: 'account_number', converter })
    accountNumber: string;

  @HasMany()
    utilities: Utility[];

}
