/* eslint-disable max-len */
import {Component, Input, OnInit} from '@angular/core';
import cronstrue from 'cronstrue';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-cron-help-message',
  template: `

    <div class="padding margin-bottom border bg-usic-surface-light">

      <ng-container *ngIf="cronString">
        <p class="mat-caption ">
          Selected schedule: <span class="text-usic-green">{{schedule}}</span> ( {{ cronString }} )
        </p>

        <br>
      </ng-container>

      <p class="mat-caption ">
        See the <a [href]="conTriggerUrl" target="_blank" rel="noopener noreferrer nofollow">Cron Trigger Tutorial</a>
        for explanation of the cron expression syntax.
      </p>
    </div>

  `,
  imports: [NgIf]
})
export class CronHelpMessageComponent implements OnInit {

  @Input() cronString: string | undefined;
  conTriggerUrl = 'http://www.quartz-scheduler.org/documentation/quartz-2.3.0/tutorials/crontrigger.html';

  constructor() { }

  ngOnInit(): void {
  }

  get schedule() {
    return cronstrue.toString(this.cronString, {verbose: true});
  }
}
