/********************************************************************************
 * ChartPieComponent (lib-chart-pie)
 *
 * Angular component to draw a pie chart using chart.js
 * Allows pie slices to be toggled via the legend and highlights current slice
 * on mouse hovers
 *
 * parameters
 * INPUT
 *   name         : String for naming this chart
 *   labels       : String[] contains the labels for each pie slice
 *   data         : Number[] contains the data for each pie slice
 *
 * OUTPUT
 *   clickedSlice : String for the name of a clicked pie slice
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

//import { Chart } from 'chart.js/auto';
import {
  ArcElement,
  Chart,
  ChartTypeRegistry,
  Filler,
  Legend,
  PieController,
  Tooltip,
} from 'chart.js';

import { ColorSchemeService } from '../../../services/color-scheme.service';
import { NgTemplateOutlet, NgStyle } from '@angular/common';

// USIC colors -  green, blue, orange
// Blood Hound colors - yellow, red
// Reconn - aqua
// On Target - dark green, light grey, medium green
const backgroundColor = ['#94c83d', '#00305B', '#F25C0F', '#ffcd03', '#5a1400', '#137289', '#0d472d', '#a7a9ac', '#0b7544'];
const hoverBackgroundColor = backgroundColor.map(color => shadeHexColor(color, 0.4));

/**
 * shadeHexColor from https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
 * @param color
 * @param percent  (positive will lighten, negative will darken)
 * @returns string
 */
function shadeHexColor(color, percent) {

  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;

  // eslint-disable-next-line no-bitwise
  const R = f >> 16;
  // eslint-disable-next-line no-bitwise
  const G = f >> 8 & 0x00FF;
  // eslint-disable-next-line no-bitwise
  const B = f & 0x0000FF;

  return '#' + (0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B))
    .toString(16).slice(1);
}


@Component({
  selector: 'lib-chart-pie',
  templateUrl: '../chart.component.html',
  styleUrl: '../chart.component.scss',
  imports: [NgTemplateOutlet, NgStyle]
})
export class ChartPieComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() name: string;
  @Input() labels: Observable<string[]>;
  @Input() data: Observable<number[]>;
  @Input() selected: string;
  @Input() height: string;

  @Output() clickedSlice = new EventEmitter<string>();
  @Output() chartCreated = new EventEmitter<boolean>();
  @Output() visibleArcs = new EventEmitter<string[]>();

  chart: Chart;
  labels$: string[];
  data$: number[];

  constructor(
    public colorScheme: ColorSchemeService,
  ) {

    Chart.register(
      // For Pie Chart
      PieController,
      ArcElement,
      Legend,
      Tooltip,
      Filler,
    );

  }

  ngAfterViewInit() {

    this.labels.subscribe((labels) => {
      this.labels$ = labels;
      if (this.data$?.length) {
        this.chart = this.createPieChart(this.name, this.labels$, this.data$);
      }
    });

    this.data.subscribe((data) => {
      this.data$ = data;
      this.chart = this.createPieChart(this.name, this.labels$, this.data$);
    });

  }

  ngOnDestroy() {
    if (this.chart && this.chart instanceof Chart) {
      this.chart.reset();
      this.chart.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selected) {

      const chart = this.chart || Chart.getChart(this.name);
      if (chart && chart instanceof Chart && this.labels$) {

        // Set all data to invisible
        this.labels$?.forEach((label, index) => {
          if (chart.getDataVisibility(index)) {
            chart.toggleDataVisibility(index);
          }
        });

        // Show the selected piece
        chart.toggleDataVisibility(this.labels$.indexOf(changes.selected.currentValue));
        chart.update();
      }

    }

  }

  /**
   * Create a new, standardized chart
   *
   * @param name the new chart's name
   * @param labels data labels
   * @param data chart's dataset (singular)
   *
   * Returns a new pie chart
   */
  createPieChart(name: string, labels: string[], data: number[]) {

    // Clear out any old chart which results in showing the loading indicator
    let chart = this.chart || Chart.getChart(this.name);
    if (chart && chart instanceof Chart) {
      chart.destroy();
      this.chart = undefined;
    }

    if (labels?.length > 0 && data?.length > 0) {
      try {
        chart = new Chart(name, {
          type: 'pie' as keyof ChartTypeRegistry,
          data: {
            labels,
            datasets: [{
              data,
              backgroundColor,
              hoverBackgroundColor,
              hoverBorderWidth: 5,
              hoverOffset: 20,
              borderColor: hoverBackgroundColor,
              hoverBorderColor: backgroundColor,
              fill: true,
            }]
          },
          options: {
            animation: {
              onComplete: () => {
                this.chartCreated.emit(true);
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: 10
            },
            plugins: {
              tooltip: {
                enabled: true,
                displayColors: true,
              },
              legend: {
                display: true,
                position: 'right',
                onClick: (event, legendItem /*, legend */) => {

                  // Hide/Show pie piece of clicked legend items

                  // const arcElement = chart.getDatasetMeta(0).data[legendItem.index] as ArcElement;
                  // console.log('clicked', legendItem.text, arcElement);

                  chart.toggleDataVisibility(legendItem.index);
                  chart.update();

                  // Emit the list of currently viible pie slices
                  const visibleArcElementIndexes = chart.getDatasetMeta(0).data
                    .filter((d, i) => chart.getDataVisibility(i))
                    .map(d => d['$context']['index']);
                  const visibleArcs = labels.filter((l, i) => visibleArcElementIndexes.includes(i));
                  this.visibleArcs.emit(visibleArcs);

                },
                onHover: (event, legendItem /*, legend */) => {

                  const arcElement = chart.getDatasetMeta(0).data[legendItem.index] as ArcElement;
                  arcElement.active = true;
                  chart.update();

                },
                onLeave: (event, legendItem /*, legend */) => {

                  const arcElement = chart.getDatasetMeta(0).data[legendItem.index] as ArcElement;
                  arcElement.active = false;
                  chart.update();

                }
              },
            },
            onClick: (event, clickedElements) => {

              if (clickedElements?.length) {
                const value = labels[clickedElements[0].index];

                // console.log('clickedSlice', value);
                this.clickedSlice.emit(value);

              }

            }
          }
        });

        // Deselect all but the selected label
        if (this.selected) {
          labels?.forEach((label, index) => {
            if (label.toUpperCase() !== this.selected.toUpperCase()) {
              chart.toggleDataVisibility(index);
            }
          });
        }

        return chart;

      } catch (error) {
        console.error('createPieChart', error);
      }
    }
  }

}
