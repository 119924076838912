import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';

import { District } from '../shareddata';
import { Asset } from './asset';

/*****************************************
 * LoanedEmployessAssetException
 *****************************************/
@JsonApiModelConfig({
  type: 'loanedEmployeeAssets'
})
export class LoanedEmployeeAssets extends JsonApiModel {

  @Attribute({ serializedName: 'employee_name', converter })
    employeeName: string;

  @Attribute({ serializedName: 'job_title', converter })
    jobTitle: string;

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @Attribute({ serializedName: 'home_supgroup', converter })
    homeSupgroup: string;

  @Attribute({ serializedName: 'home_district', converter })
    homeDistrictName: string;

  @BelongsTo()
    homeDistrict: District;

  @Attribute({ serializedName: 'home_district_id', converter })
    homeDistrictId: number;

  @Attribute({ serializedName: 'loan_supgroup', converter })
    loanSupgroup: string;

  @Attribute({ serializedName: 'loan_district', converter })
    loanDistrictName: string;

  @BelongsTo()
    loanDistrict: District;

  @Attribute({ serializedName: 'loan_district_id', converter })
    loanDistrictId: number;

  @BelongsTo()
    asset: Asset;

  @Attribute({ serializedName: 'asset_id', converter })
    assetId: number;

  @Attribute({ serializedName: 'version_number', converter })
    version: number;

  @Attribute({ serializedName: 'asset_description', converter })
    assetDescription: string;

  @Attribute({ serializedName: 'asset_type', converter })
    assetType: string;

  @Attribute({ serializedName: 'serial_number', converter })
    serialNumber: string;

  @Attribute({ serializedName: 'tag_number', converter })
    usicTag: string;

  @Attribute({ serializedName: 'asset_supgroup', converter })
    assetSupgroup: string;

  @Attribute({ serializedName: 'asset_district', converter })
    assetDistrictName: string;

  @Attribute({ serializedName: 'asset_district_id', converter })
    assetDistrictId: number;

}
