import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { SlackService } from '../services/slack.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UsicErrorHandler implements ErrorHandler {

  private slack: SlackService;
  constructor(injector: Injector) {
    setTimeout(() => this.slack = injector.get(SlackService));
  }

  handleError(error: unknown): void {
    const date = new Date();

    console.error({
      message: (error as Error).message,
      timestamp: date.toISOString(),
    });

    console.log((error as Error).stack);

    // Report the error in production
    // Unless...
    //   the error is NG0100  (see https://angular.io/errors/NG0100)
    //   the error is "Cannot read properties of null (reading 'reset')" (frequent, but can't locate)
    //   the error end with "is undefined" like "C_ is undefined" or S_ is undefined"
    //   the error is the voluminous "Cannot read properties of undefined (reading '_rawValidators')"
    //   the error is a Gateway Timeout from Here Maps "[Gateway Timeout] https://1.base.maps.ls.hereapi.com/maptile/2.1/info"
    if (environment.production &&
       !(error as Error).message?.startsWith('NG0100') &&
       !((error as Error).message === 'Cannot read properties of null (reading \'reset\')') &&
       !(error as Error).message?.endsWith('is undefined') &&
       !(error as Error).message?.includes('_rawValidators') &&
       !(error as Error).message?.startsWith('[Gateway Timeout] https://1.base.maps.ls.hereapi.com/maptile/2.1/info')
    ) {
      this.slack?.sendError(error as Error).subscribe({
        error: (err) => { console.error(err); }
      });
    }

  }
}
