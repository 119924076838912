import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { FinancialPeriod } from './financial-period';
import { District } from '../shareddata';
import { CmsRequest } from './cms-request';

/*****************************************
 * Accrual
 *****************************************/
@JsonApiModelConfig({
  type: 'accruals',
})
export class Accrual extends JsonApiModel {

  @Attribute({ serializedName: 'accrual_id', converter })
    accrualId: number;

  @Attribute({ serializedName: 'period_id', converter })
    periodId: number;

  @Attribute({ serializedName: 'amount', converter })
    amount: number;

  @Attribute({ serializedName: 'accrual_source', converter })
    accrualSource: string;

  @Attribute({ serializedName: 'accrual_source_id1', converter })
    accrualSourceId1: number;

  @Attribute({ serializedName: 'accrual_source_id2', converter })
    accrualSourceId2: number;

  @Attribute({ serializedName: 'accrual_source_id3', converter })
    accrualSourceId3: number;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'remarks', converter })
    remarks: string;

  @Attribute({ serializedName: 'attributes1', converter })
    attributes1: string;

  @Attribute({ serializedName: 'attributes2', converter })
    attributes2: string;

  @Attribute({ serializedName: 'attributes3', converter })
    attributes3: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'request_id', converter })
    requestId: number;

  @Attribute({ serializedName: 'accrual_type', converter })
    accrualType: string;

  @Attribute({ serializedName: 'variance_amount', converter })
    varianceAmount: number;

  @Attribute({ serializedName: 'retain_balance', converter })
    retainBalance: boolean;

  @Attribute({ serializedName: 'source_accrual_balance', converter })
    sourceAccrualBalance: number;

  @BelongsTo()
    cmsRequest: CmsRequest;

  @BelongsTo()
    financialPeriod: FinancialPeriod;

  @BelongsTo()
    district: District;

}
