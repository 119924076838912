/********************************************************************************
 * USICFooterComponent (lib-usic-footer)
 *
 * Angular component to show the internal website footer (once logged in)
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component } from '@angular/core';
import { BreakpointsService } from '../../services/breakpoints.service';
import { NgClass, NgStyle } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'lib-usic-footer',
  templateUrl: './usic-footer.component.html',
  styleUrls: ['./usic-footer.component.scss'],
  imports: [NgClass, NgStyle, FontAwesomeModule]
})
export class UsicFooterComponent {
  currentDate = new Date();

  constructor(
    public breakpoints: BreakpointsService,
  ) {}

}
