/********************************************************************************
 * SlackService
 *
 * Provides access to send Slack message
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Slack, environment } from '../../environments/environment';
import { Observable, retry } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SlackService {

  constructor(
    private http: HttpClient,
    private authentication: AuthenticationService,
  ) {
  }

  /**
   * sendMessage
   *
   * send a simple message to Slack
   *
   * Parameters:
   *   message  - the text of the message to be sent
   */
  sendMessage(message: string): Observable<any> {
    // This would allow us to send messages to Slack directly, but as that
    // exposes our Slack credentials, an API was added to the jsonapi to
    // send messages more securely.
    //
    //     const url = `${Slack.protocol}://${Slack.hostname}${Slack.root_url}`;
    //
    //     const headers = {
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //     };
    //     const context = new HttpContext().set(BYPASS_JWT, true);
    //     const body = {
    //       channel: Slack.channel,
    //       username: Slack.username,
    //       icon_emoji: environment.production ? ':linuxjesus:' : ':gear:',
    //       text: `*URL:* ${window.location.href}
    // *USER:* ${this.authentication.currentUserLogin}
    // *USER_AGENT:* ${navigator.userAgent}
    // \`\`\`${message}\`\`\``
    //     };

    //     return this.http.post(url, body, { headers, context }).pipe(retry(2));

    const headers = {
      'Content-Type': 'application/json',
    };

    const body = {
      username: Slack.username,
      text: `*URL:* ${window.location.href}
*USER:* ${this.authentication.currentUserLogin}
*USER_AGENT:* ${navigator.userAgent}
\`\`\`${message}\`\`\``
    };

    console.log('url',`${environment.jsonApiUrl}/slack`);

    return this.http.post(`${environment.jsonApiUrl}/slack`, body, { headers }).pipe(retry(2));

  }

  /**
   * sendError
   *
   * send an error message to Slack
   *
   * Parameters:
   *   error - the error to be sent
   */
  sendError(error: Error): Observable<any> {
    // This would allow us to send messages to Slack directly, but as that
    // exposes our Slack credentials, an API was added to the jsonapi to
    // send messages more securely.
    //
    //     const url = `${Slack.protocol}://${Slack.hostname}${Slack.root_url}`;
    //
    //     const headers = {
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //     };
    //     const context = new HttpContext().set(BYPASS_JWT, true);

    //     const body = {
    //       channel: Slack.channel,
    //       username: Slack.username,
    //       icon_emoji: environment.production ? ':linuxjesus:' : ':gear:',
    //       text: `*URL:* ${window.location.href}
    // *USER:* ${this.authentication.currentUserLogin}
    // *USER_AGENT:* ${navigator.userAgent}
    // *ERROR:* \`${error.message}\``,
    //       attachments: [
    //         {
    //           color: 'danger',
    //           title: 'Stack Trace',
    //           text: error.stack
    //         }
    //       ]
    //     };

    //     return this.http.post(url, body, { headers, context }).pipe(retry(2));

    const headers = {
      'Content-Type': 'application/json',
    };

    const body = {
      username: Slack.username,
      text: `*URL:* ${window.location.href}
*USER:* ${this.authentication.currentUserLogin}
*USER_AGENT:* ${navigator.userAgent}
*ERROR:* \`${error.message}\``,
      attachments: [
        {
          color: 'danger',
          title: 'Stack Trace',
          text: error.stack
        }
      ]
    };

    return this.http.post(`${environment.jsonApiUrl}/slack`, body, { headers }).pipe(retry(2));

  }

}
