import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * UserProfile
 * a stripped down version of User
 *****************************************/
@JsonApiModelConfig({
  type: 'userProfiles'
})
export class UserProfile extends JsonApiModel {

  @Attribute({ serializedName: 'cp_user_name', converter })
    cpUserName: string;

  @Attribute({ serializedName: 'cp_password', converter })
    cpPassword: string;

  @Attribute({ serializedName: 'first_name', converter })
    firstName: string;

  @Attribute({ serializedName: 'last_name', converter })
    lastName: string;

  @Attribute({ serializedName: 'middle_name', converter })
    middleName: string;

  @Attribute({ serializedName: 'preferred_name', converter })
    preferredName: string;

  @Attribute({ serializedName: 'email_address', converter })
    emailAddress: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'mobile_phone', converter })
    mobilePhone: string;

  @Attribute({ serializedName: 'last_logged_on', converter: dateConverter })
    lastLoggedOn: Date;

}
