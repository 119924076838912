import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import {
  Supgroup,
  EligibleAttributeValue,
  Recognition,
  WorkdayEmployee
} from '.';


/*****************************************
 * CPDistrict
 *****************************************/
@JsonApiModelConfig({
  type: 'districts'
})
export class CPDistrict extends JsonApiModel {

  @Attribute({ serializedName: 'id', converter })
    workdayId: number;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'district_code', converter })
    districtCode: number;

  @Attribute({ serializedName: 'district_abbr', converter })
    districtAbbr: string;

  @Attribute({ serializedName: 'district_name', converter })
    districtName: string;

  @Attribute({ converter })
    state: string;

  @Attribute({ converter })
    region: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    supgroups: Supgroup[];

  @HasMany()
    eligibleAttributeValues: EligibleAttributeValue[];

  @HasMany()
    recognitions: Recognition[];

  @HasMany()
    workdayEmployees: WorkdayEmployee[];

}
