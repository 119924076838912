/********************************************************************************
 * CustomerPortalService (a JsonApiDatastore)
 *
 * Implemenation of a JsonApiDatastore which is part of the angular2-jsonapi package
 * see https://www.npmjs.com/package/angular2-jsonapi
 * This defines the connection to the CustomerPortal API and what models the
 * API provides access to.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonApiDatastoreConfig, JsonApiDatastore, DatastoreConfig, JsonApiQueryData } from '@michalkotas/angular2-jsonapi';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import {
  CpDistrict,
  CustomerAccount,
  CustomerAccountActivity,
  CustomerAccountNote,
  CustomerContract,
  CustomerContractState,
  TpHistory,
  User,
  UserAccess,
  UserAccountContractAccess,
  UserContact,
  UserProfile,
  UserState,
} from '../models/customerportal';
import { UserAuth } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';
import { PersistentSettingsService } from '../services/persistent-settings.service';
import { retry } from 'rxjs/operators';
import { MAX_PAGE_LIMIT } from '../datasources/jsonapi.datasource';
import { MatSnackBar } from '@angular/material/snack-bar';

const referencedModels = {};

const config: DatastoreConfig = {
  baseUrl: `${environment.jsonApiUrl}/customerportal/1.0`,
  models: {
    cpDistricts: CpDistrict,
    customerAccountActivities: CustomerAccountActivity,
    customerAccountNotes: CustomerAccountNote,
    customerAccounts: CustomerAccount,
    customerContractStates: CustomerContractState,
    customerContracts: CustomerContract,
    tpHistories: TpHistory,
    userAccesses: UserAccess,
    userAccountContractAccesses: UserAccountContractAccess,
    userContacts: UserContact,
    userProfiles: UserProfile,
    userStates: UserState,
    users: User,

    // Add any additional models that the Customer Portal service models
    // reference here (most likely from the SharedData DB)
    ...referencedModels
  }
};

@Injectable({ providedIn: 'root' })
@JsonApiDatastoreConfig(config)
export class CustomerPortalService extends JsonApiDatastore {

  private accountNumbersSubject = new BehaviorSubject<string>('');
  public accountNumbers: Observable<string> = this.accountNumbersSubject.asObservable();

  private contractNumbersSubject = new BehaviorSubject<string>('');
  public contractNumbers: Observable<string> = this.contractNumbersSubject.asObservable();

  private userStatesSubject = new BehaviorSubject<string[]>([]);
  public userStates: Observable<string[]> = this.userStatesSubject.asObservable();

  private cpUserSubject = new BehaviorSubject<User>(undefined);
  public cpUser: Observable<User> = this.cpUserSubject.asObservable();

  private renderer: Renderer2;

  private getGpsRequestSubscription: Subscription;

  constructor(
    http: HttpClient,
    private authentication: AuthenticationService,
    private persistentSettings: PersistentSettingsService,
    private rendererFactory: RendererFactory2,
    private snackBar: MatSnackBar,
  ) {
    super(http);

    // Get an instance of Angular's Renderer2
    this.renderer = this.rendererFactory.createRenderer(null, null);

    // reload any cached data whenever the user changes (and user is a CP_USER)
    this.authentication.currentUser.subscribe(
      (currentUser: UserAuth) => {
        if (currentUser && this.authentication.currentUserIsValid &&
          this.authentication.currentUserHasRole('ROLE_CP_USER')) {

          // Ensure all subjects are set to their initial state to prevent
          // "{user} does NOT have access to these contracts" errors when switching users
          this.accountNumbersSubject.next('');
          this.contractNumbersSubject.next('');
          this.userStatesSubject.next([]);
          this.cpUserSubject.next(undefined);

          this.cacheData();
        }
      });

  }

  get pageSize(): number {
    return Number(this.persistentSettings.getSetting('CustomerPortal-page-size')) || 5;
  }
  set pageSize(size: number) {
    // Save new page size preference if it's one of the valid selections (not a temporary
    // value used for select all actions)
    if ([5, 10, 25, 100, 250, 500, 1000].includes(size)) {
      this.persistentSettings.setSetting('CustomerPortal-page-size', size.toString());
    }
  }

  /**
   * Get the saved page size for a specific component (based on prefix)
   * @param prefix
   */
  savedPageSize(prefix: string): number {
    return Number(this.persistentSettings.getSetting(`${prefix}${prefix ? '-' : ''}page-size`)) || this.pageSize;
  }

  providesModel(modelName: string) {
    // eslint-disable-next-line security/detect-object-injection
    return (config.models[modelName] && !referencedModels[modelName]);
  }


  // Preload some data
  // The angular2-jsonapi library will keep them cached
  cacheData() {

    if ((this.authentication.loginMethod === 'JWT' || this.authentication.loginMethod === 'Azure') &&
      !this.authentication.currentUserHasRole('ROLE_CP_USER_PASSWORD')) {

      // Load the CP User record for the current user
      this.findAll(User,
        {
          filter: {
            cp_user_name: this.authentication.currentUserLogin
          }
        })
        .subscribe({

          error: (error) => {
            console.error('Error caching user (retrying) =>', error);
            if (error.message !== 'Forbidden' &&
                error.message !== 'Access Denied' &&
                error.message !== 'NG0205: Injector has already been destroyed.') {
              setTimeout(() => this.cacheData(), Math.round(Math.random() * 30000));
            }
          },

          next: (userJson: JsonApiQueryData<User>) => {
            const users = userJson.getModels();

            if (users.length) {
              this.cpUserSubject.next(users[0]);
              console.log('user id:', this.currentUser.id);
            }

            // Current APIs all require the user's accountNumbers and contractNumbers
            // so pre-fetch them.
            this.findAll(UserAccountContractAccess,
              {
                filter: {
                  cp_user_name: this.authentication.currentUserLogin
                },
                page: { limit: MAX_PAGE_LIMIT }
              })
              .subscribe({
                error: (error) => {
                  console.error('Error caching user accounts and contracts (retrying) =>', error);
                  if (error.message !== 'Forbidden' &&
                      error.message !== 'NG0205: Injector has already been destroyed.') {
                    setTimeout(() => this.cacheData(), Math.round(Math.random() * 30000));
                  }
                },

                next: (accessesJson: JsonApiQueryData<UserAccountContractAccess>) => {
                  const accesses = accessesJson.getModels();

                  const accountNumbers = [...new Set(accesses.map(a => a.accountNumber))].join(',');
                  this.accountNumbersSubject.next(accountNumbers);
                  console.log('user accountNumbers:', accountNumbers);

                  let contractNumbers =
                    [...new Set(accesses.filter(a => Number(a.contractNumber) !== -1).map(a => a.contractNumber))]
                      .join(',');

                  // Convert any -1 contract numbers into the actual list of current contracts for that account
                  const allContractsAccesses = accesses.filter(a => Number(a.contractNumber) === -1);
                  if (allContractsAccesses.length > 0) {
                    allContractsAccesses.forEach(access => {
                      this.findAll(CustomerContract, {
                        filter: {
                          account_number: access.accountNumber,
                          status: 'Active',
                        },
                        page: { limit: MAX_PAGE_LIMIT }
                      })
                        .subscribe({
                          next: (contractJson: JsonApiQueryData<CustomerContract>) => {
                            const contracts = contractJson.getModels();
                            contractNumbers =
                              [...new Set(
                                contractNumbers.split(',').filter(c => Number(c) !== -1)
                                  .concat(...contracts.map(c => c.contractNumber))
                              )].join(',');
                            this.contractNumbersSubject.next(contractNumbers);
                            console.log('user contractNumbers:', contractNumbers);
                          }
                        });
                    });
                  } else {
                    this.contractNumbersSubject.next(contractNumbers);
                    console.log('user contractNumbers:', contractNumbers);
                  }


                  // Need to know which states the user has access to
                  this.findAll(UserState,
                    {
                      filter: {
                        cp_user_name: this.authentication.currentUserLogin
                      },
                      page: { limit: MAX_PAGE_LIMIT }
                    })
                    .subscribe({
                      error: (error) => {
                        console.error('Error caching user states (retrying) =>', error);
                        if (error.message !== 'Forbidden' &&
                            error.message !== 'NG0205: Injector has already been destroyed.') {
                          setTimeout(() => this.cacheData(), Math.round(Math.random() * 30000));
                        }
                      },

                      next: (statesJson: JsonApiQueryData<UserState>) => {
                        const states = [... new Set(statesJson.getModels().map(s => s.state))].sort();
                        this.userStatesSubject.next(states);
                        console.log('user states:', states.join(','));
                      }

                    });

                }
              });

          }
        });



    }

  }

  get currentUser(): User {
    return this.cpUserSubject.value;
  }

  getAccountNumbers(): string {
    return this.accountNumbersSubject.value;
  }

  getContractNumbers(): string {
    return this.contractNumbersSubject.value;
  }

  getUserStates(): string[] {
    return this.userStatesSubject.value;
  }

  /**
   * Get the yearly metrics to display in the dashboard relevant for the current user
   */
  getReceivedTicketsYear(): Observable<any> {
    // eslint-disable-next-line max-len
    const endpoint = `${config.baseUrl}/receivedTicketsYear?accountNumbers=${this.accountNumbersSubject.value}&contractNumbers=${this.contractNumbersSubject.value}`;

    return this.http.get(endpoint).pipe(retry(2));
  }

  /**
   * Get the weekly metrics to display in the dashboard relevant for the current user
   */
  getReceivedTicketsWeek(): Observable<any> {
    // eslint-disable-next-line max-len
    const endpoint = `${config.baseUrl}/receivedTicketsWeek?accountNumbers=${this.accountNumbersSubject.value}&contractNumbers=${this.contractNumbersSubject.value}`;

    return this.http.get(endpoint).pipe(retry(2));
  }

  /**
   * Get the count of open tickets for the current user
   */
  getOpenTicketCount(): Observable<any> {
    // eslint-disable-next-line max-len
    const endpoint = `${config.baseUrl}/openTickets?accountNumbers=${this.accountNumbersSubject.value}&contractNumbers=${this.contractNumbersSubject.value}`;

    return this.http.get(endpoint).pipe(retry(2));
  }

  /**
   * Get the count of open tickets for the current user
   */
  getClosedTicketCount(): Observable<any> {
    // eslint-disable-next-line max-len
    const endpoint = `${config.baseUrl}/closedTickets?accountNumbers=${this.accountNumbersSubject.value}&contractNumbers=${this.contractNumbersSubject.value}`;

    return this.http.get(endpoint).pipe(retry(2));
  }

  /**
   * Get the closed locate metrics by ticket type
   */
  getClosedLocatesByTicketType(startDate: Date, endDate: Date, groupBy?: string): Observable<any> {
    // eslint-disable-next-line max-len
    const endpoint = `${config.baseUrl}/locByTicketBucket`;

    const body = {
      accountNumbers: this.accountNumbersSubject.value.split(','),
      contractNumbers: this.contractNumbersSubject.value.split(','),
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
      groupBy // groupBy (optional) can be date, week, month or year
    };

    return this.http.post(endpoint, body).pipe(retry(2));
  }

  /**
   * Get the ticket types that compose the ticket bucket
   */
  getTicketTypeForTicketBucket(startDate: Date, endDate: Date, ticketBucket: string): Observable<any> {
    // eslint-disable-next-line max-len
    const endpoint = `${config.baseUrl}/ticketTypeForTicketBucket`;

    const body = {
      accountNumbers: this.accountNumbersSubject.value.split(','),
      contractNumbers: this.contractNumbersSubject.value.split(','),
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
      ticketBucket,
    };

    return this.http.post(endpoint, body).pipe(retry(2));
  }

  /**
   * Get the closed locate metrics by excavator
   */
  getClosedLocatesByExcavator(startDate: Date, endDate: Date, groupBy?: string): Observable<any> {
    // eslint-disable-next-line max-len
    const endpoint = `${config.baseUrl}/locByExcavator`;

    const body = {
      accountNumbers: this.accountNumbersSubject.value.split(','),
      contractNumbers: this.contractNumbersSubject.value.split(','),
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
      groupBy // groupBy (optional) can be date, week, month or year
    };

    return this.http.post(endpoint, body).pipe(retry(2));
  }

  /**
   * Get the closed locate metrics by work done for
   */
  getClosedLocatesByWorkDoneFor(startDate: Date, endDate: Date, groupBy?: string): Observable<any> {
    // eslint-disable-next-line max-len
    const endpoint = `${config.baseUrl}/locByWorkDoneFor`;

    const body = {
      accountNumbers: this.accountNumbersSubject.value.split(','),
      contractNumbers: this.contractNumbersSubject.value.split(','),
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
      groupBy // groupBy (optional) can be date, week, month or year
    };

    return this.http.post(endpoint, body).pipe(retry(2));
  }

  /**
   * Send a forgotPassword request to get an email to change the password
   */
  getForgotPasswordEmail({ username, email }): Observable<any> {
    // eslint-disable-next-line max-len
    const endpoint = `${config.baseUrl}/forgotPassword?${username?.trim() ? `username=${username}` : ''}${username?.trim() && email?.trim() ? '&' : ''}${email?.trim() ? `email=${email}` : ''}`;

    return this.http.get(endpoint).pipe(retry(2));
  }

  /**
   * Send a user registration request
   */
  postUserRegistration({ legalName, email, state, company }): Observable<any> {
    // eslint-disable-next-line max-len
    const endpoint = `${config.baseUrl}/registerUserEmail`;
    const body = {
      name: legalName?.trim(),
      email: email?.trim(),
      state,
      company: company?.trim(),
    };

    return this.http.post(endpoint, body).pipe(retry(2));
  }

  /**
   * Get the GPS data export Excel file
   */
  getGPSDataSheet(startDate: Date, endDate: Date): Promise<string> {
    return new Promise<string>((resolve, reject) => {

      const endpoint = `${config.baseUrl}/getGpsData`;
      const body = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };

      this.getGpsRequestSubscription = this.http.post(endpoint, body, { responseType: 'blob' as 'json' })
        .subscribe({

          next: (data: any) => {
            const dataType = data.type;
            const binaryData = [];
            binaryData.push(data);

            // create an temporary link to open the file
            const filename = `gpsData_${startDate.toISOString().split('T')[0]}_${endDate.toISOString().split('T')[0]}.xlsx`;
            const anchor = this.renderer.createElement('a');
            const binaryURL = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            this.renderer.setProperty(anchor, 'href', binaryURL);
            this.renderer.setAttribute(anchor, 'download', filename);
            this.renderer.appendChild(document.body, anchor);
            anchor.click();

            // remove the temporary link
            this.renderer.removeChild(document.body, anchor);

            resolve(filename);
          },

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          error: (error) => {
            this.snackBar.open('Could not download Excel file.', 'Dismiss');
            reject(error);
          }

        });

    });

  }

  /**
   * Get the GPS data export as a ZIP file of CSV files
   */
  getGPSDataZip(startDate: Date, endDate: Date, state: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {

      const endpoint = `${config.baseUrl}/getGpsZipData`;
      const body = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        state,
      };

      this.getGpsRequestSubscription = this.http.post(endpoint, body, { responseType: 'blob' as 'json' })
        .subscribe({

          next: (data: any) => {
            const dataType = data.type;
            const binaryData = [];
            binaryData.push(data);

            // create an temporary link to open the file
            const filename = `gpsData_${startDate.toISOString().split('T')[0]}_${endDate.toISOString().split('T')[0]}.zip`;
            const anchor = this.renderer.createElement('a');
            const binaryURL = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            this.renderer.setProperty(anchor, 'href', binaryURL);
            this.renderer.setAttribute(anchor, 'download', filename);
            this.renderer.appendChild(document.body, anchor);
            anchor.click();

            // remove the temporary link
            this.renderer.removeChild(document.body, anchor);

            resolve(filename);
          },

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          error: (error) => {
            this.snackBar.open('Could not download Zip file.', 'Dismiss');
            reject(error);
          }

        });

    });

  }

  cancelGetGps() {
    this.getGpsRequestSubscription?.unsubscribe();
  }

}

