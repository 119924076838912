import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PublicHeaderComponent } from './public-header.component';

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatTooltipModule,
    PublicHeaderComponent,
  ],
  exports: [
    PublicHeaderComponent
  ]
})
export class PublicHeaderModule { }
