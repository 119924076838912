import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { Visit } from './visit';
import { StatusCode } from './status-code';
import { ResponseLog } from './response-log';


/*****************************************
 * Activity
 *****************************************/
@JsonApiModelConfig({
  type: 'activities',
})
export class Activity extends JsonApiModel {

  @Attribute({ serializedName: 'feet_located', converter })
    feetLocated: number;

  @Attribute({ serializedName: 'hours_worked', converter })
    hoursWorked: number;

  @Attribute({ serializedName: 'billable_override', converter })
    billableOverride: boolean;

  @Attribute({ serializedName: 'billed', converter })
    billed: boolean;

  @Attribute({ serializedName: 'closing_override', converter })
    closingOverride: boolean;

  @Attribute({ serializedName: 'relevant', converter })
    relevant: boolean;

  @Attribute({ serializedName: 'locate_id', converter })
    locateId: number;

  @Attribute({ serializedName: 'status_id', converter })
    statusId: number;

  @Attribute({ serializedName: 'high_profile_id', converter })
    highProfileId: number;

  @Attribute({ serializedName: 'visit_id', converter })
    visitId: number;

  @Attribute({ serializedName: 'addresses_count', converter })
    addressesCount: number;

  @Attribute({ serializedName: 'export_status', converter })
    exportStatus: number;

  // Circular dependency
  // @BelongsTo()
  // locate: Locate;

  @BelongsTo()
    visit: Visit;

  @BelongsTo()
    statusCode: StatusCode;

  @HasMany()
    responseLogs: ResponseLog[];
}
