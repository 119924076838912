import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter,
} from '../../helpers/jsonapi.convertors';
import { HereMapMarkerData } from '../../components/here-map/here-map-marker-data';

import { Activity } from './activity';
import { Bucket } from './bucket';
import { ProjectTicket } from './project-ticket';
import { Term } from './term';
import { Ticket } from './ticket';
import { Transmission } from './transmission';
import { TransmissionBase } from './transmission-base';
import { TransmissionDetail } from './transmission-detail';
import { TransmissionExtra } from './transmission-extra';
import { TransmissionText } from './transmission-text';
import { Utility } from './utility';
import { TermUtilityMap } from './term-utility-map';

const VALID_ATTACHMENT_EXTENSIONS = ['.pdf', '.txt', '.eml', '.gml', '.xlsx'];
export const VALID_PHOTO_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.JPEG', '.gif', '.heic'];
export const VALID_VIDEO_EXTENSIONS = ['.mp4', '.m4v', '.mpg', '.mpeg', '.mov', '.avi'];

/*****************************************
 * Locate
 *****************************************/
@JsonApiModelConfig({
  type: 'locates',
})
export class Locate extends JsonApiModel implements HereMapMarkerData {

  @Attribute({ converter })
    open: boolean;

  @Attribute({ converter })
    seqnum: number;

  @Attribute({ serializedName: 'closed_date', converter: dateConverter })
    closedDate: Date;

  @Attribute({ serializedName: 'initial_status_date', converter: dateConverter })
    initialStatusDate: Date;

  @Attribute({ serializedName: 'client_id', converter })
    clientId: number;

  @Attribute({ serializedName: 'ticket_id', converter })
    ticketId: number;

  @Attribute({ serializedName: 'transmission_id', converter })
    transmissionId: number;

  @BelongsTo()
    transmission: Transmission;

  @BelongsTo()
    transmissionBase: TransmissionBase;

  @BelongsTo()
    transmissionExtra: TransmissionExtra;

  @BelongsTo()
    transmissionText: TransmissionText;

  @BelongsTo()
    transmissionDetail: TransmissionDetail;

  @Attribute({ serializedName: 'bucket_id', converter })
    bucketId: number;

  @Attribute({ serializedName: 'high_profile_id', converter })
    highProfileId: number;

  @Attribute({ serializedName: 'added_by_id', converter })
    addedById: number;

  @Attribute({ serializedName: 'latest_activity_id', converter })
    latestActivityId: number;

  @Attribute({ serializedName: 'legal_due_date', converter: dateConverter })
    legalDueDate: Date;

  @Attribute({ serializedName: 'our_due_date', converter: dateConverter })
    ourDueDate: Date;

  @Attribute({ serializedName: 'utility_id', converter })
    utilityId: number;

  @Attribute({ serializedName: 'high_profile', converter })
    highProfile: boolean;

  @Attribute({ serializedName: 'past_due', converter })
    pastDue: boolean;

  @Attribute({ serializedName: 'project', converter })
    project: boolean;

  @BelongsTo()
    utility: Utility;

  @Attribute({ serializedName: 'first_closing_activity_id', converter })
    firstClosingActivityId: number;

  @BelongsTo()
    bucket: Bucket;

  @BelongsTo()
    ticket: Ticket;

  @HasMany()
    activities: Activity[];

  @BelongsTo()
    latestActivity: Activity;

  @BelongsTo()
    firstClosingActivity: Activity;

  @BelongsTo()
    projectTicket: ProjectTicket;

  @BelongsTo()
    term: Term;

  @BelongsTo()
    termUtilityMap: TermUtilityMap;

  // Some helper methods to allow locates to be displayed in table and on the map

  get locateId() {
    return this.id;
  }

  get ticketNumber() {
    return this.ticket?.ticketNumber || this.transmissionBase?.ticketNumber || this.transmission?.ticketNumber;
    // return this.transmissionBase ? this.transmissionBase.ticketNumber : this.transmission?.ticketNumber;
  }

  get street() {
    return this.transmissionBase?.street || this.transmission?.street;
  }

  get city() {
    return this.transmissionBase?.city || this.transmission?.city;
  }

  get state() {
    return this.transmissionBase?.state || this.transmission?.state;
  }

  get address() {
    return this.transmissionBase?.address || this.transmission?.address;
  }

  get county() {
    return this.transmissionBase?.county || this.transmission?.county;
  }

  get lat() {
    return this.transmissionDetail?.lat || this.transmission?.lat;
  }

  get lon() {
    return this.transmissionDetail?.lon || this.transmission?.lon;
  }

  get missingLocation() {
    return !this.lat || !this.lon;
  }

  get area() {
    return this.transmissionDetail?.digAreas?.map(a => ({ lat: a.lat, lon: a.lon }));
  }

  get memberCodes() {
    return this.term?.feed?.terms?.map(t => t.code);
  }

  get visitLocationLat() {
    if (this.activities) {
      // we reverse the activites array in order to get the location of the most recent activity
      // which is more likely the actual locate rather than a reschedule or something like that
      const activitiesCopy = [...this.activities];
      return activitiesCopy.reverse().find(activity => activity.visit?.visitLocation?.latitude)?.visit.visitLocation.latitude;
    }
  }

  get visitLocationLon() {
    if (this.activities) {
      // we reverse the activites array in order to get the location of the most recent activity
      // which is more likely the actual locate rather than a reschedule or something like that
      const activitiesCopy = [...this.activities];
      return activitiesCopy.reverse().find(activity => activity.visit?.visitLocation?.longitude)?.visit.visitLocation.longitude;
    }
  }

  get photos() {
    return this.ticket.tkpAttachments ? this.ticket.tkpAttachments
      .filter(photo => VALID_PHOTO_EXTENSIONS.some(extension => photo.fileName.endsWith(extension)))
      .filter(photo => !photo.fileName.toLowerCase().includes('screenshot'))
      .filter((value, index, self) => index === self.findIndex((a) => (a.id === value.id))) : null;
  }

  get screenshots() {
    return this.ticket.tkpAttachments ? this.ticket.tkpAttachments
      .filter(photo => VALID_PHOTO_EXTENSIONS.some(extension => photo.fileName.endsWith(extension)))
      .filter(photo => photo.fileName.toLowerCase().includes('screenshot'))
      .filter((value, index, self) => index === self.findIndex((a) => (a.id === value.id))) : null;
  }

  get videos() {
    return this.ticket.tkpAttachments ? this.ticket.tkpAttachments
      .filter(photo => VALID_VIDEO_EXTENSIONS.some(extension => photo.fileName.endsWith(extension)))
      .filter((value, index, self) => index === self.findIndex((a) => (a.id === value.id))) : null;
  }

  get attachments() {
    return this.ticket.tkpAttachments ? this.ticket.tkpAttachments
      .filter(attachment => VALID_ATTACHMENT_EXTENSIONS.some(extension => attachment.fileName.endsWith(extension)))
      .filter((value, index, self) => index === self.findIndex((a) => (a.id === value.id))) : null;
  }

  get attachmentsCategorized() {
    return this.ticket.attachmentsCategorized ? this.ticket.attachmentsCategorized
      .filter(photo => VALID_PHOTO_EXTENSIONS.some(extension => photo.fileName.endsWith(extension)))
      .filter((value, index, self) => index === self.findIndex((a) => (a.id === value.id))) : null;
  }



}
