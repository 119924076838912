import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Visit } from './visit';
import { Ticket } from './ticket';


/*****************************************
 * TkpDistrict
 *****************************************/
@JsonApiModelConfig({
  type: 'tkp-districts'
})
export class TkpDistrict extends JsonApiModel {

  @Attribute({ serializedName: 'district_code', converter })
    districtCode: number;

  @Attribute({ serializedName: 'company_id', converter })
    companyId: number;

  @Attribute({ serializedName: 'inactive', converter })
    inactive: boolean;

  @Attribute({ serializedName: 'district_abbr', converter })
    districtAbbr: string;

  @Attribute({ serializedName: 'district_name', converter })
    districtName: string;

  @Attribute({ serializedName: 'time_zone', converter })
    timeZone: string;

  @Attribute({ serializedName: 'lunch_lower_limit', converter })
    lunchLowerLimit: number;

  @Attribute({ serializedName: 'lunch_upper_limit', converter })
    lunchUpperLimit: number;

  @Attribute({ serializedName: 'clock_start', converter: dateConverter })
    clockStart: Date;

  @Attribute({ serializedName: 'work_hours_id', converter })
    workHoursId: number;

  @Attribute({ serializedName: 'productivity_goal', converter })
    productivityGoal: number;

  @Attribute({ serializedName: 'client_min_verison', converter })
    clientMinVerison: string;

  @Attribute({ serializedName: 'client_pref_version', converter })
    clientPrefVersion: string;

  @Attribute({ serializedName: 'work_code', converter })
    workCode: string;

  @Attribute({ serializedName: 'address1', converter })
    address1: string;

  @Attribute({ serializedName: 'address2', converter })
    address2: string;

  @Attribute({ serializedName: 'city', converter })
    city: string;

  @Attribute({ converter })
    state: string;

  @Attribute({ converter })
    zip: string;

  @Attribute({ serializedName: 'org_region_id', converter })
    orgRegionId: number;

  @HasMany()
    visits: Visit[];

  @HasMany()
    tickets: Ticket[];


}
