import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { CPDistrict } from './district';
import { EligibleAttribute } from './eligible-attribute';
import { Job } from './job';
import { Supgroup } from './supgroup';

/*****************************************
 * EligibleAttributeValue
 *****************************************/
@JsonApiModelConfig({
  type: 'eligibleAttributeValues'
})
export class EligibleAttributeValue extends JsonApiModel {

  @BelongsTo()
    eligibleAttribute: EligibleAttribute;

  @Attribute({ serializedName: 'eligibleAttributeId', converter })
    eligibleAttributeId: number;

  @Attribute({ serializedName: 'conditionally_required', converter })
    conditionallyRequired: boolean;

  @BelongsTo()
    district: CPDistrict;

  @Attribute({ serializedName: 'districtId', converter })
    districtWorkdayId: number;

  @BelongsTo()
    supgroup: Supgroup;

  @Attribute({ serializedName: 'supgroup_code', converter })
    supgroupCode: string;

  @BelongsTo()
    job: Job;

  @Attribute({ converter })
    jobId: number;

  @Attribute({ serializedName: 'attribute_value', converter })
    attributeValue: string;

  @Attribute({ serializedName: 'unit_of_measure', converter })
    unitOfMeasure: string;

  @Attribute({ converter })
    criteria: string;

  @Attribute({ serializedName: 'eff_start_date', converter: dateConverter })
    effStartDate: Date;

  @Attribute({ serializedName: 'eff_end_date', converter: dateConverter })
    effEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

}
