import { HasMany } from '@michalkotas/angular2-jsonapi';
import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { Locate } from './locate';


/*****************************************
 * TransmissionExtra
 *****************************************/
@JsonApiModelConfig({
  type: 'transmissionExtras',
})
export class TransmissionExtra extends JsonApiModel {

  @Attribute({ serializedName: 'marking_instructions', converter })
    markingInstructions: string;

  @Attribute({ serializedName: 'cross_ref', converter })
    crossRef: string;

  @Attribute({ serializedName: 'township', converter })
    township: string;

  @Attribute({ serializedName: 'original_ticket_number', converter })
    originalTicketNumber: string;

  @Attribute({ serializedName: 'original_ticket_id', converter })
    originalTicketId: number;

  @Attribute({ serializedName: 'contact', converter })
    contact: string;

  @Attribute({ serializedName: 'zip', converter })
    zip: string;

  @Attribute({ serializedName: 'identifier', converter })
    identifier: string;

  @Attribute({ serializedName: 'depth', converter })
    depth: string;

  @Attribute({ serializedName: 'contract_seq', converter })
    contractSeq: string;

  @Attribute({ serializedName: 'excavation_means', converter })
    excavationMeans: string;

  @Attribute({ serializedName: 'excavator_state', converter })
    excavatorState: string;

  @Attribute({ serializedName: 'excavator_zip', converter })
    excavatorZip: string;

  @Attribute({ serializedName: 'sidewalk', converter })
    sidewalk: boolean;

  @Attribute({ serializedName: 'in_street', converter })
    inStreet: boolean;

  @Attribute({ serializedName: 'remark', converter })
    remark: boolean;

  @Attribute({ serializedName: 'driving_instructions', converter })
    drivingInstructions: string;

  @Attribute({ serializedName: 'duration', converter })
    duration: string;

  @Attribute({ serializedName: 'single_address', converter })
    singleAddress: string;

  @Attribute({ serializedName: 'contact_mobile', converter })
    contactMobile: string;

  @Attribute({ serializedName: 'whitelined', converter })
    whitelined: boolean;

  @HasMany()
    locates: Locate[];
}
