import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Investigation } from './investigation';

/*****************************************
 * Excavator
 *****************************************/
@JsonApiModelConfig({
  type: 'excavator'
})
export class Excavator extends JsonApiModel {

  @Attribute({ serializedName: 'name', converter })
    name: string;

  @Attribute({ serializedName: 'contact_name', converter })
    contactName: string;

  @Attribute({ serializedName: 'contact_email', converter })
    contactEmail: string;

  @Attribute({ serializedName: 'contact_phone_number', converter })
    contactPhoneNumber: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    investigations: Investigation[];

}
