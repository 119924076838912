import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany
} from '@michalkotas/angular2-jsonapi';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Estimate } from './estimate';
import { Investigation } from './investigation';
import { QuickC } from './quick-c';

/*****************************************
 * QuickCValue
 *****************************************/
@JsonApiModelConfig({
  type: 'quickCodeValue'
})
export class QuickCValue extends JsonApiModel {

  get codeId() {
    return Number(this.id);
  }

  @Attribute({ serializedName: 'quick_code_id', converter })
    quickCodeId: number;

  @Attribute({ serializedName: 'value', converter })
    value: string;

  @Attribute({ serializedName: 'display_name', converter })
    displayName: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    endDate: Date;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.startDate,
      effectiveEndDate: this.endDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.startDate = v.effectiveStartDate;
    this.endDate = v.effectiveEndDate;
  }

  @BelongsTo()
    quickC: QuickC;

  @HasMany()
    estimates: Estimate[];

  @HasMany()
    investigations: Investigation[];

}
