import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Employee } from './employee';
import { SchedulerJobStatus } from './scheduler-job-status';

// Transient data from Quartz scheduler
export class JobInfo {
  schedulerName: string;
  previousFireTime: Date;
  nextFireTime: Date;
  priority: number;
}

/*****************************************
 * SchedulerJob
 *****************************************/
@JsonApiModelConfig({
  type: 'schedulerJobs'
})
export class SchedulerJob extends JsonApiModel {

  get jobId(): number {
    return +this.id;  // (+) converts string 'id' to a number
  }

  @Attribute({ converter })
    name: string;

  @Attribute({ serializedName: 'job_group', converter })
    jobGroup: string;

  @Attribute({ serializedName: 'status_id', converter })
    statusId: number;

  @Attribute({ serializedName: 'emp_id', converter })
    empId: number;

  @Attribute({ serializedName: 'job_class', converter })
    jobClass: string;

  @Attribute({ serializedName: 'cron_expression', converter })
    cronExpression: string;

  @Attribute({ serializedName: 'description', converter })
    description: string;

  @Attribute({ serializedName: 'repeat_time', converter })
    repeatTime: number;

  @Attribute({ serializedName: 'cron_job', converter })
    cronJob: boolean;

  @Attribute({ serializedName: 'time_zone', converter })
    timeZone: string;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    endDate: Date;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ converter })
    attribute4: string;

  @Attribute({ converter })
    attribute5: string;

  @Attribute({ converter })
    parameters: string;

  // Transient data from Quartz scheduler (CAR-3)
  @Attribute({ serializedName: 'jobInfo', converter })
    jobInfo: JobInfo;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @BelongsTo()
    schedulerJobStatus: SchedulerJobStatus;

  @BelongsTo()
    employee: Employee;

  // For scheduled EmployeeMessages
  // attribute1 = recipient employee name
  // attribute2 = recipient employee id
  // attribute3 = Message.id

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
      attribute4: this.attribute4,
      attribute5: this.attribute5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
    this.attribute2 = v.attribute2;
    this.attribute3 = v.attribute3;
    this.attribute4 = v.attribute4;
    this.attribute5 = v.attribute5;
  }

}
