import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  JsonApiDatastore,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';

/**
 * MachineCustomInventory records are values collected on locator machines
 * from TicketPro and provided to KACE through PUMP.
 *
 * SOFTWARE_IDs for MachineCustomInventory are defined as:
 *
 * 19335 = supgroup
 * 19353 = district
 * 19355 = rank
 * 22422 = employee id
 * 22423 = employee name
 * 29350 = local administrators
 *
 */
// eslint-disable-next-line no-shadow
enum SoftwareID {
  supgroup = '19335',
  district = '19353',
  rank = '19355',
  employeeId = '22422',
  employeeName = '22423',
  localAdministrators = '29350',
}

type MachineCustomInventory = {
  ID: string;
  SOFTWARE_ID: SoftwareID;
  STR_FIELD_VALUE: string;
  MODIFIED: Date;
  DATE_FIELD_VALUE: string;
  FIELD_VALUE_TYPE: string;
};


type Employee = {
  acceptedAssets: any;
  active_status: string;
  announcements: any;
  assetAssignments: any;
  assignments: any;
  created_by: string;
  created_on: Date;
  deviceTokens: any;
  dist: any;
  district: string;
  district_abbr: string;
  district_id: number;
  eff_district: string;
  eff_district_abbr: string;
  eff_district_id: number;
  eff_region: string;
  eff_state: string;
  eff_sup_emp_id: number;
  eff_sup_group_code: string;
  eff_sup_group_id: any;
  eff_supervisor: string;
  effective_date: Date;
  email: string;
  emp_id: number;
  emp_type: string;
  employeeMessages: any;
  employee_key: number;
  exclusion_code: number;
  job_code: number;
  last_updated_by: string;
  last_updated_on: Date;
  legal_name: string;
  legal_name_first: string;
  legal_name_last: string;
  locatorCheckins: any;
  messages: any;
  position: string;
  region: string;
  requests: any;
  schedulerJobsInfo: any;
  state: string;
  sup_emp_id: number;
  sup_group_code: string;
  sup_group_id: any;
  supervisor: string;
  supgroup: any;
  userDataAccesses: any;
  vehicles: any;
};

type District = {
  assets: any;
  created_by: string;
  created_on: Date;
  districtLocations: any;
  district_abbr: any;
  district_code: any;
  district_id: number;
  district_name: string;
  employees: any;
  employeesActiveNoAssets: any;
  employeesOnLoans: any;
  last_updated_by: string;
  last_updated_on: Date;
  locations: any;
  region: any;
  state: any;
  supervisorGroups: any;
  userDataAccesses: any;
  workday_id: number;
};

type AssetAssignment = {
  active: boolean;
  asset: any;
  asset_id: number;
  assignedByEmployee: any;
  assigned_by: number;
  assigned_on: Date;
  assignment_id: number;
  attribute1: any;
  attribute2: any;
  attribute3: any;
  attribute4: any;
  attribute5: any;
  created_by: string;
  created_on: Date;
  effective_end_date: Date;
  effective_start_date: Date;
  employee: any;
  employeeOnLoan: any;
  employee_id: number;
  last_updated_by: string;
  last_updated_on: Date;
  notes: any;
  primary_flag: boolean;
};


/*****************************************
 * KaceMachine
 *****************************************/
@JsonApiModelConfig({
  type: 'kaceMachines'
})
export class KaceMachine extends JsonApiModel {

  @Attribute({ serializedName: 'emp_id', converter })
    emp_id: number;

  @Attribute({ serializedName: 'Modified', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'Created', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'User', converter })
    user: string;

  @Attribute({ serializedName: 'User_fullname', converter })
    userFullname: string;

  @Attribute({ serializedName: 'Name', converter })
    name: string;

  @Attribute({ serializedName: 'Mac', converter })
    macAddress: string;

  @Attribute({ serializedName: 'Ip', converter })
    ipAddress: string;

  @Attribute({ serializedName: 'Os_name', converter })
    osName: string;

  @Attribute({ serializedName: 'Os_number', converter })
    osNumber: string;

  @Attribute({ serializedName: 'Os_major', converter })
    osMajor: string;

  @Attribute({ serializedName: 'Os_minor', converter })
    osMinor: string;

  @Attribute({ serializedName: 'Os_minor2', converter })
    osMinor2: string;

  @Attribute({ serializedName: 'Os_id', converter })
    osId: string;

  @Attribute({ serializedName: 'Os_version', converter })
    osVersion: string;

  @Attribute({ serializedName: 'Os_build', converter })
    osBuild: string;

  @Attribute({ serializedName: 'Os_installed_date', converter: dateConverter })
    osInstalledDate: Date;

  @Attribute({ serializedName: 'Service_pack', converter })
    servicePack: string;

  @Attribute({ serializedName: 'Notes', converter })
    notes: string;

  @Attribute({ serializedName: 'Last_message', converter: dateConverter })
    lastMessage: Date;

  @Attribute({ serializedName: 'Last_inventory', converter: dateConverter })
    lastInventory: Date;

  @Attribute({ serializedName: 'Last_sync', converter: dateConverter })
    lastSync: Date;

  @Attribute({ serializedName: 'Last_client_update', converter: dateConverter })
    lastClientUpdate: Date;

  @Attribute({ serializedName: 'Domain', converter })
    domain: string;

  @Attribute({ serializedName: 'Last_reboot', converter: dateConverter })
    lastReboot: Date;

  @Attribute({ serializedName: 'Uptime', converter })
    uptime: string;

  @Attribute({ serializedName: 'System_directory', converter })
    systemDirectory: string;

  @Attribute({ serializedName: 'System_description', converter })
    systemDescription: string;

  @Attribute({ serializedName: 'Ram Total', converter })
    ramTotal: string;

  @Attribute({ serializedName: 'Ram_used', converter })
    ramUsed: string;

  @Attribute({ serializedName: 'Ram_max', converter })
    ramMax: string;

  @Attribute({ serializedName: 'Cs_manufacturer', converter })
    csManufacturer: string;

  @Attribute({ serializedName: 'Cs_model', converter })
    csModel: string;

  @Attribute({ serializedName: 'Cs_domain', converter })
    csDomain: string;

  @Attribute({ serializedName: 'User_logged', converter })
    userLogged: string;

  @Attribute({ serializedName: 'User_name', converter })
    userName: string;

  @Attribute({ serializedName: 'User_domain', converter })
    userDomain: string;

  @Attribute({ serializedName: 'Bios_name', converter })
    biosName: string;

  @Attribute({ serializedName: 'Bios_version', converter })
    biosVersion: string;

  @Attribute({ serializedName: 'Bios_manufacturer', converter })
    biosManufacturer: string;

  @Attribute({ serializedName: 'Bios_description', converter })
    biosDescription: string;

  @Attribute({ serializedName: 'Bios_identification_code', converter })
    biosIdentificationCode: string;

  @Attribute({ serializedName: 'Bios_serial_number', converter })
    biosSerialNumber: string;

  @Attribute({ serializedName: 'Bios_release_date', converter: dateConverter })
    biosReleaseDate: Date;

  @Attribute({ serializedName: 'Csp_id_number', converter })
    cspIdNumber: string;

  @Attribute({ serializedName: 'Asset_tag', converter })
    assetTag: string;

  @Attribute({ serializedName: 'Virtual', converter })
    virtual: string;

  @Attribute({ serializedName: 'Dot_net_versions', converter })
    dotNetVersions: string;

  @Attribute({ serializedName: 'Ie_version', converter })
    ieVersion: string;

  @Attribute({ serializedName: 'Os_family', converter })
    osFamily: string;

  @Attribute({ serializedName: 'Motherboard_primary_bus', converter })
    motherboardPrimaryBus: string;

  @Attribute({ serializedName: 'Motherboard_secondary_bus', converter })
    motherboardSecondaryBus: string;

  @Attribute({ serializedName: 'Processors', converter })
    processors: string;

  @Attribute({ serializedName: 'Sound_devices', converter })
    soundDevices: string;

  @Attribute({ serializedName: 'Cdrom_devices', converter })
    cdromDevices: string;

  @Attribute({ serializedName: 'Video_controllers', converter })
    videoControllers: string;

  @Attribute({ serializedName: 'Monitor', converter })
    monitor: string;

  @Attribute({ serializedName: 'Registry_size', converter })
    registrySize: string;

  @Attribute({ serializedName: 'Registry_max_size', converter })
    registryMaxSize: string;

  @Attribute({ serializedName: 'Pagefile_size', converter })
    pagefileSize: string;

  @Attribute({ serializedName: 'Pagefile_max_size', converter })
    pagefileMaxSize: string;

  @Attribute({ serializedName: 'Printers', converter })
    printers: string;

  @Attribute({ serializedName: 'Kuid', converter })
    kuid: string;

  @Attribute({ serializedName: 'Manual_entry', converter })
    manualEntry: string;

  @Attribute({ serializedName: 'Sys_arch', converter })
    sysArch: string;

  @Attribute({ serializedName: 'Os_arch', converter })
    osArch: string;

  @Attribute({ serializedName: 'Force_inventory', converter })
    forceInventory: boolean;

  @Attribute({ serializedName: 'Custom_field_value0', converter })
    customFieldValue0: string;

  @Attribute({ serializedName: 'Custom_field_value1', converter })
    customFieldValue1: string;

  @Attribute({ serializedName: 'Custom_field_value2', converter })
    customFieldValue2: string;

  @Attribute({ serializedName: 'Custom_field_value3', converter })
    customFieldValue3: string;

  @Attribute({ serializedName: 'Custom_field_value4', converter })
    customFieldValue4: string;

  @Attribute({ serializedName: 'Custom_field_value5', converter })
    customFieldValue5: string;

  @Attribute({ serializedName: 'Client_version', converter })
    clientVersion: string;

  @Attribute({ serializedName: 'Connect_time', converter: dateConverter })
    connectTime: Date;

  @Attribute({ serializedName: 'Disconnect_time', converter: dateConverter })
    disconnectTime: Date;

  @Attribute({ serializedName: 'Last_shutdown', converter })
    lastShutdown: string;

  @Attribute({ serializedName: 'Chassis_type', converter })
    chassisType: string;

  @Attribute({ serializedName: 'Tz_agent', converter })
    tzAgent: string;

  @Attribute({ serializedName: 'Wmi_status', converter })
    wmiStatus: string;

  @Attribute({ serializedName: 'Last_user', converter })
    lastUser: string;

  @Attribute({ serializedName: 'employee', converter })
    employee: Employee;

  @Attribute({ serializedName: 'district', converter })
    district: District;

  @Attribute({ serializedName: 'assetAssignment', converter })
    assetAssignment: AssetAssignment;

  @Attribute({ serializedName: 'Machine_custom_inventory', converter })
    Machine_custom_inventory: MachineCustomInventory[];

  // Add a couple getters to simplify access to the custom inventory fields

  get tpEmployeeId() {
    return this.Machine_custom_inventory?.find(mci => mci.SOFTWARE_ID === SoftwareID.employeeId)?.STR_FIELD_VALUE;
  }

  get tpEmployeeName() {
    return this.Machine_custom_inventory?.find(mci => mci.SOFTWARE_ID === SoftwareID.employeeName)?.STR_FIELD_VALUE;
  }

  // Extend the constructor in order to clean some data
  constructor(internalDatastore: JsonApiDatastore, data?: any) {
    super(internalDatastore, data);

    if (data?.attributes?.Machine_custom_inventory?.length) {
      data.attributes.Machine_custom_inventory.forEach(ci => {
        for (const [key, value] of Object.entries(ci)) {
          if (value === 'null' || value === 'null null') {
            // eslint-disable-next-line security/detect-object-injection
            ci[key] = undefined;
          }
        }
      });
    }
  }
}
