import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  JsonApiDatastore,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { AuthenticationService } from '../../services/authentication.service';

/*****************************************
 * Report
 *****************************************/
@JsonApiModelConfig({
  type: 'reports'
})
export class Report extends JsonApiModel {

  @Attribute({ serializedName: 'report_source_id', converter })
    reportSourceId: number;

  @Attribute({ serializedName: 'name', converter })
    name: string;

  @Attribute({ serializedName: 'report_type', converter })
    reportType: string;

  @Attribute({ serializedName: 'model', converter })
    model: string;

  @Attribute({ serializedName: 'sql', converter })
    sql: string;

  get sqlPreview() {
    return this.sql?.length > 80 ? `${this.sql.substring(0,80)}...` : this.sql;
  }

  @Attribute({ serializedName: 'params', converter })
    params: string;

  // Note: parsing of params for SP/SQL reports (similar to parsedColumns below) is done in
  // ReportingService.buildModelInspectionFromParams
  // Since the params are completely different for JSONAPI reports, it is not encapsulated here

  @Attribute({ serializedName: 'owner', converter })
    owner: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'columns', converter })
    columns: string;

  get parsedColumns(): { name: string; title: string; type: string }[] {
    // To override a default data type, you can specify a type after a ':' delimiter
    // such as "name:type"
    // To allow alternative column names, you can add a title value after a '#' delimiter
    // such as "name#title"
    // However, if the name includes a '#' such as "Invoice #" it will think the name
    // is "Invoice ".  The following regex delimiter will not split on the '#' if it
    // is preceeded by a space.
    // Unfortuntely, Safari can't handle RexExp look behind assertions so we dumb it down
    // for Safari, also need to construct it with new RegExp so it doesn't choke on it.
    const delim = this.getBrowserName() === 'safari' ? '#' : new RegExp('(?<! )#');
    return this.columns?.split(',').map(c => {
      const [fullname, type] = c.split(':');
      const [name, title] = fullname.split(delim);
      return { name, title, type };
    });
  }

  private getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(window as any).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  @Attribute({ serializedName: 'required_roles', converter })
    requiredRolesStr: string;

  get requiredRoles(): string[] {
    return this.requiredRolesStr?.split(',');
  }

  set requiredRoles(requiredRoles: string[]) {
    this.requiredRolesStr = requiredRoles?.join(',');
  }

  // Add a non-persistent boolean property (global) to indicate if the report is
  // globally accessible, or is private to a specific user.
  //
  // Setting a report as private will require access to the authentication service
  // in order to get the current user's id.  However, since this isn't a component
  // we can't just add the service for injection in the constructor, so the calling
  // component will need to set the authenticationService property.
  //
  public authenticationService: AuthenticationService;

  set global(isGlobal: boolean) {
    if (isGlobal) {
      this.owner = -1;
    } else {
      this.owner = this.authenticationService?.currentUserId || -9999;
    }
  }
  get global(): boolean {
    return this.owner === -1;
  }

  //
  // Variables to hold parsed out json:api parameter values
  //
  include: string;
  filter: any;
  fields: string;
  sort: string;

  unevaulatedFilter: any;

  // Parse out a params string into the separate variables
  private parseJSONParams(params: string) {
    if (params) {

      // Evaulate any nested variables in the parameters
      const variables = params.match(/\$\{(.*?)\}/g);
      if (variables) {
        // Save what the original filter was so we can set the expressions
        this.unevaulatedFilter = JSON.parse(params).filter;

        // eslint-disable-next-line no-eval, security/detect-eval-with-expression
        const variableResults = variables.map(v => eval(v.match(/[^${](.*)[^}]/g)[0])); // nosemgrep
        for (let i = 0; i < variables.length; i++) {
          // eslint-disable-next-line security/detect-object-injection
          params = params.replace(variables[i], variableResults[i]);
        }
      }

      // Convert the parameters from a string to JSON
      const paramJSON = JSON.parse(params);

      // extract the separate jsonapi parameters from the 'params'
      this.include = paramJSON.include;
      this.filter = paramJSON.filter;
      this.fields = paramJSON.fields;
      this.sort = paramJSON.sort;

    }

  }

  setParamsFromJSON({ include, filter, fields, sort }) {
    this.include = include;
    this.filter = filter;
    this.fields = fields;
    this.sort = sort;
    this.params = JSON.stringify({ include, filter, fields, sort });
  }

  // Extend the constructor in order to extract jsonapi params from the params string
  constructor(internalDatastore: JsonApiDatastore, data?: any) {
    super(internalDatastore, data);

    if (data.attributes.reportType === 'jsonapi' && data.attributes.params) {
      this.parseJSONParams(data.attributes.params);
    }

  }


}
