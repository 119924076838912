import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * AttWirelessInventory
 *****************************************/
@JsonApiModelConfig({
  type: 'attWirelessInventories'
})
export class AttWirelessInventory extends JsonApiModel {

  // ID is the wireless number
  get wirelessNumber() {
    return this.id;
  }

  @Attribute({ serializedName: 'imei', converter })
    imei: string;

  // AT&T IMEI = Verizon ESN
  get esn(): string {
    return this.imei;
  }

  set esn(esn: string) {
    this.imei = esn;
  }

  @Attribute({ serializedName: 'fan', converter })
    fan: string;

  @Attribute({ serializedName: 'ban', converter })
    ban: string;

  @Attribute({ serializedName: 'billing_account_name', converter })
    billingAccountName: string;

  @Attribute({ serializedName: 'wireless_user_status', converter })
    wirelessUserStatus: string;

  @Attribute({ serializedName: 'status_effective_date', converter })
    statusEffectiveDate: string;

  @Attribute({ serializedName: 'wireless_user_full_name', converter })
    wirelessUserFullName: string;

  @Attribute({ serializedName: 'device_make', converter })
    deviceMake: string;

  @Attribute({ serializedName: 'device_model', converter })
    deviceModel: string;

  @Attribute({ serializedName: 'wireless_user_activation_date', converter: dateConverter })
    wirelessUserActivationDate: Date;

  @Attribute({ serializedName: 'contract_start_date', converter: dateConverter })
    contractStartDate: Date;

  @Attribute({ serializedName: 'contract_end_date', converter: dateConverter })
    contractEndDate: Date;

  @Attribute({ serializedName: 'device_effective_date', converter: dateConverter })
    deviceEffectiveDate: Date;

  @Attribute({ serializedName: 'serivce_type', converter })
    serivceType: string;

  @Attribute({ serializedName: 'sim_number', converter })
    simNumber: string;

  // AT&T SIM = Verizon ICCID
  get iccid(): string {
    return this.simNumber;
  }

  set iccid(iccid: string) {
    this.simNumber = iccid;
  }

  @Attribute({ serializedName: 'sim_type', converter })
    simType: string;

  @Attribute({ serializedName: 'rate_plan_name', converter })
    ratePlanName: string;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  // AKA costCenter
  get costCenter(): number {
    return this.districtId;
  }

  set costCenter(costCenter: number) {
    this.districtId = costCenter;
  }

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @Attribute({ serializedName: 'udl3', converter })
    udl3: string;

  @Attribute({ serializedName: 'udl4', converter })
    udl4: string;

  @Attribute({ serializedName: 'os_version', converter })
    osVersion: string;

  @Attribute({ serializedName: 'imeisv', converter })
    imeisv: string;

  @Attribute({ serializedName: 'technology_type', converter })
    technologyType: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

}
