/* eslint-disable security/detect-object-injection */
/********************************************************************************
 * ReportingService
 *
 * The reporting service consolidates all the JsonApiDatastore services to
 * provide a comprehensive view of all the data sources and models available.
 * These can then be used flexibly to run a "report" of the data of any
 * available data model (or schedule it).
 *
 * Note: this utilizes the NgPluralizeService so apps that reference this
 * will need to import NgPluralizeModule in app.modules.ts
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable } from '@angular/core';
import { BehaviorSubject, retry, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgPluralizeService } from 'ng-pluralize';

import { AssetManagerService } from './asset-manager.service';
import { CmsService } from './cms.service';
import { KaceService } from './kace.service';
import { TicketProService } from './ticket-pro.service';
import { SharedDataService } from './shared-data.service';
import { AuthenticationService } from './authentication.service';
import { MAX_PAGE_LIMIT } from '../datasources/jsonapi.datasource';

import {
  QuickC,
  QuickCValue,
} from '../models/damagemanagement';
import {
  District,
  Employee,
  Report,
  ReportDataType,
  ReportSource,
  State,
} from '../models/shareddata';
import { PersistentSettingsService } from './persistent-settings.service';
import { CareerProgressionService, MessengerService, ModelInspection, SchedulerInfo, WebFormsService } from '../../public-api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  protected reportSourcesSubject = new BehaviorSubject<ReportSource[]>([]);
  public reportSources = this.reportSourcesSubject.asObservable();
  private reportSubscription: Subscription;

  // Mappings of names to objects
  public dataSources: any;
  public models: [string, any][];

  public reportDataTypes: ReportDataType[];

  // Cached data sets

  // Shared Data
  public districts: District[];
  public regions: string[];
  public states: State[];

  // CMS specific
  public caseStatuses: QuickCValue[];

  protected searchEmployeesSubject = new BehaviorSubject<Employee[]>([]);
  public searchEmployees = this.searchEmployeesSubject.asObservable();


  constructor(
    private authentication: AuthenticationService,
    private snackBar: MatSnackBar,
    private pluralizer: NgPluralizeService,
    private persistentSettings: PersistentSettingsService,
    private assetManagerService: AssetManagerService,
    private careerProgressionService: CareerProgressionService,
    private cmsService: CmsService,
    private kaceService: KaceService,
    private messengerService: MessengerService,
    private sharedDataService: SharedDataService,
    private ticketProService: TicketProService,
    private webFormsService: WebFormsService,
    private http: HttpClient,
  ) {

    // List of available datasource to use to connect to
    // By placing them in an object, we can directly map from the a string name
    // to the included service object.
    this.dataSources = {
      AssetManager: this.assetManagerService,
      CMS: this.cmsService,
      CareerProgression: this.careerProgressionService,
      KACE: this.kaceService,
      Messenger: this.messengerService,
      TicketPro: this.ticketProService,
      WorkDay: this.sharedDataService,
      WebForms: this.webFormsService,
    };

    // List of available models to use as basis of a report
    // By placing them in an object, we can directly map from the model name
    // to the model class.
    // By pulling the model classes directly from the data store config, the
    // list of models it dynamic and we don't need to include all the models
    // in this service in order to use them.
    // Note:
    // The "Object.fromEntries" method was added in ES2019 so if ESLint is
    // flagging it as an error, just ignore it.
    this.models = Object.fromEntries([
      ...Object.values(this.assetManagerService.datastoreConfig.models),
      ...Object.values(this.careerProgressionService.datastoreConfig.models),
      ...Object.values(this.cmsService.datastoreConfig.models),
      ...Object.values(this.kaceService.datastoreConfig.models),
      ...Object.values(this.messengerService.datastoreConfig.models),
      ...Object.values(this.ticketProService.datastoreConfig.models),
      ...Object.values(this.sharedDataService.datastoreConfig.models),
    ].map(m => [m.name, m]));

    // Load the current reports from the DB
    this.loadReports();

    // Load the current report data types from the DB
    this.loadReportDataTypes();

    // Cache up some data for select lists
    this.cacheData();

  }

  get pageSize(): number {
    return Number(this.persistentSettings.getSetting('Reporting-page-size')) || 5;
  }
  set pageSize(size: number) {
    // Save new page size preference if it's one of the valid selections (not a temporary
    // value used for select all actions)
    if ([5, 10, 25, 100, 250, 500, 1000].includes(size)) {
      this.persistentSettings.setSetting('Reporting-page-size', size.toString());
    }
  }

  /**
   * Get the saved page size for a specific component (based on prefix)
   * @param prefix
   */
  savedPageSize(prefix: string): number {
    return Number(this.persistentSettings.getSetting(`${prefix}${prefix ? '-' : ''}page-size`)) || this.pageSize;
  }

  // loadReports
  // Load the reports from the DB
  loadReports() {
    this.sharedDataService.findAll(ReportSource, {
      include: 'reports',
      filter: { active: true },
      sort: 'name'
    })
      .subscribe({
        next: (reportSourcesJSON) => {
          const reportSources = reportSourcesJSON.getModels();

          // Sort the reports
          reportSources.forEach(source => {
            source.reports.sort((a, b) => a.name.localeCompare(b.name));
          });

          this.authentication.currentUser.subscribe(() => {
            const userRoles = this.authentication.currentUserRoles;

            const userReportSources = reportSources.filter(reportSource =>
            // reportSource isn't role restricted or user has role required for reportSource
              (reportSource.requiredRoles === undefined ||
              reportSource.requiredRoles.length === 0 ||
              userRoles.some(r => reportSource.requiredRoles.includes(r)))
            );

            this.reportSourcesSubject.next(userReportSources);
          });

        },
        error: (error) => {
          console.error('Error loading reports (retrying) =>', error);
          setTimeout(() => this.loadReports(), Math.round(Math.random() * 3000));
        }
      });
  }

  // loadReportDataTypes
  // load the ReportDataTypes from the DB
  loadReportDataTypes() {
    this.sharedDataService.findAll(ReportDataType, {
      page: { limit: MAX_PAGE_LIMIT }
    }).subscribe({
      next: (dataTypesJSON) => {
        this.reportDataTypes = dataTypesJSON.getModels();
      },
      error: (error) => {
        console.error('Error loading report data types (retrying) =>', error);
        setTimeout(() => this.loadReportDataTypes(), Math.round(Math.random() * 3000));
      }
    });

  }

  //
  // Cache up some common lookup values that the reports will
  // serve up as select lists
  // We don't want to fetch these on demand since due to the way event handling
  // is done in Angular, they will be fetched and sorted over and over while
  // the user is viewing and interacting with the page.
  //
  cacheData() {

    // Cache up the list of states for any State select lists
    // Sorted by name and grouped by country
    this.sharedDataService.findAll(State, {
      page: { limit: MAX_PAGE_LIMIT },
      sort: 'countryid,name'
    })
      .subscribe({
        error: (error) => {
          console.error('Error caching states (retrying) =>', error);
          if (error.message !== 'Forbidden' &&
              error.message !== 'NG0205: Injector has already been destroyed.') {
            setTimeout(() => this.cacheData(), Math.round(Math.random() * 30000));
          }
        },

        next: (statesJSON) => {
          this.states = statesJSON.getModels();

          // Cache up the list of districts for any District select lists
          // also derive the region list from the districts
          this.sharedDataService.findAll(District, {
            page: { limit: MAX_PAGE_LIMIT },
            sort: 'district_name',
          })
            .subscribe({
              error: (error) => {
                console.error('Error caching districts (retrying) =>', error);
                if (error.message !== 'Forbidden' &&
                  error.message !== 'NG0205: Injector has already been destroyed.') {
                  setTimeout(() => this.cacheData(), Math.round(Math.random() * 30000));
                }
              },

              next: (districtsJSON) => {
                this.districts = districtsJSON.getModels();
                this.regions = [...new Set(this.districts.map(d => d.region).sort())];
              },
            });

        }
      });



  }


  /**
   * Preload any select data needed for a specified data source
   *
   * @param dataSourceName
   */
  cacheDataForDatasource(dataSourceName: string) {
    switch (dataSourceName) {

      //
      // CMS
      //
      case 'CMS': {

        // Cache up the list of case statuses (quick code values for 'case_status')
        this.cmsService.findAll(QuickC, {
          page: { limit: MAX_PAGE_LIMIT },
          include: 'quickCValues',
          filter: { code: 'case_status' },
        })
          .subscribe({
            error: (error) => {
              console.error('Error caching quick codes (retrying) =>', error);
              if (error.message !== 'Forbidden' &&
                  error.message !== 'NG0205: Injector has already been destroyed.') {
                setTimeout(() => this.cacheDataForDatasource(dataSourceName), Math.round(Math.random() * 30000));
              }
            },

            next: (quickCodesJSON) => {
              const quickCode = quickCodesJSON.getModels();
              if (quickCode.length === 1) {
                this.caseStatuses = quickCode[0].quickCValues.sort((a, b) => a.displayName.localeCompare(b.displayName));
              } else {
                console.error('Multiple matching quick codes for case_status');
              }

            }
          });

        break;
      }

    }
  }


  /**
   * Find the proper source for a given model
   *
   * @param modelName
   */
  public findReportSourceForModel(modelName: string) {
    let reportSource;

    // eslint-disable-next-line guard-for-in
    for (const dsName in this.dataSources) {
      const ds = this.dataSources[dsName];
      if (ds.providesModel(modelName)) {
        reportSource = this.reportSourcesSubject.value.find(rs => rs.dataSource === dsName);
        break;
      }
      // Also check pluralized or singluarize versions of model name
      if (this.pluralizer.isSingular(modelName) && ds.providesModel(this.pluralizer.pluralize(modelName))) {
        reportSource = this.reportSourcesSubject.value.find(rs => rs.dataSource === dsName);
        modelName = this.pluralizer.pluralize(modelName);
        break;
      }
      if (this.pluralizer.isPlural(modelName) && ds.providesModel(this.pluralizer.singularize(modelName))) {
        reportSource = this.reportSourcesSubject.value.find(rs => rs.dataSource === dsName);
        modelName = this.pluralizer.singularize(modelName);
        break;
      }

    }

    return [reportSource, modelName];
  }


  /**
   * Add or replace a report
   *
   * @param dataSourceName
   * @param report
   */
  saveReport(dataSourceName: string, reportData: any) {
    return new Promise((resolve, reject) => {

      const reportDataSource = this.reportSourcesSubject.value.find(d => d.name === dataSourceName);
      let report = reportDataSource.reports.find(r => r.name === reportData.name);
      if (report) {
        // Update the report if the current user owns the report or has an authorized role
        // for now that a role is developer (ROLE_WEBAPP - ADMINS)
        if (this.authentication.currentUserId === report.owner ||
          this.authentication.currentUserHasAnyRole([
            'ROLE_REPORT - ADMINS',
            'ROLE_WEBAPP - ADMINS',
          ])) {

          report.setParamsFromJSON(reportData);
          report.save().subscribe({
            next: (savedReport) => {
              resolve(savedReport);
            },
            error: (error) => {
              console.error('Could not save', error);
              reject(error);
            }
          });

        } else {
          reject(`Not authorized to update report ${report.name}`);
        }

      } else {
        report = this.sharedDataService.createRecord(Report, reportData);
        // Ensure the new report is owned by the current user
        report.owner = this.authentication.currentUserId;

        report.save().subscribe({
          next: (savedReport) => {
            reportDataSource.reports.push(report);
            reportDataSource.reports.sort((a, b) => a.name.localeCompare(b.name));
            resolve(savedReport);
          },
          error: (error) => {
            console.error('Could not save', error);
            reject(error);
          }
        });

      }

    });

  }

  /**
   * Run a report
   *
   * @param report
   * @param params
   */
  runReport(report: Report, params: string) {
    // Cancel a running report if a new report is requested
    this.cancelReport();

    return new Promise((resolve, reject) => {

      if (report && !report.hasDirtyAttributes) {
        // Report must already exist in order to be executed

        const endpoint = `${environment.jsonApiUrl}/reports/1.0/report/${report.id}?${params}`;

        // Invoke the URL directly so we can receive and process the resulting JSON data
        this.reportSubscription = this.http.get(endpoint, {
          headers: { accept: 'application/json' }
        })
          .pipe(retry(2))
          .subscribe({
            next: response => resolve(response),
            error: error => reject(error)
          });

      } else {
        reject('Save report before running it');
      }

    });

  }

  /**
   * Run a report by id
   * The previous runReport is the recommended method to use to ensure
   * you're attempting to run a valid report.  This is version just allow
   * quick access to specific reports
   *
   * @param reportId
   * @param params
   */
  runReportById(reportId: number, params: string) {
    // Cancel a running report if a new report is requested
    this.cancelReport();

    return new Promise((resolve, reject) => {

      const endpoint = `${environment.jsonApiUrl}/reports/1.0/report/${reportId}?${params}`;

      // Invoke the URL directly so we can receive and process the resulting JSON data
      this.reportSubscription = this.http.get(endpoint, {
        headers: { accept: 'application/json' }
      })
        .pipe(retry(2))
        .subscribe({
          next: response => resolve(response),
          error: error => reject(error)
        });

    });

  }

  cancelReport() {
    this.reportSubscription?.unsubscribe();
  }

  /**
   * Schedule a report
   *
   * @param report
   */
  scheduleReport(report: Report, schedulerInfo: SchedulerInfo, params: string) {
    return new Promise((resolve, reject) => {

      if (report && !report.hasDirtyAttributes) {
        // Report must already exist in order to be scheduled

        const endpoint = `${environment.jsonApiUrl}/reports/1.0/report/${report.id}${params ? `?${params}` : ''}`;

        this.http
          .post(endpoint, schedulerInfo)
          .subscribe({
            next: response => resolve(response),
            error: error => reject(error)
          });

      } else {
        reject('Save report before scheduling it');
      }

    });

  }


  /**
   * Remove a report
   *
   * @param dataSourceName
   * @param report
   */
  removeReport(dataSourceName: string, report: Report) {
    const reportDataSource = this.reportSourcesSubject.value.find(d => d.name === dataSourceName);
    const existingReportIndex = reportDataSource.reports.findIndex(r => r.name === report.name);
    if (existingReportIndex > -1) {
      // Remove the report if the current user owns the report or has an authorized role
      // for now that a role that doesn't even exist  TODO
      if (this.authentication.currentUserId === reportDataSource.reports[existingReportIndex].owner ||
        this.authentication.currentUserHasRole('ROLE_REPORT - ADMINS')) {
        reportDataSource.reports.splice(existingReportIndex, 1);

        // Remove any persistent settings for this report
        this.persistentSettings.clearSetting(`report-${report.id}-column-order`);

      } else {
        this.snackBar.open(`Not authorized to remove report ${report.name}`, 'OK', {duration: 3000});
      }
    }
  }

  createReport(reportData: any) {
    return this.sharedDataService.createRecord(Report, reportData);
  }

  /**
   * Find all employees that match the employeeName string
   *
   * @param employeeName
   */
  findEmployee(employeeName: string) {
    // Note: employeeName of "-10" means "All", so don't search for matching employees then
    if (employeeName && typeof employeeName === 'string' && employeeName !== '-10') {
      this.sharedDataService.findAll(Employee, {
        filter: {
          legal_name: { LIKE: `%${employeeName?.trim()?.replace(',', '%')}%` },
          emp_type: 'WD',
          active_status: 'Active',
        },
        sort: 'legal_name',
      }).subscribe(result => this.searchEmployeesSubject.next(result.getModels()));
    }
  }

  buildModelInspectionFromParams(params: string): ModelInspection[] {
    const paramArray = params?.split(',')?.map(aParam => aParam.split(':'));
    // To allow alternative column names, you can add a title value after a '#' delimiter
    // such as "name#title"
    // However, if the name includes a '#' such as "Invoice #" it will think the name
    // is "Invoice ".  The following regex delimiter will not split on the '#' if it
    // is preceeded by a space.
    // Unfortuntely, Safari can't handle RexExp look behind assertions so we dumb it down
    // for Safari, also need to construct it with new RegExp so it doesn't choke on it.
    const delim = this.getBrowserName() === 'safari' ? '#' : new RegExp('(?<! )#');
    const m = paramArray?.map(([pname, ptype]) => {
      const [dbName, display] = pname.split(delim);
      const name = dbName.slice(1); // remove starting '@'
      const [ftype, requiredCondition] = ptype.split('*');
      const [type, singleSelection] = ftype.split('!');
      return {
        name,
        display,
        type,
        dbName,
        required: requiredCondition !== undefined ? true : false,
        requiredCondition,
        singleSelection: singleSelection  !== undefined ? true : false,
      };
    });

    return m;

  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(window as any).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

}

