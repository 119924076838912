/********************************************************************************
 * CareerProgressionService (a JsonApiDatastore)
 *
 * Implemenation of a JsonApiDatastore which is part of the angular2-jsonapi package
 * see https://www.npmjs.com/package/angular2-jsonapi
 * This defines the connection to the CareerProgression API and what models the
 * API provides access to.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonApiDatastoreConfig, JsonApiDatastore, DatastoreConfig } from '@michalkotas/angular2-jsonapi';

import { environment } from '../../environments/environment';
import {
  EligibleAttribute,
  EligibleAttributeValue,
  CPDistrict,
  Supgroup,
  EmployeeCertification,
  Job,
  ProcessRun,
  Recognition,
  RunResultDetail,
  RunResult,
  WorkdayEmployee,
} from '../models/careerprogression';
import { PersistentSettingsService } from './persistent-settings.service';
import { SchedulerInfo } from '../../public-api';

const referencedModels = {};

const config: DatastoreConfig = {
  baseUrl: `${environment.jsonApiUrl}/careerprogression/1.0`,
  models: {
    districts: CPDistrict,
    eligibleAttributeValues: EligibleAttributeValue,
    eligibleAttributes: EligibleAttribute,
    employeeCertifications: EmployeeCertification,
    workdayEmployees: WorkdayEmployee,
    jobs: Job,
    processRuns: ProcessRun,
    recognitions: Recognition,
    runResultDetails: RunResultDetail,
    runResults: RunResult,
    supgroups: Supgroup,

    // Add any additional models that the Career Progression service models
    // reference here (most likely from the SharedData DB)
    ...referencedModels
  }
};

@Injectable({ providedIn: 'root' })
@JsonApiDatastoreConfig(config)
export class CareerProgressionService extends JsonApiDatastore {

  constructor(
    http: HttpClient,
    private persistentSettings: PersistentSettingsService,
  ) {
    super(http);
  }

  get pageSize(): number {
    return Number(this.persistentSettings.getSetting('CareerProgression-page-size')) || 5;
  }
  set pageSize(size: number) {
    // Save new page size preference if it's one of the valid selections (not a temporary
    // value used for select all actions)
    if ([5, 10, 25, 100, 250, 500, 1000].includes(size)) {
      this.persistentSettings.setSetting('CareerProgression-page-size', size.toString());
    }
  }

  /**
   * Get the saved page size for a specific component (based on prefix)
   * @param prefix
   */
  savedPageSize(prefix: string): number {
    return Number(this.persistentSettings.getSetting(`${prefix}${prefix ? '-' : ''}page-size`)) || this.pageSize;
  }


  providesModel(modelName: string) {
    // eslint-disable-next-line security/detect-object-injection
    return (config.models[modelName] && !referencedModels[modelName]);
  }

  /**
   * Schedule the runDailyProcess that will check all the employees that meet
   * career progression requirements
   *
   */
  scheduleDailyRun(schedulerInfo: SchedulerInfo) {
    return new Promise((resolve, reject) => {

      const endpoint = `${environment.jsonApiUrl}/careerprogression/1.0/scheduleDailyRun`;

      this.http
        .post(endpoint, schedulerInfo, { headers: { accept: 'application/text' }, responseType: 'text' } )
        .subscribe({
          next: response => resolve(response),
          error: error => reject(error)
        });

    });

  }

  /**
   * Schedule the EligibleReport that shows employees that
   * meet career progression requirements
   *
   */
  scheduleEligibleReport(schedulerInfo: SchedulerInfo) {
    return new Promise((resolve, reject) => {

      const endpoint = `${environment.jsonApiUrl}/careerprogression/1.0/scheduleEligibleReport`;

      this.http
        .post(endpoint, schedulerInfo, { headers: { accept: 'application/text' }, responseType: 'text' } )
        .subscribe({
          next: response => resolve(response),
          error: error => reject(error)
        });

    });

  }

  /**
   * Schedule the Result Report that shows employees that
   * meet career progression requirements
   *
   */
  scheduleResultReport(schedulerInfo: SchedulerInfo) {
    return new Promise((resolve, reject) => {

      const endpoint = `${environment.jsonApiUrl}/careerprogression/1.0/scheduleResultReport`;

      this.http
        .post(endpoint, schedulerInfo, { headers: { accept: 'application/text' }, responseType: 'text' } )
        .subscribe({
          next: response => resolve(response),
          error: error => reject(error)
        });

    });

  }


}
