/********************************************************************************
 * HtmlToTextPipe
 *
 * Angular pipe to strip out HTML formatting
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Pipe, PipeTransform } from '@angular/core';

const descriptRegex = new RegExp('(\\<script(.+?)\\</script\\>)|(\\<style(.+?)\\</style\\>)', 'g');

@Pipe({ name: 'htmlToText' })
export class HtmlToTextPipe implements PipeTransform {

  transform(value: string): string {
    // Inline styles and scripts will remain as text content, so
    // First remove any inline scripts
    const descriptedValue = value.replace(descriptRegex, '');

    // Then let the browser cleanup the rest
    const tmp = document.createElement('DIV');
    tmp.innerHTML = descriptedValue; // nosemgrep: javascript.browser.security.insecure-document-method.insecure-document-method
    return (tmp.textContent || tmp.innerText || '').replace(/\s+/g, ' ')?.trim();
  }
}
