<div class="here-map bg-usic-blue layout layout-column layout-align-center-center flex">

  <div id="map-controls">
    @if (!disableShowAll) {
      <button (click)="clickShowAll($event)" title="Show all markers">Show All</button>
    }
    <!-- support custom buttons to be added -->
    <ng-content></ng-content>
  </div>

  <div #map class="mapContainerStyle"></div>

  <div id="tooltip" display="none" style="position: absolute; display: none;"></div>
</div>
