/* eslint-disable max-len */
/********************************************************************************
 * TicketProService (a JsonApiDatastore)
 *
 * Implemenation of a JsonApiDatastore which is part of the angular2-jsonapi package
 * see https://www.npmjs.com/package/angular2-jsonapi
 * This defines the connection to the TicketPro API and what models the
 * API provides access to.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { JsonApiDatastoreConfig, JsonApiDatastore, DatastoreConfig } from '@michalkotas/angular2-jsonapi';
import { map, Observable, retry, Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import { HereMapMarkerData } from '../components/here-map/here-map-marker-data';
import {
  Activity,
  AffectedAttachment,
  AffectedTicket,
  AttachmentCategorized,
  Bucket,
  ClosedTicket,
  DigArea,
  DistrictBoundary,
  DueDateExtension,
  EmployeeScore,
  Feed,
  Locate,
  LocateClosedSnapshot,
  LocateOpenSnapshot,
  Note,
  OpenTicket,
  PhotoCompliance,
  ProjectTicket,
  ResponseLog,
  StatusCode,
  Term,
  TermUtilityMap,
  Ticket,
  TicketInfo,
  TkpAttachment,
  TkpAttachmentsWithNotification,
  TkpCustomer,
  TkpDistrict,
  Transmission,
  TransmissionBase,
  TransmissionExtra,
  TransmissionText,
  Usr,
  Utility,
  Visit,
  VisitLocation,
} from '../models/ticketpro';
import { TransmissionDetail } from '../models/ticketpro/transmission-detail';
import { UserAuth } from '../models/user';
import { AuthenticationService } from './authentication.service';
import { PersistentSettingsService } from './persistent-settings.service';
import { MAX_PAGE_LIMIT } from '../datasources/jsonapi.datasource';
import { CustomerPortalService } from './customer-portal.service';
import { ServiceStop } from '../models/ticketpro/service-stop';


const referencedModels = {};

const config: DatastoreConfig = {
  baseUrl: `${environment.jsonApiUrl}/ticketpro/1.0`,
  models: {
    activities: Activity,
    affectedAttachments: AffectedAttachment,
    affectedTickets: AffectedTicket,
    attachmentsCategorized: AttachmentCategorized,
    buckets: Bucket,
    closedTickets: ClosedTicket,
    digAreas: DigArea,
    districtBoundaries: DistrictBoundary,
    dueDateExtensions: DueDateExtension,
    employeeScores: EmployeeScore,
    feeds: Feed,
    locateClosedSnapshots: LocateClosedSnapshot,
    locateOpenSnapshots: LocateOpenSnapshot,
    locates: Locate,
    notes: Note,
    openTickets: OpenTicket,
    photoCompliances: PhotoCompliance,
    projectTickets: ProjectTicket,
    responseLogs: ResponseLog,
    statusesCode: StatusCode,
    terms: Term,
    termUtilityMaps: TermUtilityMap,
    tickets: Ticket,
    ticketsInfo: TicketInfo,
    tkpAttachment: TkpAttachment,
    tkpAttachmentsWithNotification: TkpAttachmentsWithNotification,
    tkpCustomers: TkpCustomer,
    tkpDistricts: TkpDistrict,
    transmissions: Transmission,
    transmissionsBase: TransmissionBase,
    transmissionsExtras: TransmissionExtra,
    transmissionsDetail: TransmissionDetail,
    transmissionsText: TransmissionText,
    usrs: Usr,
    utilities: Utility,
    visitLocations: VisitLocation,
    visits: Visit,

    // Add any additional models that the Ticket Pro service models
    // reference here (most likely from the SharedData DB)
    ...referencedModels
  }
};

@Injectable({ providedIn: 'root' })
@JsonApiDatastoreConfig(config)
export class TicketProService extends JsonApiDatastore implements OnDestroy {
  protected subscriptions: Subscription = new Subscription();
  private radiusSubscription: Subscription;
  photoCompliances: PhotoCompliance[];

  constructor(
    http: HttpClient,
    private authentication: AuthenticationService,
    private persistentSettings: PersistentSettingsService,
    private customerPortalService: CustomerPortalService,
  ) {
    super(http);

    // reload any cached data whenever the user changes
    this.subscriptions.add(this.authentication.currentUser.subscribe(
      (currentUser: UserAuth) => {
        if (currentUser && this.authentication.currentUserIsValid) {
          this.cacheData();
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get pageSize(): number {
    return Number(this.persistentSettings.getSetting('TicktPro-page-size')) || 5;
  }
  set pageSize(size: number) {
    // Save new page size preference if it's one of the valid selections (not a temporary
    // value used for select all actions)
    if ([5, 10, 25, 100, 250, 500, 1000].includes(size)) {
      this.persistentSettings.setSetting('TicktPro-page-size', size.toString());
    }
  }

  /**
   * Get the saved page size for a specific component (based on prefix)
   * @param prefix
   */
  savedPageSize(prefix: string): number {
    return Number(this.persistentSettings.getSetting(`${prefix}${prefix ? '-' : ''}page-size`)) || this.pageSize;
  }

  providesModel(modelName: string) {
    // eslint-disable-next-line security/detect-object-injection
    return (config.models[modelName] && !referencedModels[modelName]);
  }


  // Preload some data
  // The angular2-jsonapi library will keep them cached
  cacheData() {

    this.customerPortalService.accountNumbers.subscribe((accountNumbers: string) => {
      if (accountNumbers) {

        this.findAll(PhotoCompliance, {
          filter: { customer: accountNumbers },
          page: { limit: MAX_PAGE_LIMIT }
        }).subscribe({
          next: (statusJson => {
            this.photoCompliances = statusJson.getModels();
          }),
          error: (error) => {
            console.error('Error caching photo compliances (retrying) =>', error);
            if (error.message !== 'Forbidden' &&
                error.message !== 'NG0205: Injector has already been destroyed.'
            ) {
              setTimeout(() => this.cacheData(), Math.round(Math.random() * 30000));
            }
          }
        });

      }
    });
  }

  /**
   * Get tickets in a radius
   *
   * @param reportId
   * @param params Object {lat: number, lon: number, radius: number, state: string[], accounts?: string, interval?: string = '7 days'}
   */
  loadTicketsInRadius(parameters: { lat: number; lon: number; radius: number; state: string[]; accounts?: string; interval?: string }) {
    // if a previous request is in progress, cancel it before starting a new one
    this.cancelLoadTicketsInRadius();

    return new Promise<HereMapMarkerData[]>((resolve, reject) => {

      const params = new URLSearchParams(Object(parameters)).toString();
      const endpoint = `${environment.jsonApiUrl}/ticketpro/1.0/ticketInRadius?${params}`;

      // Invoke the URL directly so we can receive and process the resulting JSON data
      this.radiusSubscription = this.http.get(endpoint, {
        headers: { accept: 'application/json' }
      })
        .pipe(retry(2))
        .subscribe({
          next: (response: any) => {
            // do some data massaging
            for (const ticket of response) {
              ticket.ticketId = ticket.ticket_id;
              ticket.ticketNumber = ticket.ticket_number;
              ticket.ourDueDate = ticket.our_due_date;
              ticket.closedDate = ticket.closed_date;

              // massage the area value into JSON data
              const area = ticket.area
                .replace(/^\{/g, '[').replace(/\}$/g, ']')
                .replace(/"\{/g, '{').replace(/\}"/g, '}')
                .replace(/\\/g, '');
              ticket.area = JSON.parse(area);
            }

            resolve(response);
          },
          error: error => reject(error)
        });

    });

  }

  cancelLoadTicketsInRadius() {
    this.radiusSubscription?.unsubscribe();
  }

  /**
   * Get a list of ticket ids for a given address
   */
  getTicketIdsByAddress(streetForm: any): Observable<any> {
    const endpoint = `${config.baseUrl}/ticketsByAddress`;

    return this.http.post(endpoint, streetForm).pipe(retry(2));
  }

  /**
   * Get a list of locate ids for a given address
   */
  getLocateIdsByAddress(streetForm: any): Observable<any> {
    const endpoint = `${config.baseUrl}/locatesByAddress`;

    return this.http.post(endpoint, streetForm).pipe(retry(2));
  }


  // Load districts from a JSON file for initial prototyping
  loadDistrictBoundaries(): Observable<any> {
    const endpoint = 'assets/districtBoundaries.json';

    return this.http.get(endpoint)
      .pipe(retry(2))
      .pipe(map(res => {
        if (Array.isArray(res)) {
          const districtBoundaries: DistrictBoundary[] = [];
          res.forEach((district: any) => {
            districtBoundaries.push({
              districtId: district.district_id,
              districtName: district.district_name,
              color: `rgba(${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)}, 0.8)`,
              contactInfo: JSON.parse(district.contact_info),
              boundaries: district.boundaries,
            } as DistrictBoundary);
          });
          return districtBoundaries;
        } else {
          return res;
        }
      }));

  }


  /**
   * Get service stops (trip data) for a ticket
   *
   * @param params Object {ticketId: number, visitsId?: number[]}
   */
  getServiceStops(parameters: { ticketId: number; visitsId?: number[] }): Promise<ServiceStop[]> {

    return new Promise<ServiceStop[]>((resolve, reject) => {

      const params = new URLSearchParams(Object(parameters)).toString();
      const endpoint = `${environment.jsonApiUrl}/ticketpro/1.0/serviceStops?${params}`;

      // Invoke the URL directly so we can receive and process the resulting JSON data
      this.http.get(endpoint, {
        headers: { accept: 'application/json' }
      })
        .pipe(retry(2))
        .subscribe({
          next: (response: any) => {
            const serviceStops: ServiceStop[] = [];

            // convert the response JSON into ServiceStop objects
            for (const aStop of response) {
              const serviceStop = new ServiceStop(aStop);
              // only include stops with GPS data
              if (serviceStop.tripGps?.length && serviceStop.tripDistance > 0) {
                serviceStops.push(serviceStop as ServiceStop);
              }
            }

            resolve(serviceStops);
          },
          error: error => reject(error)
        });

    });

  }



}
