import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { Token } from './token';


/*****************************************
 * WsUser
 *****************************************/
@JsonApiModelConfig({
  type: 'wsUsers',
  // modelEndpointUrl: 'users',
})
export class WsUser extends JsonApiModel {

  @Attribute({ serializedName: 'user_name', converter })
    userName: string;

  @Attribute({ serializedName: 'password', converter })
    password: string;

  @Attribute({ serializedName: 'new_password', converter })
    newPassword: string;

  @Attribute({ serializedName: 'entity_name', converter })
    entityName: string;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  @BelongsTo()
    token: Token;

}
