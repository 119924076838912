import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { CustomerContract } from './customer-contract';


/*****************************************
 * CustomerAccount
 *****************************************/
@JsonApiModelConfig({
  type: 'customerAccounts',
})
export class CustomerAccount extends JsonApiModel {

  get accountId() {
    return Number(this.id);
  }

  @Attribute({ serializedName: 'account_number', converter })
    accountNumber: string;

  @Attribute({ serializedName: 'parent_account_number', converter })
    parentAccountNumber: string;

  @Attribute({ serializedName: 'account_name', converter })
    accountName: string;

  @Attribute({ serializedName: 'alt_name', converter })
    altName: string;

  @Attribute({ serializedName: 'item_status', converter })
    itemStatus: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    customerContracts: CustomerContract;

  // circular dependency
  // @HasMany()
  // customerAccountNotes: CustomerAccountNote[];
}
