import {
  JsonApiModelConfig,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { Employee } from './employee';

/*****************************************
 * UserRequest
 *****************************************/
@JsonApiModelConfig({
  type: 'userRequests'
})
export class UserRequest extends Employee {

  @Attribute({ serializedName: 'session_id', converter })
    sessionId: string;

  @Attribute({ serializedName: 'user_id', converter })
    userId: number;

  @Attribute({ serializedName: 'request_user', converter })
    requestUser: string;

  @Attribute({ serializedName: 'request_method', converter })
    requestMethod: string;

  @Attribute({ serializedName: 'request_url', converter })
    requestUrl: string;

  @Attribute({ serializedName: 'request_query_string', converter })
    requestQueryString: string;

  @Attribute({ serializedName: 'request_x_real_ip', converter })
    requestXRealIp: string;

  @Attribute({ serializedName: 'request_user_agent', converter })
    requestUserAgent: string;

  @Attribute({ serializedName: 'request_content_type', converter })
    requestContentType: string;

  @Attribute({ serializedName: 'request_header_crnk_compact', converter })
    requestHeaderCrnkCompact: boolean;

  @Attribute({ serializedName: 'browser_name', converter })
    browserName: string;

  @Attribute({ serializedName: 'major_version', converter })
    majorVersion: string;

  @Attribute({ serializedName: 'user_platform', converter })
    userPlatform: string;

  @Attribute({ serializedName: 'request_time_ms', converter })
    requestTimeMs: number;

  @Attribute({ serializedName: 'response_status', converter })
    responseStatus: number;

  @Attribute({ serializedName: 'response_content_type', converter })
    responseContentType: string;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ converter })
    attribute4: string;

  @Attribute({ converter })
    attribute5: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  // Data that will be added by the UI so it request can be displayed on a map
  lat: number;
  lon: number;
  get lng() {
    return this.lon;
  }
  address: string;

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
      attribute4: this.attribute4,
      attribute5: this.attribute5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
    this.attribute2 = v.attribute2;
    this.attribute3 = v.attribute3;
    this.attribute4 = v.attribute4;
    this.attribute5 = v.attribute5;
  }

}
