import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { AttachmentEntity } from './attachment-entity';
import { StorageVolume } from './storage-volume';

/*****************************************
 * Attachment
 *****************************************/
@JsonApiModelConfig({
  type: 'attachment',
  modelEndpointUrl: 'attachments'
})
export class Attachment extends JsonApiModel {

  @Attribute({ serializedName: 'volume_id', converter })
    volumeId: number;

  @BelongsTo()
    storageVolume: StorageVolume;

  @Attribute({ converter })
    priv: boolean;

  @Attribute({ converter })
    path: string;

  @Attribute({ converter })
    filename: string;

  @Attribute({ converter })
    filetype: string;

  @Attribute({ converter })
    filesize: string;

  @Attribute({ serializedName: 'attachment_entity_id', converter })
    attachmentEntityId: number;

  @BelongsTo()
    attachmentEntity: AttachmentEntity;

  @Attribute({ serializedName: 'pk1_int', converter })
    pk1Int: number;

  @Attribute({ serializedName: 'pk2_int', converter })
    pk2Int: number;

  @Attribute({ serializedName: 'pk3_int', converter })
    pk3Int: number;

  @Attribute({ serializedName: 'pk1_var', converter })
    pk1Var: string;

  @Attribute({ serializedName: 'pk2_var', converter })
    pk2Var: string;

  @Attribute({ serializedName: 'pk3_var', converter })
    pk3Var: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

}
