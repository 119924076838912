/********************************************************************************
 * CreatedLastUpdatedComponent (lib-created-last-updated)
 *
 * Angular component to show the createdBy and lastUpdateBy information for a record
 *
 * parameters
 *   newRecord    : Boolean to indicate if this is a new record or not
 *                  this essentially toggles whether or not to show this component
 *   dataModel    : Data model that has the createdBy and lastUpdatedOn information
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'lib-created-last-updated',
  templateUrl: './created-last-updated.component.html',
  styleUrls: ['./created-last-updated.component.scss'],
  imports: [DatePipe]
})
export class CreatedLastUpdatedComponent implements OnInit {
  @Input() newRecord = false;
  @Input({ required: true }) dataModel: any;

  constructor() { }

  ngOnInit(): void {
  }

}
