import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { FinancialPeriod } from './financial-period';
import { CmsRequest } from './cms-request';
import { LegalCase } from './legal-case';

/*****************************************
 * LegalRisk
 *****************************************/
@JsonApiModelConfig({
  type: 'legalRisks',
})
export class LegalRisk extends JsonApiModel {

  @Attribute({ serializedName: 'legal_risk_id', converter })
    legalPartyTypeId: number;

  @Attribute({ serializedName: 'version_number', converter })
    versionNumber: number;

  @Attribute({ serializedName: 'case_id', converter })
    caseId: number;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'lawsuit_demand_amount', converter })
    lawsuitDemandAmount: number;

  @Attribute({ serializedName: 'estimate', converter })
    estimate: boolean;

  @Attribute({ serializedName: 'accrual_amount', converter })
    accrualAmount: number;

  @Attribute({ serializedName: 'accrual_flag', converter })
    accrualFlag: boolean;

  @Attribute({ serializedName: 'period_id', converter })
    periodId: number;

  @Attribute({ serializedName: 'request_id', converter })
    requestId: number;

  @Attribute({ serializedName: 'liability_age', converter })
    liabilityAge: number;

  @Attribute({ serializedName: 'settlement_reserve', converter })
    settlementReserve: number;

  @Attribute({ serializedName: 'expense_reserve', converter })
    expenseReserve: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'expense_accrual_balance', converter })
    expenseAccrualBalance: number;

  @Attribute({ serializedName: 'settlement_accrual_balance', converter })
    settlementAccrualBalance: number;

  @BelongsTo()
    legalCase: LegalCase;

  @BelongsTo()
    financialPeriod: FinancialPeriod;

  @BelongsTo()
    cmsRequest: CmsRequest;

}
