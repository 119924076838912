<div class="main layout layout-column layout-align-start-stretch layout-fill" >

    <h1>
        Impersonator
    </h1>

    <p class="margin-top margin-bottom">
        This function allows an administrator to become a different user in order to see what they see (test access
        controls)
    </p>

    <form class="layout layout-column layout-align-start-stretch flex" #impersonatorForm="ngForm"
        (ngSubmit)="submit(impersonatorForm.form)">

        @if (errors()) {
            <!-- Errors -->
            <ul class="errors">
                @for (error of errors(); track $index) {
                    <li class="text-usic-red">{{error}}</li>
                }
            </ul>
        }

        <ng-container>

            <!-- Impersonate email -->
            <fieldset>
                <legend>Whom Shall We Impersonate?</legend>

                <mat-form-field appearance="fill" class="layout layout-fill flex">
                    <mat-label> User login to become</mat-label>
                    <input matInput type="text" name="login" placeholder="Login" ngModel>
                </mat-form-field> <!-- username -->

            </fieldset>

        </ng-container>

      <div>

        <button mat-flat-button color="accent" type="submit" [disabled]="!impersonatorForm.valid || saving">
          <mat-icon>face</mat-icon> Impersonate
        </button>

      </div>

    </form>

</div>

