import {
  JsonApiModelConfig,
} from '@michalkotas/angular2-jsonapi';
import { Locate } from './locate';


/*****************************************
 * LocateClosedSnapshot
 *****************************************/
@JsonApiModelConfig({
  type: 'locateClosedSnapshots'
})
export class LocateClosedSnapshot extends Locate {
}

