import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Application } from './application';
import { Attachment } from './attachment';

/*****************************************
 * AttachmentEntity
 *****************************************/
@JsonApiModelConfig({
  type: 'attachmentEntities'
})
export class AttachmentEntity extends JsonApiModel {

  @Attribute({ serializedName: 'application_id', converter })
    applicationId: number;

  @BelongsTo()
    application: Application;

  @Attribute({ serializedName: 'attachment_entity_name', converter })
    attachmentEntityName: string;

  @Attribute({ serializedName: 'entity_table', converter })
    entityTable: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    attachments: Attachment[];

}
