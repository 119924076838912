<div mat-dialog-title class="dialog-title padding">

  <h2>Table Usage Hints</h2>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>

</div>

<mat-dialog-content class="flex padding">

  <ul>

    <li>
      <b>Table can be sorted by column.</b> Mouse over the right side of a column header until an up arrow (<span
        class="ansi-character">&uarr;</span>) appears. Click on the arrow to sort the table by that column toggling between ascending, descending, and
      no sort order.
    </li>

    @if (data.filtering === 'column') {
    <li class="padding-top">
      <b>Table can be filtered by column.</b> To filter the table by a specific column value,
      enter a filter value in the box at the top of the column. Partial values are supported and matching values will be highlighted.<br />
      Multiple filter values can be specified by separating them with a vertical bar (|).<br />
      Exact values can be specified by preceding the filter value with an equals sign (=).<br />
      Filter values can be excluded by preceding them with an exclaimation point (!). In other words, anything not
      maching the filter value.
    </li>
    } @else if (data.filtering === 'global') {

      <li class="padding-top">
      <b>Table can be filtered.</b>  Any value entered into the "Search Table" field will be applied to filter the entire table.
    </li>
    }

    <li class="padding-top">
      <b>Columns can be resized.</b> Move your mouse over the column divider in the column header until your cursor
      changes to a resize icon
      (<span class="ansi-character larger">&#8697;</span>), then click and drag the column to your prefered size. Your
      preferences will be saved and restored the next time you visit the same page.
    </li>

    <li class="padding-top">
      <b>Columns can be re-ordered.</b> Using the <mat-icon matPrefix>view_week</mat-icon> Columns button, click and
      drag the column to your prefered position.
      Your preferences will be saved and restored the next time you visit the same page.
    </li>

    <li class="padding-top">
      <b>Columns can be toggled.</b> Using the <mat-icon matPrefix>view_week</mat-icon> Columns button, click on the
      column name to toggle the column on (<mat-icon class="material-symbols-outlined">visibility</mat-icon>)
      or off (<mat-icon class="material-symbols-outlined">visibility_off</mat-icon>).
      Your preferences will be saved and restored the next time you visit the same page.
    </li>

    <li class="padding-top">
      <b>Cell values can be copied to the clipboard.</b> Right-click on the cell and it's value will be copied to the
      clipboard.
    </li>

  </ul>


</mat-dialog-content>

<mat-dialog-actions>

  <button mat-button (click)="onNoClick()">Close</button>

</mat-dialog-actions>
