import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import {
  EligibleAttributeValue,
  Recognition,
  WorkdayEmployee
} from '.';


/*****************************************
 * Job
 *****************************************/
@JsonApiModelConfig({
  type: 'jobs'
})
export class Job extends JsonApiModel {

  @Attribute({ converter })
    title: string;

  @Attribute({ converter })
    seq: number;

  @Attribute({ serializedName: 'eff_start_date', converter: dateConverter })
    effStartDate: Date;

  @Attribute({ serializedName: 'eff_end_date', converter: dateConverter })
    effEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    eligibleAttributeValues: EligibleAttributeValue[];

  @HasMany()
    recognitions: Recognition[];

  @HasMany()
    workdayEmployees: WorkdayEmployee[];
}
