import {
  JsonApiModelConfig,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { Employee } from './employee';

/*****************************************
 * UserEmployeeAccess
 *****************************************/
@JsonApiModelConfig({
  type: 'userEmployeeAccesses'
})
export class UserEmployeeAccess extends Employee {

  @Attribute({ serializedName: 'accessing_emp_id ', converter })
    accessingEmpId: number;
}
