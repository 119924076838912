import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * DistrictBoundary
 *****************************************/
@JsonApiModelConfig({
  type: 'districtBoundaries',
})
export class DistrictBoundary extends JsonApiModel {

  get districtId() {
    return +this.id;  // (+) converts string 'id' to a number
  }

  @Attribute({ serializedName: 'distric_name', converter })
    districtName: string;

  @Attribute({ serializedName: 'color', converter })
    color$: string;

  get color() {
    return this.color$ ||
      `rgba(${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)}, 0.8)`;
  }
  @Attribute({ serializedName: 'contact_info', converter })
    contactInfo$: string;

  @Attribute({ serializedName: 'boundaries', converter })
    boundaries: string;  // GeoJSON string

  /**
   * Return the contactInfo as a JSON object rather than a raw string.
   * Also sort the contacts so that Area Managers are listed before
   * Associate Managers, and then multiple associates are alphabetical
   */
  get contactInfo() {
    const ordering = {}; // map for efficient lookup of sortIndex
    const sortOrder = ['Area Manager', 'Associate Area Manager'];
    // eslint-disable-next-line security/detect-object-injection
    for (let i = 0; i < sortOrder.length; i++) { ordering[sortOrder[i]] = i; }

    try {
      return JSON.parse(this.contactInfo$)?.sort( function(a, b) {
        return (ordering[a.role] - ordering[b.role]) || a.email.localeCompare(b.email);
      });
    } catch {
      return this.contactInfo$;
    }
  }

}
