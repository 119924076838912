import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { Feed } from './feed';


/*****************************************
 * Term
 *****************************************/
@JsonApiModelConfig({
  type: 'terms',
})
export class Term extends JsonApiModel {

  @Attribute({ converter })
    code: string;

  @Attribute({ serializedName: 'feed_id', converter })
    feedId: number;

  @Attribute({ converter })
    description: string;

  @Attribute({ converter })
    parse: boolean;

  @Attribute({ serializedName: 'charge_once', converter })
    chargeOnce: boolean;

  @Attribute({ serializedName: 'respond_code', converter })
    respondCode: string;

  @Attribute({ serializedName: 'do_not_respond', converter })
    doNotRespond: boolean;

  @Attribute({ serializedName: 'read_from_master_code', converter })
    readFromMasterCode: boolean;

  @Attribute({ serializedName: 'read_from_members', converter })
    readFromMembers: boolean;

  @Attribute({ converter })
    inactive: boolean;

  @Attribute({ serializedName: 'create_audit_item', converter })
    createAuditItem: boolean;

  @Attribute({ serializedName: 'do_not_close', converter })
    doNotClose: boolean;

  @Attribute({ serializedName: 'unlocatable_term_id', converter })
    unlocatableTermId: number;

  @Attribute({ serializedName: 'unlocatable_status_id', converter })
    unlocatableStatusId: number;

  @Attribute({ serializedName: 'add_text_only', converter })
    add_text_only: boolean;

  @BelongsTo()
    feed: Feed;
}
