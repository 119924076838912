import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Asset } from '.';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';

/*****************************************
 * RepairReason
 *****************************************/
@JsonApiModelConfig({
  type: 'repairReasons'
})
export class RepairReason extends JsonApiModel {

  @Attribute({ converter })
    description: string;

  @Attribute({ converter })
    name: string;

  @Attribute({ converter })
    notes: string;

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    assets: Asset[];

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

}
