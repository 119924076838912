/* eslint-disable security/detect-object-injection */
/* eslint-disable no-underscore-dangle */
/********************************************************************************
 * ChartBarComponent (lib-chart-bar)
 *
 * Angular component to draw a bar chart using chart.js
 * Highlights current bar on mouse hovers
 *
 * parameters
 * INPUT
 *   name         : String for naming this chart
 *   labels       : String[] contains the labels for each pie slice
 *   data         : Number[] contains the data for each pie slice
 *
 * OUTPUT
 *   clickedBar   : String for the name of a clicked bar
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component, AfterViewInit, EventEmitter, Input, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartTypeRegistry,
  Filler,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';

import { ColorSchemeService } from '../../../services/color-scheme.service';
import { NgTemplateOutlet, NgStyle } from '@angular/common';

// USIC colors -  green, blue, orange
// Blood Hound colors - yellow, red
// Reconn - aqua
// On Target - dark green, light grey, medium green
const backgroundColor = ['#94c83d', '#00305B', '#F25C0F', '#ffcd03', '#5a1400', '#137289', '#0d472d', '#a7a9ac', '#0b7544'];
const hoverBackgroundColor = backgroundColor.map(color => shadeHexColor(color, 0.4));

/**
 * shadeHexColor from https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
 * @param color
 * @param percent  (positive will lighten, negative will darken)
 * @returns string
 */
function shadeHexColor(color, percent) {

  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;

  // eslint-disable-next-line no-bitwise
  const R = f >> 16;
  // eslint-disable-next-line no-bitwise
  const G = f >> 8 & 0x00FF;
  // eslint-disable-next-line no-bitwise
  const B = f & 0x0000FF;

  return '#' + (0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B))
    .toString(16).slice(1);
}



@Component({
  selector: 'lib-chart-bar',
  templateUrl: '../chart.component.html',
  styleUrl: '../chart.component.scss',
  imports: [NgTemplateOutlet, NgStyle]
})
export class ChartBarComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() name: string;
  @Input() labels: Observable<string[]>;
  @Input() data: Observable<number[]>;
  @Input() selected: string;
  @Input() height: string;

  @Output() clickedBar = new EventEmitter<string>();
  @Output() chartCreated = new EventEmitter<boolean>();

  chart: Chart;
  labels$: string[];
  data$: number[];

  constructor(
    public colorScheme: ColorSchemeService,
  ) {

    Chart.register(
      CategoryScale,
      LinearScale,
      // For Bar Chart
      BarController,
      BarElement,
      Legend,
      Tooltip,
      Filler,
    );

  }

  ngAfterViewInit() {

    this.labels.subscribe((labels) => {
      this.labels$ = labels;
      if (this.data$?.length) {
        this.chart = this.createBarChart(this.name, this.labels$, this.data$);
      }
    });

    this.data.subscribe((data) => {
      this.data$ = data;
      this.chart = this.createBarChart(this.name, this.labels$, this.data$);
    });

  }

  ngOnDestroy() {
    if (this.chart && this.chart instanceof Chart) {
      this.chart.reset();
      this.chart.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selected) {

      const chart = this.chart || Chart.getChart(this.name);
      if (chart && chart instanceof Chart) {

        // Select the new bar and deselect the old one
        if (changes.selected.currentValue) {
          this.labels$?.forEach((label, index) => {
            // const barElement = chart.getDatasetMeta(0).data[index] as BarElement;
            if (label.toUpperCase() === changes.selected.currentValue.toUpperCase()) {
              chart.setActiveElements([{ datasetIndex: 0, index }]);
              chart.getDatasetMeta(0)['_dataset']['backgroundColor'][index] = backgroundColor[index % backgroundColor.length];
            } else if (label.toUpperCase() === changes.selected.previousValue.toUpperCase()) {
              chart.getDatasetMeta(0)['_dataset']['backgroundColor'][index] = '#c0c0c0';
            }
          });
          chart.update();
        }

      }

    }

  }


  /**
   * Create a new, standardized chart
   *
   * @param name the new chart's name
   * @param labels data labels
   * @param data chart's dataset (singular)
   *
   * Returns a new bar chart
   */
  createBarChart(name: string, labels: string[], data: number[]) {

    // Clear out any old chart which results in showing the loading indicator
    let chart = this.chart || Chart.getChart(this.name);
    if (chart && chart instanceof Chart) {
      chart.destroy();
      this.chart = undefined;
    }

    if (labels?.length > 0 && data?.length > 0) {
      try {
        chart = new Chart(name, {
          type: 'bar' as keyof ChartTypeRegistry,
          data: {
            labels,
            datasets: [{
              data,
              backgroundColor: [...backgroundColor],
              hoverBackgroundColor,
              hoverBorderWidth: 5,
              borderColor: hoverBackgroundColor,
              hoverBorderColor: backgroundColor,
              fill: true,
            }]
          },
          options: {
            animation: {
              onComplete: () => {
                this.chartCreated.emit(true);
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true
              }
            },
            plugins: {
              tooltip: {
                enabled: true,
                displayColors: true,
              },
              legend: {
                display: false,
              },
            },
            onClick: (event, clickedElements) => {

              if (clickedElements?.length) {
                const value = labels[clickedElements[0].index];

                // console.log('clickedBar', value);
                this.clickedBar.emit(value);

              }

            }
          }
        });

        // Deselect all but the selected label
        if (this.selected) {
          labels?.forEach((label, index) => {
            // const barElement = chart.getDatasetMeta(0).data[index] as BarElement;
            if (label.toUpperCase() === this.selected.toUpperCase()) {
              chart.setActiveElements([{ datasetIndex: 0, index }]);
              chart.getDatasetMeta(0)['_dataset']['backgroundColor'][index] = backgroundColor[index % backgroundColor.length];
            } else {
              chart.getDatasetMeta(0)['_dataset']['backgroundColor'][index] = '#c0c0c0';
            }
          });
        }

        // Ths tilted titles seem to cause problems for clicking on the x-axis labels,
        // but it might be worth further study in the future
        //
        // // see: https://stackoverflow.com/questions/71615569/how-to-detect-click-on-chart-js-3-7-1-axis-label
        // //
        // const findLabel = (_labels, evt) => {
        //   let found = false;
        //   let res = null;

        //   _labels.forEach(l => {
        //     l.labels.forEach((label, index) => {
        //       if (evt.x > label.x && evt.x < label.x2 && evt.y > label.y && evt.y < label.y2) {
        //         res = {
        //           label: label.label,
        //           index
        //         };
        //         found = true;
        //       }
        //     });
        //   });

        //   return [found, res];
        // };

        // const getLabelHitboxes = (scales) => (Object.values(scales).map((s: LinearScale | CategoryScale | any) => ({
        //   scaleId: s.id,
        //   labels: s._labelItems.map((e, i) => ({
        //     x: e.options.translation[0] - s._labelSizes.widths[i],
        //     x2: e.options.translation[0] + s._labelSizes.widths[i] / 2,
        //     y: e.options.translation[1] - s._labelSizes.heights[i] / 2,
        //     y2: e.options.translation[1] + s._labelSizes.heights[i] / 2,
        //     label: e.label,
        //     index: i
        //   }))
        // })));

        // Chart.register({
        //   id: 'label',
        //   afterEvent: (_chart, event) => {
        //     const evt = event.event;

        //     if (evt.type !== 'click') {
        //       return;
        //     }

        //     const [found, labelInfo] = findLabel(getLabelHitboxes(chart.scales), evt);

        //     if (found) {
        //       console.log(labelInfo);
        //     }

        //   }
        // });

        return chart;
      } catch (error) {
        console.error('createBarChart', error);
      }
    }
  }

}
