import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { CPDistrict } from './district';


/*****************************************
 * Supgroup
 *****************************************/
@JsonApiModelConfig({
  type: 'supgroups'
})
export class Supgroup extends JsonApiModel {

  @Attribute({ serializedName: 'description', converter })
    supgroupCode: string;

  @BelongsTo()
    district: CPDistrict;

  @Attribute({ converter })
    districtId: number;

  @Attribute({ converter })
    region: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

}
