/********************************************************************************
 * ClipboardService
 *
 * Simple service to place a value into the clipboard
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    private snackBar: MatSnackBar,
  ) {
    // Get an instance of Angular's Renderer2
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  copy(value: string) {

    // create an temporary text input field to contain the value and select the value
    const input = this.renderer.createElement('input');
    this.renderer.setProperty(input, 'value', value);
    this.renderer.appendChild(document.body, input);
    input.focus();
    input.select();
    input.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text inside the text field to the clipboard
    document.execCommand('copy');
    this.snackBar.open(`Copied "${value}" to clipboard`, undefined, { duration: 2000 });

    // remove the temporary text input field
    this.renderer.removeChild(document.body, input);

    return value;
  }
}
