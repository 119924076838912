/********************************************************************************
 * TitleizePipe
 *
 * Angular pipe to convert camelCase or dasherized values into titlized strings
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'titleize' })
export class TitleizePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    } else {
      // Special case for SQL SP parameter names, remove @ prefix  CP-135
      if (value.startsWith('@')) {
        value = value.replace(/^@/g, '');
        // Also make variables like 'startdate' to be 'startDate' so they titleize correctly
        value = value.replace(/date$/, 'Date');
        // variables like 'casename' to be 'caseName' so they titleize correctly
        value = value.replace(/name$/, 'Name');
      }
      // first dasherize to handle camel case strings
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      value = value.replace(/([A-Z])|[\s._-](\w)/g, (match, p1, p2, offset) => {
        if (p1) { return '-' + p1.toLowerCase(); }
        if (p2) { return '-' + p2.toLowerCase(); }
      });

      // then capitalize the first letter of every word in a string
      // this also separates words by spaces if formally separated by dashes or underscores
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      value = value.replace(/^([A-Z])|[\s-_](\w)/g, (match, p1, p2, offset) => {
        if (p2) { return ' ' + p2.toUpperCase(); }
        return p1.toLowerCase();
      });

      // Capitalize the first letters of the string
      return (value?.charAt(0)?.toUpperCase() + value?.slice(1))?.trim();
    }
  }
}

