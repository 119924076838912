import { NgModule, InjectionToken } from '@angular/core';
import { NavItem } from '@usic/public-api';

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
  name: string;
  navigation: NavItem[];
}

export const APP_DI_CONFIG: AppConfig = {
  name: 'Portal',
  navigation: [
    {
      name: 'Admin',
      linkRoute: '/admin/impersonate',
      title: 'Administration',
      requiredRoles: [
        'ROLE_WEBAPP - ADMINS',
      ]
    },  ]
};

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})
export class AppConfigModule { }
