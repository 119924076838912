import { Component, OnInit } from '@angular/core';
import { LoginPageComponent } from '@usic/public-api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [LoginPageComponent]
})
export class LoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  forgotPassword(event) {
    window.location.href = 'https://usicllc.com/passwordreset';
  }

}
