import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { AssetAttribute } from './asset-attribute';
import { AssetMake } from './asset-make';
import { AssetModel } from './asset-model';
import { AssetType } from './asset-type';
import { QuickCode } from './quick-code';

/*****************************************
 * AttributeMapping
 *****************************************/
@JsonApiModelConfig({
  type: 'attributeMappings'
})
export class AttributeMapping extends JsonApiModel {

  @Attribute({ converter })
    notes: string;

  @BelongsTo()
    assetAttribute: AssetAttribute;

  @Attribute({ serializedName: 'attribute_id', converter })
    assetAttributeId: number;

  @BelongsTo()
    assetType: AssetType;

  @Attribute({ serializedName: 'asset_type_id', converter })
    assetTypeId: number;

  @BelongsTo()
    assetMake: AssetMake;

  @Attribute({ serializedName: 'make_id', converter })
    assetMakeId: number;

  @BelongsTo()
    assetModel: AssetModel;

  @Attribute({ serializedName: 'model_id', converter })
    assetModelId: number;

  @BelongsTo()
    quickCode: QuickCode;

  @Attribute({ serializedName: 'quick_code_id', converter })
    quickCodeId: number;

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

}
