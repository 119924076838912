import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { Locate } from './locate';
import { Usr } from './usr';


/*****************************************
 * Bucket
 *****************************************/
@JsonApiModelConfig({
  type: 'buckets',
})
export class Bucket extends JsonApiModel {

  @Attribute({ converter })
    name: string;

  @Attribute({ serializedName: 'user_id', converter })
    userId: number;

  @BelongsTo()
    usr: Usr;

  @Attribute({ converter })
    screening: boolean;

  @Attribute({ converter })
    translore: boolean;

  @HasMany()
    locates: Locate[];
}
