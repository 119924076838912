/********************************************************************************
 * CmsService (a JsonApiDatastore)
 *
 * Implemenation of a JsonApiDatastore which is part of the angular2-jsonapi package
 * see https://www.npmjs.com/package/angular2-jsonapi
 * This defines the connection to the CMS (DamageManagement) API and what models the
 * API provides access to.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { JsonApiDatastoreConfig, JsonApiDatastore, DatastoreConfig } from '@michalkotas/angular2-jsonapi';
import { Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import {
  Accrual,
  AccrualSummary,
  ApproverListing,
  BillableCustomer,
  CmsCustomer,
  CmsDistrict,
  CMSInvestigationDetail,
  CasesSubtype,
  CasesType,
  Claim,
  ClaimAllocationType,
  ClaimCategory,
  ClaimDetail,
  ClaimDiscount,
  ClaimPayee,
  ClaimSpendCategory,
  CmsErrorLog,
  CmsRequest,
  CmsUserDataAccess,
  Damage,
  DamageCustomer,
  DamageDetail,
  DamageListing,
  DamagePhoto,
  Estimate,
  Excavator,
  Facility,
  FinancialPeriod,
  FlatRateCustomer,
  Investigation,
  InvestigationDetail,
  InvestigationDetailLine,
  InvestigationDetailsVersion,
  InvestigationVersion,
  Journal,
  LawFirm,
  LegalCase,
  LegalDamage,
  LegalParty,
  LegalRisk,
  PayeeAddress,
  PolicyDropOffEstimate,
  QuestionnaireAnswer,
  QuestionnaireAnswersVersion,
  QuestionnaireQuestion,
  QuickC,
  QuickCValue,
  UserRoleAssignment,
  UsrRole,
  UtilitiesProfilesView,
  UtilityandFacility,
  WfNotification,
  WfNotificationsArchive
} from '../models/damagemanagement';
import {
  Country,
  Customer,
  District,
  Employee,
  State,
} from '../models/shareddata';
import { Ticket } from '../models/ticketpro';
import { UserAuth } from '../models/user';
import { AuthenticationService } from './authentication.service';
import { PersistentSettingsService } from './persistent-settings.service';

const referencedModels = {
  customers: Customer,
  districts: District,
  employees: Employee,
  states: State,
  countries: Country,
  tickets: Ticket,
};

const config: DatastoreConfig = {
  baseUrl: `${environment.jsonApiUrl}/cms/1.0`,
  models: {
    accrualSummaries:AccrualSummary,
    accruals: Accrual,
    approverListings: ApproverListing,
    billableCustomers: BillableCustomer,
    casesSubtypes: CasesSubtype,
    casesTypes: CasesType,
    claimAllocationTypes: ClaimAllocationType,
    claimCategories: ClaimCategory,
    claimDetails: ClaimDetail,
    claimDiscounts: ClaimDiscount,
    claimPayees: ClaimPayee,
    claimSpendCategories: ClaimSpendCategory,
    claims: Claim,
    cmsCustomers: CmsCustomer,
    cmsDistricts: CmsDistrict,
    cmsErrorLogs: CmsErrorLog,
    cmsInvestigationDetails: CMSInvestigationDetail,
    cmsRequests: CmsRequest,
    cmsUserDataAccesses: CmsUserDataAccess,
    damageCustomers:DamageCustomer,
    damageDetails: DamageDetail,
    damageListings: DamageListing,
    damagePhotos: DamagePhoto,
    damages: Damage,
    estimates: Estimate,
    excavator: Excavator,
    facilities: Facility,
    financialPeriods: FinancialPeriod,
    flatRateCustomers: FlatRateCustomer,
    investigationDetailLines: InvestigationDetailLine,
    investigationDetails: InvestigationDetail,
    investigationDetailsVersions: InvestigationDetailsVersion,
    investigationVersions: InvestigationVersion,
    investigations: Investigation,
    journals: Journal,
    lawFirms: LawFirm,
    legalCases: LegalCase,
    legalDamages: LegalDamage,
    legalParties:LegalParty,
    legalRisks: LegalRisk,
    payeeAddresses: PayeeAddress,
    policyDropOffEstimates: PolicyDropOffEstimate,
    questionaireAnswersVersions: QuestionnaireAnswersVersion,
    questionnaireAnswers: QuestionnaireAnswer,
    questionnaireQuestions: QuestionnaireQuestion,
    quickCode: QuickC,
    quickCodeValue: QuickCValue,
    userRoleAssignments: UserRoleAssignment,
    usrRoles: UsrRole,
    utilitiesProfiles: UtilitiesProfilesView,
    utilityandFacilities: UtilityandFacility,
    wfNotifications: WfNotification,
    wfNotificationsArchives: WfNotificationsArchive,

    // Add any additional models that the Ticket Pro service models
    // reference here (most likely from the SharedData DB)
    ...referencedModels
  }
};

@Injectable({ providedIn: 'root' })
@JsonApiDatastoreConfig(config)
export class CmsService extends JsonApiDatastore implements OnDestroy {
  protected subscriptions: Subscription = new Subscription();

  constructor(
    http: HttpClient,
    private authentication: AuthenticationService,
    private persistentSettings: PersistentSettingsService,
  ) {
    super(http);

    // reload any cached data whenever the user changes
    this.subscriptions.add(this.authentication.currentUser.subscribe(
      (currentUser: UserAuth) => {
        if (currentUser && this.authentication.currentUserIsValid) {
          this.cacheData();
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get pageSize(): number {
    return Number(this.persistentSettings.getSetting('CMS-page-size')) || 5;
  }
  set pageSize(size: number) {
    // Save new page size preference if it's one of the valid selections (not a temporary
    // value used for select all actions)
    if ([5, 10, 25, 100, 250, 500, 1000].includes(size)) {
      this.persistentSettings.setSetting('CMS-page-size', size.toString());
    }
  }

  /**
   * Get the saved page size for a specific component (based on prefix)
   * @param prefix
   */
  savedPageSize(prefix: string): number {
    return Number(this.persistentSettings.getSetting(`${prefix}${prefix ? '-' : ''}page-size`)) || this.pageSize;
  }

  providesModel(modelName: string) {
    // eslint-disable-next-line security/detect-object-injection
    return (config.models[modelName] && !referencedModels[modelName]);
  }


  // Preload some data
  // The angular2-jsonapi library will keep them cached
  cacheData() {
  }

}
