import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Damage } from './damage';
import { InvestigationDetail } from './investigation-detail';
import { Claim } from './claim';
import { LegalCase } from './legal-case';

/*****************************************
 * ClaimDetail
 *****************************************/
@JsonApiModelConfig({
  type: 'claimDetails'
})
export class ClaimDetail extends JsonApiModel {

  @Attribute({ serializedName: 'claim_id', converter })
    claimId: number;

  @Attribute({ serializedName: 'damage_id', converter })
    damageId: number;

  @Attribute({ serializedName: 'case_id', converter })
    caseId: number;

  @Attribute({ serializedName: 'payment_amount', converter })
    paymentAmount: number;

  @Attribute({ serializedName: 'facility_type_id', converter })
    facilityTypeId: number;

  @Attribute({ serializedName: 'utility_type_id', converter })
    utilityTypeId: number;

  @Attribute({ serializedName: 'facility_id', converter })
    facilityId: number;

  @Attribute({ serializedName: 'estimate_variance_reason_id', converter })
    estimateVarianceReasonId: number;

  @Attribute({ serializedName: 'retain_accrual_balance', converter })
    retainAccrualBalance: boolean;

  @Attribute({ serializedName: 'investigation_detail_id', converter })
    investigationDetailId: number;

  @Attribute({ serializedName: 'estimate_id', converter })
    estimateId: number;

  @Attribute({ serializedName: 'accrued', converter })
    accrued: boolean;

  @Attribute({ serializedName: 'accrued_amount', converter })
    accruedAmount: number;

  @Attribute({ serializedName: 'estimate_version_number', converter })
    estimateVersionNumber: number;

  @Attribute({ serializedName: 'estimate_accrued_balance', converter })
    estimateAccruedBalance: number;

  @Attribute({ serializedName: 'estimate_accrued_amount', converter })
    estimateAccruedAmount: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @BelongsTo()
    claim: Claim;

  @BelongsTo()
    legalCase: LegalCase;

  @BelongsTo()
    damage: Damage;

  @BelongsTo()
    investigationDetail: InvestigationDetail;

}
