import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany
} from '@michalkotas/angular2-jsonapi';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { QuickCValue } from './quick-c-value';

/*****************************************
 * QuickC
 *****************************************/
@JsonApiModelConfig({
  type: 'quickCode',
  modelEndpointUrl: 'quickCodes',
})
export class QuickC extends JsonApiModel {

  get codeId() {
    return Number(this.id);
  }

  @Attribute({ serializedName: 'code', converter })
    code: string;

  @Attribute({ serializedName: 'display_name', converter })
    displayName: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    endDate: Date;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.startDate,
      effectiveEndDate: this.endDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.startDate = v.effectiveStartDate;
    this.endDate = v.effectiveEndDate;
  }

  @HasMany()
    quickCValues: QuickCValue[];


}
