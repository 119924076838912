import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';

/*****************************************
 * UtilitiesProfilesView
 *****************************************/
@JsonApiModelConfig({
  type: 'utilitiesProfiles'
})
export class UtilitiesProfilesView extends JsonApiModel {

  @Attribute({ serializedName: 'facility_type', converter })
    facilityType: string;

  @Attribute({ serializedName: 'facility_type_id', converter })
    facilityTypeId: number;

  @Attribute({ serializedName: 'utility_type', converter })
    utilityType: string;

  @Attribute({ serializedName: 'utility_type_id', converter })
    utilityTypeId: number;

  @Attribute({ serializedName: 'facility', converter })
    facility: string;

  @Attribute({ serializedName: 'facility_id', converter })
    facilityId: string;

}
