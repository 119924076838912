<section class="bg-usic-white layout-row layout-align-center-center layout-fill">

  <div [ngClass]="{'flex-80' : !breakpoints.screen('xs')}">

    <div class="margin-bottom">
      <h1>{{appName}}</h1>
      <h2>{{signInMsg}}</h2>
    </div>

    <div class="margin-top layout"
         [ngClass]="{
         'layout-column' : breakpoints.screen('xs'),
         'layout-row layout-align-space-around-stretch' : !breakpoints.screen('xs') }">

      <div
        class="layout layout-column"
        [ngClass]="{ 'flex-45' : breakpoints.screen('gt-sm') }">

        @if (errorText) {
          <div class="alert alert-danger alert-dismissible" role="alert">
            {{ errorText }}
          </div>
        }

        <h3 class="my-account text-center">My Account</h3>

        <lib-login-form class="margin-top margin-bottom" method="{{method}}" [showAzure]="showAzure" (loading)="loading($event)" (errorMsg)="loginError($event)"></lib-login-form>

        <div class="help-links layout-row layout-align-center-center">
          <a class="margin-right" mat-button color="grey" (click)="clickForgotPassword($event)">Forgot Password</a>
          <a mat-button color="grey" (click)="clickHelp($event)">Need Help?</a>
        </div>

        @if (isLoading) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
      </div>

      @if (allowRegister) {

        <div class="vertical-line flex layout layout-align-center-center"
            [ngClass]="{'hide' : breakpoints.screen('xs') }">
          <span>OR</span>
        </div>

        <div class="padding-top border-top margin-top"
            [ngClass]="{'hide' : breakpoints.screen('xs') }"></div>

        <div class="layout layout-column layout-align-start-center"
          [ngClass]="{'flex-40' : !breakpoints.screen('xs') }">

          <h3 class="text-center">Register</h3>

          <p class="mat-subtitle-2 text-black-medium margin">Not registered yet? It's easy. Get Started Here.</p>

          <a mat-raised-button color="primary" (click)="clickRegister($event)">Register Now</a>
        </div>

      }

    </div>

  </div>

</section>
