import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Location } from '../assetmanager';
import { State } from '.';

/*****************************************
 * Country
 *****************************************/
@JsonApiModelConfig({
  type: 'countries'
})
export class Country extends JsonApiModel {

  @Attribute({ serializedName: 'country_code', converter })
    countryCode: string;

  @Attribute({ serializedName: 'country_name', converter })
    countryName: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    states: State[];

  @HasMany()
    locations: Location[];
}
