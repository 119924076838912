import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter,
} from '../../helpers/jsonapi.convertors';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import { Application } from './application';
import { Employee } from './employee';
import { District } from './district';


/*****************************************
 * UserDataAccess
 *****************************************/
@JsonApiModelConfig({
  type: 'userDataAccesses'
})
export class UserDataAccess extends JsonApiModel {

  @Attribute({ serializedName: 'application_id', converter })
    applicationId: number;

  @Attribute({ serializedName: 'emp_id', converter })
    empId: number;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'sup_group_code', converter })
    supGroupCode: string;

  @Attribute({ serializedName: 'all_districts', converter })
    allDistricts: boolean;

  @Attribute({ serializedName: 'all_supgroups', converter })
    allSupgroups: boolean;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ converter })
    attribute4: string;

  @Attribute({ converter })
    attribute5: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @BelongsTo()
    application: Application;

  @BelongsTo()
    employee: Employee;

  @BelongsTo({ key : 'dist' })
    district: District;


  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
      attribute4: this.attribute4,
      attribute5: this.attribute5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
    this.attribute2 = v.attribute2;
    this.attribute3 = v.attribute3;
    this.attribute4 = v.attribute5;
    this.attribute5 = v.attribute4;
  }

}
