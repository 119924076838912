<div class="sidebar bg-usic-surface-light" #sidebar
     [ngClass]="{
     'closed' : closed,
     'mouse-over' : closed && mouseOver,
      'left' : position === 'end'}"
     [ngStyle]="{ 'width' : closed ? (ignoreIcons ? '8px' : iconsWidth) : sidebarWidth | async }"
     (dblclick)="resetSidebar()"
     mwlResizable
     [enableGhostResize]="!closed"
     (resizing)="onResize($event)">

  <div class="content"
       matTooltip="Drag divider to resize, double-click to reset." matTooltipShowDelay="5000" matTooltipPosition="above">

    @for (item of nav; track item.title) {
      <div class="nav small-margin"
          [ngClass]="{'active': activeItem === item.tab}"
          (click)="navClick(item.tab)">

        <h3>{{ item.title }}</h3>
        @if (item.desc) {
          <p class="desc mat-caption"> {{ item.desc }} </p>
        }

      </div>
    }

    <div>

      @if (navItems) {
        <mat-list>

          @for (navItem of navItems; track $index) {

            @if (navItem.name === 'divider') {

              <mat-divider></mat-divider>

            } @else if (navItem.show) {

              <mat-list-item>
                <button mat-button color="primary"
                        [routerLink]="navItem.linkRoute"
                        routerLinkActive="mat-accent"
                        [routerLinkActiveOptions]="{exact: true}"
                        (click)="navItemClick(navItem)"> <!-- currentNav=navItem -->

                    <mat-icon class="margin-right material-symbols-outlined"
                              matListIcon
                              [matTooltip]="navItem.title || navItem.name">

                      {{ (navItem?.icon  || navItem?.symbol) || 'settings' }}

                    </mat-icon>

                  {{ navItem.name }}

                </button>
              </mat-list-item>

            }

          }

        </mat-list>
      }

      <ng-content></ng-content>

    </div>

  </div>

  <div class="resize-handle border"
       mwlResizeHandle
       [resizeEdges]="resizeEdges"
  ></div>

</div>

@if (showHideTrigger) {
  <button class="show-hide-trigger border mat-elevation-z1"
          [ngClass]="{ 'left' : position === 'end' }"
          type="button"
          [matTooltip]="helpText" [attr.aria-label]="helpText"
          (click)="closeSidebar()">

    <mat-icon> <ng-container [ngTemplateOutlet]="position === 'end' ? chevronLeft : chevronRight"></ng-container> </mat-icon>

    <ng-template #chevronRight> {{ closed ? chevronRightIcon : chevronLeftIcon }} </ng-template>
    <ng-template #chevronLeft> {{ closed ? chevronLeftIcon : chevronRightIcon }} </ng-template>

  </button>
}
