<div mat-dialog-title>

  <h1 class="layout layout-align-center-center flex" [ngStyle]="breakpoints.screen('xs') && {'font-size' : '20px'}">
    {{title}}
  </h1>

  <button class="title-close-button" mat-icon-button mat-dialog-close="Closed.">
    <mat-icon> close </mat-icon>
  </button>

</div>

<mat-dialog-content>
  <lib-here-map
    [driver]="driver"
    [area]="area"
    [locate]="locate"
    [photos]="photos"
    [videos]="videos"
    [album]="album"
    [locates]="locates"
    [userRequests]="userRequests"
    [(address)]="address"
    (clickMarker)="clickMarker($event)"></lib-here-map>
</mat-dialog-content>
