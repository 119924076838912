import { BelongsTo, HasMany } from '@michalkotas/angular2-jsonapi';
import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { DigArea } from './dig-area';
import { Locate } from './locate';
import { Ticket } from './ticket';
import { TransmissionBase } from './transmission-base';
import { TransmissionText } from './transmission-text';


/*****************************************
 * TransmissionDetail
 *****************************************/
@JsonApiModelConfig({
  type: 'transmissionsDetail',
})
export class TransmissionDetail extends JsonApiModel {

  @Attribute({ converter })
    sourcefilename: string;

  @Attribute({ serializedName: 'caller_name', converter })
    callerName: string;

  @Attribute({ serializedName: 'caller_phone', converter })
    callerPhone: string;

  @Attribute({ serializedName: 'caller_fax', converter })
    callerFax: string;

  @Attribute({ serializedName: 'caller_email', converter })
    callerEmail: string;

  @Attribute({ serializedName: 'excavator_address_1', converter })
    excavatorAddress1: string;

  @Attribute({ serializedName: 'excavator_city', converter })
    excavatorCity: string;

  @Attribute({ serializedName: 'work_done_for', converter })
    workDoneFor: string;

  @Attribute({ converter })
    excavator: string;

  @Attribute({ converter })
    maplocation: string;

  @Attribute({ converter })
    mapbook: string;

  @Attribute({ converter })
    description: string;

  @Attribute({ converter })
    lat: number;

  @Attribute({ converter })
    lon: number;

  @Attribute({ serializedName: 'version_number', converter })
    versionNumber: string;

  @Attribute({ serializedName: 'cross_street1', converter })
    crossStreet1: string;

  @Attribute({ serializedName: 'cross_street2', converter })
    crossStreet2: string;

  @Attribute({ converter })
    boring: boolean;

  @BelongsTo()
    transmissionText: TransmissionText;

  @HasMany()
    tickets: Ticket[];

  @HasMany()
    locates: Locate[];

  @BelongsTo()
    transmissionBase: TransmissionBase;

  @HasMany()
    digAreas: DigArea[];
}
