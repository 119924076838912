import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
  MatExpansionPanelDescription,
  MatExpansionPanelActionRow
} from '@angular/material/expansion';
import { NgClass } from '@angular/common';
import { MatIconAnchor } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatPrefix } from '@angular/material/form-field';

@Component({
  selector: 'lib-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  imports: [
    MatExpansionPanel,
    NgClass,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatIconAnchor,
    MatTooltip,
    MatIcon,
    MatPrefix,
    MatExpansionPanelDescription,
    MatExpansionPanelActionRow
  ]
})
export class ExpansionPanelComponent implements OnInit {

  @Input() panelTitle = 'panelTitle';
  @Input() expanded = true;
  @Output() expandedChange = new EventEmitter<boolean>();
  @Input() customClass;
  @Input() icon: string;
  @Input() iconColor;
  @Input() iconTooltip: string;
  @Input() includeActions = false;

  @Output() iconFunction = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  iconClicked(event) {
    event.stopPropagation();
    this.iconFunction.emit();
  }

  opened() {
    this.expandedChange.emit(true);
  }

  closed() {
    this.expandedChange.emit(false);
  }

}
