import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Damage } from './damage';

/*****************************************
 * DamageListing
 *****************************************/
@JsonApiModelConfig({
  type: 'damageListings'
})
export class DamageListing extends JsonApiModel {

  @Attribute({ serializedName: 'dl_notification_id', converter })
    notificationId: string;

  @Attribute({ serializedName: 'dl_damage_id', converter })
    damageId: number;

  @Attribute({ serializedName: 'dl_add1', converter })
    street: string;

  @Attribute({ serializedName: 'dl_city', converter })
    city: string;

  @Attribute({ serializedName: 'dl_abbrev', converter })
    state: string;

  @Attribute({ serializedName: 'dl_damage_date_time', converter: dateConverter })
    damageDateTime: Date;

  @Attribute({ serializedName: 'dl_excavator', converter })
    excavator: string;

  @Attribute({ serializedName: 'dl_customer_id', converter })
    customerId: number;

  @Attribute({ serializedName: 'dl_account_number', converter })
    accountNumber: string;

  @Attribute({ serializedName: 'dl_customer_claim_number', converter })
    customerClaimNumber: string;

  @Attribute({ serializedName: 'dl_duplicate_damage_id', converter })
    duplicateDamageId: number;

  @BelongsTo()
    damage: Damage;

  @BelongsTo()
    duplicateDamage: Damage;

}
