import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';

/*****************************************
 * AffectedTicket
 *****************************************/
@JsonApiModelConfig({
  type: 'affectedTickets'
})
export class AffectedTicket extends JsonApiModel {

  @Attribute({ serializedName: 'ticket_id', converter })
    ticketId: number;

  @Attribute({ serializedName: 'geo_hash', converter })
    geoHash: string;

  @Attribute({ serializedName: 'geo_hash_key', converter })
    geoHashKey: string;

  @Attribute({ serializedName: 'lat', converter })
    lat: number;

  @Attribute({ serializedName: 'lon', converter })
    lon: number;

  @Attribute({ serializedName: 'effective_date', converter: dateConverter })
    effective_date: Date;

  @Attribute({ serializedName: 'closed_date', converter: dateConverter })
    closed_date: Date;

}

