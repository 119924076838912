import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CamelCasePipe } from './camel-case.pipe';
import { CircularJsonPipe } from './circular-json.pipe';
import { DataFormatterPipe } from './data-formatter.pipe';
import { HighlightTextPipe } from './highlight-text.pipe';
import { HtmlToTextPipe } from './htmlToText.pipe';
import { ResolvePipe } from './resolve.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TitleizePipe } from './titleize.pipe';

@NgModule({
  imports: [
    CommonModule,
    CamelCasePipe,
    CircularJsonPipe,
    DataFormatterPipe,
    HighlightTextPipe,
    HtmlToTextPipe,
    ResolvePipe,
    SafeHtmlPipe,
    TitleizePipe
  ],
  exports: [
    CamelCasePipe,
    CircularJsonPipe,
    DataFormatterPipe,
    HighlightTextPipe,
    HtmlToTextPipe,
    ResolvePipe,
    SafeHtmlPipe,
    TitleizePipe,
  ],
})
export class PipesModule { }
