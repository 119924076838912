/********************************************************************************
 * SharedDataService (a JsonApiDatastore)
 *
 * Implemenation of a JsonApiDatastore which is part of the angular2-jsonapi package
 * see https://www.npmjs.com/package/angular2-jsonapi
 * This defines the connection to the SharedData API and what models the
 * API provides access to.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonApiDatastoreConfig, JsonApiDatastore, DatastoreConfig, JsonApiModel } from '@michalkotas/angular2-jsonapi';
import { MatSnackBar } from '@angular/material/snack-bar';


import { environment } from '../../environments/environment';
import {
  Application,
  Attachment,
  AttachmentEntity,
  Contract,
  Country,
  Customer,
  District,
  Employee,
  EmployeeActiveNoAssets,
  EmployeeOnLoan,
  EmployeesActiveMultipleItAssets,
  Report,
  ReportDataType,
  ReportGroup,
  ReportGroupSection,
  ReportSource,
  ReportsSectionOrder,
  SchedulerJob,
  SchedulerJobStatus,
  State,
  StorageVolume,
  SupervisorGroup,
  UserDataAccess,
  UserDataAccessView,
  UserEmployeeAccess,
  UserLogin,
  UserRequest,
} from '../models/shareddata';
import {
  Asset,
  AssetAssignment,
  Announcement,
  DistrictLocation,
  Location,
  LocatorCheckin,
  Vehicle,
  WirelessInventory,
} from '../models/assetmanager';
import {
  DeviceToken,
} from '../models/messaging';

import { Observable } from 'rxjs';
import { PersistentSettingsService } from './persistent-settings.service';
import { MAX_PAGE_LIMIT } from '../datasources/jsonapi.datasource';
import { retry } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { UserAuth } from '../models/user';

const referencedModels = {
  announcements: Announcement,
  assets: Asset,
  assetAssignments: AssetAssignment,
  districtLocations: DistrictLocation,
  locations: Location,
  locatorCheckins: LocatorCheckin,
  vehicles: Vehicle,
  deviceTokens: DeviceToken,
  wirelessInventories: WirelessInventory,
};

const config: DatastoreConfig = {
  baseUrl: `${environment.jsonApiUrl}/shareddata/1.0`,
  models: {
    applications: Application,
    attachment: Attachment,
    attachmentEntities: AttachmentEntity,
    contracts: Contract,
    countries: Country,
    customers: Customer,
    districts: District,
    employees: Employee,
    employeesActiveMultipleItAssets: EmployeesActiveMultipleItAssets,
    employeesNoAssets: EmployeeActiveNoAssets,
    employeesOnLoan: EmployeeOnLoan,
    reportDataTypes: ReportDataType,
    reportGroups: ReportGroup,
    reportGroupSections: ReportGroupSection,
    reportsSectionOrders: ReportsSectionOrder,
    reportSources: ReportSource,
    reports: Report,
    schedulerJobs: SchedulerJob,
    schedulerJobStatuses: SchedulerJobStatus,
    states: State,
    storageVolumes: StorageVolume,
    supervisorGroups: SupervisorGroup,
    userDataAccessView: UserDataAccessView,
    userDataAccesses: UserDataAccess,
    userEmployeeAccesses: UserEmployeeAccess,
    userLogins: UserLogin,
    userRequests: UserRequest,

    // Add any additional models that the Shared Data service models
    // reference here
    ...referencedModels
  }
};

@Injectable({ providedIn: 'root' })
@JsonApiDatastoreConfig(config)
export class SharedDataService extends JsonApiDatastore {
  private renderer: Renderer2;
  schedulerJobStatuses: SchedulerJobStatus[];

  constructor(
    http: HttpClient,
    private persistentSettings: PersistentSettingsService,
    private authentication: AuthenticationService,
    private rendererFactory: RendererFactory2,
    private snackBar: MatSnackBar,
  ) {
    super(http);
    console.clear();

    console.log('%cGreetings from USIC!', 'padding-top: 0.5em; font-size: 2em; padding-bottom: 0.5em; color: #00305B;');
    console.log(`%c\nIf you experience problems with this site, contact us.
    \u{1F4BB} https://usicllc.com/contact/
    \u{1F4DE} 317-575-7800\n`,
    'padding-top: 0.5em; padding-bottom: 0.5em; color: #00305B;');

    // Get an instance of Angular's Renderer2
    this.renderer = this.rendererFactory.createRenderer(null, null);

    this.authentication.currentUser.subscribe({
      next: (x: UserAuth) => {
        if (x && this.authentication.currentUserIsValid) {
          this.cacheData();
        }
      }
    });
  }

  get pageSize(): number {
    return Number(this.persistentSettings.getSetting('SharedData-page-size')) || 5;
  }
  set pageSize(size: number) {
    // Save new page size preference if it's one of the valid selections (not a temporary
    // value used for select all actions)
    if ([5, 10, 25, 100, 250, 500, 1000].includes(size)) {
      this.persistentSettings.setSetting('SharedData-page-size', size.toString());
    }
  }

  /**
   * Get the saved page size for a specific component (based on prefix)
   * @param prefix
   */
  savedPageSize(prefix: string): number {
    return Number(this.persistentSettings.getSetting(`${prefix}${prefix ? '-' : ''}page-size`)) || this.pageSize;
  }

  providesModel(modelName: string) {
    // eslint-disable-next-line security/detect-object-injection
    return (config.models[modelName] && !referencedModels[modelName]);
  }


  // Preload some data
  // The angular2-jsonapi library will keep them cached
  cacheData() {
    if (this.authentication.currentUserIsValid && !this.authentication.currentUserHasRole('ROLE_CP_USER_PASSWORD')) {

      this.findAll(Application, {
        page: { limit: MAX_PAGE_LIMIT }
      }).subscribe({

        complete: () => {
          this.findAll(SchedulerJobStatus, {
            page: { limit: MAX_PAGE_LIMIT }
          }).subscribe({
            next: (statusJson => {
              this.schedulerJobStatuses = statusJson.getModels();
            }),
            error: (error) => {
              console.error('Error caching scheduler job statuses (retrying) =>', error);
              if (error.message !== 'Forbidden' &&
                  error.message !== 'NG0205: Injector has already been destroyed.') {
                setTimeout(() => this.cacheData(), Math.round(Math.random() * 30000));
              }
            }
          });
        },

        error: (error) => {
          console.error('Error caching applications (retrying) =>', error);
          if (error.message !== 'Forbidden' &&
              error.message !== 'NG0205: Injector has already been destroyed.') {
            setTimeout(() => this.cacheData(), Math.round(Math.random() * 30000));
          }
        }
      });

    }
  }

  // Get the application id of the named application.
  getApplicationId(applicationName: string): number {
    const applications = this.peekAll(Application);
    const application = applications.find(a => a.name === applicationName);
    if (application) {
      return +application.id; // (+) converts string 'id' to a number
    } else {
      return 0;
    }
  }

  /**
   * Upload attachment(s) for a given entity.
   */
  uploadAttachments(attachments: FileList, entity: JsonApiModel): Observable<any> {
    const endpoint = `${config.baseUrl}/attachment`;

    const formData: FormData = new FormData();
    formData.set('entityName', entity.modelConfig.type);
    // usually, the entity.id is what we want, but assets have a compound id
    // of assetId-versionNumber, so if an assetId exists, use that, otherwise
    // use the entity.id
    formData.set('entityId', entity.assetId || entity.id);
    for (let i = 0; i < attachments.length; i++) {
      formData.append('files', attachments.item(i), attachments.item(i).name);
    }
    return this.http
      .post(endpoint, formData, { headers: { 'ngsw-bypass': 'true' } });
  }

  /**
   * Upload single file attachment for a given entity.
   */
  uploadAttachment(attachment: File, entity: JsonApiModel): Observable<any> {
    const endpoint = `${config.baseUrl}/attachment`;

    const formData: FormData = new FormData();
    formData.set('entityName', entity.modelConfig.type);
    // usually, the entity.id is what we want, but assets have a compound id
    // of assetId-versionNumber, so if an assetId exists, use that, otherwise
    // use the entity.id
    formData.set('entityId', entity.assetId || entity.id);
    formData.append('files', attachment, attachment.name);
    return this.http
      .post(endpoint, formData, { headers: { 'ngsw-bypass': 'true' } });
  }

  /**
   * Download a specific attachment.
   */
  downloadAttachment(attachmentId: string, filename: string): void {
    const endpoint = `${config.baseUrl}/attachment/${attachmentId}`;

    this.http.get(endpoint, { responseType: 'blob' as 'json' })
      .pipe(retry(2))
      .subscribe({
        next: (data: any) => {
          const dataType = data.type;
          const binaryData = [];
          binaryData.push(data);

          // create an temporary link to open the file
          const anchor = this.renderer.createElement('a');
          const binaryURL = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          this.renderer.setProperty(anchor, 'href', binaryURL);
          this.renderer.setAttribute(anchor, 'download', filename);
          this.renderer.appendChild(document.body, anchor);
          anchor.click();

          // remove the temporary link
          this.renderer.removeChild(document.body, anchor);

        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        error: (error) => {
          this.snackBar.open(`Could not download ${filename}`, 'OK', { duration: 3000 });
        }
      });
  }

  /**
   * Record that the user is using a specific application
   */
  updateUserLogin(applicationId: number) {
    const currentUser = this.authentication.currentUserValue;
    if (currentUser && this.authentication.currentUserIsValid) {

      this.findAll(UserLogin, {
        filter: {
          attribute2: currentUser.loginName,
        },
        sort: '-login_date',
        page: { limit: 1 },
      }).subscribe({
        next: response => {
          const userLogins: UserLogin[] = response.getModels();
          if (userLogins?.length === 1) {
            const userLogin = response.getModels()[0];
            userLogin.applicationId = applicationId;
            userLogin.screenHeight = screen.height;
            userLogin.screenWidth = screen.width;
            if (userLogin.hasDirtyAttributes) {
              userLogin.save().subscribe({
                error: error => console.error('Could not update UserLogin for', currentUser.loginName, error)
              });
            }
          } else {
            console.error('Could not find recent UserLogin for', currentUser.loginName);
          }
        },
        error: error => {
          console.error('Could not find recent UserLogin for', currentUser.loginName, error);
          setTimeout(() => this.updateUserLogin(applicationId), Math.round(Math.random() * 30000));
        }
      });

    }
  }

  /**
   * Add a report to a report section
   * TODO this is a temporary solution until REP-118 is completed
   */
  addReportToReportSection(
    reportSectionId: number,
    reportId: number,
    seq: number
  ): Observable<any> {
    const endpoint = `${config.baseUrl}/reportsSectionOrders`;
    const body = {
      data: {
        type: 'reportsSectionOrders',
        id: `${reportSectionId}-${reportId}`,
        attributes: {
          seq
        }
      }
    };

    return this.http
      .post(endpoint, body, { headers: {
        'content-type': 'application/vnd.api+json',
        accept: 'application/vnd.api+json',
        'ngsw-bypass': 'true' } });
  }


}
