import { Component, OnInit } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'lib-usic-accordion',
  templateUrl: './usic-accordion.component.html',
  imports: [MatAccordion]
})
export class UsicAccordionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
