import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { UsicTableColumn } from '@usic/public-api';
import { BreakpointsService } from '../../../../services/breakpoints.service';
import { UsicPageHeaderComponent } from '../../../usic-page-header/usic-page-header.component';
import { NgClass } from '@angular/common';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { TitleizePipe } from '../../../../pipes/titleize.pipe';

@Component({
  selector: 'app-cell-editor',
  templateUrl: './cell-editor.component.html',
  styleUrls: ['./cell-editor.component.scss'],
  imports: [
    UsicPageHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatButton,
    MatDialogClose,
    TitleizePipe
  ]
})
export class CellEditorComponent implements OnInit {
  cellForm: FormGroup;

  constructor(
    public breakpoints: BreakpointsService,
    @Inject(MAT_DIALOG_DATA) public data: {column: UsicTableColumn; value: string}
    // private dialogRef: MatDialogRef<any>
  ) {
  }

  ngOnInit(): void {
    this.cellForm = new FormGroup({
      cell: new FormControl(this.data.value, Validators.required)
    });
  }

}
