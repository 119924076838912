import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { CmsCustomer } from './cms-customer';

/*****************************************
 * ClaimPayee
 *****************************************/
@JsonApiModelConfig({
  type: 'claimPayees',
})
export class ClaimPayee extends JsonApiModel {

  @Attribute({ serializedName: 'customer_id', converter })
    customerId: number;

  @Attribute({ serializedName: 'first_name', converter })
    firstName: string;

  @Attribute({ serializedName: 'last_name', converter })
    lastName: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    endDate: Date;

  @BelongsTo()
    cmsCustomer: CmsCustomer;

}
