/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { Component, Input } from '@angular/core';
import { UsicDialogComponent } from '../usic-dialog.component';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatPrefix } from '@angular/material/form-field';
import { MatDialogClose } from '@angular/material/dialog';
import { TitleizePipe } from '../../../pipes/titleize.pipe';

@Component({
  selector: 'usic-dialog-buttons',
  templateUrl: './dialog-buttons.component.html',
  styles: [`
    .debug-panel {

      background-color: #fff;
      box-shadow: 1px 1px 10px rgba(0,0,0,.42);
      padding: 0 1rem 0 0;
      position : fixed; bottom : 10%; right : 5%;
      text-align: left;
      z-index : 1000;

    }
    .debug-panel .title { padding: 1rem 0 0 1rem; }
    .debug-panel li { margin-bottom : 1em; }
  `],
  imports: [MatButton, MatIcon, MatPrefix, MatDialogClose, TitleizePipe]
})
export class DialogButtonsComponent {

  private _parent: UsicDialogComponent;
  @Input({ required: true }) set parent(value: UsicDialogComponent ) {
    this._parent = value;
  }
  get parent(): UsicDialogComponent {
    return this._parent;
  }

  constructor() { }

}
