import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { VerizonWirelessInventorySfo } from './verizon-wireless-inventory-sfo';


/*****************************************
 * VerizonWirelessInventory
 *****************************************/
@JsonApiModelConfig({
  type: 'verizonWirelessInventories'
})
export class VerizonWirelessInventory extends JsonApiModel {

  // ID is MDN which is the wireless number
  get wirelessNumber() {
    return this.id;
  }

  @Attribute({ serializedName: 'cost_center', converter })
    costCenter: number;

  // AKA DistrictId
  get districtId(): number {
    return this.costCenter;
  }

  set districtId(districtId: number) {
    this.costCenter = districtId;
  }

  @Attribute({ serializedName: 'ecpd', converter })
    ecpd: number;

  @Attribute({ serializedName: 'customer_id', converter })
    customerId: number;

  @Attribute({ serializedName: 'bill_acc_num', converter })
    billAccNum: number;

  @Attribute({ serializedName: 'bill_cycle_id', converter })
    billCycleId: number;

  @Attribute({ serializedName: 'iccid', converter })
    iccid: string;

  // Verizon ICCID = AT&T SIM
  get simNumber(): string {
    return this.iccid;
  }

  set simNumber(simNumber: string) {
    this.iccid = simNumber;
  }

  @Attribute({ serializedName: 'esn', converter })
    esn: string;

  // Verizon ESN = AT&T IMEI
  get imei(): string {
    return this.esn;
  }

  set imei(imei: string) {
    this.esn = imei;
  }

  @Attribute({ serializedName: 'ip', converter })
    ip: string;

  @Attribute({ serializedName: 'pplan_id', converter })
    pplanId: number;

  @Attribute({ serializedName: 'pplan_desc', converter })
    ratePlanName: string;

  @Attribute({ serializedName: 'device_make', converter })
    deviceMake: string;

  @Attribute({ serializedName: 'device_model', converter })
    deviceModel: string;

  @Attribute({ serializedName: 'cust_mtn_stat', converter })
    custMtnStat: string;

  // Verizon cust_mtn_stat = AT&T wireless_user_status
  get wirelessUserStatus(): string {
    switch(this.custMtnStat) {
      case 'A': return 'Active';
      case 'AC': return 'Active - MTN Changed';
      case 'AR': return 'Active - Reassigned';
      case 'AT': return 'Active - MTN Transfered';
      case 'B': return 'Suspended - No Access Chg';
      case 'C': return 'Inactive - MTN Changed';
      case 'D': return 'Disconnected';
      case 'R': return 'Inactive - Reassigned';
      case 'S': return 'Suspended';
      case 'T': return 'MTN Transfer';
      case 'NF': return 'MTN Not Found';
      default: return 'Unknown';
    }
  }

  set wirelessUserStatus(status: string) {
    switch(this.custMtnStat) {
      case 'Active': this.custMtnStat = 'A';
        break;
      case 'Active - MTN Changed': this.custMtnStat = 'AC';
        break;
      case 'Active - Reassigned': this.custMtnStat = 'AR';
        break;
      case 'Active - MTN Transfered': this.custMtnStat = 'AT';
        break;
      case 'Suspended - No Access Chg': this.custMtnStat = 'B';
        break;
      case 'Inactive - MTN Changed': this.custMtnStat = 'C';
        break;
      case 'Disconnected': this.custMtnStat = 'D';
        break;
      case 'Inactive - Reassigned': this.custMtnStat = 'R';
        break;
      case 'Suspended': this.custMtnStat = 'S';
        break;
      case 'MTN Transfer': this.custMtnStat = 'T';
        break;
      case 'MTN Not Found': this.custMtnStat = 'NF';
        break;
      default: this.custMtnStat = '';
    }
  }

  @Attribute({ serializedName: 'reason_code', converter })
    reasonCode: string;

  @Attribute({ serializedName: 'activation_date', converter: dateConverter })
    wirelessUserActivationDate: Date;

  @Attribute({ serializedName: 'upgrade_elig_date', converter: dateConverter })
    upgradeEligDate: Date;

  @Attribute({ serializedName: 'last_upgrade_date', converter: dateConverter })
    lastUpgradeDate: Date;

  @Attribute({ serializedName: 'suspn_deactv_dt', converter: dateConverter })
    suspnDeactvDt: Date;

  @Attribute({ serializedName: 'mtn_stat_eff_date', converter: dateConverter })
    deviceEffectiveDate: Date;

  @Attribute({ serializedName: 'auto_reconn_date', converter: dateConverter })
    autoReconnDate: Date;

  @Attribute({ serializedName: 'ip_pool_name', converter })
    ipPoolName: string;

  @Attribute({ serializedName: 'bsns_id', converter })
    bsnsId: string;

  @Attribute({ serializedName: 'given_nme', converter })
    givenName: string;

  @Attribute({ serializedName: 'mdl_init', converter })
    mdlInit: string;

  @Attribute({ serializedName: 'family_nme', converter })
    familyName: string;

  get wirelessUserFullName(): string {
    return `${this.givenName} ${this.familyName}`;
  }

  set wirelessUserFullName(fullName: string) {
    const [givenName, familyName] = fullName.split(' ');
    this.givenNme = givenName;
    this.familyNme = familyName;
  }

  employeeId: number;

  @Attribute({ serializedName: 'ccntrct_end_dt', converter: dateConverter })
    contractEndDate: Date;

  @Attribute({ serializedName: 'pplan_cost', converter })
    pplanCost: number;

  @Attribute({ serializedName: 'min', converter })
    min: string;

  @Attribute({ serializedName: 'ccntrct_start_dt', converter: dateConverter })
    contractStartDate: Date;

  @Attribute({ serializedName: 'current_dvcid', converter })
    currentDvcid: string;

  @Attribute({ serializedName: 'sfo_code_cnt', converter })
    sfoCodeCnt: number;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @HasMany()
    verizonWirelessInventorySfos: VerizonWirelessInventorySfo[];

}
