import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { AttachmentEntity } from './attachment-entity';
import { StorageVolume } from './storage-volume';
import { UserDataAccess } from './user-data-access';

/*****************************************
 * Application
 *****************************************/
@JsonApiModelConfig({
  type: 'applications'
})
export class Application extends JsonApiModel {

  @Attribute({ converter })
    name: string;

  @Attribute({ converter })
    url: string;

  @Attribute({ serializedName: 'db_server', converter })
    dbServer: string;

  @Attribute({ serializedName: 'db_name', converter })
    dbName: string;

  @Attribute({ serializedName: 'db_user', converter })
    dbUser: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    storageVolumes: StorageVolume[];

  @HasMany()
    attachmentEntities: AttachmentEntity[];

  @HasMany()
    userDataAccesses: UserDataAccess[];

}
