import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CronOptions, CronEditorModule } from 'ngx-cron-editor';
import { AuthenticationService } from '../../services/authentication.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { UsicPageHeaderComponent } from '../usic-page-header/usic-page-header.component';
import { CronHelpMessageComponent } from '../scheduled-jobs/cron-help-message.component';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'lib-usic-cron-editor',
  templateUrl: './usic-cron-editor.component.html',
  styleUrls: ['./usic-cron-editor.component.scss'],
  imports: [
    UsicPageHeaderComponent,
    FormsModule,
    CronEditorModule,
    ReactiveFormsModule,
    CronHelpMessageComponent,
    MatButton,
    MatDialogClose]
})
export class UsicCronEditorComponent implements OnInit {

  cronForm: FormControl; // no need for cronString bc the value of this form is the cron string
  cronOptions: CronOptions = {
    defaultTime: `${new Date().getHours()}:${new Date().getMinutes()}:00`,

    hideMinutesTab: true,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: true,
    hideSpecificWeekDayTab: false,
    hideSpecificMonthWeekTab: false,

    use24HourTime: true,
    hideSeconds: true,

    cronFlavor: 'quartz' // standard or quartz
  };

  constructor(
    private authentication: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<any>) {

    this.cronOptions.hideAdvancedTab = !authentication.currentUserHasRole('ROLE_WEBAPP - ADMINS');

  }

  ngOnInit(): void {

    this.cronForm = new FormControl(this.data);

  }
}
