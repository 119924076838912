/* eslint-disable no-underscore-dangle */
import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';

import {
  converter,
  dateConverter,
} from '../../helpers/jsonapi.convertors';

import { District, SupervisorGroup, Employee } from '../shareddata';
import { AssetAssignment } from './asset-assignment';
import { AssetAttributeValue } from './asset-attribute-value';
import { AssetModel } from './asset-model';
import { AssetStatus } from './asset-status';
import { CategoryTypeMakeMapping } from './category-type-make-mapping';
import { DeletionType } from './deletion-type';
import { Location } from './location';
import { LocatorCheckin } from './locator-checkin';
import { RepairReason } from './repair-reason';

/*****************************************
 * Asset
 *****************************************/
@JsonApiModelConfig({
  type: 'assets'
})
export class Asset extends JsonApiModel {

  @Attribute({ serializedName: 'asset_id', converter })
    assetId: number;

  @Attribute({ serializedName: 'version_number', converter })
    version: number;

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ converter })
    description: string;

  @Attribute({ serializedName: 'purchase_date', converter: dateConverter })
    purchaseDate: Date;

  @Attribute({ serializedName: 'purchase_price', converter })
    purchasePrice: number;

  @Attribute({ converter })
    tax: number;

  @Attribute({ converter })
    warranty: boolean;

  @Attribute({ serializedName: 'warranty_start_date', converter: dateConverter })
    warrantyStartDate: Date;

  @Attribute({ serializedName: 'warranty_end_date', converter: dateConverter })
    warrantyEndDate: Date;

  @BelongsTo()
    deletionType: DeletionType;

  @Attribute({ serializedName: 'deletion_reason_id', converter })
    deletionReasonId: number;

  @BelongsTo()
    assetModel: AssetModel;

  @Attribute({ serializedName: 'model_id', converter })
    assetModelId: number;

  @Attribute({ serializedName: 'serial_number', converter })
    serialNumber: string;

  @Attribute({ serializedName: 'usic_tag', converter })
    usicTag: string;

  @BelongsTo()
    assetStatus: AssetStatus;

  @Attribute({ serializedName: 'status_id', converter })
    assetStatusId: number;

  @BelongsTo()
    dist: District;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ converter })
    active: boolean;

  @Attribute({ serializedName: 'source_system', converter })
    sourceSystem: string;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ converter })
    attribute4: string;

  @Attribute({ converter })
    attribute5: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ converter })
    notes: string;

  @BelongsTo()
    categoryTypeMakeMapping: CategoryTypeMakeMapping;

  @Attribute({ serializedName: 'mapping_id', converter })
    mappingId: number;

  @BelongsTo()
    location: Location;

  @Attribute({ serializedName: 'location_id', converter })
    locationId: number;

  @Attribute({ serializedName: 'in_transfer', converter })
    inTransfer: boolean;

  @BelongsTo()
    employeeAcceptedBy: Employee;

  @Attribute({ serializedName: 'accepted_by_id', converter })
    acceptedById: number;

  @BelongsTo()
    supgroup: SupervisorGroup;

  @Attribute({ serializedName: 'supgroup_code', converter })
    supgroupCode: string;

  @Attribute({ serializedName: 'book_value', converter })
    bookValue: number;

  @Attribute({ serializedName: 'organization_id', converter })
    organizationId: number;

  @Attribute({ serializedName: 'workday_id', converter })
    workdayId: string;

  @Attribute({ serializedName: 'tracking_no', converter })
    _trackingNo: string;

  get trackingNo() {
    return this._trackingNo;
  }

  set trackingNo(trackingNo: string) {
    // Remove all spaces from tracking numbers
    this._trackingNo = trackingNo ? trackingNo.replace(/ /g, '') : trackingNo;
  }

  @Attribute({ serializedName: 'police_report_number', converter })
    policeReportNumber: string;

  @HasMany()
    assetAttributeValues: AssetAttributeValue[];

  @BelongsTo()
    repairReason: RepairReason;

  @Attribute({ serializedName: 'repair_reason_id', converter })
    repairReasonId: number;

  @Attribute({ serializedName: 'loss_address', converter })
    lossAddress: string;

  @Attribute({ serializedName: 'loss_description', converter })
    lossDescription: string;

  @Attribute({ serializedName: 'loss_date', converter: dateConverter })
    lossDate: Date;

  @Attribute({ serializedName: 'original_creation_date', converter: dateConverter })
    originalCreationDate: Date;

  @Attribute({ serializedName: 'reference_id', converter })
    referenceId: string;

  @BelongsTo()
    assetAssignment: AssetAssignment;

  @HasMany()
    locatorCheckins: LocatorCheckin[];

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
      attribute4: this.attribute4,
      attribute5: this.attribute5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
    this.attribute2 = v.attribute2;
    this.attribute3 = v.attribute3;
    this.attribute4 = v.attribute5;
    this.attribute5 = v.attribute4;
  }


  /**
   * Since the assetId and version number are now returned as non-persistent
   * values, we no longer need to extract them from the id, but I'm going
   * to leave this in here commented out as an example of how to transform
   * attributes in the constructor if necessary
   */
  /*
    assetId: number;
    version: number;

    // Extend the constructor in order to extract assetId and version from id
    constructor(internalDatastore: JsonApiDatastore, data?: any) {
        super(internalDatastore, data);

        if (data.id) {
            [this.assetId, this.version] = data.id.split('-').map(n => +n);  // (+) converts string 'id' to a number
        }
    }
    */

}
