export class ServiceStop {
  tripStartAt: Date;
  tripEndAt: Date;
  tripGps: {
    lat: number;
    lon: number;
    when_recorded: Date;
  }[];
  tripEndLon: number;
  tripUid: number;
  departureAt: Date;
  tripStartLon: number;
  ticketNumber: string;
  ticketId: number;
  lpServiceStopId: number;
  tripDistance: number;
  tripEndLat: number;
  userId: number;
  tripStartLat: number;
  processedAt: Date;
  visitId: number;
  tripDuration: number;
  arrivedAt: Date;

  constructor(data: any) {
    this.tripStartAt = new Date(data.trip_start_at);
    this.tripEndAt = new Date(data.trip_end_at);
    this.tripGps = data.trip_gps ? JSON.parse(data.trip_gps) : undefined;
    this.tripEndLon = data.trip_end_lon;
    this.tripUid = data.trip_uid;
    this.departureAt = new Date(data.departured_at);
    this.tripStartLon = data.trip_start_lon;
    this.ticketNumber = data.ticket_number;
    this.ticketId = data.ticket_id;
    this.lpServiceStopId = data.lp_service_stop_id;
    this.tripDistance = data.trip_distance;
    this.tripEndLat = data.trip_end_lat;
    this.userId = data.user_id;
    this.tripStartLat = data.trip_start_lat;
    this.processedAt = new Date(data.processed_at);
    this.visitId = data.visit_id;
    this.tripDuration = data.trip_duration;
    this.arrivedAt = new Date(data.arrived_at);
  }
}
