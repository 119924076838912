/********************************************************************************
 * ErrorInterceptor (implements HttpInterceptor)
 *
 * Centralized HTTP error handling
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
// import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authentication: AuthenticationService,
    // private ngLocation: Location,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        // Extract underlying error message
        const errorMsg = err.error?.message || err.error?.error || err.message || err.statusText;


        if (err.status === HttpStatusCode.Unauthorized) {
          // auto logout if 401 response returned from api
          console.error('Unauthorized for access to', request.url);
          this.authentication.logout();
          return throwError(() => new Error(err.statusText));
          // location.reload();

        } else if (err.error.errors instanceof Array) {
          // JSON:API error, let angular2-jsonapi handle it
          for (const [index, e] of err.error.errors.entries()) {
            console.error('Error array value [', index, ']', e);

            if (e instanceof Object) {
              const message = e.title || e.detail || e.message;

              if (typeof message === 'string') {
              // remove SQL [select ...] from error message
                const select = message.indexOf('[select') || -1;
                if (select > -1) {
                  const end = message.indexOf(']', select);
                  // eslint-disable-next-line security/detect-object-injection
                  err.error.errors[index] = message.substring(0, select) + message.substring(end + 1);
                  // eslint-disable-next-line security/detect-object-injection
                  console.error('New error array value', err.error.errors[index]);
                }
              }
            }
          }
          return throwError(() => new Error(JSON.stringify(err.error.errors)));

        } else if (err.status === HttpStatusCode.Forbidden) {
          // User not permitted to access the API
          console.error('Forbidden access to', request.url);
          this.router.navigate(['/']);
          // this.ngLocation.back();

        } else if (err.status === HttpStatusCode.BadRequest &&
                   err.error?.description === 'Token is not valid anymore') {
          // User token has expired.  Response should look like:
          // {
          //    "httpCode": 400,
          //    "error": "BAD_REQUEST",
          //    "description": "Token is not valid anymore",
          //    "path": "https://jsonapi.usicllc.com/customerportal/1.0/closedTickets"
          // }
          //
          // Send a token renewal request (unless this was already a renew or login request)
          // and then retry the original request with the new token if the renewal is a success
          console.error(`[${new Date().toLocaleString()}] Expired token when accessing URL ${request.url}`);

          if (!request.url.includes('/login') && !request.url.includes('/renew')) {
            return this.authentication.renewToken().pipe(
              switchMap((success) => {
                if (success) {
                  console.log(`[${new Date().toLocaleString()}] Renewed token, retrying request  ${request.url}`);
                  request = request
                    .clone({
                      setHeaders: {
                        Authorization: `Bearer ${this.authentication.currentUserValue.token}`
                      }
                    });
                  return next.handle(request);
                } else {
                  return throwError(() => new Error(errorMsg));
                }
              })
            );
          }

        } else if (err.error instanceof Blob) {
          // Other errors
          if (err.error?.type === 'application/json') {
            err.error.text().then(JSON.parse).then((errorJson: any) => {
              console.error('Error JSON', errorJson);
            });
          } else {
            err.error.text().then((errorText: string) => {
              console.error('Error text', errorText);
            });
          }
        }

        // Log underlying error and continue
        console.error('General error', errorMsg);
        return throwError(() => new Error(errorMsg));
      }));
  }
}
