/********************************************************************************
 * TreeNode
 *
 * Logical representation of the SVGElements laid out via the TreeEditor
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

export class TreeNodeData {
  // logical characteristics
  name: string;
  type: string;
  description: string;
  data: any;

  // structural characteristics
  parent: any;
  usib: any;
  lsib: any;
  first: any;

}

export class TreeNode extends TreeNodeData {
  INITIAL_LINE_LENGTH = 38;
  INITIAL_LINE_ARROW_SIZE = 12;

  constructor(
    nodeData?: TreeNodeData,
  ) {
    super();
    Object.assign(this, nodeData);
  }

  /*
     * This is the replacement for the 'traverse' function
     * to work like other JS collections
     *
     * ex.
     * root.forEach(node => { ... });
     */
  forEach(fn) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let n = this;
    let cont = true;

    while (cont) {
      fn(n);  // invoke the function passing in the node

      if (n.first === undefined) {
        while (cont && ((n.lsib === undefined) || n === this)) {
          if ((n.parent === undefined) || (n === this)) {
            cont = false;
          } else {
            n = n.parent;
          }
        }
        n = n.lsib;
      } else {
        n = n.first;
      }

    }
  }

  /**
   * The following code would implements an iterator for the tree
   * to enable...
   *
   *   for (const node of root) { ... }
   *
   * types of processing of the entire subtree of a given this.
   */
  [Symbol.iterator](): any {
    let firstTime = true;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const root = this;
    let n = root;
    let cont = true;

    return {
      next() {
        if (firstTime && cont) {
          firstTime = false;
          return {
            value: root,
            done: false
          };
        } else {
          if (n.first === undefined) {
            while (cont && ((n.lsib === undefined) || n === root)) {
              if ((n.parent === undefined) || (n === root)) {
                cont = false;
              } else {
                n = n.parent;
              }
            }
            n = n.lsib;
          } else {
            n = n.first;
          }
          return {
            value: n,
            done: !cont
          };
        }
      }
    };
  }

  /*
     * This is the replacement for the 'find' function
     * to work like other JS collections
     *
     * ex.
     * root.find(node => { ... });
     */
  find(fn): TreeNode {
    for (const node of this) {
      if (fn(node)) {
        return node;
      }
    }
    return undefined;
  }

  //
  // Getters for graphical components of node
  //

  /**
   * Get the group that contains the node shape and connecting arrows
   */
  get svgNode(): SVGGElement {
    return document.getElementById(this.name) as unknown as SVGGElement;
  }

  updateNodeName(newName: string) {
    this.svgNode.id = newName;
    this.subtree.id = 'subtree-' + newName;
    this.name = newName;
  }

  /**
   * Get the main node shape
   */
  get svgShape(): SVGGeometryElement {
    return document.getElementById('shape-' + this.name) as unknown as SVGGeometryElement;
  }

  /**
   * Get the text node that displays the description
   */
  get svgDescription(): SVGGElement {
    return document.getElementById('title-' + this.name) as unknown as SVGTextElement;
  }

  updateNodeDescription(description: string) {
    const textNode = this.svgDescription?.childNodes[0];
    if (textNode) {
      textNode.nodeValue = description;
      this.fitShapeToText();
    }
  }

  /**
   * Get the line that points to the node's children
   */
  get svgThenLine(): SVGLineElement {
    return document.getElementById('THEN-line-' + this.name) as unknown as SVGLineElement;
  }

  /**
   * Get the label for the line pointing to the node's children
   */
  get svgThenTitle(): SVGTextElement {
    return document.getElementById('THEN-title-' + this.name) as unknown as SVGTextElement;
  }

  /**
   * Get the line that points to the node's lower sibling
   */
  get svgElseLine(): SVGLineElement {
    return document.getElementById('ELSE-line-' + this.name) as unknown as SVGLineElement;
  }

  /**
   * Get the label for the line that points to the node's lower sibling
   */
  get svgElseTitle(): SVGTextElement {
    return document.getElementById('ELSE-title-' + this.name) as unknown as SVGTextElement;
  }

  /**
   * Get the group that encapsulates this node and all it's children
   */
  get subtree(): SVGGElement {
    return document.getElementById('subtree-' + this.name) as unknown as SVGGElement;
  }

  /**
   * Set the logical (0 offset) x coordinate for this node
   */
  set x(x: number) {
    this.subtree?.setAttribute('transform', `translate(${x || 0}, ${this.y})`);

    // also ensure the parent's then line is adjusted to this node's new location
    const svgParentElementThenLine = this.parent?.svgThenLine;
    if (svgParentElementThenLine) {
      svgParentElementThenLine?.setAttribute('x2', String((x || 0) - this.INITIAL_LINE_ARROW_SIZE));
    }


  }

  /**
   * Get the logical (0 offset) x coordinate for this node
   */
  get x(): number {
    return Number(this.subtree?.getAttribute('transform')?.match(/translate\((.*),(.*)\)/)[1]) || 0;
  }

  /**
   * Get the x offset of the whole tree
   */
  get xOffset(): number {
    return document.getElementById('tree').getBoundingClientRect().x;
  }

  /**
   * Set the logical (0 offset) y coordinate for this node
   * This will also adjust an upper sibling's connector line length to continue to connect
   */
  set y(y: number) {
    // console.log('setting', this.description, 'y to', y);
    this.subtree?.setAttribute('transform', `translate(${this.x}, ${y || 0})`);

    // also ensure the upper sibling's else line is adjusted to this node's new location
    const svgUsibElementElseLine = this.usib?.svgElseLine;
    if (svgUsibElementElseLine) {
      // console.log('setting else for ', this.usib.description, 'y2 to', String(y - this.usib.y - this.INITIAL_LINE_ARROW_SIZE));
      svgUsibElementElseLine?.setAttribute('y2', String((y || 0) - this.usib.y - this.INITIAL_LINE_ARROW_SIZE));
    }

    // TODO this causes a gap in the subtree that needs to be tracked down
    //
    // also ensure the parent's else line is adjusted to this node's new location
    // const svgParentElementElseLine = this.parent?.svgElseLine;
    // if (svgParentElementElseLine) {
    //   svgParentElementElseLine?.setAttribute('y2', String(y - this.parent.y - this.INITIAL_LINE_ARROW_SIZE));
    // }

  }

  /**
   * Get the logical (0 offset) y coordinate for this node
   */
  get y(): number {
    return Number(this.subtree?.getAttribute('transform')?.match(/translate\((.*),(.*)\)/)[2]) || 0;
  }

  /**
   * Get the y offset of the whole tree
   */
  get yOffset(): number {
    return document.getElementById('tree')?.getBoundingClientRect()?.y;
  }

  /**
   * Get the logical bootom the node.
   * This is the actual bottom minus the parent's actual y plus some space for the arrow
   */
  get bottom(): number {
    return Math.ceil(this.svgNode?.getBoundingClientRect()?.bottom - this.parent?.actualY) + this.INITIAL_LINE_ARROW_SIZE;
  }

  /**
   * Get the actual x (includes browser page offset) of this node
   */
  get actualX(): number {
    return Math.ceil(this.subtree?.getBoundingClientRect()?.x);
  }

  /**
   * Get the actual y (includes browser page offset) of this node
   */
  get actualY(): number {
    return Math.ceil(this.subtree?.getBoundingClientRect()?.y);
  }

  /**
   * Get the actual bottom (includes browser page offset) of this node
   */
  get actualBottom(): number {
    return Math.ceil(this.subtree?.getBoundingClientRect()?.bottom);
  }

  /**
   * Get the width of this node and its connectors
   */
  get width(): number {
    return this.svgNode?.getBBox()?.width + this.INITIAL_LINE_ARROW_SIZE;
  }

  /**
   * Get the height of this node and its connectors
   */
  get height(): number {
    return this.svgNode?.getBBox()?.width;
  }

  /**
   * Get the width of this node's full subtree
   */
  get fullWidth(): number {
    return this.subtree?.getBBox()?.width;
  }

  /**
   * Get the height of this node's full subtree
   */
  get fullHeight(): number {
    return this.subtree?.getBBox()?.width;
  }

  /**
   * Get all this node's children and lower siblings and their children
   */
  get allChildrenAndLSibs(): SVGElement[] {
    const children = this.subtree?.childNodes;
    const allChildrenAndLSibs = [];
    this.recursivelyFindChildren(children, allChildrenAndLSibs);
    let lsib = this.lsib;
    while (lsib) {
      this.recursivelyFindChildren(lsib?.subtree?.childNodes, allChildrenAndLSibs);
      lsib = lsib?.lsib;
    }
    return allChildrenAndLSibs;
  }

  private recursivelyFindChildren(children, allChildren) {
    children?.forEach(elem => {
      if (elem.nodeType === 1) {
        allChildren.push(elem);
      }
      const elemChildren = elem.childNodes;
      this.recursivelyFindChildren(elemChildren, allChildren);
    });
  }

  /**
   * fitShapeToText resizes a node shape to fix the size of the text
   */
  fitShapeToText(): number {
    // Determine text width
    const svgElementTitle = this.svgDescription;
    if (svgElementTitle) {
      const svgWidth = Math.ceil(svgElementTitle.getBBox().width) + 20;

      // Adjust the node shape width component according to text width
      const svgElementShape = this.svgShape;
      const svgElementThenLine = this.svgThenLine;
      const svgElementThenTitle = this.svgThenTitle;

      if (svgElementShape.hasAttribute('points')) {
        if (svgElementShape.classList.contains('global-rule') || svgElementShape.classList.contains('rule')) {
          const points = `0,25 10,0 ${svgWidth - 10},0 ${svgWidth},25 ${svgWidth - 10},50 10,50`;
          svgElementShape.setAttribute('points', points);
        } else if (svgElementShape.classList.contains('adder')) {
          const points = `1,25 10,15 ${svgWidth - 10},15 ${svgWidth},25 ${svgWidth + 1},50 0,50`;
          svgElementShape.setAttribute('points', points);
        }
      } else {
        svgElementShape.setAttribute('width', String(svgWidth));
      }

      svgElementThenLine?.setAttribute('x1', String(svgWidth));
      svgElementThenLine?.setAttribute('x2', String(svgWidth + this.INITIAL_LINE_LENGTH));
      svgElementThenTitle?.setAttribute('x', String(svgWidth + 2));

      return svgWidth;
    }
  }


}
