import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Claim } from './claim';
import { ClaimSpendCategory } from './claim-spend-category';
import { ClaimAllocationType } from './claim-allocation-type';

/*****************************************
 * ClaimCategory
 *****************************************/
@JsonApiModelConfig({
  type: 'claimCategories'
})
export class ClaimCategory extends JsonApiModel {

  get categoryId() {
    return Number(this.id);
  }

  @Attribute({ serializedName: 'claim_category_name', converter })
    name: string;

  @Attribute({ serializedName: 'claim_category_type', converter })
    type: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    claims: Claim[];

  @HasMany()
    claimAllocationTypes: ClaimAllocationType[];

  @HasMany()
    claimSpendCategories: ClaimSpendCategory[];

}
