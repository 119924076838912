import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Employee } from '../shareddata/employee';
import { Asset } from './asset';

/*****************************************
 * LocatorCheckin
 *****************************************/
@JsonApiModelConfig({
  type: 'locatorCheckins'
})
export class LocatorCheckin extends JsonApiModel {

  @Attribute({ serializedName: 'emp_id', converter })
    empId: number;

  @Attribute({ serializedName: 'checkin_action', converter })
    checkinAction: string;

  @Attribute({ serializedName: 'serial_number', converter })
    serialNumber: string;

  @Attribute({ serializedName: 'usic_tag', converter })
    usicTag: string;

  @Attribute({ serializedName: 'asset_id', converter })
    assetId: number;

  @Attribute({ serializedName: 'asset_version', converter })
    assetVersion: number;

  @BelongsTo()
    asset: Asset;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'reviewed_on', converter: dateConverter })
    reviewedOn: Date;

  @Attribute({ serializedName: 'reviewed_by', converter })
    reviewedBy: string;

  @Attribute({ serializedName: 'notes', converter })
    notes: string;

  @BelongsTo()
    employee: Employee;


}
