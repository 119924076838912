import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Profile } from './profile';
import { ProfileLevel } from './profile-level';

/*****************************************
 * ProfileValue
 *****************************************/
@JsonApiModelConfig({
  type: 'profileValues'
})
export class ProfileValue extends JsonApiModel {

  @BelongsTo()
    profileLevel: ProfileLevel;

  @Attribute({ serializedName: 'profile_level_id', converter })
    profileLevelId: number;

  @BelongsTo()
    profile: Profile;

  @Attribute({ serializedName: 'profile_id', converter })
    profileId: number;

  @Attribute({ serializedName: 'value_data_type', converter })
    valueDataType: string;

  @Attribute({ converter })
    value: string;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
  }

}
