import { NgModule } from '@angular/core';

// Azure AD
import { IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation } from '@azure/msal-browser';
import { MsalModule,
  MsalService,
  MSAL_INSTANCE } from '@azure/msal-angular';
import { OAuthSettings } from '@usic/environments/environment';

let msalInstance: IPublicClientApplication | undefined;

export function MSALInstanceFactory(): IPublicClientApplication {
  msalInstance = msalInstance ?? new PublicClientApplication({
    auth: {
      clientId: OAuthSettings.appId,
      // redirectUri: OAuthSettings.redirectUri,
      redirectUri: window.location.origin + window.location.pathname,
      authority: OAuthSettings.authority,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: true,
    },
  });

  // Fix for uninitialized_public_client_application
  // see aka.ms/msaljs/browser-errors
  msalInstance.initialize();

  return msalInstance;
}

@NgModule({
  imports: [
    // Azure AD
    MsalModule,
  ],
  providers: [
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    MsalService
  ]
})
export class AzureLoginModule { }
