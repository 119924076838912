/********************************************************************************
 * BreakpointsService (a BreakpointObserver)
 *
 * Implementation of the Material CDK BreakpointObserver
 * see https://material.angular.io/cdk/layout/overview#breakpointobserver
 * This defines a set of viewport breakpoints (media queries) that allow us
 * to react to changes in viewport sizes for a responsive UI.
 *
 * author: Pam Tingiris (pamelatingiris@usicllc.com)
 ********************************************************************************/

import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreakpointsService implements OnDestroy {
  destroyed = new Subject<void>();

  mediaQueryMap = new Map([
    ['xs', Breakpoints.XSmall],
    ['sm', Breakpoints.Small],
    ['md', Breakpoints.Medium],
    ['lg', Breakpoints.Large],
    ['xl', Breakpoints.XLarge],
    ['gt-xs', '(min-width: 600px)'],
    ['gt-sm', '(min-width: 960px)'],
    ['gt-md', '(min-width: 1280px)'],
  ]);

  protected matchesSubject = new BehaviorSubject([]);
  matches = this.matchesSubject.asObservable();

  constructor(public breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        '(min-width: 600px)',
        '(min-width: 960px)',
        '(min-width: 1280px)',
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        const matchedBreakpoints = Object.entries(result.breakpoints).filter(e => e[1]);
        this.matchesSubject.next(matchedBreakpoints);
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  /***
   * Screen sizes for responsive styling
   * @param size 'xs', 'gt-xs'
   * */
  screen(size) {
    let screenSize = false;
    this.matches.subscribe(m => {

      const mq = this.mediaQueryMap.get(size);
      screenSize = m.filter(match => match[0] === mq).length === 1;

    });
    return screenSize;
  }

}
