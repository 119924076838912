import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import {
  Employee,
} from '../shareddata';

/*****************************************
 * Announcement
 *****************************************/
@JsonApiModelConfig({
  type: 'announcements'
})
export class Announcement extends JsonApiModel {

  @Attribute({ serializedName: 'announcement_text', converter })
    announcementText: string;

  @Attribute({ serializedName: 'high_priority', converter })
    highPriority: boolean;

  @BelongsTo()
    employee: Employee;

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
    this.attribute2 = v.attribute2;
    this.attribute3 = v.attribute3;
  }

}
