import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * TermUtilityMap
 *****************************************/
@JsonApiModelConfig({
  type: 'termUtilityMaps',
})
export class TermUtilityMap extends JsonApiModel {

  @Attribute({ serializedName: 'utility_id', converter })
    utilityId: number;

  // @BelongsTo()
  //   locate: Locate;
}
