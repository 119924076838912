/* eslint-disable no-underscore-dangle */
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { UsicTableComponent } from '../usic-table.component';
import { BreakpointsService } from '../../../services/breakpoints.service';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatPrefix } from '@angular/material/form-field';
import { MatButton, MatIconButton } from '@angular/material/button';
import { NgTemplateOutlet, AsyncPipe, KeyValuePipe } from '@angular/common';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ColumnsSelectorComponent } from '../columns-selector/columns-selector.component';
import { TableHelpComponent } from './table-help/table-help.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrl: './table-toolbar.component.scss',
  imports: [
    MatTooltip,
    MatMenuTrigger,
    MatIcon,
    MatPrefix,
    MatButton,
    NgTemplateOutlet,
    MatMenu,
    MatMenuItem,
    MatSlideToggle,
    MatProgressSpinner,
    MatIconButton,
    ColumnsSelectorComponent,
    AsyncPipe,
    KeyValuePipe
  ]
})
export class TableToolbarComponent {
  @Output() changeColumns = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();

  private _parent: UsicTableComponent;
  @Input({ required: true }) set parent(value: UsicTableComponent) {
    this._parent = value;
  }
  get parent(): UsicTableComponent {
    return this._parent;
  }

  readonly dialog = inject(MatDialog);

  constructor(
    public breakpoints: BreakpointsService,
  ) { }

  onChangeColumns(displayedColumns) {
    this.changeColumns.emit(displayedColumns);
  }

  showTableHelp() {
    const dialogRef = this.dialog.open(TableHelpComponent, {
      height: '75%',
      width: '75%',
      disableClose: false,
      panelClass: 'usic-dialog',
      hasBackdrop: true,
      data: {
        filtering: this.parent.filtering,
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The help dialog was closed');
    });
  }

}
