import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * CustomerContract
 *****************************************/
@JsonApiModelConfig({
  type: 'customerContracts',
})
export class CustomerContract extends JsonApiModel {

  get contractId() {
    return this.id;
  }

  @Attribute({ serializedName: 'contract_number', converter })
    contractNumber: string;

  @Attribute({ serializedName: 'account_number', converter })
    accountNumber: string;

  @Attribute({ serializedName: 'contract_name', converter })
    contractName: string;

  @Attribute({ serializedName: 'status', converter })
    status: string;

  @Attribute({ serializedName: 'item_status', converter })
    itemStatus: string;

  @Attribute({ serializedName: 'state', converter })
    state: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;
}
