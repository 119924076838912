import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * VerizonWirelessInventoryUsageSummary
 *****************************************/
@JsonApiModelConfig({
  type: 'verizonWirelessInventoryUsageSummaries'
})
export class VerizonWirelessInventoryUsageSummary extends JsonApiModel {

  @Attribute({ serializedName: 'mdn', converter })
    mdn: string;

  @Attribute({ serializedName: 'usage_date', converter: dateConverter })
    usageDate: string;

  @Attribute({ serializedName: 'acct_no', converter })
    acctNo: number;

  @Attribute({ serializedName: 'sub_acct_no', converter })
    subAcctNo: number;

  @Attribute({ serializedName: 'usage_minutes', converter })
    usageMinutes: number;

  @Attribute({ serializedName: 'usage_bytes', converter })
    usageBytes: number;

  @Attribute({ serializedName: 'usage_sms', converter })
    usageSms: number;

}
