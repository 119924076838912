import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
  BelongsTo,
  JsonApiDatastore
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { CPDistrict } from './district';
import { Job } from './job';
import { ProcessRun } from './process-run';
import { RunResultDetail } from './run-result-detail';
import { Supgroup } from './supgroup';
import { WorkdayEmployee } from './workday-employee';

/*****************************************
 * RunResult
 *****************************************/
@JsonApiModelConfig({
  type: 'runResults'
})
export class RunResult extends JsonApiModel {

  @BelongsTo()
    currentJob: Job;

  @Attribute({ serializedName: 'current_job_id', converter })
    currentJobId: number;

  @Attribute({ serializedName: 'current_job_profile', converter })
    currentJobProfile: string;

  @BelongsTo()
    nextJob: Job;

  @Attribute({ serializedName: 'next_job_id', converter })
    nextJobId: number;

  @Attribute({ serializedName: 'next_job_profile', converter })
    nextJobProfile: string;

  @BelongsTo()
    workdayEmployee: WorkdayEmployee;

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @Attribute({ serializedName: 'preferred_name', converter })
    preferredName: string;

  @Attribute({ converter })
    result: string;

  @BelongsTo()
    processRun: ProcessRun;

  @Attribute({ serializedName: 'run_id', converter })
    runId: number;

  @Attribute({ converter })
    compensation: string;

  @Attribute({ serializedName: 'continuous_service_date', converter: dateConverter })
    continuousServiceDate: Date;

  @Attribute({ serializedName: 'hire_date', converter: dateConverter })
    hireDate: Date;

  @Attribute({ serializedName: 'length_of_time_in_title', converter })
    lengthOfTimeInTitle: number;

  @BelongsTo()
    district: CPDistrict;

  // This is equivelant to the district_id
  @Attribute({ serializedName: 'cost_center_id', converter })
    costCenterId: number;

  @Attribute({ serializedName: 'cost_center_name', converter })
    costCenterName: string;

  @Attribute({ serializedName: 'district_program_type', converter })
    districtProgramType: string;

  @Attribute({ serializedName: 'manager_name', converter })
    managerName: string;

  @Attribute({ serializedName: 'manager_employee_id', converter })
    managerEmployeeId: string;

  @Attribute({ serializedName: 'district_manager_name', converter })
    districtManagerName: string;

  @Attribute({ serializedName: 'district_manager_employee_id', converter })
    districtManagerEmployeeId: string;

  @Attribute({ serializedName: 'district_manager_email', converter })
    districtManagerEmail: string;

  @BelongsTo()
    supgroup: Supgroup;

  @Attribute({ serializedName: 'supervisor_group_name', converter })
    supervisorGroupName: string;

  @Attribute({ serializedName: 'next_eligible_date', converter: dateConverter })
    nextEligibleDate: Date;

  @Attribute({ serializedName: 'eff_start_date', converter: dateConverter })
    effStartDate: Date;

  @Attribute({ serializedName: 'eff_end_date', converter: dateConverter })
    effEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    runResultDetails: RunResultDetail[];

  usicTenure: number;
  yearsInTitle: number;

  // Extend the constructor in order to compute tenures
  constructor(internalDatastore: JsonApiDatastore, data?: any) {
    super(internalDatastore, data);

    const now = Date.now();

    // Compute USIC Tenure
    const start = Date.parse(data.attributes.hireDate);
    // convert milliseconds to days  (1000 * 60 * 60 * 24) = 86400000
    // then years (days / 365.25) with 2 decminal places
    this.usicTenure = Number((Math.round((now - start) / 86400000) / 365.25).toFixed(2));

    // Convert the lengthOfTimeInTitle from days to years with 2 decimal places
    this.yearsInTitle = Number((data.attributes.lengthOfTimeInTitle / 365.25).toFixed(2));
  }
}
