/********************************************************************************
 * ImpersonatorComponent (lib-impersonator)
 *
 * Angular component to provide a form to prompt for a user to become.
 * Enforces security roles for who it allowed to impersonate someone else.
 *
 * parameters
 *   requiredRoles      : List of roles required to impersonate someone.
 *                        Defaults to 'ROLE_WEBAPP - ADMINS'
 *   navOnSuccess       : Route to navigate to on a successful impersonation.
 *   navOnUnauthorized  : Route to navigate to on a unsuccessful impersonation.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component, OnInit, Input, signal } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'lib-impersonator',
  templateUrl: './impersonator.component.html',
  styleUrls: ['./impersonator.component.scss'],
  imports: [FormsModule, MatFormField, MatLabel, MatInput, MatButton, MatIcon]
})
export class ImpersonatorComponent implements OnInit {
  @Input() requiredRoles = 'ROLE_WEBAPP - ADMINS';
  @Input() navOnSuccess = '/';
  @Input() navOnUnauthorized = '/';

  saving = false;
  errors = signal<string[]>([]);

  constructor(
    private authentication: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {

    this.authentication?.currentUser?.subscribe({
      complete: () => {
        if (!this.authentication.currentUserHasAnyRole([this.requiredRoles])) {
          console.error('Unauthorized user');
          this.snackBar.open('Unauthorized user', 'OK', { duration: 3000, panelClass: 'multi-line-snackbar' });
          this.router.navigate([this.navOnUnauthorized]);
        }
      },
      error: (error) => { console.error(error); },
    });

  }

  ngOnInit(): void {
  }

  submit(impersonateForm: FormGroup) {
    this.saving = true;

    const username = impersonateForm.value.login;
    if (username) {
      this.authentication.become(username)
        .subscribe({
          complete: () => {
            this.router.navigate([this.navOnSuccess]);
          },
          error: errorResponse => {
            const error = errorResponse.error || errorResponse.message;
            const errorMsg = `Could not become ${username} due to ${JSON.stringify(error)}`;
            console.error(errorMsg);
            this.snackBar.open(errorMsg, 'OK', { duration: 3000, panelClass: 'multi-line-snackbar' });
            this.errors.set([errorMsg]);
          }
        }).add(() => {
          this.saving = false;
        });
    } else {
      this.errors.set(['Missing login name']);
      this.saving = false;
    }

  }
}

