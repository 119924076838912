import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
  BelongsTo,
  JsonApiDatastore
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { CPDistrict } from './district';
import { EmployeeCertification } from './employee-certification';
import { Job } from './job';
import { RunResult } from './run-result';
import { Supgroup } from './supgroup';


/*****************************************
 * WorkdayEmployees
 *****************************************/
@JsonApiModelConfig({
  type: 'workdayEmployees'
})
export class WorkdayEmployee extends JsonApiModel {

  @Attribute({ serializedName: 'preferred_name', converter })
    preferredName: string;

  @BelongsTo()
    job: Job;

  @Attribute({ serializedName: 'job_profile', converter })
    jobProfile: string;

  @Attribute({ serializedName: 'continuous_service_date', converter: dateConverter })
    continuousServiceDate: Date;

  @Attribute({ serializedName: 'hire_date', converter: dateConverter })
    hireDate: Date;

  @Attribute({ serializedName: 'length_of_time_in_title', converter })
    lengthOfTimeInTitle: number;

  @BelongsTo()
    district: CPDistrict;

  // This is equivelant to the district_id
  @Attribute({ serializedName: 'cost_center_id', converter })
    costCenterId: number;

  @Attribute({ serializedName: 'cost_center_name', converter })
    costCenterName: string;

  @Attribute({ serializedName: 'district_program_type', converter })
    districtProgramType: string;

  @Attribute({ serializedName: 'manager_name', converter })
    managerName: string;

  @Attribute({ serializedName: 'manager_employee_id', converter })
    managerEmployeeId: string;

  @Attribute({ serializedName: 'district_manager_name', converter })
    districtManagerName: string;

  @Attribute({ serializedName: 'district_manager_employee_id', converter })
    districtManagerEmployeeId: string;

  @Attribute({ serializedName: 'district_manager_email', converter })
    districtManagerEmail: string;

  @BelongsTo()
    supgroup: Supgroup;

  @Attribute({ serializedName: 'supervisor_group_name', converter })
    supervisorGroupName: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @HasMany()
    employeeCertifications: EmployeeCertification[];

  @HasMany()
    runResults: RunResult[];

  usicTenure: number;

  // Extend the constructor in order to compute tenures
  constructor(internalDatastore: JsonApiDatastore, data?: any) {
    super(internalDatastore, data);

    const now = Date.now();

    // Compute USIC Tenure
    const start = Date.parse(data.attributes.hireDate);
    // convert milliseconds to days  (1000 * 60 * 60 * 24) = 86400000
    this.usicTenure = Math.round((now - start) / 86400000);

  }

}
