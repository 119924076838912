@if (!parent.printPreview && 
     (!column.icon && parent.filtering === 'column' && column.filterName !== 'none')) {

  <!-- if there is column filtering, show the column heading as inputs,
       otherwise, just show basic text names for the headers -->

  <span (click)="$event.stopPropagation()" [ngClass]="{'hide' : !parent.expandFilters}">

    @switch (column.type) {

      @case (['date', 'datetime'].includes(column.type) ? column.type : '') {
        <mat-form-field appearance="outline">
          <mat-label> {{column.title || (column.name | titleize)}} </mat-label>
          <input matInput [matDatepicker]="picker" placeholder=" {{column.title || (column.name | titleize)}} "
            [formControl]="parent.getFormControl(column.name)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      }

      @case (['boolean', 'truthy'].includes(column.type) ? column.type : '') {
        <mat-form-field appearance="outline">
          <mat-label> {{column.title || (column.name | titleize)}} </mat-label>
          <select matNativeControl placeholder="{{column.title || (column.name | titleize)}}"
            [formControl]="parent.getFormControl(column.name)">
            <option value=""></option>
            <option value="true">Yes</option>
            <option value="false">No</option>
            >
          </select>
        </mat-form-field>
      }

      @default {
        <mat-form-field appearance="outline">
          <mat-label> {{column.title || (column.name | titleize) }} </mat-label>
          <input matInput placeholder=" {{column.title || (column.name | titleize)}} "
            [formControl]="parent.getFormControl(column.name)" (keydown)="$event.stopPropagation()">
          <!-- <mat-icon matSuffix >search</mat-icon> -->
        </mat-form-field>
      }

    }

  </span>

  <span [ngClass]="{ 'hide' : parent.expandFilters }">
    {{ column.title ? column.title : (column.name | titleize) }}
  </span>

} @else {

  <!-- basicTitle -->
  <mat-form-field appearance="outline">

    <mat-label> {{ column.title ? column.title : (column.name | titleize) }} </mat-label>
    <input matInput type="text" readonly>

  </mat-form-field>
}