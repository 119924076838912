import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * SchedulerJobStatus
 *****************************************/
@JsonApiModelConfig({
  type: 'schedulerJobStatuses',
})
export class SchedulerJobStatus extends JsonApiModel {

  get statusId() {
    return +this.id; // (+) converts string 'id' to a number
  }

  @Attribute({ serializedName: 'code', converter })
    code: string;

  @Attribute({ serializedName: 'description', converter })
    description: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.startDate,
      effectiveEndDate: this.endDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.startDate = v.effectiveStartDate;
    this.endDate = v.effectiveEndDate;
  }

}
