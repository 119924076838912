import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { Asset } from './asset';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';

// The last_user value from NetMotion is prefixed with "SMPCORPORATE\"
// We'll use a converter to remove that prefix for display purposes
const lastUserConverter = {
  mask: (value: string) => {
    const prefixEnd = value.indexOf('\\') + 1;
    return prefixEnd > 0 ? value.slice(prefixEnd) : value;
  },
  unmask: (value: string) => value
};

/*****************************************
 * NetmotionDevice
 *****************************************/
@JsonApiModelConfig({
  type: 'netmotionDevices'
})
export class NetmotionDevice extends JsonApiModel {

  @Attribute({ serializedName: 'device_group', converter })
    deviceGroup: string;

  @Attribute({ serializedName: 'disconnect_reason', converter })
    disconnectReason: string;

  @Attribute({ serializedName: 'last_connect', converter: dateConverter })
    lastConnect: Date;

  @Attribute({ serializedName: 'last_user', converter: lastUserConverter })
    lastUser: string;

  @Attribute({ serializedName: 'locality_version', converter })
    localityVersion: string;

  @Attribute({ serializedName: 'mobility_version', converter })
    mobilityVersion: string;

  @Attribute({ serializedName: 'serial_number', converter })
    serialNumber: string;

  @Attribute({ serializedName: 'operating_system', converter })
    operatingSystem: string;

  @Attribute({ converter: dateConverter })
    registered: Date;

  @Attribute({ serializedName: 'last_disconnect', converter: dateConverter })
    lastDisconnect: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @BelongsTo()
    asset: Asset;

}
