@if (includeEmployees && includeEmployeeSearch) {
  <div class="employee-search border padding margin-bottom" [ngClass]="{'layout-row layout-gap' : breakpoints.screen('gt-md')}">

    <div class="form-fields employee-search"
        [ngClass]="[(breakpoints.screen('xs') || breakpoints.screen('sm')) ? 'layout layout-column' : '', breakpoints.screen('gt-sm') ? 'layout layout-gap' : '', breakpoints.screen('gt-md') ? 'flex-' + defaultFlexWidth : '']" >

      <mat-form-field class="search-by-name" appearance="fill" class="flex" >

        <mat-label> Search for Employee by Name </mat-label>

        <input class="employee-search-input" matInput type="text" placeholder="Employee name"
              [(ngModel)]="employeeSearch" #employeeSearchInput="ngModel"
              [matAutocomplete]="autoName">

        <mat-autocomplete class="employee-legal-name" autoActiveFirstOption #autoName="matAutocomplete">

          @for (employee of searchEmployees | async; track employee.empId) {
            <mat-option [ngClass]="{'text-usic-grey': indicateEmployeeStatus.includes(employee.activeStatus)}"
                        (onSelectionChange)="searchEmployee(employee)"
                        [value]="employee.legalName" [matTooltip]="employee.districtName">

              {{employee.legalName}}

              @if (indicateEmployeeStatus.includes(employee.activeStatus)) {
                <mat-icon matSuffix
                          matTooltip="This employee's status is {{employee.activeStatus}}."> info </mat-icon>
              }
            </mat-option>
          }

        </mat-autocomplete>

      </mat-form-field>

      <mat-form-field class="search-by-id" appearance="fill" class="flex">

        <mat-label> Search for Employee by ID </mat-label>

        <input class="employee-id-search-input" matInput type="text" placeholder="Employee ID"
              [(ngModel)]="employeeIdSearch" #employeeIdSearchInput="ngModel"
              [matAutocomplete]="autoId">

        <mat-autocomplete class="employee-emp-id" autoActiveFirstOption #autoId="matAutocomplete">

          @for ( employee of searchEmployees | async; track employee.empId) {
            <mat-option [ngClass]="{'text-usic-grey': indicateEmployeeStatus.includes(employee.activeStatus)}"
                        (onSelectionChange)="searchEmployee(employee)"
                        [value]="employee.empId">

              {{employee.empId}} - {{employee.legalName}}

              @if (indicateEmployeeStatus.includes(employee.activeStatus)) {
                <mat-icon matSuffix
                          matTooltip="This employee's status is {{employee.activeStatus}}."> info </mat-icon>
              }
            </mat-option>
          }

        </mat-autocomplete>

      </mat-form-field>

    </div>

  </div>
}

<div class="district-supgroup border padding margin-bottom"
     [ngClass]="{'layout-row layout-gap' : breakpoints.screen('gt-md')}" >

  <div class="form-fields"
       [ngClass]="[(breakpoints.screen('xs') || breakpoints.screen('sm')) ? 'layout layout-column' : '', breakpoints.screen('gt-sm') ? 'layout layout-gap' : '', breakpoints.screen('gt-md') ? 'flex-' + defaultFlexWidth : '']" >

    <mat-form-field class="region" appearance="fill" class="layout-fill flex">

      <mat-label>Region</mat-label>
      <select matNativeControl name="region" [disabled]="!regions || readOnly || readOnlyRegions"
        (change)="selectRegion($event)" [required]="districtRequired">

        @if (allowNoRegion) {
          <option>Select a region</option>
        }

        @if (!allowNoRegion && regions?.length > 1) {
          <option>{{OPTION_ALL_REGIONS}}</option>
        }

        @for (region of regions; track $index) {
          <option [selected]="region === regionName">{{region}}</option>
        }

      </select>

    </mat-form-field>

    <mat-form-field class="district" appearance="fill" class="layout-fill flex">

      <mat-label>District</mat-label>

      <select matNativeControl name="district" [disabled]="!districts || readOnly || readOnlyDistricts"
        (change)="selectDistrict($event)" [required]="districtRequired">

        @if (allowNoDistrict) {
          <option> {{ districts ? 'Select a district' : 'No region selected' }} </option>
        }

        @if (!allowNoDistrict && districts?.length > 1) {
          <option>{{OPTION_ALL_DISTRICTS}} </option>
        }

        @for (district of districts; track district.workdayId) {
          <option [selected]="district.workdayId == districtNo" [value]="district.workdayId">
            {{district.districtName}}
          </option>
        }

      </select>

    </mat-form-field>

    @if (includeSupgroups) {
      <mat-form-field class="supgroup" appearance="fill" class="layout-fill flex">

        <mat-label>Supgroup / Department</mat-label>

        <select matNativeControl name="supgroup"
          [disabled]="!supgroupCodes || supgroupCodes.length === 0 || readOnly || readOnlySupgroups"
          (change)="selectSupgroup($event)" [required]="supgroupRequired">

          @if (allowNoSupgroup) {
            <option> {{ supgroupCodes ? 'Select a supgroup' : 'No district selected' }} </option>
          }

          @if (!allowNoSupgroup && supgroupCodes?.length > 1) {
            <option>{{OPTION_ALL_SUPGROUPS}} </option>
          }

          @for (supgroup of supgroupCodes; track supgroup) {
            <option [selected]="supgroup === supGroupCode">{{supgroup}}</option>
          }

        </select>

      </mat-form-field>
    }

    @if (includeEmployees) {
      <mat-form-field class="employee" appearance="fill" class="layout-fill flex">

        <mat-label>Employee</mat-label>

        <select matNativeControl name="employee"
          [disabled]="!employees || employees.length === 0 || readOnly || readOnlyEmployees"
          (change)="selectEmployee($event)" [required]="employeeRequired">

          @if (allowNoEmployee) {
            <option> {{ employees ? 'Select an employee' : 'No supgroup selected' }} </option>
          }

          @for (employee of employees; track employee.empId) {
            <option [ngClass]="{'text-usic-grey': indicateEmployeeStatus.includes(employee.activeStatus)}"
                    [selected]="employee.empId == employeeId"
                    [value]="employee.empId">

              {{employee.legalName}}

            </option>
          }

        </select>

      </mat-form-field>
    }

    <!-- Can add an extra field or button here -->
    <ng-content></ng-content>

  </div>


</div>
