<lib-usic-error-panel [errors]="errors()"></lib-usic-error-panel>

<lib-usic-table
  [jsonApiDataSource]="jsonApiDataSource"
  [displayedColumns]="displayedColumns"
  [allowNew]="allowNew"
  filtering="column"
  prefix="scheduledJobs"
  [expandFilters]="true"
  (deleteRecord)="delete($event)"
  (clickCell)="onClickCell($event)"
  (clickRow)="clickRow.emit($event)"
  (dblClickRow)="dblClickRow.emit($event)"
  (createNew)="createNew.emit($event)"
  (changeColumns)="changeColumns.emit($event)"
  (cancelClicked)="cancelClicked.emit($event)"
  >

@if (isAdmin) {
  <div class="margin-right">

    <mat-slide-toggle disableRipple="true"
                      (change)="toggleShowAllJobs($event)"
                      [checked]="showAllJobs">
      <span class="mat-caption"> Show All Jobs </span>
    </mat-slide-toggle>

  </div>
}

</lib-usic-table>
