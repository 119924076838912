import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Damage } from './damage';
import { LegalCase } from './legal-case';

/*****************************************
 * LegalDamage
 *****************************************/
@JsonApiModelConfig({
  type: 'legalDamages',
})
export class LegalDamage extends JsonApiModel {

  @Attribute({ serializedName: 'damage_id', converter })
    damage_id: number;

  @Attribute({ serializedName: 'case_id', converter })
    case_id: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @BelongsTo()
    damage: Damage;

  @BelongsTo()
    legalCase: LegalCase;

}
