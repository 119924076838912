<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="layout layout-column flex">

  <mat-form-field appearance="fill" class="flex">

    <mat-label>Username</mat-label>
    <input matInput type="text" required
           formControlName="username"
           autocomplete="username"
           [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>

    <mat-icon matSuffix> perm_identity </mat-icon>

    @if (submitted && f.username.errors) {
      <mat-error>
        @if (f.username.errors.required) {
          <div>Username is required</div>
        }
      </mat-error>
    }

  </mat-form-field>

  <mat-form-field appearance="fill">

    <mat-label>Password</mat-label>
    <input matInput type="password" required
           formControlName="password"
           autocomplete="current-password"
           [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>

    <mat-icon matSuffix> lock </mat-icon>

    @if (submitted && f.password.errors) {
      <mat-error>
        @if (f.password.errors.required) {
          <div>Password is required</div>
        }
      </mat-error>
    }

  </mat-form-field>

  <div class="layout layout-align-center-center layout-gap-20px">

    <button mat-flat-button color="accent" [disabled]="isLoading">
      @if (isLoading) {
        <span class="spinner-border spinner-border-sm mr-1"></span>
      }
      Login
    </button>

    @if (showAzure) {
      <button mat-flat-button color="accent" [disabled]="isLoading" (click)="azureLogin()">
        @if (isLoading) {
          <span class="spinner-border spinner-border-sm mr-1"></span>
        }
        Microsoft Login
      </button>
    }

  </div>

</form>
