<div class="layout-column layout-align-space-between-stretch layout-fill" [ngClass]="{'offline': offline}">

  <div class="app-header">
    @if (currentUser) {
      <lib-usic-header appName="Apps"
                      [env]="env"
                      [navItems]="navItems"
                      [userActions]="userActions"
                      (help)="getHelp()">

        @if (shouldInstall) {
          <button mat-icon-button matTooltip="Install App" (click)="installPWA()">
            @if (breakpoints.screen('gt-xs')) {
              <mat-icon>install_desktop</mat-icon>
            } @else {
              <mat-icon>install_mobile</mat-icon>
            }
          </button>
        }

      </lib-usic-header>
    } @else {
      <lib-public-header></lib-public-header>
    }
  </div>

  <div class="app-body bg-usic-surface-light flex-grow">
    <router-outlet></router-outlet>
  </div>

  <div class="app-footer">
    @if (currentUser) {
      <lib-usic-footer></lib-usic-footer>
    } @else {
      <lib-public-footer></lib-public-footer>
    }
  </div>

</div>

