<div class="layout layout-column" [ngClass]="{'layout-fill' : addFlexGrow}">

  @if (!isSlider) {
    <usic-dialog-title [parent]="self"></usic-dialog-title>
  }

  @if (!dialogForm) {

    <!-- only render components that use FormGroup within a parentForm -->
    <form [formGroup]="parentForm" (ngSubmit)="onSubmit()" [ngClass]="{'flex-grow' : addFlexGrow}">
      <usic-dialog-content [parent]="self">

        <ng-container *ngTemplateOutlet="contentOutlet"></ng-container>

      </usic-dialog-content>

      @if (includeActions) {
        <mat-dialog-actions align="end">

          <usic-dialog-buttons [parent]="self">

            @for (button of additionalButtons; track $index) {

              <!-- type="button" is required to circumvent form submission -->
              <button mat-flat-button
                      type="button"
                      [color]="parentForm?.invalid ? 'warn' : 'accent'"
                      [disabled]="( childForm || dialogForm )?.pristine || ( parentForm || dialogForm )?.invalid || submitBtnDisabled"
                      (click)="button.buttonFn($event, parentForm)">

                {{button.buttonName}}

              </button>

            }

          </usic-dialog-buttons>

        </mat-dialog-actions>
      }
    </form>

  } @else {

    <ng-container *ngTemplateOutlet="contentOutlet"></ng-container>

  }

</div>

<!-- components that use ngForm render the form, including SUBMIT and CANCEL. -->
<ng-template #contentOutlet>
  <ng-content></ng-content>
</ng-template>

