@if (!newRecord) {
    <div>
        @if (dataModel.createdBy) {
            <p>Created by {{ dataModel.createdBy }} on
                {{ dataModel.createdOn | date:'yyyy-MM-dd' }}.
                @if (dataModel.lastUpdatedBy && dataModel.createdOn != dataModel.lastUpdatedOn) {
                    <span> Last updated by {{ dataModel.lastUpdatedBy }} on
                        {{ dataModel.lastUpdatedOn | date:'yyyy-MM-dd' }}</span>
                }
            </p>
        }
    </div>
}
