/********************************************************************************
 * angular2-jsonapi convertors
 *
 * In order to correctly handle data formats from the API, we need some convertors
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

export interface PropertyConverter {
  mask(value: any): any;
  unmask(value: any): any;
}

// There seems to be a bug in the angular2-jsonapi code where it
// tries to use a converter even if you didn't specify one so
// make a noop-converter
export const converter: PropertyConverter = { mask: (value: any) => value, unmask: (value: any) => value };

// Convert values that are known to be numbers
export const numberConverter: PropertyConverter = { mask: (value: any) => Number(value), unmask: (value: any) => String(value) };

// They defined this DateConverter in their code, but don't correctly identify date
// fields to use it, and they don't export it for our use
export const dateConverter: PropertyConverter = {
  mask(value: any) {
    if (typeof value === 'string') {
      return new Date(Date.parse(value));
    } else {
      return value;
    }
  },
  unmask(value: any) {
    if (value instanceof Date && !isNaN(Number(value)) ) {
      return value.toISOString();
    } else {
      return value;
    }
  }
};

// Support raw JSON data in a string
export const jsonConverter: PropertyConverter = {
  mask(value: any) {
    if (typeof value === 'string') {
      return JSON.parse(value);
    } else {
      return value;
    }
  },
  unmask(value: any) {
    return String(value);
  }
};

