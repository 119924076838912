import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { ClaimCategory } from './claim-category';

/*****************************************
 * ClaimAllocationType
 *****************************************/
@JsonApiModelConfig({
  type: 'claimAllocationTypes',
})
export class ClaimAllocationType extends JsonApiModel {

  @Attribute({ serializedName: 'claim_category_id', converter })
    claimCategoryId: number;

  @Attribute({ serializedName: 'type_allocation_name', converter })
    typeAllocationName: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'seq', converter })
    seq: number;

  @BelongsTo()
    claimCategory: ClaimCategory;

}
