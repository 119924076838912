/********************************************************************************
 * MessengerService (a JsonApiDatastore)
 *
 * Implemenation of a JsonApiDatastore which is part of the angular2-jsonapi package
 * see https://www.npmjs.com/package/angular2-jsonapi
 * This defines the connection to the Messaging API and what models the
 * API provides access to.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonApiDatastoreConfig, JsonApiDatastore, DatastoreConfig } from '@michalkotas/angular2-jsonapi';

import { environment } from '../../environments/environment';
import { PersistentSettingsService } from './persistent-settings.service';
import {
  DeviceToken,
  EmployeeMessage,
  Message,
  SmsMessage,
  SmsMessageLog,
  Status,
} from '../models/messaging';
import { Employee } from '../models/shareddata/employee';
import { BehaviorSubject, Observable } from 'rxjs';
import { MAX_PAGE_LIMIT } from '../datasources/jsonapi.datasource';
import { SchedulerInfo } from '../models/schedulable';

const referencedModels = {
  employees: Employee,
};

const config: DatastoreConfig = {
  baseUrl: `${environment.jsonApiUrl}/messaging/1.0`,
  models: {
    deviceTokens: DeviceToken,
    employeeMessages: EmployeeMessage,
    messages: Message,
    statuses: Status,
    smsMessages: SmsMessage,
    smsMessageLogs: SmsMessageLog,

    // Add any additional models that the Messenger service models
    // reference here (most likely from the SharedData DB)
    ...referencedModels
  }
};

@Injectable({ providedIn: 'root' })
@JsonApiDatastoreConfig(config)
export class MessengerService extends JsonApiDatastore {

  private loadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loaded = this.loadedSubject.asObservable();

  constructor(
    http: HttpClient,
    private persistentSettings: PersistentSettingsService,
  ) {
    super(http);

    if (http) {
      this.cacheData();
    }

  }

  get pageSize(): number {
    return Number(this.persistentSettings.getSetting('Messenger-page-size')) || 5;
  }
  set pageSize(size: number) {
    // Save new page size preference if it's one of the valid selections (not a temporary
    // value used for select all actions)
    if ([5, 10, 25, 100, 250, 500, 1000].includes(size)) {
      this.persistentSettings.setSetting('Messenger-page-size', size.toString());
    }
  }

  /**
   * Get the saved page size for a specific component (based on prefix)
   * @param prefix
   */
  savedPageSize(prefix: string): number {
    return Number(this.persistentSettings.getSetting(`${prefix}${prefix ? '-' : ''}page-size`)) || this.pageSize;
  }


  providesModel(modelName: string) {
    // eslint-disable-next-line security/detect-object-injection
    return (config.models[modelName] && !referencedModels[modelName]);
  }

  // Preload some data
  // The angular2-jsonapi library will keep them cached
  cacheData() {
    // So we load the profile values at the start of the application.
    this.findAll(Status, {
      filter: {
        effective_start_date: { le: ((new Date()).toISOString().substring(0, 10)) },
        effective_end_date: { ge: ((new Date()).toISOString().substring(0, 10)) },
      },
      page: { limit: MAX_PAGE_LIMIT }
    })
      .subscribe({
        error: (error) => {
          console.error('Error caching statuses (retrying) =>', error);
          if (error.message !== 'Forbidden' &&
              error.message !== 'NG0205: Injector has already been destroyed.') {
            setTimeout(() => this.cacheData(), Math.round(Math.random() * 30000));
          }
        },

        complete: () => {
          this.loadedSubject.next(true);
          this.loadedSubject.complete();
        }
      });
  }

  // Access the preloaded statuses and return a desired status.
  getStatus(code: string): Status {
    const statuses = this.peekAll(Status);
    const status = statuses.find(s => s.code === code);
    if (status) {
      return status;
    }
  }

  // Access the preloaded statuses and return a desired status id.
  getStatusId(code: string): number {
    return Number(this.getStatus(code)?.id || -1);
  }

  /**
   * Send a batch of messages
   * Returns an array of EmployeeMessageResponse objects
   */
  batchSend(params: {
    messageId: number;
    employeeIds: number[];
    attribute1: string;
    attribute2: string;
    attribute3: string;
    attribute4: string;
    attribute5: string;
    schedulerInfo?: SchedulerInfo;
    cronExpression?: string;
    timeZone?: string;
  }): Observable<any> {
    const endpoint = `${config.baseUrl}/batchSend`;

    return this.http
      .post(endpoint, params, { headers: { 'ngsw-bypass': 'true' } });
  }

  /**
   * Send SMS messages
   * Returns an array of EmployeeMessageResponse objects
   */
  sendSms(params: {
    messageId: number;
    employeeIds: number[];
    attribute1: string;
    attribute2: string;
    attribute3: string;
    attribute4: string;
    attribute5: string;
    schedulerInfo?: SchedulerInfo;
    cronExpression?: string;
    timeZone?: string;
  }): Observable<any> {
    const endpoint = `${config.baseUrl}/sendSms`;

    return this.http
      .post(endpoint, params, { headers: { 'ngsw-bypass': 'true' } });
  }

}
