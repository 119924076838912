/* eslint-disable no-underscore-dangle */
import { Component, Input } from '@angular/core';
import { UsicTableColumn } from '../../../models/general';
import { UsicTableComponent } from '../usic-table.component';
import { NgClass } from '@angular/common';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TitleizePipe } from '../../../pipes/titleize.pipe';

@Component({
  selector: 'normal-header',
  templateUrl: './normal-header.component.html',
  styles: [`
    mat-form-field { width: 100% }
  `],
  imports: [
    NgClass,
    MatFormField,
    MatLabel,
    MatInput,
    MatDatepickerInput,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    TitleizePipe
  ]
})
export class NormalHeaderComponent {

  @Input({ required: true }) column: UsicTableColumn;

  private _parent: UsicTableComponent;
  @Input({ required: true }) set parent(value: UsicTableComponent) {
    this._parent = value;
  }
  get parent(): UsicTableComponent {
    return this._parent;
  }

  constructor() { }

}
