// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  name: 'prod',
  production: true,
  serviceWorker: true,
  routingApiUrl: 'http://10.2.5.156:8080',
  jsonApiUrl: 'https://jsonapi.usicllc.com'
};


export const OAuthSettings = {
  appId: '42eacee1-3a46-460f-8d7f-508046c346b8',
  redirectUri: 'https://apps.usicllc.com',
  authority: 'https://login.microsoftonline.com/organizations',
  scopes: [],
};


export const Here = {
  useCIT: true,
  useHTTPS: true,
  // unencrypted key stored in USIC Bitwarden vault
  encApiKey: 'U2FsdGVkX1+9EShgqEuqfNxevq+Qp1RDq7aDig6cIS22c4pRu+IB2nt5t6dSEY7MWqEWEl5Rv4ToEvuUAmE5jg==',
};


export const Graphhopper = {
  protocol: 'https',
  hostname: 'graphhopper.com',
  root_url: '/api/1/',
  // unencrypted key stored in USIC Bitwarden vault
  encApiKey: 'U2FsdGVkX18C37V8Ae61d77hPgGm4N/nWrOcG73eoMShSoFWfWb/q4j52Az/MpWI6uCUyOMOA4xGP4lUjMcRgw=='
};


export const Slack = {
  username: 'Steve'
};
