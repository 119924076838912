import {
  JsonApiModelConfig,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { Message } from './message';
import { Employee } from '../shareddata/employee';
import { Status } from './status';
import { SchedulableJsonApiModel } from '../schedulable';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';


/*****************************************
 * EmployeeMessage
 *****************************************/
@JsonApiModelConfig({
  type: 'employeeMessages',
})
export class EmployeeMessage extends SchedulableJsonApiModel {

  get uuid() {
    return this.id;
  }

  @Attribute({ serializedName: 'reference_id', converter })
    referenceId: string;

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number; // message recipient

  @BelongsTo()
    employee: Employee;

  @Attribute({ serializedName: 'message_id', converter })
    messageId: number;

  @BelongsTo()
    message: Message;

  @Attribute({ serializedName: 'status_id', converter })
    statusId: number;

  @BelongsTo()
    status: Status;

  @Attribute({ serializedName: 'sent_at', converter: dateConverter })
    sentAt: Date;

  @Attribute({ serializedName: 'first_read_at', converter: dateConverter })
    firstReadAt: Date;

  @Attribute({ serializedName: 'last_read_at', converter: dateConverter })
    lastReadAt: Date;

  @Attribute({ serializedName: 'cleared_at', converter: dateConverter })
    clearedAt: Date;

  @Attribute({ serializedName: 'acknowledged_at', converter: dateConverter })
    acknowledgedAt: Date;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ converter })
    attribute4: string;

  @Attribute({ converter })
    attribute5: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
      attribute4: this.attribute4,
      attribute5: this.attribute5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v?.attribute1;
    this.attribute2 = v?.attribute2;
    this.attribute3 = v?.attribute3;
    this.attribute4 = v?.attribute4;
    this.attribute5 = v?.attribute5;
  }

  //
  // These aren't currently being used, but since they exist in the DB...
  //

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;


  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.startDate,
      effectiveEndDate: this.endDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.startDate = v.effectiveStartDate;
    this.endDate = v.effectiveEndDate;
  }

}
