import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';

import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';

import { CustomerAccount } from './customer-account';
import { CustomerContract } from './customer-contract';
import { User } from './user';



/*****************************************
 * UserAccess
 *****************************************/
@JsonApiModelConfig({
  type: 'userAccesses'
})
export class UserAccess extends JsonApiModel {

  @Attribute({ serializedName: 'cp_user_id', converter })
    cpUserId: number;

  @Attribute({ serializedName: 'eff_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'eff_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'customer_account_id', converter })
    customerAccountId: number;

  @Attribute({ serializedName: 'customer_contract_id', converter })
    customerContractId: number;

  @BelongsTo()
    user: User;

  @BelongsTo()
    customerAccount: CustomerAccount;

  @BelongsTo()
    customerContract: CustomerContract;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

}
