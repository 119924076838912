import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { QuestionnaireQuestion } from './questionnaire-question';
import { QuestionnaireAnswer } from './questionnaire-answer';
import { Investigation } from './investigation';

/*****************************************
 * QuestionnaireAnswersVersion
 *****************************************/
@JsonApiModelConfig({
  type: 'questionaireAnswersVersions'
})
export class QuestionnaireAnswersVersion extends JsonApiModel {

  @Attribute({ serializedName: 'answer_id', converter })
    answerId: number;

  @Attribute({ serializedName: 'investigation_id', converter })
    investigationId: number;

  @Attribute({ serializedName: 'investigation_detail_id', converter })
    investigationDetailId: number;

  @Attribute({ serializedName: 'question_id', converter })
    questionId: number;

  @Attribute({ serializedName: 'answer', converter })
    answer: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'changed_date', converter: dateConverter })
    changedDate: Date;

  @Attribute({ serializedName: 'changed_by', converter })
    changedBy: number;

  @Attribute({ serializedName: 'investigation_version_id', converter })
    investigationVersionId: number;

  @BelongsTo()
    questionnaireAnswer: QuestionnaireAnswer;

  @BelongsTo()
    investigation: Investigation;

  @BelongsTo()
    questionnaireQuestion: QuestionnaireQuestion;

}
