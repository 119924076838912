import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { WfNotification } from './wf-notification';

/*****************************************
 * WfNotificationsArchive
 *****************************************/
@JsonApiModelConfig({
  type: 'wfNotificationsArchives',
})
export class WfNotificationsArchive extends JsonApiModel {

  @Attribute({ serializedName: 'notification_id', converter })
    notificationId: number;

  @Attribute({ serializedName: 'process_id', converter })
    processId: number;

  @Attribute({ serializedName: 'activity_id', converter })
    activityId: number;

  @Attribute({ serializedName: 'step_id', converter })
    stepId: number;

  @Attribute({ serializedName: 'status_id', converter })
    statusId: number;

  @Attribute({ serializedName: 'idType', converter })
    idType: string;

  @Attribute({ serializedName: 'id_column_table', converter })
    idColumnTable: string;

  @Attribute({ serializedName: 'owner', converter })
    owner: number;

  @Attribute({ serializedName: 'activity_name', converter })
    activityName: string;

  @Attribute({ serializedName: 'text', converter })
    text: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'next_step_url', converter })
    nextStepUrl: string;

  @Attribute({ serializedName: 'idvalue', converter })
    idValue: number;

  @Attribute({ serializedName: 'completed_date', converter: dateConverter })
    completedDate: Date;

  @Attribute({ serializedName: 'result', converter })
    result: string;

  @Attribute({ serializedName: 'wf_item_id', converter })
    wfItemId: number;

  @Attribute({ serializedName: 'remarks', converter })
    remarks: string;

  @Attribute({ serializedName: 'archive_date', converter: dateConverter })
    archiveDate: Date;

  @BelongsTo()
    wfNotification: WfNotification;

}
