import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@usic/public-api';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { HelpComponent } from './help/help.component';
import { DigcheckPrivacyPolicyComponent } from './digcheck-privacy-policy/digcheck-privacy-policy.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'help', component: HelpComponent },
  { path: 'digcheck-privacy-policy', component: DigcheckPrivacyPolicyComponent },
  {
    path: 'changePassword', component: HomeComponent, canActivate: [AuthGuard],
    resolve: {
      url: 'externalUrlRedirectResolver'
    },
    data: {
      externalUrl: 'https://usicllc.com/passwordreset'
    }
  },

  // Administration
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
