/* eslint-disable security/detect-object-injection */
import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JsonAPIDataSource } from '../../datasources/jsonapi.datasource';
import { UsicTableColumn } from '../../models/general';
import { SchedulerJob } from '../../models/shareddata';
import { AuthenticationService } from '../../services/authentication.service';
import { SharedDataService } from '../../services/shared-data.service';
import { UsicCronEditorComponent } from '../usic-cron-editor/usic-cron-editor.component';
import { ChangeStatusComponent } from './change-status.component';
import { UsicErrorPanelComponent } from '../usic-error-panel/usic-error-panel.component';
import { UsicTableComponent } from '../usic-table/usic-table.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'lib-scheduled-jobs',
  templateUrl: './scheduled-jobs.component.html',
  styleUrls: ['./scheduled-jobs.component.css'],
  imports: [UsicErrorPanelComponent, UsicTableComponent, MatSlideToggle]
})
export class ScheduledJobsComponent implements OnInit {

  @Input() jobGroup: string;
  @Input() allowNew = false;
  @Input() isAdmin = false;
  @Input() showAllJobs = false;
  @Input() attribute1: UsicTableColumn;
  @Input() attribute2: UsicTableColumn;
  @Input() attribute3: UsicTableColumn;
  @Input() attribute4: UsicTableColumn;
  @Input() attribute5: UsicTableColumn;

  @Output() clickCell = new EventEmitter();
  @Output() clickRow = new EventEmitter();
  @Output() dblClickRow = new EventEmitter();
  @Output() createNew = new EventEmitter();
  @Output() deleteRecord = new EventEmitter();
  @Output() changeColumns = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();

  @Output() filteredData = new EventEmitter();


  changeStatusComponent = ChangeStatusComponent;

  displayedColumns: UsicTableColumn[] = [
    { name: 'id', type: 'number', hidden: true },
    { name: 'empId', type: 'string', title: 'Employee Id', hidden: true },
    { name: 'employee.legalName', type: 'string', title: 'Employee Name', filterName: 'employee.legal_name', hidden: true },
    { name: 'createdOn', type: 'datetime', title: 'Date Created', filterName: 'created_on' },
    { name: 'cronExpression', type: 'cron', title: 'Schedule', filterName: 'cron_expression' },
    { name: 'description', type: 'string', editable: true },
    { name: 'schedulerJobStatus.code', type: 'component', title: 'Job Status', component: this.changeStatusComponent },
    { name: 'jobInfo.previousFireTime', type: 'datetime', title: 'Last Run', filterName: 'none' },
    { name: 'jobInfo.nextFireTime', type: 'datetime', title: 'Next Run', filterName: 'none' },
    { name: 'jobGroup', type: 'string', filterName: 'job_group', hidden: true },
    { name: 'attribute1', type: 'string', title: 'Attribute 1', hidden: true },
    { name: 'attribute2', type: 'string', title: 'Attribute 2', hidden: true },
    { name: 'attribute3', type: 'string', title: 'Attribute 3', hidden: true },
    { name: 'attribute4', type: 'string', title: 'Attribute 4', hidden: true },
    { name: 'attribute5', type: 'string', title: 'Attribute 5', hidden: true },
    { name: 'delete', title: 'Unschedule', type: 'icon', icon: 'highlight_off', sortName: 'none', filterName: 'none' },
  ];

  errors = signal<string[]>([]);

  jsonApiDataSource: JsonAPIDataSource;


  constructor(
    private sharedData: SharedDataService,
    private authentication: AuthenticationService,
    protected dialog: MatDialog,
    protected snackBar: MatSnackBar
  ) {

  }

  ngOnInit(): void {

    // Allow instantiations to overload what the attribute values are used for in the table

    if (this.attribute1) {
      const index = this.displayedColumns.findIndex(column => column.name === 'attribute1');
      this.displayedColumns[index] = this.attribute1;
    }

    if (this.attribute2) {
      const index = this.displayedColumns.findIndex(column => column.name === 'attribute2');
      this.displayedColumns[index] = this.attribute2;
    }

    if (this.attribute3) {
      const index = this.displayedColumns.findIndex(column => column.name === 'attribute3');
      this.displayedColumns[index] = this.attribute3;
    }

    if (this.attribute4) {
      const index = this.displayedColumns.findIndex(column => column.name === 'attribute4');
      this.displayedColumns[index] = this.attribute4;
    }

    if (this.attribute5) {
      const index = this.displayedColumns.findIndex(column => column.name === 'attribute5');
      this.displayedColumns[index] = this.attribute5;
    }

    this.displayedColumns.find(c => c.name === 'employee.legalName').hidden = !this.showAllJobs;

    this.jsonApiDataSource = new JsonAPIDataSource(this.sharedData, SchedulerJob, 'schedulerJobStatus,employee');
    this.jsonApiDataSource.filter = {
      job_group: this.jobGroup,
      emp_id: this.showAllJobs ? undefined : this.authentication.currentUserId,
    };

    this.jsonApiDataSource.loading.subscribe((loading) => {
      if (loading === false) {
        this.filteredData.emit(this.jsonApiDataSource.filteredData);
      }
    });

  }

  delete(record: SchedulerJob) {
    if (confirm(`Remove scheduled job,\n${record.description}?`)) {
      this.sharedData.deleteRecord(SchedulerJob, record.id).subscribe({
        complete: () => {
          this.jsonApiDataSource.loadData();
        },
        error: (error) => {
          console.error(error);
          this.snackBar.open('Failed to remove scheduled job.', 'Dismiss', { duration: 3000 });
        }
      });
    }
  }

  toggleShowAllJobs(event) {
    this.showAllJobs = event.checked;

    if (this.showAllJobs) {
      this.jsonApiDataSource.filter.emp_id = undefined;
    } else {
      this.jsonApiDataSource.filter.emp_id = this.authentication.currentUserId;
    }

    this.displayedColumns.find(c => c.name === 'employee.legalName').hidden = !this.showAllJobs;
    this.jsonApiDataSource.loadData();
  }

  onClickCell(event) {

    switch (event.key) {
      case 'cronExpression': {
        this.openCronEditor(event.value, event.row);
        event.event.stopPropagation();
        break;
      }
      default:
        this.clickCell.emit(event);
    }

  }

  openCronEditor(cronString: string, row: SchedulerJob) {

    const cronDialogRef = this.dialog.open(UsicCronEditorComponent, {
      data: cronString,
      width: 'auto',
      height: 'auto',
      panelClass: 'border'
    });

    cronDialogRef.afterClosed().subscribe(result => {

      if (result && (result !== cronString)) {

        row.cronExpression = result;
        row.save().subscribe({
          complete: () => {
            this.jsonApiDataSource.loadData();
            this.snackBar.open('Schedule saved!', 'OK');
          },
          error: error => { console.error(error); }
        });

      }

    });

  }

}
