/* eslint-disable security/detect-object-injection */
import { Injectable } from '@angular/core';
import { JsonApiModel, ModelType } from '@michalkotas/angular2-jsonapi';
import { formatDate } from '@angular/common';
import { deepAssign } from '../helpers/deep-assign';
import { UsicTableColumn } from '../models/general';
import { TitleizePipe } from '../pipes/titleize.pipe';
import { FormGroup, Validators } from '@angular/forms';

export type ModelInspection = {
  name: string;
  display?: string;
  type: string;
  dbName: string;
  relation?: string;
  attributes?: ModelInspection[];
  required?: boolean;
  requiredCondition?: string;
  singleSelection?: boolean;
};

export type Includable = {
  name: string;
  dbName: string;
  checked: boolean;
  includes?: Includable[];
};

// Set of columns that are primarily for internal purposes and should be hidden by default
const DEFAULT_HIDDEN_COLUMNS = [
  'createdOn',
  'created_on',
  'createdBy',
  'created_by',
  'lastUpdatedOn',
  'last_updated_on',
  'lastUpdatedBy',
  'last_updated_by',
  'effectiveStartDate',
  'effective_start_date',
  'eff_start_date',
  'effectiveEndDate',
  'effective_end_date',
  'eff_end_date',
  'active',
  'version',
  'description',   // Usually blank or a repeat of the name
  'searchText',
];



@Injectable({
  providedIn: 'root'
})
export class JsonApiModelService {

  constructor() { }

  /**
   * inspectJsonApiModel
   *
   * This helper function will use JavaScript reflection to return an array of all the
   * json:api attributes and relationships of a model.  This facilitiates the ability
   * to construct forms and tables in a generic manner for any model
   */
  inspect(model: ModelType<JsonApiModel>, prefix?: string, dbPrefix?: string): ModelInspection[] {
    const obj = model?.prototype;
    const inspectionResults: ModelInspection[] = [{
      // allow ID to be included
      name: 'id',
      dbName: 'id',
      type: 'string'
    }];

    if (obj) {
      // const metaKeys = Reflect.getMetadataKeys(obj);
      const attributes = Reflect.getMetadata('Attribute', obj);
      const attributeMappings = Reflect.getMetadata('AttributeMapping', obj);
      const belongsTo = Reflect.getMetadata('BelongsTo', obj);
      const hasMany = Reflect.getMetadata('HasMany', obj);

      for (const attributeName in attributes) {
        if (attributeName) {
          const attributeMapping = Object.keys(attributeMappings).find(m => attributeMappings[m] === attributeName);
          const attributeType = String((Reflect.getMetadata('design:type', obj, attributeName))?.name)?.toLowerCase();
          if (attributeMapping && attributeType) {
            inspectionResults.push({
              name: prefix ? `${prefix}.${attributeName}` : attributeName,
              type: attributeType,
              dbName: dbPrefix ? `${dbPrefix}.${attributeMapping}` : attributeMapping,
            });
          }
        }
      }

      if (belongsTo) {
        for (const bt of belongsTo) {
          const belongsToName = bt.propertyName;
          // prevent any infinite loops
          if (!prefix?.includes(belongsToName)) {
            const belongsToModel = (Reflect.getMetadata('design:type', obj, belongsToName));
            const belongsToType = belongsToModel?.name;
            const belongsToDbName = bt.relationship || belongsToModel.name;
            const belongsToInspection = this.inspect(
              belongsToModel,
              prefix ? `${prefix}.${belongsToName}` : belongsToName,
              dbPrefix ? `${dbPrefix}.${belongsToDbName}` : belongsToDbName,
            );
            if (!inspectionResults.find(i => i.name === (prefix ? `${prefix}.${belongsToName}` : belongsToName))) {
              inspectionResults.push({
                name: prefix ? `${prefix}.${belongsToName}` : belongsToName,
                type: belongsToType,
                dbName: dbPrefix ? `${dbPrefix}.${belongsToDbName}` : belongsToDbName,
                relation: 'BelongsTo',
                attributes: belongsToInspection,
              });
            }
            // inspectionResults.push(...belongsToInspection);
          }
        }
      }


      if (hasMany) {
        for (const hm of hasMany) {
          const hasManyName = hm.propertyName;

          const hasManyModel = (Reflect.getMetadata('design:type', obj, hasManyName));
          const hasManyType = hasManyModel?.name;

          // For the time being, the type for HasMany will always be 'Array' as there
          // seems to be no way to determine the data type of the array.  This means we
          // can't get the possible attributes for hasMany relations like we do for belongsTo.
          // See:
          // https://stackoverflow.com/questions/35022658/how-do-i-get-array-item-type-in-typescript-using-the-reflection-api
          // https://github.com/microsoft/TypeScript/issues/7169

          inspectionResults.push({
            name: prefix ? `${prefix}.${hasManyName}` : hasManyName,
            type: hasManyType,
            dbName: prefix ? `${prefix}.${hasManyName}` : hasManyName,
            relation: 'HasMany',
          });

        }
      }

    }

    return inspectionResults;
  }


  /**
   * Recurively build list of includable models
   *
   * @param modelInspection
   */
  buildIncludables(modelInspection: ModelInspection[], selectedIncludes: string): Includable[] {

    const includables = modelInspection
      .filter((m: ModelInspection) => m.relation)
      .sort((a: ModelInspection, b: ModelInspection) => a.name.localeCompare(b.name));

    const includes: Includable[] = [];
    includables.forEach(includable => {

      if (includable.relation === 'BelongsTo') {
        includes.push({
          name: includable.name,
          dbName: includable.dbName,
          checked: (selectedIncludes?.split(',').includes(includable.dbName) ? true : false),
          includes: includable.attributes ? this.buildIncludables(includable.attributes, selectedIncludes) : undefined,
        });

      } else if (includable.relation === 'HasMany' && includable.name.split('.').length === 1) {
        // only allow top-level hasMany relationships to be included
        // If we want to allow nested hasManys, then uncomment the 'includes' line below
        // and comment out the second half of the prior if condition
        includes.push({
          name: '# ' + includable.name,
          dbName: includable.dbName,
          checked: (selectedIncludes?.split(',').includes(includable.dbName) ? true : false),
          // includes: includable.attributes ? this.buildIncludables(includable.attributes, selectedIncludes) : undefined,
        });
      }
    });

    includes.sort((a, b) => a.name.localeCompare(b.name));
    return includes;
  }

  shouldHideColumn(columnName) {
    return DEFAULT_HIDDEN_COLUMNS.includes(columnName) ||
      columnName.startsWith('attribute') ||
      columnName.endsWith('Id') ||
      // ID columns from SP or SQL queries aren't always capitalized or offset with a '_'.
      // Hopefully we don't have many normal column names that end in 'id', if it becomes a
      // problem, remove this condition.
      columnName.endsWith('id');
  }

  /**
   * Build a list of columns that can be displayed in a UsicTable based on the
   * model inspection
   *
   * @param modelInspection: ModelInspection[]
   * @param included:string list of data models that should be included
   * @param columns:string list of columns that should be displayed initially
   */
  buildUsicTableColumns(
    modelInspection: ModelInspection[],
    included: string,
    columns?: string,
    hideNew: boolean = true,
  ): UsicTableColumn[] {

    const usicTableColumns: UsicTableColumn[] = [];
    const titleize = new TitleizePipe();

    // Build the columns recursively to ensure nested attributes are fully shown
    function doBuild(inspection: ModelInspection[]) {
      inspection.forEach(modelAttribute => {

        if (!modelAttribute.relation) {
          // This is a base attribute of the model not a relation

          // In case this is a nested attribute, strip off all the prefixes
          // const columnTitle = titleize.transform(modelAttribute.name.split('.').slice(-1)[0]);
          const baseName = modelAttribute.name.split('.').slice(-1)[0];

          // don't push a duplicate column name
          if (!usicTableColumns.find(dc => dc.name === modelAttribute.name)) {
            usicTableColumns.push({
              name: modelAttribute.name,
              // title: columnTitle,
              type: baseName.endsWith('Id') ? 'string' : modelAttribute.type,
              sortName: modelAttribute.dbName,
              filterName: modelAttribute.dbName,
              // To reduce clutter, hide some columns by default
              hidden: this.shouldHideColumn(baseName) || modelAttribute.type === 'object'
            });
          }

        } else if (included?.split(',').includes(modelAttribute.dbName)) {
          // this is an included model.
          // If it's a one-to-many relationship ('HasMany') then just add the
          // ability to count the associated data.
          // If it's a one-to-one relationship ('BelongsTo') then include the
          // related data as viewable columns.

          if (modelAttribute.relation === 'HasMany') {
            // One-To-Many
            const columnTitle = '# ' + titleize.transform(modelAttribute.name.split('.').slice(-1)[0]);

            usicTableColumns.push({
              name: modelAttribute.name + '.length',
              title: columnTitle,
              type: 'number',
              sortName: 'none',
              filterName: 'none',
            });

          } else {
            // One-to-One

            // include it's attributes (recursively)
            if (modelAttribute.attributes?.length) {
              doBuild.apply(this, [modelAttribute.attributes]);
            }
          }
        }
      });
    }
    doBuild.apply(this, [modelInspection]);

    // Hide/Show and order columns to match the selected report columns
    //
    const reportColumns = columns?.split(',');
    if (reportColumns?.length) {
      // Toggle hide/show based on saved columns
      usicTableColumns.forEach(displayColumn => {
        if (reportColumns.includes(displayColumn.filterName || displayColumn.name) ||
          (displayColumn.filterName === 'none' && reportColumns.includes(displayColumn.name.replace(/\.length/, '')))) {
          displayColumn.hidden = false;
        } else {
          const baseName = displayColumn.name.split('.').slice(-1)[0];
          if (this.shouldHideColumn(baseName)) {
            displayColumn.hidden = true;
          } else {
            displayColumn.hidden = hideNew;
          }
        }
      });

      // Re-order to match the saved columns
      reportColumns.forEach((reportColumn, toIndex) => {
        const fromIndex = usicTableColumns
          .findIndex(d => (reportColumn === (d.filterName || d?.name)) ||
            (reportColumn === d.name.replace(/\.length/, '')));
        if (fromIndex > -1) {
          const displayColumn = usicTableColumns[fromIndex];
          usicTableColumns.splice(fromIndex, 1);
          usicTableColumns.splice(toIndex, 0, displayColumn);
        }
      });

    }

    return usicTableColumns;
  }

  // Clone the filter and do any data formatting needed to convert it from
  // the FormGroup values to the JSON:API filter
  //
  sanitizeFilter(filter): any {
    const santizedFilter = deepAssign({}, filter);

    // Convert any multi-select values into a comma separate list of values
    // eslint-disable-next-line guard-for-in
    for (const p in santizedFilter) {
      if (santizedFilter[p] instanceof Array) {
        santizedFilter[p] = santizedFilter[p].join(',');
      }

      // The mat-date-range-input gives dates as Date objects in GMT so
      // convert them to a string and drop the time part to just be a date
      if (santizedFilter[p]?.ge && santizedFilter[p]?.le) {

        if (santizedFilter[p].ge instanceof Date) {
          santizedFilter[p].ge = santizedFilter[p].ge.toISOString().split('T')[0];
        }
        if (santizedFilter[p].le instanceof Date) {
          santizedFilter[p].le = santizedFilter[p].le.toISOString().split('T')[0];
        }

      }
    }

    // Remove any null or empty string values from the filter
    function stripNulls(aFilter) {
      Object.keys(aFilter)
        .forEach(key => {
          if ( aFilter[key] === null ||
              (typeof aFilter[key] === 'string' && aFilter[key].length === 0) ) {
            delete aFilter[key];
          } else if (aFilter[key] instanceof Object) {
            stripNulls(aFilter[key]);
          }
        });
    }
    stripNulls(santizedFilter);

    return Object.keys(santizedFilter).length ? santizedFilter : undefined;
  }

  /**
   * Inspect a model and create a form group config for its attributes
   *
   * @param model
   * @param readOnly
   * @returns
   */
  createFormGroupConfigForModel(model: ModelType<JsonApiModel>, readOnly: boolean = false): { [key: string]: any } {
    const modelInspection = this.inspect(model).map(i => i.relation ? undefined : i).filter(i => i);
    const configData = {};

    modelInspection.forEach(mi => {
      if (mi.type === 'date') {
        configData[mi.name] = [{ value: formatDate(new Date(), 'yyyy-MM-dd', 'en'), disabled: readOnly }];
      } else {
        configData[mi.name] = [{ value: null, disabled: readOnly }];
      }
    });

    return configData;
  }

  /**
   * Inspect a model and set the form fields for the object data
   *
   * @param formGroup
   * @param formPrefix
   * @param model
   * @param object
   */
  loadFormForModelFromObject(formGroup: FormGroup, formPrefix: string, model: ModelType<JsonApiModel>, object: any) {
    const modelInspection = this.inspect(model).map(i => i.relation ? undefined : i).filter(i => i);
    modelInspection.forEach(mi => {
      if (mi.type === 'date') {
        this.fixDate(formGroup, object[mi.name], formPrefix ? `${formPrefix}.${mi.name}` : mi.name);
      } else {
        formGroup.get(formPrefix ? `${formPrefix}.${mi.name}` : mi.name).setValue(object[mi.name]);
      }
    });
  }

  /**
   * Set the 'required' validator on the names fields
   *
   * @param formGroup
   * @param formPrefix
   * @param requiredFieldNames
   */
  setRequiredFields(formGroup: FormGroup, formPrefix: string, requiredFieldNames: string[]) {
    for (const fieldName of requiredFieldNames) {
      formGroup.get(formPrefix ? `${formPrefix}.${fieldName}` : fieldName).setValidators(Validators.required);
    }

  }

  fixDate(formGroup: FormGroup, date: Date, formControlName: string) {
    // Fix timezone problem with date picker
    if (date !== undefined && date.getFullYear() > 2000) {
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      formGroup.get(formControlName).setValue(formatDate(date, 'yyyy-MM-dd', 'en'));
    }
  }

}
