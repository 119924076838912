/* eslint-disable security/detect-object-injection */
/********************************************************************************
 * BillingService
 *
 * Temporary service that returns static Billing Rules until a data API is
 * available
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/


import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BillingRule } from '../models/billing/billing-rule';
import { BillingRuleItem } from '../models/billing/billing-rule-item';
import { BillingRuleLimit } from '../models/billing';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  billingRules: BillingRule[] = [];

  constructor() {

    // TODO this would all be replaced with API calls
    // for now we'll use the constant data below

    const globalRulesCopy: BillingRuleItem[] = [];
    GLOBAL_RULE_ITEMS.forEach(r => {
      globalRulesCopy.push(r.clone());
    });

    const billingRule1 = new BillingRule({
      name: 'CenturyLink FL',
      customerId: 0,
      contractId: 0,
      utilityId: 0,
      effectiveStartDate: new Date(),
      effectiveEndDate: new Date(),
      groupBy: 'Revision Number',
      chargeType: 'Footage',
      comboChargeScope: null,
      applicableToAll: false,
      checkCountInAllContracts: true
    }, ...globalRulesCopy);

    billingRule1.push(...CENTURYLINK_RULE_ITEMS);
    billingRule1.limits = BILLING_RULE_LIMITS.slice(1,2);

    this.billingRules.push(billingRule1);

    globalRulesCopy.length = 0;
    GLOBAL_RULE_ITEMS.forEach(r => {
      globalRulesCopy.push(r.clone());
    });

    const billingRule2 = new BillingRule({
      name: 'Lakeland Regional Sewer District IN (LKELND SWR)',
      customerId: 0,
      contractId: 0,
      utilityId: 0,
      effectiveStartDate: new Date(Date.parse('2019-12-02')),
      effectiveEndDate: new Date(Date.parse('2099-12-31')),
      groupBy: 'Revision Number',
      chargeType: 'Per Ticket',
      comboChargeScope: null,
      applicableToAll: false,
      checkCountInAllContracts: true
    }, ...globalRulesCopy);

    billingRule2.limits = BILLING_RULE_LIMITS.slice();
    billingRule2.push(...LAKELAND_REGIONAL_RULE_ITEMS);
    this.billingRules.push(billingRule2);

  }

  // Simulate JSON API
  find(name: string): Observable<BillingRule> {
    return of(this.billingRules.find(billingRule => billingRule.name === name));
  }

  // Simulate JSON API
  findAll(): Observable<BillingRule[]> {
    return of(this.billingRules);
  }

}


// TODO temporarily define tree data as a constants.
// this will be replaced with a data service to pull from a DB table

const BILLING_RULE_LIMITS: BillingRuleLimit[] = [
  {
    workCode: 'AH',
    altWorkCode: 'QH',
    limit: 1,
    dayLimit: 45,
    global: false,
    utilityId: 0,
  } as BillingRuleLimit,
  {
    workCode: 'PT',
    altWorkCode: 'QH',
    limit: 1,
    dayLimit: 45,
    global: false,
    utilityId: 0,
  } as BillingRuleLimit,
  {
    workCode: 'QH',
    altWorkCode: 'Project_Hourly',
    limit: 1,
    dayLimit: 0,
    global: false,
    utilityId: 0,
  } as BillingRuleLimit
];

const GLOBAL_RULE_ITEMS: BillingRuleItem[] = [
  new BillingRuleItem({
    sequenceNo: '1',
    forRule: 'global',
    type: 'global-rule',
    description: 'Is Cost Center Missing?',
    condition: '( district_abbrev is Null ) And ( Is Unit Verified = no )',
    passed: { serviceFlag: 'Flag' },
  }),
  new BillingRuleItem({
    sequenceNo: '2',
    forRule: 'global',
    type: 'global-rule',
    description: 'Minutes Worked Greater Than 500?',
    condition: '( minutes worked > 500 )',
    passed: { serviceFlag: 'Flag' },
  }),
  new BillingRuleItem({
    sequenceNo: '3',
    forRule: 'global',
    type: 'global-rule',
    description: 'Paintable or Ticket Type not mapped?',
    condition: '(( PYPN is Null ) OR ( ticket Type is Null ))',
    passed: { serviceFlag: 'Flag' },
  }),
];

const CENTURYLINK_RULE_ITEMS: BillingRuleItem[] = [
  new BillingRuleItem({
    sequenceNo: '1',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is Non-Verbal Ticket?',
    condition: '( Ticket Number Not Like M )',
  }),
  new BillingRuleItem({
    sequenceNo: '1.1',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is CDC Manually added?',
    condition: '( feedname Starts With MAN )',
    passed: { workCode: 'NC' },
  }),
  new BillingRuleItem({
    sequenceNo: '1.2',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is work done for CenturyLink?',
    // eslint-disable-next-line max-len
    condition: '(( Work Done For Like CENTURYLINK ) OR ( Work Done For Like CENT%RY%LIN ) OR ( Work Done For Like CE%NT%RY%LIN ) OR ( Work Done For Like CENTRUY%LINK ) OR ( Work Done For Ends With CENT%RY ) OR ( Work Done For Like CENTURYLINK%TELEPHONE ))',
    passed: { workCode: 'NC' },
  }),
  new BillingRuleItem({
    sequenceNo: '1.3',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is Excavator CenturyLink?',
    condition: '( excavator Like CenturyLinkALL )',
    passed: { workCode: 'NC' },
  }),
  new BillingRuleItem({
    sequenceNo: '2',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is Billing Ticket Type Cancellation?',
    condition: '( Ticket Type = CANCELLATION )',
    passed: { workCode: 'NC' },
  }),
  new BillingRuleItem({
    sequenceNo: '3',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is Work Description Cancel?',
    condition: '( work description = CANCEL )',
    passed: { workCode: 'NC' },
  }),
  new BillingRuleItem({
    sequenceNo: '4',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Has Authorization Locate?',
    condition: '( authorization = Locate )',
  }),
  new BillingRuleItem({
    sequenceNo: '4.1',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is Work Description like wdap?',
    condition: '( work description like wdap )',
  }),
  new BillingRuleItem({
    sequenceNo: '4.1.1',
    forRule: 'CenturyLink FL',
    type: 'adder',
    description: 'Minutes Worked > 15',
    adder: {
      type: 'Periodic',
      units: 'Min',
      field: 'Minutes Worked',
      from: 0,
      to: 15,
      repeatAfter: 15,
    },
    passed: { workCode: 'W' },
  }),
  new BillingRuleItem({
    sequenceNo: '5',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is Billing Ticket Type Hourly?',
    condition: '( billing ticket type = Hourly )',
  }),
  new BillingRuleItem({
    sequenceNo: '5.1',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is Work Description WatchDog?',
    condition: '( work description = WatchDog )',
  }),
  new BillingRuleItem({
    sequenceNo: '5.1.1',
    forRule: 'CenturyLink FL',
    type: 'adder',
    description: 'Minutes Worked > 15',
    adder: {
      type: 'Periodic',
      units: 'Min',
      field: 'Minutes Worked',
      from: 0,
      to: 15,
      repeatAfter: 15,
    },
    passed: { workCode: 'W' },
  }),
  new BillingRuleItem({
    sequenceNo: '6',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is Not Authorized (review)?',
    condition: '( Answer Is Null )',
  }),
  new BillingRuleItem({
    sequenceNo: '6.1',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is Work Description WatchDog?',
    condition: '( work description = WatchDog )',
    passed: { serviceFlag: 'Flag' },
  }),
  new BillingRuleItem({
    sequenceNo: '7',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Is Work Description WatchDog?',
    condition: '( work description = WatchDog )',
  }),
  new BillingRuleItem({
    sequenceNo: '7.1',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Has Authorization Locate?',
    condition: '(( Answer Is Not Null ) And ( Answer NOT IN Not Authorized,noone ))',
  }),
  new BillingRuleItem({
    sequenceNo: '7.1.1',
    forRule: 'CenturyLink FL',
    type: 'adder',
    description: 'Minutes Worked > 15',
    adder: {
      type: 'Periodic',
      units: 'Min',
      field: 'Minutes Worked',
      from: 0,
      to: 15,
      repeatAfter: 15,
    },
    passed: { workCode: 'W' },
  }),
  new BillingRuleItem({
    sequenceNo: '8',
    forRule: 'CenturyLink FL',
    type: 'rule',
    description: 'Always (used for Per Ticket Customers) Last Rule',
    condition: '( Status Code Is Not Null )',
  }),
  new BillingRuleItem({
    sequenceNo: '8.1',
    forRule: 'CenturyLink FL',
    type: 'adder',
    description: 'Minutes Worked > 120',
    adder: {
      type: 'Periodic',
      units: 'Min',
      field: 'Minutes Worked',
      from: 0,
      to: 120,
      repeatAfter: 120,
    },
    passed: { workCode: 'PT' }
  })
];

const LAKELAND_REGIONAL_RULE_ITEMS: BillingRuleItem[] = [
  new BillingRuleItem({
    sequenceNo: '1',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'rule',
    description: 'Is CDC Manually added?',
    condition: '( feedname Starts With MAN )',
    passed: { workCode: 'NC' },
  }),
  new BillingRuleItem({
    sequenceNo: '2',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'rule',
    description: 'Is Verbal Ticket?',
    condition: '( Ticket Number Starts With M )',
    passed: { workCode: 'NC' },
  }),
  new BillingRuleItem({
    sequenceNo: '3',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'rule',
    description: 'Is Billing Ticket Type Calcellation?',
    condition: '( Ticket Type = CANCELLATION )',
    passed: { workCode: 'NC' },
  }),
  new BillingRuleItem({
    sequenceNo: '4',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'rule',
    description: 'Has After Hours been charged?',
    condition: '( Ticket Number Has Already Charged AH )',
  }),
  new BillingRuleItem({
    sequenceNo: '4.1',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'adder',
    description: 'Minutes > 15',
    adder: {
      type: 'Periodic',
      units: 'Min',
      field: 'Minutes',
      from: 0,
      to: 15,
      repeatAfter: 15,
    },
    passed: { workCode: 'AH' },
  }),
  new BillingRuleItem({
    sequenceNo: '5',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'rule',
    description: 'Has Per Ticket been charged?',
    condition: '( Ticket Number Has Already Charged pt )',
  }),
  new BillingRuleItem({
    sequenceNo: '5.1',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'adder',
    description: 'Minutes > 15',
    adder: {
      type: 'Periodic',
      units: 'Min',
      field: 'Minutes',
      from: 0,
      to: 15,
      repeatAfter: 15,
    },
    passed: { workCode: 'PT' },
  }),
  new BillingRuleItem({
    sequenceNo: '6',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'rule',
    description: 'Is Billing Ticket Type Emergency?',
    condition: '( Billing Ticket Type = emergency ) OR ( Billing Ticket Type = *****EMERGENCY***** )',
  }),
  new BillingRuleItem({
    sequenceNo: '6.1',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'rule',
    description: 'Is Transmit Date a Holiday?',
    condition: '( Transmit Date IS Off Day )',
  }),
  new BillingRuleItem({
    sequenceNo: '6.1.1',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'adder',
    description: 'Minutes > 15',
    adder: {
      type: 'Periodic',
      units: 'Min',
      field: 'Minutes',
      from: 0,
      to: 15,
      repeatAfter: 15,
    },
    passed: { workCode: 'AH' },
  }),
  new BillingRuleItem({
    sequenceNo: '6.2',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'rule',
    description: 'Is Transmit Date a Sat-Sun?',
    condition: '( Transmit Date IS Week End )',
  }),
  new BillingRuleItem({
    sequenceNo: '6.2.1',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'adder',
    description: 'Minutes > 15',
    adder: {
      type: 'Periodic',
      units: 'Min',
      field: 'Minutes',
      from: 0,
      to: 15,
      repeatAfter: 15,
    },
    passed: { workCode: 'AH' },
  }),
  new BillingRuleItem({
    sequenceNo: '6.3',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'rule',
    description: 'Is Transmit Time 5:00PM - 7:00AM?',
    condition: '( Transmit Date between 05:00 PM And 07:00 AM )',
  }),
  new BillingRuleItem({
    sequenceNo: '6.3.1',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'adder',
    description: 'Minutes > 15',
    adder: {
      type: 'Periodic',
      units: 'Min',
      field: 'Minutes',
      from: 0,
      to: 15,
      repeatAfter: 15,
    },
    passed: { workCode: 'AH' },
  }),
  new BillingRuleItem({
    sequenceNo: '7',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'rule',
    description: 'Always (used for Per Ticket Customers) Last Rule',
    condition: '( Status Code Is Not Null )',
  }),
  new BillingRuleItem({
    sequenceNo: '7.1',
    forRule: 'Lakeland Regional Sewer District IN (LKELND SWR)',
    type: 'adder',
    description: 'Minutes Worked > 120',
    adder: {
      type: 'Periodic',
      units: 'Min',
      field: 'Minutes Worked',
      from: 0,
      to: 120,
      repeatAfter: 120,
    },
    passed: { workCode: 'PT' },
  })
];
