import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { TkpCustomer } from './tkp-customer';


/*****************************************
 * Utility
 *****************************************/
@JsonApiModelConfig({
  type: 'utilities',
})
export class Utility extends JsonApiModel {

  @Attribute({converter })
    name: string;

  sanitizedName(username) {
    if (username === 'demouser' && this.name) {
      return this.name.replace(/ameren/ig, 'Public Utility');
    } else {
      return this.name;
    }
  }

  @Attribute({converter })
    abbrev: string;

  @Attribute({ serializedName: 'customer_id', converter })
    customerId: number;

  @Attribute({ serializedName: 'office_id', converter })
    officeId: number;

  @Attribute({ serializedName: 'screen_bucket_id', converter })
    screenBucketId: number;

  @Attribute({ serializedName: 'require_area', converter })
    requireArea: boolean;

  @Attribute({ serializedName: 'app_key', converter })
    appKey: string;

  @Attribute({ serializedName: 'signature_requirement', converter })
    signatureRequirement: number;

  @Attribute({ serializedName: 'service_list_id', converter })
    serviceListId: number;

  @Attribute({ serializedName: 'auto_close', converter })
    autoClose: number;

  @Attribute({ serializedName: 'export_billing', converter })
    exportBilling: boolean;

  @Attribute({ serializedName: 'has_gis_data', converter })
    hasGisData: boolean;

  @Attribute({ serializedName: 'require_certificate', converter })
    requireCertificate: boolean;

  @Attribute({ serializedName: 'require_reschedule', converter })
    requireReschedule: boolean;

  @Attribute({ serializedName: 'require_eta', converter })
    requireEta: boolean;

  @Attribute({ serializedName: 'qc_bucket_id', converter })
    qcBucketId: number;

  @Attribute({ serializedName: 'drawing_abbrev', converter })
    drawingAbbrev: string;

  @Attribute({ serializedName: 'proximity_code', converter })
    proximityCode: string;

  @Attribute({ serializedName: 'contract_number', converter })
    contractNumber: string;

  @Attribute({ serializedName: 'contract_name', converter })
    contractName: string;

  @Attribute({ serializedName: 'require_manual_time', converter })
    requireManualTime: boolean;

  @Attribute({ serializedName: 'hp_scoring', converter })
    hpScoring: boolean;

  @Attribute({ serializedName: 'exclude_holdoff', converter })
    excludeHoldoff: boolean;

  @Attribute({ serializedName: 'require_academy_certificate', converter })
    requireAcademyCertificate: boolean;

  @BelongsTo()
    tkpCustomer: TkpCustomer;

}
