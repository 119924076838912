/********************************************************************************
 * EffectiveDatesComponent (lib-effective-dates)
 *
 * Angular component to provide edit controls for effective start and end dates
 *
 * parameters
 *   dataModel       : 2-way binding of a data model that has attributes for the
 *                     effectiveStardDate and effectiveEndDates for template-driven
 *                     forms (equivalant to ngModel)
 *
 *   showAsFieldset  : controls whether or not the fields should be encapsulated
 *                     in a fieldset  (default: true)
 *
 * Also implements reactive form ControlValueAccessor as described
 * https://stackoverflow.com/questions/39661430/angular-2-formcontrolname-inside-component
 *
 *   formControl     : The FormControl from the parent formGroup for this control
 *                     to enable adding validators.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { forwardRef, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { BreakpointsService } from '../../services/breakpoints.service';
import { NgClass, DatePipe } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

export const EFFECTIVE_DATES_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EffectiveDatesComponent),
  multi: true
};

/**
 * This component accepts and returns 2 dates
 */
export type EffectiveDates = {
  effectiveStartDate: Date;
  effectiveEndDate: Date;
};

/**
 * Validator to ensure the start date is before the end date
 */
function startBeforeEnd(c: AbstractControl): ValidationErrors | null {
  if (c.value !== null &&
    c.value.effectiveStartDate &&
    c.value.effectiveEndDate &&
    c.value.effectiveStartDate >= c.value.effectiveEndDate) {
    return { startBeforeEnd: true };
  }
  return null;
}


@Component({
  selector: 'lib-effective-dates',
  templateUrl: './effective-dates.component.html',
  styleUrls: ['./effective-dates.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [EFFECTIVE_DATES_CONTROL_VALUE_ACCESSOR],
  imports: [NgClass, MatFormField, MatLabel, MatInput, FormsModule, DatePipe]
})
export class EffectiveDatesComponent implements ControlValueAccessor, AfterViewInit {

  // ngModel equivalent for template-driven forms
  currentModel: any;
  @Input()
  get dataModel() {
    return this.currentModel;
  }
  set dataModel(val) {
    this.currentModel = val;
    this.dataModelChange.emit(this.currentModel);
  }
  @Output() dataModelChange = new EventEmitter<any>();

  // Current form control for this component when using reactive forms.
  @Input() formControl: AbstractControl = new FormControl();

  // The internal data model for form control value access (reactive forms)
  private innerValue: EffectiveDates = { effectiveStartDate: null, effectiveEndDate: null };
  private onChange: (value: EffectiveDates) => void;
  public onTouched: () => void;

  @Input() showAsFieldset = true;

  constructor(
    public breakpoints: BreakpointsService
  ) { }

  // Lifecycle hook. angular.io for more info
  ngAfterViewInit() {

    // If this is being used in a reactive form, add the validation
    if (this.formControl) {
      const priorValidators = this.formControl.validator;
      if (priorValidators) {
        this.formControl.setValidators([startBeforeEnd, this.formControl.validator]);
      } else {
        this.formControl.setValidators(startBeforeEnd);
      }
      this.formControl.updateValueAndValidity();
    }

  }

  changeEffectiveStartDate(event: any) {
    const newDate: Date = event.target.valueAsDate;
    if (newDate && newDate.getFullYear() > 1900) {
      // Fix timezone problem with date picker
      newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
      this.innerValue.effectiveStartDate = newDate;

      if (this.onChange) {
        this.onChange(this.innerValue);
      }
    }
  }

  changeEffectiveEndDate(event: any) {
    const newDate: Date = event.target.valueAsDate;
    if (newDate && newDate.getFullYear() > 1900) {
      // Fix timezone problem with date picker
      newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
      this.innerValue.effectiveEndDate = newDate;

      if (this.onChange) {
        this.onChange(this.innerValue);
      }
    }
  }

  // From ControlValueAccessor interface
  writeValue(value: EffectiveDates) {
    this.innerValue = value;

    // To allow template-driven ngModel work without error
    if (!this.currentModel) {
      this.currentModel = this.innerValue;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: (value: EffectiveDates) => void): void {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // get accessor
  get value(): EffectiveDates {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: EffectiveDates) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

}

