import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { QuestionnaireAnswer } from './questionnaire-answer';
import { QuestionnaireAnswersVersion } from './questionnaire-answers-version';

/*****************************************
 * QuestionnaireQuestion
 *****************************************/
@JsonApiModelConfig({
  type: 'questionnaireQuestions'
})
export class QuestionnaireQuestion extends JsonApiModel {

  @Attribute({ serializedName: 'question_id', converter })
    questionId: number;

  @Attribute({ serializedName: 'code', converter })
    code: string;

  @Attribute({ serializedName: 'description', converter })
    description: string;

  @Attribute({ serializedName: 'label', converter })
    label: string;

  @Attribute({ serializedName: 'reference_criteria', converter })
    referenceCriteria: string;

  @Attribute({ serializedName: 'seq', converter })
    seq: number;

  @Attribute({ serializedName: 'placement', converter })
    placement: string;

  @Attribute({ serializedName: 'master_question', converter })
    masterQuestion: boolean;

  @Attribute({ serializedName: 'criteria', converter })
    criteria: string;

  @Attribute({ serializedName: 'type', converter })
    type: string;

  @Attribute({ serializedName: 'visibility', converter })
    visibility: string;

  @Attribute({ serializedName: 'required', converter })
    required: boolean;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    endDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    questionnaireAnswers: QuestionnaireAnswer[];

  @HasMany()
    questionnaireAnswersVersions: QuestionnaireAnswersVersion[];

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.startDate,
      effectiveEndDate: this.endDate
    };

  }

}
