import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { FinancialPeriod } from './financial-period';
import { QuickCValue } from './quick-c-value';
import { District } from '../shareddata';
import { WfNotification } from './wf-notification';
import { Damage } from './damage';
import { Investigation } from './investigation';
import { InvestigationDetail } from './investigation-detail';
import { CmsRequest } from './cms-request';

/*****************************************
 * PolicyDropOffEstimate
 *****************************************/
@JsonApiModelConfig({
  type: 'policyDropOffEstimates',
})
export class PolicyDropOffEstimate extends JsonApiModel {

  @Attribute({ serializedName: 'version_number', converter })
    versionNumber: number;

  @Attribute({ serializedName: 'wf_item_id', converter })
    wfItemId: number;

  @Attribute({ serializedName: 'investigation_id', converter })
    investigationId: number;

  @Attribute({ serializedName: 'investigation_detail_id', converter })
    investigationDetailId: number;

  @Attribute({ serializedName: 'damage_id', converter })
    damageId: number;

  @Attribute({ serializedName: 'wf_notification_id', converter })
    wfNotificationId: number;

  @Attribute({ serializedName: 'estimate_category_id', converter })
    estimateCategoryId: number;

  @Attribute({ serializedName: 'period_id', converter })
    periodId: number;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'request_id', converter })
    requestId: number;

  @Attribute({ serializedName: 'dispute_reason_id', converter })
    disputeReasonId: number;

  @Attribute({ serializedName: 'dispute_estimate', converter })
    disputeEstimate: boolean;

  @Attribute({ serializedName: 'accrued', converter })
    accrued: boolean;

  @Attribute({ serializedName: 'count_against_quality_ratio', converter })
    countAgainstQualityRatio: boolean;

  @Attribute({ serializedName: 'third_party_name', converter })
    thirdPartyName: string;

  @Attribute({ serializedName: 'amount', converter })
    amount: number;

  @Attribute({ serializedName: 'default_amount', converter })
    defaultAmount: number;

  @Attribute({ serializedName: 'adjusted_amount', converter })
    adjustedAmount: number;

  @Attribute({ serializedName: 'accrual_amount', converter })
    accrualAmount: number;

  @Attribute({ serializedName: 'difference_reason_id', converter })
    differenceReasonId: number;

  @Attribute({ serializedName: 'estimate_date', converter: dateConverter })
    estimateDate: Date;

  @Attribute({ serializedName: 'change_reason', converter })
    changeReason: string;

  @Attribute({ serializedName: 'completed', converter })
    completed: boolean;

  @Attribute({ serializedName: 'approver', converter })
    approver: number;

  @Attribute({ serializedName: 'completed_by', converter })
    completedBy: number;

  @Attribute({ serializedName: 'completed_date', converter: dateConverter })
    completedDate: Date;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'policy_drop_off_period_id', converter })
    policyDropOffPeriodId: number;

  @Attribute({ serializedName: 'policy_drop_off', converter })
    policyDropOff: boolean;

  @Attribute({ serializedName: 'policy_drop_off_month', converter })
    policyDropOffMonth: number;

  @Attribute({ serializedName: 'policy_drop_off_year', converter })
    policyDropOffYear: number;

  @Attribute({ serializedName: 'policy_drop_off_amount', converter })
    policyDropOffAmount: number;

  @Attribute({ serializedName: 'prorated_payment_amount', converter })
    proratedPaymentAmount: number;

  @Attribute({ serializedName: 'attributes1', converter })
    attributes1: string;

  @Attribute({ serializedName: 'attributes2', converter })
    attributes2: string;

  @Attribute({ serializedName: 'attributes3', converter })
    attributes3: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'accrual_balance', converter })
    accrualBalance: number;

  @BelongsTo()
    investigation: Investigation;

  @BelongsTo()
    investigationDetail: InvestigationDetail;

  @BelongsTo()
    damage: Damage;

  @BelongsTo()
    wfNotification: WfNotification;

  @BelongsTo()
    financialPeriod: FinancialPeriod;

  @BelongsTo()
    quickCValue: QuickCValue;

  @BelongsTo()
    cmsRequest: CmsRequest;

  @BelongsTo()
    district: District;

}
