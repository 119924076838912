import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import {
  Announcement,
  AssetAssignment,
  LocatorCheckin,
  Vehicle,
  WirelessInventory,
} from '../assetmanager';
import { DeviceToken } from '../messaging';
import { District } from './district';
import { SupervisorGroup } from './supervisor-group';
import { UserDataAccess } from './user-data-access';


/*****************************************
 * Employee
 *****************************************/
@JsonApiModelConfig({
  type: 'employees'
})
export class Employee extends JsonApiModel {

  @Attribute({ serializedName: 'emp_id', converter })
    empId: number;

  @Attribute({ serializedName: 'emp_type', converter })
    empType: string;

  @Attribute({ serializedName: 'legal_name', converter })
    legalName: string;

  @Attribute({ serializedName: 'legal_name_first', converter })
    legalNameFirst: string;

  @Attribute({ serializedName: 'legal_name_last', converter })
    legalNameLast: string;

  @Attribute({ serializedName: 'job_code', converter })
    jobCode: number;

  @Attribute({ converter })
    position: string;

  @Attribute({ converter })
    email: string;

  @Attribute({ serializedName: 'active_status', converter })
    activeStatus: string;

  @Attribute({ serializedName: 'effective_date', converter: dateConverter })
    effectiveDate: Date;

  @Attribute({ serializedName: 'sup_group_id', converter })
    supervisorGroupId: number;

  @Attribute({ serializedName: 'sup_group_code', converter })
    supGroupCode: string;

  @Attribute({ serializedName: 'sup_emp_id', converter })
    supEmpId: number;

  @Attribute({ converter })
    supervisor: string;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'district', converter })
    districtName: string;

  @Attribute({ serializedName: 'district_abbr', converter })
    districtAbbr: string;

  @Attribute({ converter })
    state: string;

  @Attribute({ converter })
    region: string;

  @Attribute({ serializedName: 'eff_sup_group_id', converter })
    effSupGroupId: number;

  @Attribute({ serializedName: 'eff_sup_group_code', converter })
    effSsupGroupCode: string;

  @Attribute({ serializedName: 'eff_sup_emp_id', converter })
    effSupEmpId: number;

  @Attribute({ serializedName: 'eff_supervisor', converter })
    effSupervisor: string;

  @Attribute({ serializedName: 'eff_district_id', converter })
    effDistrictId: number;

  @Attribute({ serializedName: 'eff_district', converter })
    effDistrict: string;

  @Attribute({ serializedName: 'eff_district_abbr', converter })
    effDistrictAbbr: string;

  @Attribute({ serializedName: 'eff_state', converter })
    effState: string;

  @Attribute({ serializedName: 'eff_region', converter })
    effRegion: string;

  @Attribute({ serializedName: 'exclusion_code', converter })
    exclusionCode: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ converter })
    action: string;

  @Attribute({ converter })
    reason: string;

  @Attribute({ serializedName: 'estimated_return_date', converter })
    estimatedReturnDate: string;


  @BelongsTo({ key : 'dist' })
    district: District;

  @BelongsTo({ key: 'supgroup' })
    supervisorGroup: SupervisorGroup;

  @HasMany()
    announcements: Announcement[];

  @HasMany()
    assetAssignments: AssetAssignment[];

  @HasMany()
    wirelessInventories: WirelessInventory[];

  @HasMany()
    vehicles: Vehicle[];

  @HasMany()
    userDataAccesses: UserDataAccess[];

  @HasMany()
    locatorCheckins: LocatorCheckin[];

  @HasMany()
    deviceTokens: DeviceToken[];

  /*
    @HasMany()
    requests: Request[];
    */

  get displayName() {
    return this.legalName.replace(/^(.+), ([^\s]+)\s?(.*?)$/i, '$2 $1');
  }

}
