import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';

/*****************************************
 * EmployeeScore
 *****************************************/
@JsonApiModelConfig({
  type: 'employeeScores'
})
export class EmployeeScore extends JsonApiModel {

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @Attribute({ serializedName: 'employee_trend_date', converter: dateConverter })
    employeeTrendDate: Date;

  @Attribute({ serializedName: 'employee_quality_score', converter })
    employeeQualityScore: number;

  @Attribute({ serializedName: 'employee_most_recent_damage_date', converter })
    employeeMostRecentDamageDate: string;

  @Attribute({ serializedName: 'driver_score', converter })
    driverScore: number;

  @Attribute({ serializedName: 'driver_eph', converter })
    driverEph: number;

  @Attribute({ serializedName: 'excluded_telematices_ind', converter })
    excludedTelematicsInd: string;
}
