import {
  JsonApiModelConfig,
} from '@michalkotas/angular2-jsonapi';
import { Locate } from './locate';


/*****************************************
 * LocateOpenSnapshot
 *****************************************/
@JsonApiModelConfig({
  type: 'locateOpenSnapshots'
})
export class LocateOpenSnapshot extends Locate {
}

