import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Application } from './application';
import { Attachment } from './attachment';

/*****************************************
 * StorageVolume
 *****************************************/
@JsonApiModelConfig({
  type: 'storageVolumes'
})
export class StorageVolume extends JsonApiModel {

  @Attribute({ serializedName: 'application_id', converter })
    applicationId: number;

  @BelongsTo()
    application: Application;

  @Attribute({ converter })
    path: string;

  @Attribute({ converter })
    protocol: string;

  @Attribute({ converter })
    active: boolean;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    attachments: Attachment[];

}
