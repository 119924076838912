import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { Asset } from './asset';

/*****************************************
 * AssetTrackMaintenance
 *****************************************/
@JsonApiModelConfig({
  type: 'assetTrackMaintenances'
})
export class AssetTrackMaintenance extends JsonApiModel {

  @Attribute({ serializedName: 'asset_type', converter })
    assetType: string;

  @Attribute({ serializedName: 'serial_number', converter })
    serialNumber: string;

  @Attribute({ serializedName: 'tag_number', converter })
    tagNumber: string;

  @Attribute({ serializedName: 'contact_name', converter })
    contactName: string;

  @Attribute({ serializedName: 'location_name', converter })
    locationName: string;

  @Attribute({ serializedName: 'organization_name', converter })
    organizationNAme: string;

  @Attribute({ serializedName: 'calibration_due_date', converter })
    calibrationDueDate: string;

  @Attribute({ converter })
    task: string;

  @Attribute({ serializedName: 'asset_status', converter })
    assetStatus: string;

  @BelongsTo()
    asset: Asset;

  @Attribute({ serializedName: 'asset_id', converter })
    assetId: number;

  @Attribute({ serializedName: 'version_number', converter })
    version: number;


}
