import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import { Locate } from './locate';
import {
  converter,
  dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * TicketInfo
 *****************************************/
@JsonApiModelConfig({
  type: 'projectTickets',
})
export class ProjectTicket extends JsonApiModel {

  @Attribute({ serializedName: 'ticket_id', converter })
    ticketId: number;

  // circular dependency
  // @BelongsTo()
  // ticket: Ticket;

  @HasMany()
    locates: Locate[];

  @Attribute({ serializedName: 'identified_by_id', converter })
    identifiedById: number;

  @Attribute({ serializedName: 'identified_date', converter: dateConverter })
    identifiedDate: Date;

}
