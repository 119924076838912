/********************************************************************************
 * SchedulableJsonApiModel
 *
 * Base class for a JsonApiModel that can be scheduled in the Quartz scheduler.
 * Also defines the SchedulerInfo class which is added to the JsonApiModel
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import {
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';

export class SchedulerInfo {
  cron_expression: string;
  time_zone: string;
  repeat_time: number;
  start_date: string;
  end_date: string;
}

export class SchedulableJsonApiModel extends JsonApiModel {

  @Attribute()
    schedulerInfo: SchedulerInfo;

}
