/********************************************************************************
 * JwtInterceptor (implements HttpInterceptor)
 *
 * Add the JWT authorization to requests automatically
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';

// Allow bypassing of this interceptor for non-JWT requests
export const BYPASS_JWT = new HttpContextToken(() => false);

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authentication: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.context.get(BYPASS_JWT) === true) {
      return next.handle(request);
    }

    // add authorization header with jwt token if available
    const currentUser = this.authentication.currentUserValue;
    if (currentUser && currentUser.token) {

      // Need to do separate clones to set each header.  If you
      // try to consolidate like:
      //
      // const headers = new HttpHeaders({
      //   Authorization: `Bearer ${currentUser.token}`,
      //   'Crnk-Compact': 'true',
      // });
      // request = request.clone({ headers });
      //
      // the request looses other headers set by the json:api
      // library like:
      // Content-Type: 'application/vnd.api+json'

      request = request
        .clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token}`
          }
        })
        .clone({
          setHeaders: {
            'Crnk-Compact': 'true'
          }
        });

    }

    return next.handle(request);
  }
}
