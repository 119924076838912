import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { CmsCustomer } from './cms-customer';
import { Damage } from './damage';

/*****************************************
 * DamageCustomer
 *****************************************/
@JsonApiModelConfig({
  type: 'damageCustomers',
})
export class DamageCustomer extends JsonApiModel {

  @Attribute({ serializedName: 'damage_id', converter })
    damageId: number;

  @Attribute({ serializedName: 'customer_id', converter })
    customerId: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy$: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy$: string;

  @BelongsTo()
    cmsCustomer: CmsCustomer;

  @BelongsTo()
    damage: Damage;

}
