@if (displayedColumns.length) {
  @if (breakpoints.screen('gt-xs')) {
    <button mat-button type="button"
            matTooltip="Modify Columns" matTooltipPosition="above"
            [matMenuTriggerFor]="columnsMenu">

      <mat-icon matPrefix>view_week</mat-icon>
      <span> Columns</span>
    </button>
  } @else {
    <button mat-icon-button class="small-button"
            matTooltip="Modify Columns" matTooltipPosition="above"
            [matMenuTriggerFor]="columnsMenu">
      <mat-icon matPrefix>view_week</mat-icon>
    </button>
  }
}

<mat-menu #columnsMenu="matMenu">

  <div cdkDropList (cdkDropListDropped)="reorderColumn($event)">

    @for (column of displayedColumns; track $index) {

      @if (column.name !== 'select' && !(column.hideMobile && isMobile)) {
        <button mat-menu-item
                cdkDrag [cdkDragData]="column" class="column-button"
                (click)="toggleColumn($event, column)">
          <mat-icon class="material-symbols-outlined">{{column.hidden ? 'visibility_off' : 'visibility'}}</mat-icon>
          <span class="column-name">{{column.title || (column.name | titleize)}}</span>
        </button>
      }

    }

    <!--<button mat-menu-item (click)="hideAllColumns()">
      <mat-icon>restore</mat-icon>
      <span>Hide All Columns</span>
    </button>-->

    <button mat-menu-item (click)="resetColumns()" class="column-button">
      <mat-icon>restore</mat-icon>
      <span class="column-name">Reset Columns</span>
    </button>

  </div>

</mat-menu>
