/********************************************************************************
 * PublicHeaderComponent (lib-public-header)
 *
 * Angular component to show the public website header
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component, Renderer2 } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'lib-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['../public-header/public-header.component.scss'],
  imports: [NgClass, MatTooltip]
})

export class PublicHeaderComponent {
  public hamburgerActive = false;
  public mNavIsActive = false;

  constructor(private render: Renderer2) { }

  clickHamburger(event: MouseEvent) {
    event.preventDefault();

    this.hamburgerActive = !this.hamburgerActive;
    this.mNavIsActive = !this.mNavIsActive;
    // $("html").toggleClass('with-scrollbarComp');
    // $("body").toggleClass('hide-scrollbar');
  }

  clickDropdown(event: any) {
    event.preventDefault();
    const isActive = event.target.classList.contains('is-active');

    if (isActive) {
      this.render.removeClass(event.target, 'is-active');
    } else {
      this.render.addClass(event.target, 'is-active');
    }
  }

}
