import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Report } from './report';

/*****************************************
 * ReportSource
 *****************************************/
@JsonApiModelConfig({
  type: 'reportSources'
})
export class ReportSource extends JsonApiModel {

  @Attribute({ serializedName: 'name', converter })
    name: string;

  @Attribute({ serializedName: 'data_source', converter })
    dataSource: string;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'required_roles', converter })
    requiredRolesStr: string;

  get requiredRoles(): string[] {
    return this.requiredRolesStr?.split(',');
  }

  set requiredRoles(requiredRoles: string[]) {
    this.requiredRolesStr = requiredRoles?.join(',');
  }

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    reports: Report[];

  reportsForUser(userId: number, userRoles: string[]) {
    return this.reports.filter(report =>
      // report isn't role restricted or user has role required for report
      (report.requiredRoles === undefined ||
        report.requiredRoles.length === 0 ||
        userRoles.some(r => report.requiredRoles.includes(r)))
      &&
      // report is global or owned by the current user
      (report.global ||
        report.owner === userId)
    );
  }
}
