import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Claim } from './claim';
import { ClaimCategory } from './claim-category';

/*****************************************
 * ClaimSpendCategory
 *****************************************/
@JsonApiModelConfig({
  type: 'claimSpendCategories'
})
export class ClaimSpendCategory extends JsonApiModel {

  @Attribute({ serializedName: 'claim_category_id', converter })
    claimCategoryId: number;

  @Attribute({ serializedName: 'facility_damage_related', converter })
    facilityDamageRelated: boolean;

  @Attribute({ serializedName: 'spend_category', converter })
    spendCategory: string;

  @Attribute({ serializedName: 'wd_acct', converter })
    wdAcct: string;

  @Attribute({ serializedName: 'include_pl_rp_paid', converter })
    includePlRpPaid: boolean;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    claims: Claim[];

  @BelongsTo()
    claimCategory: ClaimCategory;

}
