import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Employee, SupervisorGroup } from '.';
import { Asset, DistrictLocation, Location } from '../assetmanager';


/*****************************************
 * District
 *****************************************/
@JsonApiModelConfig({
  type: 'districts'
})
export class District extends JsonApiModel {

  @Attribute({ serializedName: 'workday_id', converter })
    workdayId: number;

  @Attribute({ serializedName: 'district_code', converter })
    districtCode: number;

  @Attribute({ serializedName: 'district_abbr', converter })
    districtAbbr: string;

  @Attribute({ serializedName: 'district_name', converter })
    districtName: string;

  @Attribute({ converter })
    state: string;

  @Attribute({ converter })
    region: string;

  @Attribute({ serializedName: 'district_manager_employee_id', converter })
    districtManagerEmployeeId: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    assets: Asset[];

  @HasMany()
    supervisorGroups: SupervisorGroup[];

  @HasMany()
    employees: Employee[];

  @HasMany()
    locations: Location[];

  @HasMany()
    districtLocations: DistrictLocation[];

  // circular dependency
  // @BelongsTo()
  //   districtManager: Employee;
}
