import {Component, Input, OnInit} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'lib-usic-error-panel',
  templateUrl: './usic-error-panel.component.html',
  styleUrls: ['./usic-error-panel.component.scss'],
  imports: [MatIcon, MatIconButton]
})
export class UsicErrorPanelComponent implements OnInit {
  @Input({ required: true }) errors: string[];

  constructor() { }

  ngOnInit(): void {
  }

}
