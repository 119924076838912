/********************************************************************************
 * PublicFooterComponent (lib-public-footer)
 *
 * Angular component to show the public website footer
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component } from '@angular/core';

@Component({
  selector: 'lib-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['../public-header/public-header.component.scss']
})
export class PublicFooterComponent {
  currentDate = new Date();

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
