import {
  JsonApiModelConfig,
} from '@michalkotas/angular2-jsonapi';
import { TkpAttachment } from './tkp-attachment';


/*****************************************
 * TkpAttachmentsWithNotification
 *****************************************/
@JsonApiModelConfig({
  type: 'tkpAttachmentsWithNotification',
  modelEndpointUrl: 'attachmentsWithNotifications',
})
export class TkpAttachmentsWithNotification extends TkpAttachment {
}
