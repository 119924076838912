import { BelongsTo, HasMany } from '@michalkotas/angular2-jsonapi';
import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { Locate } from './locate';
import { TransmissionBase } from './transmission-base';


/*****************************************
 * TransmissionText
 *****************************************/
@JsonApiModelConfig({
  type: 'transmissionsText',
})
export class TransmissionText extends JsonApiModel {

  get transmissionId() {
    return this.id;
  }

  @Attribute({ serializedName: 'raw_image', converter })
    rawImage: string;


  @HasMany()
    locates: Locate[];

  @BelongsTo()
    transmissionBase: TransmissionBase;

}
