import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { Asset } from './asset';

/*****************************************
 * AssetTransferException
 *****************************************/
@JsonApiModelConfig({
  type: 'assetTransferExceptions'
})
export class AssetTransferException extends JsonApiModel {

  @Attribute({ serializedName: 'employee_name', converter })
    employeeName: string;

  @Attribute({ serializedName: 'job_title', converter })
    jobTitle: string;

  @Attribute({ serializedName: 'asset_type', converter })
    assetType: string;

  @Attribute({ serializedName: 'serial_number', converter })
    serialNumber: string;

  @Attribute({ serializedName: 'tag_number', converter })
    tagNumber: string;

  @Attribute({ serializedName: 'transferring_cost_center', converter })
    transferringCostCenter: string;

  @Attribute({ serializedName: 'transferring_district_id', converter })
    transferringDistrictId: number;

  @Attribute({ serializedName: 'receiving_cost_center', converter })
    receivingCostCenter: string;

  @Attribute({ serializedName: 'receiving_supgroup_code', converter })
    receivingSupgroupCode: string;

  @Attribute({ serializedName: 'total_days_in_transit', converter })
    totalDaysInTransit: number;

  @Attribute({ serializedName: 'asset_district_id', converter })
    assetDistrictId: number;

  @Attribute({ serializedName: 'asset_supgroup_code', converter })
    assetSupgroupCode: string;

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @BelongsTo()
    asset: Asset;

  @Attribute({ serializedName: 'asset_id', converter })
    assetId: number;

  @Attribute({ serializedName: 'version_number', converter })
    version: number;

}
