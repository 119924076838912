import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Investigation } from './investigation';
import { Employee } from '../shareddata';
import { Damage } from './damage';
import { InvestigationDetailsVersion } from './investigation-details-version';

/*****************************************
 * InvestigationVersion
 *****************************************/
@JsonApiModelConfig({
  type: 'investigationVersions',
})
export class InvestigationVersion extends JsonApiModel {

  @Attribute({ serializedName: 'investigation_id', converter })
    investigationId: number;

  @Attribute({ serializedName: 'damage_id', converter })
    damageId: number;

  @Attribute({ serializedName: 'excavator_id', converter })
    excavatorId: number;

  @Attribute({ serializedName: 'excavation_type_id', converter })
    excavationTypeId: number;

  @Attribute({ serializedName: 'ticket_id', converter })
    ticketId: number;

  @Attribute({ serializedName: 'ticket_number', converter })
    ticketNumber: string;

  @Attribute({ serializedName: 'ticket_start_date', converter: dateConverter })
    ticketStartDate: Date;

  @Attribute({ serializedName: 'ticket_closed_date', converter: dateConverter })
    ticketClosedDate: Date;

  @Attribute({ serializedName: 'inv_start_date', converter: dateConverter })
    invStartDate: Date;

  @Attribute({ serializedName: 'inv_end_date', converter: dateConverter })
    invEndDate: Date;

  @Attribute({ serializedName: 'dig_up_dmg_req', converter })
    digUpDmgReq: string;

  @Attribute({ serializedName: 'dig_up_dmg_ticket', converter })
    digUpDmgTicket: boolean;

  @Attribute({ serializedName: 'pics_taken_by', converter })
    picsTakenBy: number;

  @Attribute({ serializedName: 'approved', converter })
    approved: number;

  @Attribute({ serializedName: 'approved_date', converter: dateConverter })
    approvedDate: Date;

  @Attribute({ serializedName: 'approver', converter })
    approver: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'add_1', converter })
    add1: string;

  @Attribute({ serializedName: 'add_2', converter })
    add2: string;

  @Attribute({ serializedName: 'city', converter })
    city: string;

  @Attribute({ serializedName: 'stateid', converter })
    stateId: number;

  @Attribute({ serializedName: 'zip', converter })
    zip: string;

  @Attribute({ serializedName: 'county', converter })
    county: string;

  @Attribute({ serializedName: 'countryid', converter })
    countryId: number;

  @Attribute({ serializedName: 'cross_str1', converter })
    crossStr1: string;

  @Attribute({ serializedName: 'cross_str2', converter })
    crossStr2: string;

  @Attribute({ serializedName: 'excavator', converter })
    excavator: string;

  @Attribute({ serializedName: 'locator_id', converter })
    locatorId: number;

  @Attribute({ serializedName: 'locator_first_nm', converter })
    locatorFirstNm: string;

  @Attribute({ serializedName: 'locator_last_nm', converter })
    locatorLastNm: string;

  @Attribute({ serializedName: 'ticket_type', converter })
    ticketType: string;

  @Attribute({ serializedName: 'type_of_work', converter })
    typeOfWork: string;

  @Attribute({ serializedName: 'boring', converter })
    boring: boolean;

  @Attribute({ serializedName: 'completed', converter })
    completed: boolean;

  @Attribute({ serializedName: 'owner', converter })
    owner: string;

  @Attribute({ serializedName: 'completed_date', converter: dateConverter })
    completed_date: Date;

  @Attribute({ serializedName: 'wf_item_id', converter })
    wfItemId: number;

  @Attribute({ serializedName: 'search_text', converter })
    searchText: string;

  @Attribute({ serializedName: 'latitude', converter })
    latitude: number;

  @Attribute({ serializedName: 'longitude', converter })
    longitude: number;

  @Attribute({ serializedName: 'geo_location', converter })
    geoLocation: string;

  @Attribute({ serializedName: 'dots_number', converter })
    dotsNumber: string;

  @Attribute({ serializedName: 'customer_claim_number', converter })
    customerClaimNumber: string;

  @Attribute({ serializedName: 'changed_date', converter : dateConverter})
    changedDate: Date;

  @Attribute({ serializedName: 'changed_by', converter })
    changedBy: number;

  @Attribute({ serializedName: 'post_approver_change', converter })
    postApproverChange: boolean;

  @Attribute({ serializedName: 'change_reason_id', converter })
    changeReasonId: number;

  @Attribute({ serializedName: 'change_reason_text', converter })
    changeReasonText: string;

  @Attribute({ serializedName: 'one_call_request_ticket_id', converter })
    oneCallRequestTicketId: number;

  @Attribute({ serializedName: 'customer_claim_number_2', converter })
    customerClaimNumber2: string;

  @BelongsTo()
    investigation: Investigation;

  @BelongsTo()
    damage: Damage;

  @BelongsTo()
    approverEmployee: Employee;

  @BelongsTo()
    picsTakenByEmployee: Employee;

  @HasMany()
    investigationDetailsVersions: InvestigationDetailsVersion[];

}
