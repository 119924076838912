import {
  JsonApiModelConfig,
} from '@michalkotas/angular2-jsonapi';
import { Employee } from './employee';


/*****************************************
 * EmployeesActiveMultipleItAssets
 *****************************************/
@JsonApiModelConfig({
  type: 'employeesActiveMultipleItAssets'
})
export class EmployeesActiveMultipleItAssets extends Employee {
}

