/********************************************************************************
 * LoginFormComponent (lib-login-form)
 *
 * Angular component to show a login form and invoke the authentication
 *
 * parameters
 *   method    : string indicating which method of the authentication to use
 *               current options are
 *               'jwt' for JWT (via Active Directory) login via JSON:API
 *               'activedirectory' for Active Directory login via routing demo API
 *               'customerportal' for Customer Portal login via the CP API
 *   showAzure : boolean indicating if the 'Microsoft Login' option should be available
 *
 * events
 *   loading  : event emitter indicating when a login is in process
 *   errorMsg : event emitter to provide error
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'lib-login-form',
  templateUrl: 'login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  imports: [FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, NgClass, MatIcon, MatSuffix, MatError, MatButton]
})
export class LoginFormComponent implements OnInit {
  @Input() method: string;
  @Input() showAzure = true;
  @Output() loading = new EventEmitter<boolean>();
  @Output() errorMsg = new EventEmitter<string>();
  loginForm: FormGroup;
  isLoading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authentication: AuthenticationService
  ) {
    // redirect to home if already logged in
    if (this.authentication.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.loading.emit(this.isLoading);

    let loginFn;
    switch (this.method.toLocaleLowerCase()) {
      case 'jwt':
        loginFn = 'loginJWT';
        break;
      case 'activedirectory':
        loginFn = 'loginAD';
        break;
      case 'customerportal':
        loginFn = 'loginCP';
        break;
      default:
        loginFn = 'loginJWT';
    }
    // eslint-disable-next-line security/detect-object-injection
    this.authentication[loginFn](this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe({
        complete: () => {
          this.router.navigate([this.returnUrl]);
        },
        error: error => {
          if (error.error && error.error.errors instanceof Array) {
            // error is from JSON:API
            this.errorMsg.emit(error.error.errors[0].detail);
          } else {
            this.errorMsg.emit(error);
          }
        }
      })
      .add(() => {
        this.isLoading = false;
        this.loading.emit(this.isLoading);
      });
  }

  azureLogin() {
    this.authentication.loginAzure()
      .then((result) => {
        if (result) {
          this.authentication.validateAzureResult(result)
            .pipe(first())
            .subscribe({
              complete: () => {
                this.router.navigate([this.returnUrl]);
              },
              error: error => {
                if (error.error && error.error.errors instanceof Array) {
                  // error is from JSON:API
                  this.errorMsg.emit(error.error.errors[0].detail);
                } else {
                  this.errorMsg.emit(error);
                }
              }
            })
            .add(() => {
              this.isLoading = false;
              this.loading.emit(this.isLoading);
            });
        }
      });
    return false;
  }


}
