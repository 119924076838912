<div class="table-toolbar bg-usic-surface-light border-top border-bottom">

  <div class="layout layout-align-space-between-center">

    <!-- selected actions -->
    <div class="layout layout-align-start-center flex selected-actions bg-usic-white mat-caption">

      @if (parent.selectedRows?.length && parent.selectedActions?.length) {

        <div class="more-icon layout layout-align-start-center"
             [matMenuTriggerFor]="selectedMenu"
             matTooltip="Perform actions on selected rows"
             matTooltipPosition="above">
          <mat-icon matPrefix>more_vert</mat-icon>
        </div>

        <div class="padding-right layout layout-align-start-center">

          @if (parent.cumulativeSelections && parent.cumulativeSelectionsKeys?.length) {

            <button class="show-selected"
                    [matMenuTriggerFor]="cumSelectedMenu"
                    mat-flat-button color="accent">

              <ng-container *ngTemplateOutlet="selectedText"></ng-container>

            </button>

          } @else {

            <ng-container *ngTemplateOutlet="selectedText"></ng-container>

          }

          <ng-template #selectedText>
            <span style="white-space: nowrap">{{parent.selectedRows?.length}} selected</span>
          </ng-template>

          <mat-menu #cumSelectedMenu="matMenu">

            <ol>

              @for (selectedRow of parent.selectedRows; let i = $index; track i) {

                <li class="mat-caption padding-right">

                  @for (property of parent.selectedRows[i] | keyvalue; track property.key) {

                    @for (key of parent.cumulativeSelectionsKeys; track key.name; let keysIdx = $index) {

                      @if (property.key === key.name) {
                        <span>
                          {{ keysIdx && ( keysIdx > 0 ) ? '|' : null }}
                          {{ key.label }}: {{ property.value }}
                        </span>
                      }

                    }

                  }

                </li>

              }

            </ol>

          </mat-menu>

        </div>

        <mat-menu #selectedMenu="matMenu">
          @for (selectedAction of parent.selectedActions; track selectedAction.name) {
            <button mat-menu-item class="selected-action-button" (click)="selectedAction.action($event)" [disabled]="!selectedAction.enabled">

              @if (selectedAction.class) {

                <div class="{{selectedAction.class}}">
                  <mat-icon>{{selectedAction.icon}}</mat-icon>
                  <span></span>
                </div>
                <span class="selected-action-name">{{selectedAction.name}}</span>

              } @else {

                <ng-container>

                  <mat-icon>{{selectedAction.icon}}</mat-icon>
                  <span class="selected-action-name">{{selectedAction.name}}</span>

                </ng-container>
              }

            </button>
          }
        </mat-menu>

        <div class="padding-left layout layout-align-start-center">
          <mat-slide-toggle disableRipple="true"
                            (change)="$event ? parent.cumulativeSelections = $event.checked : null"
                            [checked]="parent.cumulativeSelections">
            <span class="mat-caption"> Cumulative </span>
          </mat-slide-toggle>
        </div>

      }

    </div>

    <!-- loading spinners -->
    <div class="text-usic-blue layout layout-align-center flex">

      @if ((parent.jsonApiDataSource?.loading | async) || parent.dataSourceLoading) {
        <button mat-button
                (click)="parent.jsonApiDataSource?.cancel(); cancelClicked.emit()"
                matTooltip="Data loading, click to abort">
          <mat-spinner [diameter]="18" color="accent"></mat-spinner>
          <span class="text-usic-green">Loading...</span>
        </button>
      }

      @if (!(parent.jsonApiDataSource?.loading | async) && parent.isExporting) {
        <button mat-button
                (click)="parent.cancelExport()"
                matTooltip="Exporting to Excel, click to abort">
          <mat-spinner [diameter]="18" color="accent"></mat-spinner>
          <span class="text-usic-green">Exporting...</span>
        </button>
      }

    </div>

    <!-- right buttons -->
    <div class="right-buttons layout layout-gap-25 layout-align-end-center flex">
      <ng-content></ng-content>

      @if (parent.filtering === 'column' && parent.hasFilters) {
        <!-- clear filters button -->
        @if (breakpoints.screen('gt-xs')) {
          <button mat-button
                  matTooltip="Clear column filters" matTooltipPosition="left"
                  (click)="parent.clearFilters()">
            <mat-icon matPrefix>search_off</mat-icon>
            Clear filters
          </button>
        } @else {
          <button mat-icon-button class="small-button"
                  matTooltip="Clear column filters" matTooltipPosition="left"
                  (click)="parent.clearFilters()">
            <mat-icon matPrefix>search_off</mat-icon>
          </button>
        }

      }

      @if (parent.provideExcelExport && !parent.isMobile) {
        <!-- export button -->
        @if (breakpoints.screen('gt-xs')) {
          <button mat-button
                  matTooltip="Export {{ parent.selectedRows?.length > 0 ? 'selected rows' : 'full table' }} to Excel" matTooltipPosition="above"
                  [disabled]="parent.isExporting"
                  (click)="parent.exportToExcel()">
            <mat-icon matPrefix>cloud_download</mat-icon>
            Export {{ parent.selectedRows?.length > 0 ? 'selected' : 'table' }}
          </button>
        } @else {
          <button mat-icon-button class="small-button"
                  matTooltip="Export {{ parent.selectedRows?.length > 0 ? 'selected rows' : 'full table' }} to Excel" matTooltipPosition="above"
                  [disabled]="parent.isExporting"
                  (click)="parent.exportToExcel()">
            <mat-icon matPrefix>cloud_download</mat-icon>
          </button>
        }

      }

      <lib-columns-selector [displayedColumns]="parent.displayedColumns"
                            [prefix]="parent.prefix"
                            [hiddenColumns]="parent.hiddenColumns"
                            [persistColumns]="parent.persistColumns"
                            (changeColumns)="onChangeColumns($event)">
      </lib-columns-selector>

      @if (parent.allowNew) {
        <!-- NEW button -->
        @if (breakpoints.screen('gt-xs')) {
          <button type="button" mat-flat-button color="grey"
                  matTooltip="Create New" matTooltipPosition="above"
                  (click)="parent.onCreateNew($event)">
            <mat-icon matPrefix> add </mat-icon>
              {{ parent.createButtonText || 'New' }}
          </button>
        } @else {
          <button mat-icon-button class="small-button"
                  matTooltip="Create New" matTooltipPosition="above"
                  (click)="parent.onCreateNew($event)">
            <mat-icon matPrefix> add </mat-icon>
          </button>
        }

      }

      <button mat-icon-button class="small-button text-usic-blue"
        matTooltip="Table Tips" matTooltipPosition="above"
        [disabled]="parent.isExporting" (click)="showTableHelp()">
        <mat-icon matPrefix class="material-symbols-outlined">lightbulb_2</mat-icon>
      </button>

    </div>

  </div>

</div>
