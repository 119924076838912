import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { BillableCustomer } from './billable-customer';
import { ClaimPayee } from './claim-payee';
import { DamageCustomer } from './damage-customer';
import { FlatRateCustomer } from './flat-rate-customer';
import { InvestigationDetailsVersion } from './investigation-details-version';

/*****************************************
 * CmsCustomer
 *****************************************/
@JsonApiModelConfig({
  type: 'cmsCustomers'
})
export class CmsCustomer extends JsonApiModel {

  get customerId() {
    return Number(this.id);
  }

  @Attribute({ serializedName: 'account_level_customer_id', converter })
    accountLevelCustomerId: number;

  @Attribute({ serializedName: 'account_level_customer', converter })
    accountLevelCustomer: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'eff_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'eff_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;


  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  @HasMany()
    damageCustomers: DamageCustomer[];

  @HasMany()
    claimPayees: ClaimPayee[];

  @HasMany()
    flatRateCustomers: FlatRateCustomer[];

  @HasMany()
    billableCustomers: BillableCustomer[];

  @HasMany()
    investigationDetailsVersions: InvestigationDetailsVersion[];

}
