<div class="date-range-select layout" [ngClass]="{'layout-column' : breakpoints.screen('xs'), 'layout-align-space-between-center layout-gap' : breakpoints.screen('gt-xs')}">

  <mat-form-field appearance="fill" class="flex">

    <mat-label>{{startDatePlaceholder}}</mat-label>
    <input matInput name="startDate" placeholder="{{startDatePlaceholder}}"
           [matDatepicker]="startDatePicker"
           [(ngModel)]="startDateModel"
           #startDate="ngModel"
           (change)="changeStartDate($event)" (blur)="onTouched()">
    <mat-datepicker #startDatePicker disabled="false"></mat-datepicker>
    <!--<mat-hint>Select a date</mat-hint>-->

    <div matSuffix>
      <button mat-icon-button (click)="this.dropdownToggle.start = !this.dropdownToggle.start">
        <mat-icon>date_range</mat-icon>
      </button>

      <div class="dropdown mat-elevation-z1" [ngStyle]="{'display': dropdownToggle.start ? 'block' : 'none' }">

        <div class="open-calendar padding-bottom border-bottom layout layout-align-start-center layout-gap" (click)="startDateModelChange">
          <mat-icon matPrefix>event</mat-icon>
          <span>Show Calendar</span>
        </div>

        @for (option of quickOptions; track option.value) {
          <p>
            <a href="javascript:void(0)"
              (click)="setDateRange(option.value)">{{ option.label }}</a>
          </p>
        }

      </div>

    </div>

  </mat-form-field>

  <mat-form-field appearance="fill" class="flex">

    <mat-label>{{endDatePlaceholder}}</mat-label>
    <input matInput [matDatepicker]="endDatePicker" name="endDate"
           [(ngModel)]="endDateModel" #endDate="ngModel"
           placeholder="{{endDatePlaceholder}}"
           (change)="changeEndDate($event)" (blur)="onTouched()">
    <mat-datepicker #endDatePicker disabled="false"></mat-datepicker>
    <!--<mat-hint>Select a date</mat-hint>-->

    <div matSuffix>
      <button mat-icon-button (click)="this.dropdownToggle.end = !this.dropdownToggle.end">
        <mat-icon>date_range</mat-icon>
      </button>

      <div class="dropdown mat-elevation-z1" [ngStyle]="{'display': dropdownToggle.end ? 'block' : 'none' }">

        <div class="open-calendar padding-bottom border-bottom layout layout-align-start-center layout-gap" (click)="endDateModelChange">
          <mat-icon matPrefix>event</mat-icon>
          <span>Show Calendar</span>
        </div>

        @for (option of quickOptions; track option.value) {
          <p>
            <a class="dropdown-item"
              (click)="setDateRange(option.value)"
              href="javascript:void(0)">{{ option.label }}</a>
          </p>
        }
      </div>
    </div>

  </mat-form-field>

  <ng-content></ng-content>

</div>
