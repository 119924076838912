import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * ResponseLog
 *****************************************/
@JsonApiModelConfig({
  type: 'responseLogs',
})
export class ResponseLog extends JsonApiModel {

  @Attribute({ serializedName: 'activity_id', converter })
    activityId: number;

  @Attribute({ serializedName: 'feed_id', converter })
    feedId: number;

  @Attribute({ serializedName: 'transmission_id', converter })
    transmissionId: number;

  @Attribute({ serializedName: 'when_sent', converter: dateConverter })
    whenSent: Date;

  @Attribute({ serializedName: 'whenreplied', converter: dateConverter })
    whenReplied: Date;

  @Attribute({ serializedName: 'reply', converter })
    reply: string;

  @Attribute({ serializedName: 'success', converter })
    success: boolean;

  @Attribute({ serializedName: 'codesent', converter })
    codesent: string;

  @Attribute({ serializedName: 'title', converter })
    title: string;

  @Attribute({ serializedName: 'seq_number', converter })
    seqNumber: number;


  // Circular dependency
  // @BelongsTo()
  // activity: Activity;

  // Circular dependency
  // @BelongsTo()
  // feed: Feed;

  // Circular dependency
  // @BelongsTo()
  // transmission: Transmission;



}
