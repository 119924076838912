import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Asset } from './asset';

/*****************************************
 * WriteoffLine
 *****************************************/
@JsonApiModelConfig({
  type: 'writeoffLines'
})
export class WriteoffLine extends JsonApiModel {

  @Attribute({ serializedName: 'error_message', converter })
    errorMessage: string;

  @Attribute({ serializedName: 'asset_number', converter })
    assetNumber: string;

  @Attribute({ serializedName: 'batch_id', converter })
    batchId: number;

  @Attribute({ serializedName: 'asset_model', converter })
    assetModel: string;

  @Attribute({ serializedName: 'serial_number', converter })
    serialNumber: string;

  @Attribute({ serializedName: 'import_status', converter })
    importStatus: string;

  @BelongsTo()
    asset: Asset;

  @Attribute({ serializedName: 'asset_id', converter })
    assetId: number;

  @Attribute({ serializedName: 'processed_on', converter: dateConverter })
    processedOn: Date;

  @Attribute({ serializedName: 'asset_status', converter })
    assetStatus: string;

  @Attribute({ serializedName: 'usic_tag', converter })
    usicTag: string;

  @Attribute({ converter })
    processed: boolean;

  @Attribute({ serializedName: 'asset_make', converter })
    assetMake: string;

  @Attribute({ serializedName: 'writeoff_date', converter: dateConverter })
    writeoffDate: Date;

  @Attribute({ serializedName: 'description', converter })
    description: string;

  @Attribute({ converter })
    district: string;

  @Attribute({ serializedName: 'asset_type', converter })
    assetType: string;

  @Attribute({ serializedName: 'asset_notes', converter })
    assetNotes: string;

}
