import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { District } from '../shareddata';

/*****************************************
 * CmsUserDataAccess
 *****************************************/
@JsonApiModelConfig({
  type: 'cmsUserDataAccesses',
  modelEndpointUrl: 'userDataAccesses',
})
export class CmsUserDataAccess extends JsonApiModel {

  @Attribute({ serializedName: 'peoplesoft_id', converter })
    peoplesoftId: number;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    endDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'all_districts', converter })
    allDistricts: boolean;

  @BelongsTo()
    district: District;

}
