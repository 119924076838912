import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * VerizonWirelessInventoryUsage
 *****************************************/
@JsonApiModelConfig({
  type: 'verizonWirelessInventoryUsages'
})
export class VerizonWirelessInventoryUsage extends JsonApiModel {

  @Attribute({ serializedName: 'acct_no', converter })
    acctNo: number;

  @Attribute({ serializedName: 'sub_acct_no', converter })
    subAcctNo: number;

  @Attribute({ serializedName: 'mdn', converter })
    mdn: string;

  @Attribute({ serializedName: 'usage_date', converter: dateConverter })
    usageDate: string;

  @Attribute({ serializedName: 'usage_time', converter: dateConverter })
    usageTime: Date;

  @Attribute({ serializedName: 'usage_type', converter })
    usageType: string;

  @Attribute({ serializedName: 'usage_count', converter })
    usageCount: string;

  @Attribute({ serializedName: 'overseas_ind', converter })
    overseasInd: string;

  @Attribute({ serializedName: 'incoming_direction_ind', converter })
    incomingDirectionInd: string;

  @Attribute({ serializedName: 'dialed_digits', converter })
    dialedDigits: string;

  @Attribute({ serializedName: 'roaming_ind', converter })
    roamingInd: string;

  @Attribute({ serializedName: 'roaming_provider', converter })
    roamingProvider: string;

  @Attribute({ serializedName: 'country_code', converter })
    countryCode: string;

  @Attribute({ serializedName: 'duration_time', converter })
    durationTime: number;

  @Attribute({ serializedName: 'sequence_num', converter })
    sequenceNum: number;

  @Attribute({ serializedName: 'data_type', converter })
    dataType: string;

  @Attribute({ serializedName: 'filler', converter })
    filler: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

}
