import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * WsUserAccess
 *****************************************/
@JsonApiModelConfig({
  type: 'wsUserAccesses',
  // modelEndpointUrl: 'usersAccesses',
})
export class WsUserAccess extends JsonApiModel {

  @Attribute({ serializedName: 'token_id', converter })
    tokenId: number;

  @Attribute({ serializedName: 'namespace', converter })
    namespace: string;

  @Attribute({ serializedName: 'classs', converter })
    classs: string;

  @Attribute({ serializedName: 'method', converter })
    method: string;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  // circular dependency
  // @BelongsTo()
  // token: Token;

}
