import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { CpDistrict } from './cp-district';
import { User } from './user';


/*****************************************
 * UserContact
 *****************************************/
@JsonApiModelConfig({
  type: 'userContacts'
})
export class UserContact extends JsonApiModel {

  @Attribute({ serializedName: 'cp_user_id', converter })
    cpUserId: number;

  set cpUser(cpUser: User) {
    this.cpUserId = +cpUser.id;
  }

  @Attribute({ serializedName: 'cp_user_name', converter })
    cpUserName: string;

  @Attribute({ serializedName: 'cp_district_code', converter })
    districtCode: number;

  @Attribute({ serializedName: 'cp_district_abbr', converter })
    districtAbbr: string;

  @Attribute({ serializedName: 'cp_district_name', converter })
    districtName: string;

  @Attribute({ serializedName: 'usic_contact_id', converter })
    usicContactId: number;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'district_state', converter })
    districtState: string;

  set district(cpDistrict: CpDistrict) {
    this.districtId = +cpDistrict.id;
    this.districtName = cpDistrict.districtName;
    this.districtAbbr = cpDistrict.districtAbbr;
    this.districtCode = cpDistrict.districtCode;
    this.districtState = cpDistrict.state;
  }

  @Attribute({ serializedName: 'address_line1', converter })
    addressLine1: string;

  @Attribute({ serializedName: 'address_line2', converter })
    addressLine2: string;

  @Attribute({ serializedName: 'address_line3', converter })
    addressLine3: string;

  @Attribute({ serializedName: 'city', converter })
    city: string;

  @Attribute({ serializedName: 'state', converter })
    state: string;

  @Attribute({ serializedName: 'country', converter })
    country: string;

  @Attribute({ serializedName: 'dm_name', converter })
    dmName: string;

  @Attribute({ serializedName: 'dm_email', converter })
    dmEmail: string;

  @Attribute({ serializedName: 'dm_phone_no', converter })
    dmPhoneNo: string;

  @Attribute({ serializedName: 'hr', converter })
    hr: string;

  @Attribute({ serializedName: 'hr_phone_no', converter })
    hrPhoneNo: string;

  @Attribute({ serializedName: 'rd_name', converter })
    rdName: string;

  @Attribute({ serializedName: 'rd_phone_no', converter })
    rdPhoneNo: string;

  @Attribute({ serializedName: 'rd_email', converter })
    rdEmail: string;

  @Attribute({ serializedName: 'it_support', converter })
    itSupport: string;

  @Attribute({ serializedName: 'it_support_phone_no', converter })
    itSupportPhoneNo: string;

  @Attribute({ serializedName: 'sales_contact', converter })
    salesContact: string;

  @Attribute({ serializedName: 'sales_phone_no', converter })
    salesPhoneNo: string;

  @Attribute({ serializedName: 'sales_email', converter })
    salesEmail: string;

  @Attribute({ serializedName: 'general_contact', converter })
    generalContact: string;

  @Attribute({ serializedName: 'general_phone_no', converter })
    generalPhoneNo: string;

  @Attribute({ serializedName: 'general_email', converter })
    generalEmail: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'eff_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'eff_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }
}
