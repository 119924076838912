import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute, BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { Employee } from '../shareddata/employee';


/*****************************************
 * DeviceToken
 *****************************************/
@JsonApiModelConfig({
  type: 'deviceTokens',
})
export class DeviceToken extends JsonApiModel {

  get deviceTokenId() {
    return +this.id; // (+) converts string 'id' to a number
  }

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;  // recipient

  @BelongsTo()
    employee: Employee;

  @Attribute({ serializedName: 'device_token', converter })
    deviceToken: string;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ converter })
    attribute4: string;

  @Attribute({ converter })
    attribute5: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
      attribute4: this.attribute4,
      attribute5: this.attribute5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
    this.attribute2 = v.attribute2;
    this.attribute3 = v.attribute3;
    this.attribute4 = v.attribute4;
    this.attribute5 = v.attribute5;
  }

}
