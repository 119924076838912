import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { FinancialPeriod } from './financial-period';
import { District } from '../shareddata';
import { CmsRequest } from './cms-request';

/*****************************************
 * AccrualSummary
 *****************************************/
@JsonApiModelConfig({
  type: 'accrualSummaries',
})
export class AccrualSummary extends JsonApiModel {

  @Attribute({ serializedName: 'accrual_id', converter })
    accrualId: number;

  @Attribute({ serializedName: 'period_id', converter })
    periodId: number;

  @Attribute({ serializedName: 'request_id', converter })
    requestId: number;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'accrual_type', converter })
    accrualType: string;

  @Attribute({ serializedName: 'beginning_balance', converter })
    beginningBalance: number;

  @Attribute({ serializedName: 'ending_balance', converter })
    endingBalance: number;

  @Attribute({ serializedName: 'period_amount', converter })
    periodAmount: number;

  @Attribute({ serializedName: 'attributes1', converter })
    attributes1: string;

  @Attribute({ serializedName: 'attributes2', converter })
    attributes2: string;

  @Attribute({ serializedName: 'attributes3', converter })
    attributes3: string;

  @Attribute({ serializedName: 'current_flag', converter })
    currentFlag: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @BelongsTo()
    cmsRequest: CmsRequest;

  @BelongsTo()
    financialPeriod: FinancialPeriod;

  @BelongsTo()
    district: District;

}
