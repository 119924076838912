/* eslint-disable max-len */
import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { Ticket } from './ticket';
import { Usr } from './usr';
import { Visit } from './visit';


/*****************************************
 * DueDateExtension
 *****************************************/
@JsonApiModelConfig({
  type: 'dueDateExtensions',
})
export class DueDateExtension extends JsonApiModel {

  @Attribute({ serializedName: 'ticket_id', converter })
    ticketId: number;

  @Attribute({ serializedName: 'usr_id', converter })
    usrId: number;

  @Attribute({ serializedName: 'when_extended', converter: dateConverter })
    whenExtended: Date;

  @Attribute({ serializedName: 'due_date', converter: dateConverter })
    dueDate: Date;

  @Attribute({ serializedName: 'approved_by', converter })
    approvedBy: string;

  @Attribute({ serializedName: 'approver_phone', converter })
    approverPhone: string;

  @Attribute({ converter })
    notes: string;

  @Attribute({ converter })
    reason: string;

  @Attribute({ serializedName: 'old_due_date', converter: dateConverter })
    oldDueDate: Date;

  @Attribute({ serializedName: 'when_entered', converter: dateConverter })
    whenEntered: Date;

  @Attribute({ serializedName: 'visit_id', converter })
    visit_id: number;

  @BelongsTo()
    ticket: Ticket;

  @BelongsTo()
    usr: Usr;

  @BelongsTo()
    visit: Visit;

  formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  get noteText() {
    return `Due date extended to ${this.dueDate.toLocaleString()},
    approved by ${this.approvedBy?.trim()}${this.formatPhoneNumber(this.approverPhone) ? ' / ' + this.formatPhoneNumber(this.approverPhone) : ''}.
    ${this.notes?.trim()?.length > 1 ? 'Notes: ' + this.notes : ''}`;
  }
}
