import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import { MapPhoto } from '../../components/here-map/here-map.component';
import {
  converter,
  dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * TkpAttachment
 *****************************************/
@JsonApiModelConfig({
  type: 'tkpAttachment',
  modelEndpointUrl: 'attachments',
})
export class TkpAttachment extends JsonApiModel implements MapPhoto {

  get attachmentId() {
    return this.id;
  }

  @Attribute({ serializedName: 'attachment_type', converter })
    attachmentType: string;

  @Attribute({ serializedName: 'entity_kind', converter })
    entityKind: string;

  @Attribute({ serializedName: 'entity_id', converter })
    entityId: number;

  @Attribute({ serializedName: 'attached_by', converter })
    attachedBy: number;

  @Attribute({ serializedName: 'when_attached', converter: dateConverter })
    whenAttached: Date;

  @Attribute({ serializedName: 'when_uploaded', converter: dateConverter })
    whenUploaded: Date;

  @Attribute({ serializedName: 'file_name', converter })
    fileName: string;

  @Attribute({ serializedName: 'file_size', converter })
    fileSize: number;

  @Attribute({ converter })
    hash: string;

  @Attribute({ converter })
    status: string;

  @Attribute({ converter })
    lat: number;

  @Attribute({ converter })
    lon: number;

  @Attribute({ serializedName: 'created_date', converter: dateConverter })
    createdDate: Date;

  @Attribute({ serializedName: 'created_time', converter })
    createdTime: number;

  @Attribute({ serializedName: 'original_ticket_number', converter })
    originalTicketNumber: string;

  get whenCreated() {
    return this.createdDate;
  }

  get url() {
    // Remove the https://attachments.usicllc.com part in order to
    // use proxy which allows export to work around CORS issue (CP-83)
    //    return `/attachment/${this.id}`;
    return `/attachment/${this.id}`;
  }

  // URLs to get thumnail versions of attachments
  //
  get url_sm() {
    return `/attachment-sm/${this.id}`;
  }

  get url_med() {
    return `/attachment-med/${this.id}`;
  }

  get url_lg() {
    return `/attachment-lg/${this.id}`;
  }

  get latLon() {
    return `${this.lat}, ${this.lon}`;
  }
}
