/* eslint-disable security/detect-object-injection */
/* eslint-disable no-underscore-dangle */
/********************************************************************************
 * JsonAPIDataSource
 *
 * Angular DataSource that connects to a JsonApiDatastore and JsonApiModel
 * (part of the angular2-jsonapi package - see
 * https://www.npmjs.com/package/angular2-jsonapi) to retrieve data from a
 * JSON:API compliant API.
 * This is primarily used with a MatTable (USIC-Table) to allow the table to
 * control which pages of data it needs for display.
 *
 * See constructor comment for usage information.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ErrorResponse, JsonApiQueryData, ModelConfig } from '@michalkotas/angular2-jsonapi';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ModelType, JsonApiModel, JsonApiDatastore } from '@michalkotas/angular2-jsonapi';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import qs from 'qs';
import { environment } from '../../environments/environment';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { signal } from '@angular/core';

// Maximum number of results allowed by API (aka crnk.max-page-limit)
export const MAX_PAGE_LIMIT = 1000;

export class JsonAPIDataSource implements DataSource<JsonApiModel> {

  // the connect method will return an observable of this underlying subject
  // for new result sets to be sent out.
  private apiResultsSubject = new BehaviorSubject<JsonApiModel[]>([]);
  get filteredData(): JsonApiModel[] {
    return this.apiResultsSubject.value;
  }

  // internal additional subject streams to allow subscribers to monitor
  // additional transient information
  private resultCountSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private loadingSubscription: Subscription;

  // the actual observers of the prior subjects which can be subscribed to
  // for updates
  public resultCount = this.resultCountSubject.asObservable();
  public loading = this.loadingSubject.asObservable();
  public errors = signal<string[]>([]);

  // current query conditionals selected by the user for paging/sorting
  public sortDirection = 'asc';
  public pageIndex = 0;
  public pageSize = 100;

  // if we need to set special request headers
  public customHeaders: any;

  // if we need to override the URL for a request
  public customUrl: string;

  // provide access to the JsonApiDatastore baseURL
  get baseUrl(): string {
    const modelConfig: ModelConfig = Reflect.getMetadata('JsonApiModelConfig', this.modelType);
    return `${modelConfig.baseUrl || this.jsonApiDatastore.datastoreConfig.baseUrl}/${modelConfig.modelEndpointUrl || modelConfig.type}`;
  }

  // provide access to the JsonApiDatastore baseURL necessary for an Excel export
  get baseUrlForExport(): string {
    const modelConfig: ModelConfig = Reflect.getMetadata('JsonApiModelConfig', this.modelType);
    return `${environment.jsonApiUrl}/reports/1.0/excel/${modelConfig.modelEndpointUrl || modelConfig.type}`;
  }

  /**
   * Provides access to the request parameters as a URL string
   * This is generally used as the base to build a customURL from
   */
  getRequestParams(overrides?: any): string {
    const dsParams = Object.assign({}, {
      filter: this.filter,
      include: this.include,
      sort: this.sortColumn,
      page: {
        limit: this.pageSize,
        offset: (this.pageIndex * this.pageSize)
      }
    }, overrides);

    // Remove empty values
    Object.keys(dsParams).forEach(key => dsParams[key] === undefined && delete dsParams[key]);

    return qs.stringify(qs.parse(dsParams));
  }

  /// Stream that emits when a new filter string is set on the data source.
  private readonly _filter = new BehaviorSubject<any>({});
  public filterObserver = this._filter.asObservable();

  /**
   * Filter term that should be used to filter out objects from the data array. To override how
   * data objects match to this filter string, provide a custom function for filterPredicate.
   */
  get filter(): any { return this._filter.value; }
  set filter(filter: any) {

    if (JSON.stringify(filter) !== JSON.stringify(this._filter.value)) {
      // Reset the page number when changing the filter
      if (this._paginator) {
        this._paginator.pageIndex = 0;
        this.pageIndex = 0;
      }

      // remove any undefined and empty values
      Object.keys(filter).forEach(key =>
        (filter[key] === undefined || filter[key] === null || filter[key] === '' || filter[key].LIKE === '%%') &&
        delete filter[key] && delete this.filter[key]);

      this._filter.next(filter);
    }
  }

  /**
   * Instance of the MatPaginator component used by the table to control what page of the data is
   * displayed. Page changes emitted by the MatPaginator will trigger an update to the
   * table's rendered data.
   *
   * Note that the data source uses the paginator's properties to calculate which page of data
   * should be displayed. If the paginator receives its properties as template inputs,
   * e.g. `[pageLength]=100` or `[pageIndex]=1`, then be sure that the paginator's view has been
   * initialized before assigning it to this data source.
   */
  get paginator(): MatPaginator | null { return this._paginator; }
  set paginator(paginator: MatPaginator | null) {
    this._paginator = paginator;
    if (paginator) {
      this._paginator.page
        .subscribe(newPage => {
          if (this.pageSize === newPage.pageSize && this.pageIndex === newPage.pageIndex) {
            // sometimes multiple page events get triggers for the same thing, so
            // ignore duplicates
            return;
          }

          // when the page size is changed, revert back to the first page
          if (this.pageSize !== newPage.pageSize) {
            this._paginator.pageIndex = 0;
            this.pageIndex = 0;
          } else {
            this.pageIndex = newPage.pageIndex;
          }
          this.pageSize = newPage.pageSize;
          if (typeof this.jsonApiDatastore['pageSize'] === 'number') {
            this.jsonApiDatastore['pageSize'] = this.pageSize;
          }

          // We're using customUrl to completely override the normal URL creation which will break the paging
          // so if we're using a customUrl, update the paging parameters
          if (this.customUrl) {
            const pageLimitRx = /page\[limit\]=\d+/i;
            const pageLimitEncodedRx = /page%5Blimit%5D=\d+/i;
            const pageOffsetRx = /page\[offset\]=\d+/i;
            const pageOffsetEncodedRx = /page%5Boffset%5D=\d+/i;
            this.customUrl = this.customUrl
              .replace(pageLimitRx, `page[limit]=${this.pageSize}`)
              .replace(pageLimitEncodedRx, `page%5Blimit%5D=${this.pageSize}`)
              .replace(pageOffsetRx, `page[offset]=${(this.pageIndex * this.pageSize)}`)
              .replace(pageOffsetEncodedRx, `page%5Boffset%5D=${(this.pageIndex * this.pageSize)}`);
          }

          if (this.pageSize <= MAX_PAGE_LIMIT) {
            this.loadData();
          } else {
            this.loadLargeData();
          }

        });
    }
  }
  private _paginator: MatPaginator | null;

  private _sort = new BehaviorSubject<MatSort>(null);
  sortObserver = this._sort.asObservable();

  /**
   * Instance of the MatSort directive used by the table to control its sorting. Sort changes
   * emitted by the MatSort will trigger an update to the table's rendered data.
   */
  get sort(): MatSort | null { return this._sort.value; }
  set sort(sort: MatSort | null) {
    setTimeout(() => {
      if (sort) {
        this._sort.next(sort);

        this.sort?.sortChange
          .subscribe(( newSort ) => {
            this.sortDirection = newSort.direction;
            this.loadData();
          });
      }
    });

  }

  // Get the sortColumn from MatSort
  get sortColumn(): string | undefined {
    return this.sort?.active ? (this.sort.direction === 'desc' ? '-' : '') + this.sort.active : undefined;
  }
  // Set the MatSort from a string value
  set sortColumn(sortColumn: string) {
    const sort = this.sort || new MatSort();
    if (sortColumn) {
      if (sortColumn.startsWith('-')) {
        sortColumn = sortColumn.slice(1);
        sort.direction = 'desc';
      }
      sort.active = sortColumn;
    } else {
      sort.active = undefined;
      sort.direction = undefined;
    }
    this.sort = sort;
  }

  /**
   * Allow jsonApiDatastore to be swapped out
   * This only makes sense for similar models like a subclass.
   * Use with caution.
   */
  set datastore(jsonApiDatastore: JsonApiDatastore) {
    this.jsonApiDatastore = jsonApiDatastore;
  }

  /**
   * Allow data model to be swapped out
   * This only makes sense for similar models like a subclass.
   * Use with caution.
   */
  set model(modelType: ModelType<JsonApiModel>) {
    this.modelType = modelType;
  }
  get model() {
    return this.modelType;
  }

  /**
   * Constructor
   *
   * @param jsonApiDatastore An instance of this is the common base class for a
   *                         angular2-jsonapi connection to an API.  It defines
   *                         what data models are available through the API and
   *                         how to access the API.
   * @param modelType        Which data model from the data store this data
   *                         source will be retrieving.
   * @param include          a list of any related data models we want included
   *                         when retrieving the data.
   * @param sortValue        a string of attribute names to sort by.
   *
   * For example, to create a data source for the Career Progression API that
   * retrieve the RunResults and includes the RunResultDetails, the data source
   * would be constructed like:
   *    JsonAPIDataSource(this.careerProgression, RunResult, 'runResultDetails');
   * where this.careerProgression is of type CareerProgressionService
   * The resulting jsonApiDataSource would be passed into a lib-usic-table in the
   * HTML template such as:
   *    <lib-usic-table
   *     [jsonApiDataSource]="jsonApiDataSource"
   *     [displayedColumns]="displayedColumns"
   *     ...
   *    </lib-usic-table>
   */
  constructor(
    private jsonApiDatastore: JsonApiDatastore,
    private modelType: ModelType<JsonApiModel>,
    public include?: string,
    public sortValue?: string,
  ) {
    if (this.sortValue) {
      this.sortColumn = this.sortValue;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  connect(collectionViewer?: CollectionViewer): Observable<JsonApiModel[]> {
    this._filter.subscribe(() => this.loadData());
    return this.apiResultsSubject.asObservable();
  }

  // connect without actually loading new data
  reconnect(): Observable<JsonApiModel[]> {
    return this.apiResultsSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disconnect(collectionViewer: CollectionViewer): void {
    this.apiResultsSubject.complete();
    this.loadingSubject.complete();
    this.resultCountSubject.complete();
  }

  loadData() {
    if (this.modelType) {
      // If we need to fetch new data (ex the filter changed) and we're currently still retrieving
      // data, discard the old request since we no longer need (or want) the results
      if (this.loadingSubject.getValue()) {
        this.loadingSubscription?.unsubscribe();
      }

      // Place the setting of the loading indicator in a timeout to to defer this code to another
      // Javascript Virtual Machine turn which prevents the error:
      //   NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: '-1'.
      // see https://blog.angular-university.io/angular-debugging/
      setTimeout(() => {
        this.loadingSubject.next(true);
      });

      this.errors.set([]);

      this.loadingSubscription = this.jsonApiDatastore.findAll(this.modelType,
        (this.customUrl ? undefined : {
          filter: this.filter,
          include: this.include,
          sort: this.sortColumn,
          page: {
            limit: this.pageSize,
            offset: (this.pageIndex * this.pageSize)
          }
        }), this.customHeaders, this.customUrl)
        .subscribe({
          next: (apiResults: JsonApiQueryData<JsonApiModel>) => {
            const totalResourceCount = apiResults.getMeta()?.meta?.totalResourceCount;
            this.resultCountSubject.next(totalResourceCount);

            const apiResultsData = apiResults.getModels();
            this.apiResultsSubject.next(apiResultsData);
            setTimeout(() => {
              this.loadingSubject.next(false);
            });
            this.loadingSubscription.unsubscribe();
          },
          error: (errorResponse) => {
            // console.error(errorResponse);
            let errors: string[];
            if (errorResponse instanceof ErrorResponse) {
              errors = errorResponse.errors.map(e => (e.detail || e.title));
            } else {
              errors = [errorResponse];
            }
            this.errors.set(errors);
            this.resultCountSubject.next(0);
            this.apiResultsSubject.next([]);
            setTimeout(() => {
              this.loadingSubject.next(false);
            });
          }
        });


    }
  }

  /**
   * loadLargeData is used when we need to load page sizes over the limit of MAX_RESULTS
   */
  loadLargeData() {
    // If we need to fetch new data (ex the filter changed) and we're currently still retrieving
    // data, discard the old request since we no longer need (or want) the results
    if (this.loadingSubject.getValue()) {
      this.loadingSubscription?.unsubscribe();
    }
    setTimeout(() => {
      this.loadingSubject.next(true);
    });

    this.errors.set([]);

    // Retrieving more than MAX_RESULTS at once causes server problems
    // So we'll loads chunks of MAX_RESULTS
    const numFetches = Math.ceil(this.pageSize / MAX_PAGE_LIMIT);
    const batches: number[] = [];
    for (let i = 0; i < numFetches; i++) {
      batches.push(i);
    }
    this.loadBatches(batches, [])
      .then(results => {
        console.log('loaded', results.length, 'records');
        this.apiResultsSubject.next(results);
        setTimeout(() => {
          this.loadingSubject.next(false);
        });
      });
  }

  /**
   * Load a batch of MAX_RESULTS records
   *
   * @param batchNum
   */
  private loadBatch(batchNum: number): Promise<[number, JsonApiModel[]]> {

    return new Promise(resolve => {
      if (this.customUrl) {
        const pageLimitRx = /page\[limit\]=\d+/i;
        const pageLimitEncodedRx = /page%5Blimit%5D=\d+/i;
        const pageOffsetRx = /page\[offset\]=\d+/i;
        const pageOffsetEncodedRx = /page%5Boffset%5D=\d+/i;
        this.customUrl = this.customUrl
          .replace(pageLimitRx, `page[limit]=${MAX_PAGE_LIMIT}`)
          .replace(pageLimitEncodedRx, `page%5Blimit%5D=${MAX_PAGE_LIMIT}`)
          .replace(pageOffsetRx, `page[offset]=${(batchNum * MAX_PAGE_LIMIT)}`)
          .replace(pageOffsetEncodedRx, `page%5Boffset%5D=${(batchNum * MAX_PAGE_LIMIT)}`);
      }

      this.jsonApiDatastore.findAll(this.modelType,
        (this.customUrl ? undefined :
          {
            filter: this.filter,
            include: this.include,
            sort: this.sortColumn,
            page: {
              limit: MAX_PAGE_LIMIT,
              offset: (batchNum * MAX_PAGE_LIMIT)
            }
          }), this.customHeaders, this.customUrl)
        .subscribe({
          next: (apiResults: JsonApiQueryData<JsonApiModel>) => {
            resolve([batchNum, apiResults.getModels()]);
          },
          error: (errorResponse) => {
            console.error(errorResponse);
            resolve([batchNum, []]);
          }
        });

    });

  }

  /**
   * Load the batches of records, but just one btach at a time so as not to overwhelm
   * the server or the browser
   *
   * @param batches array of batch numbers to load
   * @param apiResultsData array of records retrieved so for
   */
  private async loadBatches(batches: number[], apiResultsData: JsonApiModel[]): Promise<JsonApiModel[]> {
    console.log('loadBatches', batches);
    await this.loadBatch(batches[0])
      .then(([i, results]) => {
        apiResultsData = apiResultsData.concat(results);
        batches = batches.filter(l => l !== i);
      });
    if (batches.length > 0) {
      return this.loadBatches(batches, apiResultsData);
    } else {
      return new Promise<JsonApiModel[]>(resolve => resolve(apiResultsData));
    }
  }


  /**
   * Cancel (abandon) any current request and reset the loading status
   */
  cancel() {
    this.loadingSubscription?.unsubscribe();
    setTimeout(() => {
      this.loadingSubject.next(false);
    });
  }

  moveRecord(fromIndex: number, toIndex: number) {
    const currentRows = this.filteredData;
    moveItemInArray(currentRows, fromIndex, toIndex);
    this.apiResultsSubject.next(currentRows);
  }
}
