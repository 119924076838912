import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';

/*****************************************
 * CasesSubtype
 *****************************************/
@JsonApiModelConfig({
  type: 'casesSubtypes',
})
export class CasesSubtype extends JsonApiModel {

  @Attribute({ serializedName: 'cases_type_id', converter })
    casesTypeId: number;

  @Attribute({ serializedName: 'cases_sub_type_description', converter })
    casesSubTypeDescription: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  // circular dependency
  // @BelongsTo()
  //   legalCases: LegalCase;

  // circular dependency
  // @BelongsTo()
  //   casesType: CasesType;

}
