@if (!printPreview) {

  <table-toolbar [parent]="self"
                (changeColumns)="onChangeColumns($event)"
                (cancelClicked)="cancelClicked.emit()">

    <!--<div class="mat-caption" [ngClass]="{ 'bg-usic-red' : !editComponent}">

      {{ editComponent?.name || 'No editComponent' }}

    </div>-->
    <ng-content></ng-content>

  </table-toolbar>

  @if (filtering === 'global') {
    <global-filter [parent]="self"></global-filter>
  }

  <div class="table-wrapper"
      [ngClass]="tableClass + ' page-size-' + tableSize">

    <lib-usic-table-resize #tableResizeComponent
                          [columns]="displayedColumns"
                          [persistColumns]="persistColumns"
                          [isMobile]="isMobile"
                          (isResizing)="isResizing=$event"
                          [prefix]="prefix">

      <mat-table class="usic-table"
        [ngClass]="{'border-left': printPreview}"
        [ngStyle]="{ width: customWidth ? customWidth : 'auto' }"
        [dataSource]="dataSource"
        cdkDropList
        [cdkDropListDisabled]="!reorderable"
        (cdkDropListDropped)="onListDrop($event)"
        matSort [matSortDisabled]="isResizing" (matSortChange)="changeSort.emit($event)"
        matTableExporter [hiddenColumns]="hiddenColumns"
        #exporter="matTableExporter">

        <!-- Column Definitions -->

        @for (column of displayedColumns; track $index; let i = $index) {
          <ng-container
            matColumnDef="{{column.icon || column.name}}"
            [sticky]="column.sticky">

            @if (column.name === 'select') {
              <!-- Checkbox Column (Row Select Boxes) -->

              <!-- Checkbox Header Cell -->
              <mat-header-cell class="bg-usic-surface-light border-right" *matHeaderCellDef>

                <checkbox-header [parent]="self"></checkbox-header>

              </mat-header-cell>

              <!-- Checkbox Cell -->
              <mat-cell class="border-right" *matCellDef="let row; let i = index" (click)="$event.stopPropagation()">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? toggleRow(row) : null"
                  [checked]="selectionModel.isSelected(row)"
                  aria-label="Select Row {{i + 1}}">
                </mat-checkbox>
              </mat-cell>

              @if (hasFooter) {
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              }

          } @else {

            <!-- Normal Column -->

            <!-- Normal Header Cell -->
            <mat-header-cell
              class="bg-usic-surface-light border-right" *matHeaderCellDef
              mat-sort-header="{{ column.sortName !== 'none' ? (column.sortName || column.filterName || column.name) : '' }}"
              disabled="{{ column.sortName === 'none' }}"
              [ngClass]="{
                          'column-filters' : filtering === 'column' && expandFilters,
                          'numeric' : ['number','float','currency','date','monthYear'].includes(column.type),
                          'icon' : column.type === 'icon',
                          'border-top' : printPreview
                          }"
              [ngStyle]="{ width: column.width + 'px' }"
              [hidden]="column.hidden || (column.hideMobile && isMobile)">

              <normal-header [column]="column" [parent]="self"></normal-header>

              <div class="resize-handle right"
                  (mousedown)="tableResizeComponent.onResizeColumn($event, i)"></div>

            </mat-header-cell>

            <!-- Normal Cell -->
            <mat-cell class="border-right" *matCellDef="let element"
                      [ngClass]="{
                        'numeric' : ['number','float','currency','date','monthYear'].includes(column.type),
                        'icon' : column.type === 'icon'
                      }"
                      [ngStyle]="{ width: column.width + 'px' }"
                      [hidden]="column.hidden || (column.hideMobile && isMobile)">

              <normal-cell [column]="column" [element]="element" [parent]="self"></normal-cell>

            </mat-cell>

            @if (hasFooter) {

              <mat-footer-cell *matFooterCellDef class="numeric bg-usic-surface-light"
                [ngStyle]="{ width: column.width + 'px' }"
                [hidden]="column.hidden || (column.hideMobile && isMobile)">

                @if (hasTotalForColumn(column.name)) {
                  Total: {{ getTotalForColumn(column.name) | currency }}
                }

              </mat-footer-cell>

            }

          }

          </ng-container>
        }

        <!-- Row Definitions -->
        <mat-header-row *matHeaderRowDef="columnNames; sticky: true"></mat-header-row>

        <mat-row *matRowDef="let row; ; let i = index; columns: columnNames;" [id]="i"
                (click)="onRowClick($event, row)"
                (dblclick)="onRowDblClick($event, row)"
                [ngClass]="setClasses(row)"
                cdkDrag [cdkDragDisabled]="!reorderable"
                >
        </mat-row>

        @if (hasFooter) {
          <mat-footer-row *matFooterRowDef="columnNames; sticky: true"></mat-footer-row>
        }

      </mat-table>

    </lib-usic-table-resize>

  </div>

} @else {

  <table class="border">
    <thead>
      <tr class="mat-header-row">
        @for (column of printableColumns; track column.name) {
          <th class="border padding">
            {{ column.title || (column.name | titleize) }}
          </th>
        }
      </tr>
    </thead>
    <tbody>
      @for (row of dataSource.filteredData; track $index) {
        <tr>
          @for (column of printableColumns; track column.name) {
            <td class="border padding"
                [ngClass]="{'numeric' : ['number','float','currency','date','monthYear'].includes(column.type) }">
                {{ row | resolve:(column.name) | dataFormatter: column.type: (dataSource?.filter ? dataSource.filter[column.filterName || column.name] : undefined) }}
            </td>
          }
        </tr>
      }
    </tbody>
  </table>

}

<div class="usic-paginator" (contextmenu)="setDynamicPageSize()">

  <mat-paginator [pageSize]="printPreview ? resultCount() : pageSize" [length]="resultCount()"
                 [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>

  @if (pageCount) {

    <div class="mat-paginator page-count bg-usic-surface-light border-bottom layout layout-align-end">

      <div class="layout layout-gap-half">

        <label class="page-label" for="page">Page: </label>
        <div class="layout layout-align-start-start flex">

          <div>

            <input matNativeControl id="page" type="number"
                  min=1 [max]="pageCount"
                  [value]="page + 1" (change)="page = $event.target.value - 1">

          </div>

          <div>&nbsp; of &nbsp;{{pageCount}}</div>

        </div>

      </div>

    </div>

  }
</div>

