@if (errors && errors.length) {
  <div class="errors bg-usic-white mat-elevation-z2">

    <div class="error-panel">

      <div class="layout layout-align-space-between-center">

        <h3 class="text-usic-red no-margin layout layout-align-start-center layout-gap">
          <mat-icon> error</mat-icon>
          <span>ERROR</span>
        </h3>

        <button class="close" mat-icon-button (click)="errors = []"> <mat-icon> close </mat-icon> </button>
      </div>

      <ul>
        @for (error of errors; track $index) {
          <li> {{ error }}</li>
        }
      </ul>

      <p class="mat-caption no-margin">
        <ng-content></ng-content>
      </p>

    </div>

  </div>
}
