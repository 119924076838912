import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { Asset } from './asset';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Employee } from '../shareddata/employee';


/*****************************************
 * PumpDevice
 *****************************************/
@JsonApiModelConfig({
  type: 'pumpDevices'
})
export class PumpDevice extends JsonApiModel {

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @Attribute({ serializedName: 'pump_date', converter: dateConverter })
    pumpDate: Date;

  @Attribute({ serializedName: 'safety_screen_triggers_24', converter })
    safetyScreenTriggers24: number;

  @Attribute({ serializedName: 'safety_screen_version', converter })
    safetyScreenVersion: string;

  @Attribute({ serializedName: 'model', converter })
    model: string;

  @Attribute({ serializedName: 'host_name', converter })
    hostName: string;

  @Attribute({ serializedName: 'os_name', converter })
    osName: string;

  @Attribute({ serializedName: 'os_version', converter })
    osVersion: string;

  @Attribute({ serializedName: 'serial_number', converter })
    serialNumber: string;

  @Attribute({ serializedName: 'ip_address', converter })
    ipAddress: string;

  @Attribute({ serializedName: 'free_space', converter })
    freeSpace: string;

  @Attribute({ serializedName: 'memory', converter })
    memory: string;

  @Attribute({ serializedName: 'available_gps_ports', converter })
    availableGpsPorts: string;

  @Attribute({ serializedName: 'last_gps_lock', converter: dateConverter })
    lastGpsLock: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @BelongsTo()
    employee: Employee;

  @BelongsTo()
    asset: Asset;

}
