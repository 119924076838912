import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * CustomerContractState
 *****************************************/
@JsonApiModelConfig({
  type: 'customerContractStates',
})
export class CustomerContractState extends JsonApiModel {

  @Attribute({ serializedName: 'account_number', converter })
    accountNumber: string;

  @Attribute({ serializedName: 'contract_number', converter })
    contractNumber: string;

  @Attribute({ serializedName: 'state', converter })
    state: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;
}
