<lib-usic-dialog
  [title]="data.appName + 'Help' | titleize"
  [includeActions]="false">

  <div class="margin">

    <p>Lorem ipsum dolor sit amet. Aut consequatur impedit quo corrupti nostrum qui dolor minus et voluptatem repudiandae
      sed enim soluta. Qui sequi odit et sint sunt ut exercitationem officia sed galisum culpa sed incidunt voluptatum non
      dolorem impedit ad illo provident. Est quas voluptatem non voluptatum dolorum et blanditiis incidunt. Eos expedita
      rerum et officia fugiat ut asperiores odit eum molestias reprehenderit id mollitia perferendis? </p>
    <p>Et praesentium cumque et illo sint sit nihil magni et aspernatur natus non molestias corporis ab dicta culpa ut
      sapiente exercitationem. Ut fugit cumque quo molestiae totam ut animi earum. Ad veniam illo ut alias rerum id modi
      deleniti quo autem eaque ad deserunt internos? In itaque maxime eum fugiat itaque qui doloremque voluptatem qui
      nostrum maxime. </p>
    <p>Et accusamus accusantium vel odit reprehenderit ut mollitia magni. Quo nulla nesciunt sit eligendi consequatur eum
      dolor error! In praesentium delectus et eaque natus At nobis porro sit alias debitis est aliquam rerum et officiis
      repellat a dignissimos dignissimos. </p>
    <p>Sed nostrum quia At doloremque mollitia et maxime eius aut consequatur optio. Ex quia dolorem et earum voluptate
      cum unde fugiat sed voluptatem iusto est consequatur itaque et sunt modi non possimus odit. </p>
    <p>Et aperiam alias qui internos temporibus cum autem quas in aperiam culpa. Ab cupiditate enim aut pariatur dolor ut
      voluptas saepe et sequi modi et earum soluta. </p>
    <p>Ut accusamus odio eos modi galisum aut doloribus voluptatem est architecto iure qui repellendus necessitatibus. Vel
      dolor magni et nemo assumenda et fugiat consequuntur est iure enim et veniam nihil a harum dolorum hic rerum
      accusantium. </p>
    <p>Quo laboriosam debitis a quidem ullam cum incidunt quia a galisum omnis ea fugiat culpa non exercitationem
      repudiandae? Id repellat reprehenderit est commodi saepe vel nulla alias in aperiam consequatur ut enim amet est
      eaque dolore et corrupti magnam. Quo consequatur pariatur id fugiat laboriosam non placeat distinctio rem vero
      veritatis et commodi quae non illo laboriosam hic tenetur quia! Ut laudantium odio nam voluptas molestias vel nemo
      iusto est vitae omnis qui Quis aperiam. </p>
    <p>Quo internos atque eos esse excepturi sit accusamus aspernatur et consequatur molestiae cum illum consequatur. Ut
      vitae nemo quo deleniti sapiente non ducimus dolores hic laudantium natus qui mollitia facilis. </p>
    <p>Aut pariatur blanditiis ut iure maiores et commodi nostrum et velit voluptate hic autem consequatur a magni
      architecto. Eum suscipit explicabo et consequatur error est nesciunt explicabo. Est nihil labore aut cupiditate
      nesciunt eum nobis assumenda et vitae assumenda et facilis itaque. </p>
    <p>In consequatur doloremque sed ratione optio et assumenda ducimus. Et corporis dolorem sit veritatis aliquam et
      galisum molestiae qui aliquid suscipit ut nobis fugit eos similique reiciendis ut consequatur internos. Est labore
      aliquid in ratione internos ut dolorum architecto et voluptas nulla? </p>

  </div>


</lib-usic-dialog>