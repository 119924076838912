import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { RunResult } from '.';


/*****************************************
 * ProcessRun
 *****************************************/
@JsonApiModelConfig({
  type: 'processRuns'
})
export class ProcessRun extends JsonApiModel {

  @Attribute({ serializedName: 'run_date', converter: dateConverter })
    runDate: Date;

  @Attribute({ serializedName: 'pay_period', converter })
    payPeriod: string;

  @Attribute({ serializedName: 'run_by', converter })
    runBy: string;

  @Attribute({ serializedName: 'run_status', converter })
    runStatus: string;

  @HasMany()
    runResults: RunResult[];
}
