import { HasMany } from '@michalkotas/angular2-jsonapi';
import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter,
} from '../../helpers/jsonapi.convertors';
import { Locate } from './locate';
import { ResponseLog } from './response-log';
import { Ticket } from './ticket';


/*****************************************
 * TransmissionBase
 *****************************************/
@JsonApiModelConfig({
  type: 'transmissionsBase',
})
export class TransmissionBase extends JsonApiModel {

  @Attribute({ serializedName: 'ticket_id', converter })
    ticketId: number;

  @HasMany()
    tickets: Ticket[];

  @Attribute({ serializedName: 'ticket_number', converter })
    ticketNumber: string;

  @Attribute({ serializedName: 'message_type', converter })
    messageType: string;

  @Attribute({ serializedName: 'trans_number', converter })
    transNumber: string;

  @Attribute({ serializedName: 'transmit_date', converter: dateConverter })
    transmitDate: Date;

  @Attribute({ serializedName: 'call_date', converter: dateConverter })
    callDate: Date;

  @Attribute({ serializedName: 'work_date', converter: dateConverter })
    workDate: Date;

  @Attribute({ serializedName: 'parse_date', converter: dateConverter })
    parseDate: Date;

  @Attribute({ converter })
    county: string;

  @Attribute({ converter })
    city: string;

  @Attribute({ converter })
    state: string;

  @Attribute({ serializedName: 'streetname', converter })
    streetName: string;

  @Attribute({ serializedName: 'street_number', converter })
    streetNumber: string;

  get street() {
    // eslint-disable-next-line max-len
    return `${this.streetNumber ? this.streetNumber : ''} ${this.streetName ? this.streetName + ',' : ''}`.trim();
  }

  get address() {
    // eslint-disable-next-line max-len
    return `${this.streetNumber ? this.streetNumber : ''} ${this.streetName ? this.streetName + ',' : ''} ${this.city ? this.city + ',' : ''} ${this.state}`.trim();
  }

  @Attribute({ converter })
    emergency: boolean;

  @Attribute({ serializedName: 'short_notice', converter })
    shortNotice: boolean;

  @Attribute({ serializedName: 'after_hours', converter })
    afterHours: boolean;

  @Attribute({ converter })
    tickettype: string;

  @Attribute({ serializedName: 'feed_id', converter })
    feedId: number;

  @Attribute({ converter })
    meet: boolean;

  @Attribute({ serializedName: 'meet_date', converter: dateConverter })
    meetDate: Date;

  @Attribute({ serializedName: 'meet_code', converter })
    meetCode: string;

  @Attribute({ serializedName: 'transmitted_legal_due_date', converter: dateConverter })
    transmittedLegalDueDate: Date;

  @Attribute({ serializedName: 'transmission_type', converter })
    transmissionType: string;

  @Attribute({ serializedName: 'added_by_id', converter })
    addedById: number;

  @Attribute({ serializedName: 'service_area_code', converter })
    serviceAreaCode: string;

  @Attribute({ serializedName: 'master_code', converter })
    masterCode: string;

  @Attribute({ serializedName: 'billing_code', converter })
    billingCode: string;

  @Attribute({ serializedName: 'download_time', converter: dateConverter })
    downloadTime: Date;

  @Attribute({ converter })
    subdivision: string;

  @Attribute({ serializedName: 'street_dir', converter })
    streetDir: string;

  @Attribute({ converter })
    typeofwork: string;

  @Attribute({ serializedName: 'lead_time', converter })
    leadTime: string;

  @Attribute({ converter })
    blasting: boolean;

  @Attribute({ serializedName: 'usic_ticket_type', converter })
    usicTicketType: string;

  @HasMany()
    locates: Locate[];

  @HasMany()
    responseLogs: ResponseLog[];
}
