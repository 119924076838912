import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * Note
 *****************************************/
@JsonApiModelConfig({
  type: 'notes',
})
export class Note extends JsonApiModel {

  @Attribute({ serializedName: 'entity_kind', converter })
    entityKind: string;

  @Attribute({ serializedName: 'entity_id', converter })
    entityId: number;

  @Attribute({ serializedName: 'entered_by_id', converter })
    enteredById: number;

  @Attribute({ serializedName: 'activity_id', converter })
    activityId: number;

  @Attribute({ serializedName: 'whenentered', converter: dateConverter })
    whenEntered: Date;

  @Attribute({ serializedName: 'whenposted', converter: dateConverter })
    whenPosted: Date;

  @Attribute({ converter })
    type: string;

  @Attribute({ serializedName: 'note_text', converter })
    noteText: string;

  @Attribute({ serializedName: 'excavator_note', converter })
    excavatorNote: string;

}
