import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { CustomerAccount } from './customer-account';
import { CustomerContract } from './customer-contract';


/*****************************************
 * UserAccountContractAccess
 *****************************************/
@JsonApiModelConfig({
  type: 'userAccountContractAccesses'
})
export class UserAccountContractAccess extends JsonApiModel {

  @Attribute({ serializedName: 'account_number', converter })
    accountNumber: number;

  @Attribute({ serializedName: 'contract_number', converter })
    contractNumber: number;

  @BelongsTo()
    customerAccount: CustomerAccount;

  @BelongsTo()
    customerContract: CustomerContract;

}
