import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { District, Country, State } from '../shareddata';

/*****************************************
 * Location
 *****************************************/
@JsonApiModelConfig({
  type: 'locations'
})
export class Location extends JsonApiModel {

  @Attribute({ converter })
    name: string;

  @Attribute({ converter })
    notes: string;

  @Attribute({ converter })
    city: string;

  @Attribute({ converter })
    latitude: number;

  @Attribute({ converter })
    longitude: number;

  @BelongsTo()
    state: State;

  @Attribute({ serializedName: 'state_id', converter })
    stateId: number;

  @Attribute({ converter })
    fax: string;

  @Attribute({ converter })
    email: string;

  @Attribute({ converter })
    zip: string;

  @Attribute({ converter })
    address1: string;

  @Attribute({ converter })
    address2: string;

  @Attribute({ converter })
    mobile: string;

  @Attribute({ serializedName: 'location_type_id', converter })
    locationTypeId: number;

  @Attribute({ converter })
    phone: string;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ converter })
    attribute4: string;

  @Attribute({ converter })
    attribute5: string;

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    districts: District[];

  @BelongsTo()
    country: Country;

  @Attribute({ serializedName: 'country_id', converter })
    countryId: number;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
      attribute4: this.attribute4,
      attribute5: this.attribute5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
    this.attribute2 = v.attribute2;
    this.attribute3 = v.attribute3;
    this.attribute4 = v.attribute5;
    this.attribute5 = v.attribute4;
  }

}
