import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { CmsCustomer } from './cms-customer';
import { Employee, State } from '../shareddata';
import { Facility } from './facility';

/*****************************************
 * BillableCustomer
 *****************************************/
@JsonApiModelConfig({
  type: 'billableCustomers',
})
export class BillableCustomer extends JsonApiModel {

  @Attribute({ serializedName: 'billable_customers_group_id', converter })
    billableCustomersGroupId: number;

  @Attribute({ serializedName: 'customer_id', converter })
    customerId: number;

  @Attribute({ serializedName: 'contract_id', converter })
    contractId: string;

  @Attribute({ serializedName: 'state_id', converter })
    stateId: number;

  @Attribute({ serializedName: 'utility_type_id', converter })
    utilityTypeId: string;

  @Attribute({ serializedName: 'facility_id', converter })
    facilityId: number;

  @Attribute({ serializedName: 'notified_by_category_id', converter })
    notifiedByCategoryId: number;

  @Attribute({ serializedName: 'not_billable', converter })
    notBillable: boolean;

  @Attribute({ serializedName: 'inv_photos_required', converter })
    invPhotosRequired: number;

  @Attribute({ serializedName: 'days_to_send_report', converter })
    daysToSendReport: number;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    endDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy$: string;

  @Attribute({ serializedName: 'created_by_id', converter })
    createdById: number;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy$: string;

  @Attribute({ serializedName: 'last_updated_by_id', converter })
    lastUpdatedById: number;

  @BelongsTo()
    cmsCustomer: CmsCustomer;

  @BelongsTo()
    facility: Facility;

  @BelongsTo()
    state: State;

  @BelongsTo()
    createdBy: Employee;

  @BelongsTo()
    lastUpdatedBy: Employee;

}
