/********************************************************************************
 * AdditionalAttributesComponent (lib-additional-attributes)
 *
 * Angular component to provide edit controls for additional attributes
 *
 * parameters
 *   numAttributes   : number of attribute input fields to provide
 *   showAsFieldset  : controls whether or not the fields should be encapsulated
 *                     in a fieldset  (default: true)
 *   dataModel       : 2-way binding of a data model that has the attributes
 *                     for template-driven forms (equivalant to ngModel)
 *
 * Also implements reactive form ControlValueAccessor as described
 * https://stackoverflow.com/questions/39661430/angular-2-formcontrolname-inside-component
 *
 *   formControl     : The FormControl from the parent formGroup for this control
 *                     to enable adding validators.
  *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { forwardRef, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, AbstractControl, FormsModule } from '@angular/forms';
import { BreakpointsService } from '../../services/breakpoints.service';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

export const ADDITIONAL_ATTRIBUTES_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AdditionalAttributesComponent),
  multi: true
};

/**
 * This component accepts and returns up to 5 attributes
 */
export type Attributes = {
  attribute1?: string;
  attribute2?: string;
  attribute3?: string;
  attribute4?: string;
  attribute5?: string;
};


@Component({
  selector: 'lib-additional-attributes',
  templateUrl: './additional-attributes.component.html',
  styleUrls: ['./additional-attributes.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ADDITIONAL_ATTRIBUTES_CONTROL_VALUE_ACCESSOR],
  imports: [NgClass, NgTemplateOutlet, MatFormField, MatLabel, MatInput, FormsModule]
})
export class AdditionalAttributesComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() numAttributes = 5;
  attributeNumbers = [];

  // ngModel equivalent for template-driven forms
  currentModel: any;
  @Input()
  get dataModel() {
    return this.currentModel;
  }
  set dataModel(val) {
    this.currentModel = val;
    this.dataModelChange.emit(this.currentModel);
  }
  @Output() dataModelChange = new EventEmitter<any>();

  // Current form control for this component when using reactive forms.
  @Input() formControl: AbstractControl = new FormControl();

  @Input() showAsFieldset = true;
  @Input() labels: string[];
  @Input() required = false;

  // The internal data model for form control value access (reactive forms)
  private innerValue: Attributes = {};
  private onChange: (value: Attributes) => void;
  public onTouched: () => void;

  constructor(
    public breakpoints: BreakpointsService
  ) {
  }

  ngOnInit(): void {
    if (this.labels === undefined) {
      this.labels = [];
      this.attributeNumbers.forEach(attributeNumber => {
        this.labels.push(` Attribute ${attributeNumber}`);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.numAttributes) {
      this.attributeNumbers = Array(Number(this.numAttributes)).fill(0).map((x, i) => i + 1);
    }
    if (changes.labels) {
      // If the provided labels are numbered, use the label number values for attributeNumbers.
      // This allows breaks in sequence. For example
      // this.labels = ['%1$s', '%3$s']
      // will result in attributeNumber [ 1, 3 ] instead of [1, 2, 3]
      const labelNumbers = [...new Set(this.labels.map(l => Number(l.match(/(\d)/g))).flat().filter(l => l))];
      if (labelNumbers.length) {
        this.attributeNumbers = labelNumbers;
      }
    }
  }


  modelForAttr(attributeNum) {
    return this.currentModel[`attribute${attributeNum}`];
  }

  changeAttribute(name: string, event: any) {
    const value: string = event.target.value?.trim();

    // eslint-disable-next-line security/detect-object-injection
    this.innerValue[name] = value ? value : null;

    if (this.onChange) {
      this.onChange(this.innerValue);
    }
  }


  // From ControlValueAccessor interface
  writeValue(value: Attributes) {
    this.innerValue = value;

    // To allow template-driven ngModel work without error
    if (!this.currentModel) {
      this.currentModel = this.innerValue;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: (value: Attributes) => void): void {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // get accessor
  get value(): Attributes {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: Attributes) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

}
