import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  jsonConverter,
} from '../../helpers/jsonapi.convertors';

// Event data structure
export class TpEvent  {
  addresses: number;
  description: string;
  feed: string;
  feet: number;
  minutes: number;
  reply: string;
  respCode: string;
  type: string;
  user: string;
  utilAbbrevs: any;
  utilNames: any;
  utility: string;
  when: Date;
  whenSaved: Date;
  whenSynced: Date;
}

/*****************************************
 * TpHistory  (TicketPro History)
 *****************************************/
@JsonApiModelConfig({
  type: 'tpHistories',
})
export class TpHistory extends JsonApiModel {

  get ticketId() {
    return +this.id; // (+) converts string 'id' to a number
  }

  @Attribute({ serializedName: 'events', converter: jsonConverter })
    events: TpEvent[];

}
