import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { BillableCustomer } from './billable-customer';
import { Investigation } from './investigation';
import { Damage } from './damage';
import { FlatRateCustomer } from './flat-rate-customer';

/*****************************************
 * Facility
 *****************************************/
@JsonApiModelConfig({
  type: 'facilities'
})
export class Facility extends JsonApiModel {

  @Attribute({ serializedName: 'fd_damage_id', converter })
    damageId: number;

  @Attribute({ serializedName: 'fd_investigation_id', converter })
    investigationId: number;

  @Attribute({ serializedName: 'fd_customer_name', converter })
    customerName: string;

  @HasMany()
    flatRateCustomers: FlatRateCustomer[];

  @HasMany()
    billableCustomers: BillableCustomer[];

  @BelongsTo()
    damage: Damage;

  @BelongsTo()
    investigation: Investigation;

}
