<mat-expansion-panel class="border-bottom mat-elevation-z0"
                     [ngClass]="customClass"
                     [expanded]="expanded"
                     (opened)="opened()" (closed)="closed()">

  <mat-expansion-panel-header>
    <mat-panel-title>

      <div class="layout layout-align-center-center">

        @if (icon) {
          <a mat-icon-button
             [matTooltip]="iconTooltip" (click)="iconClicked($event)">

            <mat-icon matPrefix [color]="iconColor">

              {{ icon }}

            </mat-icon>
          </a>
        }

        <span class="nowrap"> {{ panelTitle }} </span>

      </div>

    </mat-panel-title>

    <mat-panel-description>
      <ng-content select="[description]"></ng-content>
    </mat-panel-description>

  </mat-expansion-panel-header>

  <ng-content></ng-content>

  @if (includeActions) {
    <mat-action-row>
      <ng-content select="[actions]"></ng-content>
    </mat-action-row>
  }

</mat-expansion-panel>
