import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany
} from '@michalkotas/angular2-jsonapi';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Claim } from './claim';

/*****************************************
 * PayeeAddress
 *****************************************/
@JsonApiModelConfig({
  type: 'payeeAddresses'
})
export class PayeeAddress extends JsonApiModel {

  @Attribute({ serializedName: 'claim_payee_id', converter })
    claimPayeeId: number;

  @Attribute({ serializedName: 'payee', converter })
    payee: string;

  @Attribute({ serializedName: 'address', converter })
    address: string;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    endDate: Date;

  @Attribute({ serializedName: 'workday_vendor_number', converter })
    workdayVendorNumber: string;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.startDate,
      effectiveEndDate: this.endDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.startDate = v.effectiveStartDate;
    this.endDate = v.effectiveEndDate;
  }

  @HasMany()
    claims: Claim[];

}
