@if (column.linkRoute) {

  @if (column.linkId4) {

    <a [routerLink]="[column.linkRoute, element[column.linkId], element[column.linkId2], element[column.linkId3], element[column.linkId4]]">
      <ng-container *ngTemplateOutlet="iconOrText"></ng-container>
    </a>

  } @else if (column.linkId3) {

    <a [routerLink]="[column.linkRoute, element[column.linkId], element[column.linkId2], element[column.linkId3]]">
      <ng-container *ngTemplateOutlet="iconOrText"></ng-container>
    </a>

  } @else if (column.linkId2) {

    <a [routerLink]="[column.linkRoute, element[column.linkId], element[column.linkId2]]">
      <ng-container *ngTemplateOutlet="iconOrText"></ng-container>
    </a>

  } @else {

    <a [routerLink]="[column.linkRoute, element[column.linkId]]">
      <ng-container *ngTemplateOutlet="iconOrText"></ng-container>
    </a>

  }

} @else {

  @switch (column.type) {

    @case ('component') {
      <ng-container *ngComponentOutlet="column.component; injector: myInjector;"></ng-container>
    }

    @default {

      @if (column.linkUrl) {

        <!-- nosemgrep: generic.html-templates.security.var-in-href.var-in-href, javascript.express.security.audit.xss.mustache.var-in-href.var-in-href, python.django.security.audit.xss.template-href-var.template-href-var, python.flask.security.xss.audit.template-href-var.template-href-var -->
        <a href="{{element | resolve:(column.linkUrl)}}"
          [attr.data-content]="element | resolve:(column.name) | dataFormatter:(column.type)"
          target="_blank" rel="noopener noreferrer nofollow">

          <ng-container *ngTemplateOutlet="iconOrText"></ng-container>

        </a>

      } @else {

        <div (click)="parent.onCellClick($event, column, element)"
          (contextmenu)="parent.onContextMenu($event, column, element)">

          @switch (column.type) {

            @case ('component') {
              <ng-container *ngComponentOutlet="column.component; injector: myInjector;"></ng-container>
            }

            @case ('icon') {
              @if (!column.omitIf || (column.omitIf && !element[column.omitIf])) {
                <mat-icon [attr.data-value]="element[column.name]">{{column.icon}}</mat-icon>
              }
            }

            @default {
              @if (column.value) {
              <span [innerHTML]="column.value | dataFormatter:(column.type)| highlightText : parent[column.name + 'Filter']?.value || parent.globalFilter?.value"></span>
              } @else {
              <span [innerHTML]="element | resolve:(column.name) | dataFormatter: column.type: (parent?.dataSource?.filter ? parent.dataSource.filter[column.filterName || column.name] : undefined) | highlightText : parent[column.name + 'Filter']?.value || parent.globalFilter?.value"></span>
              }
            }

          }

        </div>

      }

    }

  }

}

<ng-template #iconOrText>
  @if (column.icon) {
    <mat-icon>{{column.icon}}</mat-icon>
  } @else {
    <span [innerHTML]="element | resolve:(column.name) | dataFormatter:(column.type)| highlightText : parent[column.name + 'Filter']?.value || parent.globalFilter?.value"></span>
  }
</ng-template>
