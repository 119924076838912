import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';

/*****************************************
 * FinancialPeriod
 *****************************************/
@JsonApiModelConfig({
  type: 'financialPeriods'
})
export class FinancialPeriod extends JsonApiModel {

  @Attribute({ serializedName: 'period_name', converter })
    name: string;

  @Attribute({ serializedName: 'year', converter })
    year: number;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    endDate: Date;

  @Attribute({ serializedName: 'status_id', converter })
    statusId: number;

  @Attribute({ serializedName: 'attribute1', converter })
    attribute1: string;

  @Attribute({ serializedName: 'attribute2', converter })
    attribute2: string;

  @Attribute({ serializedName: 'attribute3', converter })
    attribute3: string;

  @Attribute({ serializedName: 'closed_for_estimates', converter })
    closedForEstimates: boolean;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.startDate,
      effectiveEndDate: this.endDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.startDate = v.effectiveStartDate;
    this.endDate = v.effectiveEndDate;
  }

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
    this.attribute2 = v.attribute2;
    this.attribute3 = v.attribute3;
  }

  // circular dependency
  // @HasMany()
  //   claims: Claim[];

  // circular dependency
  // @HasMany()
  //   estimates: Estimate[];

  // circular dependency
  // @HasMany()
  //   accrualSummaries: AccrualSummary[];

  // circular dependency
  // @HasMany()
  //   accruals: Accrual[];

  // circular dependency
  // @HasMany()
  //   legalRisks: LegalRisk[];

  // circular dependency
  // @HasMany()
  //   policyDropOffEstimates: PolicyDropOffEstimate;

}
