import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';

/*****************************************
 * CmsErrorLog
 *****************************************/
@JsonApiModelConfig({
  type: 'cmsErrorLogs',
})
export class CmsErrorLog extends JsonApiModel {

  @Attribute({ serializedName: 'severity', converter })
    severity: number;

  @Attribute({ serializedName: 'error_state', converter })
    errorState: number;

  @Attribute({ serializedName: 'module', converter })
    module: string;

  @Attribute({ serializedName: 'line_no', converter })
    lineNo: number;

  @Attribute({ serializedName: 'message', converter })
    message: string;

  @Attribute({ serializedName: 'creation_date', converter: dateConverter })
    creationDate: Date;

}
