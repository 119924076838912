@if (pageName) {
  <div class="page-header layout layout-wrap layout-align-space-between-center">

    <h1> {{ pageName }}
      @if (pageInfo && breakpoints.screen('gt-sm')) {
        <mat-icon [matTooltip]="pageInfo" #info="matTooltip"
                  matTooltipPosition="right"
                  matTooltipShowDelay="500"
                  matTooltipHideDelay="1000">info</mat-icon>
      }
    </h1>

    <ng-content></ng-content>

  </div>
}
