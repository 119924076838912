import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * EmployeeCertification
 *****************************************/
@JsonApiModelConfig({
  type: 'employeeCertifications'
})
export class EmployeeCertification extends JsonApiModel {

  @Attribute({ serializedName: 'record_id', converter })
    recordId: number;

  @Attribute({ serializedName: 'emp_id', converter })
    empId: number;

  @Attribute({ serializedName: 'path_name', converter })
    pathName: string;

  @Attribute({ serializedName: 'initial_qualification_date', converter: dateConverter })
    initialQualificationDate: Date;

  @Attribute({ converter })
    active: boolean;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

}
