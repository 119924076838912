<div class="layout layout-column layout-fill">

  <div> <ng-content></ng-content> </div>

  <div class="table-map bg-usic-white" class="layout-row flex">

    <div class="table-wrapper border-top">

      <lib-usic-table #usicTable
                      [displayedColumns]="displayedColumns"
                      [jsonApiDataSource]="jsonApiDataSource"
                      [filtering]="filtering"
                      [expandFilters]="expandFilters"
                      [provideExcelExport]="provideExcelExport"
                      [selectByKey]="selectByKey"
                      [selectByValue]="selectedTicketId"
                      [(selectedRows)]="selectedRows"
                      [editComponent]="editComponent"
                      [cancelRefresh]="cancelRefresh"
                      [dataSourceLoading]="dataSourceLoading"
                      [prefix]="prefix"
                      [dynamicPageSize]="dynamicPageSize"
                      (changePage)="changePage.emit($event)"
                      (changeSort)="changeSort.emit($event)"
                      (openDialog)="openDialog.emit($event)"
                      (clickCell)="clickCell.emit($event)"
                      (clickRow)="clickRow.emit($event)"
                      (dblClickRow)="dblClickRow.emit($event)"
                      (createNew)="createNew.emit($event)"
                      (deleteRecord)="deleteRecord.emit($event)"
                      (changeColumns)="changeColumns.emit($event)"
                      (cancelClicked)="cancelClicked.emit($event)"
                      >
                      <ng-content select="[table]"></ng-content>
                      @if (breakpoints.screen('xs') || breakpoints.screen('sm')) {
                        <mat-icon (click)='showMap()' matSuffix title="Show Map" matTooltip="Show Map" style="cursor:pointer;">map</mat-icon>
                      }
                    </lib-usic-table>

    </div>

    @if (breakpoints.screen('gt-sm')) {

      <lib-usic-sidebar #mapSidebar
                        sidebarName="map-table-sidebar"
                        position="end"
                        defaultWidth="40vw"
                        helpText="Show/Hide Map"
                        chevronRightIcon="arrow_circle_right"
                        chevronLeftIcon="arrow_circle_left"
                        [ignoreIcons]="true">

        <div class="map-wrapper border-top">

        <lib-here-map [locates]="displayedTickets"
                      [userRequests]="displayedRequests"
                      [selectByKey]="selectByKey"
                      [selectByValue]="selectedTicketId"
                      [selectByCoord]="selectedReqCoord"
                      [disableShowAll]="disableShowAll"
                      (clickMarker)="selectTicketById($event)"
                      (hoverMarker)="selectTicketById($event)"
                      (clickCluster)="clickCluster.emit($event)"
                      ></lib-here-map>

        </div>

      </lib-usic-sidebar>

    }

  </div> <!-- .table-map -->

</div>
