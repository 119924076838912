import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { ResponseLog } from './response-log';
import { Term } from './term';
import { Transmission } from './transmission';
import { TransmissionBase } from './transmission-base';


/*****************************************
 * Feed
 *****************************************/
@JsonApiModelConfig({
  type: 'feeds',
})
export class Feed extends JsonApiModel {

  @Attribute({ serializedName: 'feedname', converter })
    feedName: string;

  @Attribute({ serializedName: 'queue_responses', converter })
    queueResponses: boolean;

  @Attribute({ serializedName: 'notify_excavators', converter })
    notifyExcavators: boolean;

  @Attribute({ serializedName: 'time_zone', converter })
    timeZone: string;

  @Attribute({ serializedName: 'feed_state', converter })
    feedState: string;

  @Attribute({ converter })
    manual: boolean;

  @Attribute({ serializedName: 'parser_id', converter })
    parserId: number;

  @Attribute({ serializedName: 'parser_on', converter })
    parserOn: boolean;

  @Attribute({ serializedName: 'low_volume_threshold', converter })
    lowVolumeThreshold: number;

  @Attribute({ converter })
    extensions: string;

  @Attribute({ serializedName: 'allow_combine', converter })
    allowCombine: boolean;

  @Attribute({ serializedName: 'respond_trans', converter })
    respondTrans: boolean;

  @Attribute({ serializedName: 'update_due_dates', converter })
    updateDueDates: boolean;

  @Attribute({ serializedName: 'billed_manually', converter })
    billedManually: boolean;

  @Attribute({ serializedName: 'call_after_hours', converter })
    callAfterHours: boolean;

  @Attribute({ serializedName: 'call_normal_hours', converter })
    callNormalHours: boolean;

  @Attribute({ converter })
    inactive: boolean;

  @Attribute({ serializedName: 'notify_reschedule', converter })
    notifyReschedule: boolean;

  @Attribute({ converter })
    preferred: boolean;

  @Attribute({ serializedName: 'sticky_routing', converter })
    stickyRouting: boolean;

  @Attribute({ serializedName: 'reopen_on_update', converter })
    reopenOnUpdate: boolean;

  @Attribute({ serializedName: 're_call_normal_hours', converter })
    reCallNormalHours: boolean;

  @Attribute({ serializedName: 're_call_after_hours', converter })
    reCallAfterHours: boolean;

  @Attribute({ serializedName: 'respect_state_boundaries', converter })
    respectStateBoundaries: boolean;

  @Attribute({ serializedName: 'centralized_routing', converter })
    centralizedRouting: boolean;

  @Attribute({ serializedName: 'update_callout_policy', converter })
    updateCalloutPolicy: string;

  @Attribute({ converter })
    unlocatable: boolean;

  @Attribute({ converter })
    description: string;

  @Attribute({ serializedName: 'excav_notif_format', converter })
    excavNotifFormat: string;

  @Attribute({ serializedName: 'respond_attachments', converter })
    respondAttachments: boolean;

  @Attribute({ serializedName: 'respond_assignments', converter })
    respondAssignments: boolean;

  @Attribute({ serializedName: 'respond_excav_notif', converter })
    respondExcavNotif: boolean;

  @Attribute({ serializedName: 'excavator_reschedule', converter })
    excavatorReschedule: boolean;

  @Attribute({ serializedName: 'one_call_center', converter })
    oneCallCenter: string;

  @Attribute({ serializedName: 'do_not_respond', converter })
    doNotRespond: boolean;

  @Attribute({ serializedName: 'unique_transmissions', converter })
    uniqueTransmissions: boolean;

  @Attribute({ serializedName: 're_call_events', converter })
    reCallEvents: string;

  @Attribute({ serializedName: 'notif_pattern_id', converter })
    notifPatternId: number;

  @Attribute({ serializedName: 'delay_minutes', converter })
    delayMinutes: number;

  @Attribute({ serializedName: 'attach_transmission', converter })
    attachTransmission: boolean;

  @Attribute({ serializedName: 'route_corporate_holidays', converter })
    routeCorporateHolidays: boolean;

  @HasMany()
    terms: Term[];

  @HasMany()
    transmissionsBase: TransmissionBase[];

  @HasMany()
    transmissions: Transmission[];

  @HasMany()
    responseLogs: ResponseLog[];

}
