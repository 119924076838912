<div class="margin-top">

  <lib-usic-page-header pageName="Recurring Delivery Schedule"></lib-usic-page-header>

</div>

<form class="padding"> <!-- nosemgrep: python.django.security.django-no-csrf-token.django-no-csrf-token -->

<!--  cronString {{ cronForm?.value }}-->

  <cron-editor
    class="usic-cron-editor"
    [formControl]="cronForm"
    [options]="cronOptions">
  </cron-editor>

  <app-cron-help-message [cronString]="cronForm.value"></app-cron-help-message>

  <div class="layout layout-align-end-center">

    <button mat-flat-button color="accent" type="submit" [mat-dialog-close]="cronForm.value"> Submit </button>
    <button mat-button type="reset" mat-dialog-close> Cancel </button>

  </div>

</form>
