import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * AttWirelessInventoryUsage
 *****************************************/
@JsonApiModelConfig({
  type: 'attWirelessInventoryUsages'
})
export class AttWirelessInventoryUsage extends JsonApiModel {

  // ID is the wireless number
  get wirelessNumber() {
    return this.id;
  }

  @Attribute({ serializedName: 'fan', converter })
    fan: string;

  @Attribute({ serializedName: 'ban', converter })
    ban: string;

  @Attribute({ serializedName: 'usage_requested_date', converter: dateConverter })
    usage_requested_date: string;

  @Attribute({ serializedName: 'billing_cycle_end_date', converter: dateConverter })
    billing_cycle_end_date: string;

  @Attribute({ serializedName: 'voice_plan_name', converter })
    voice_plan_name: string;

  @Attribute({ serializedName: 'voice_minutes_anytime', converter })
    voice_minutes_anytime: string;

  @Attribute({ serializedName: 'data_plan_name_1', converter })
    data_plan_name_1: string;

  @Attribute({ serializedName: 'data_usage_1', converter })
    data_usage_1: string;

  @Attribute({ serializedName: 'data_plan_name_2', converter })
    data_plan_name_2: string;

  @Attribute({ serializedName: 'data_usage_2', converter })
    data_usage_2: string;

  @Attribute({ serializedName: 'data_plan_name_3', converter })
    data_plan_name_3: string;

  @Attribute({ serializedName: 'data_usage_3', converter })
    data_usage_3: string;

  @Attribute({ serializedName: 'data_plan_name_4', converter })
    data_plan_name_4: string;

  @Attribute({ serializedName: 'data_usage_4', converter })
    data_usage_4: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

}
