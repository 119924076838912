import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import {
  Employee,
} from '../shareddata';

/* The device_group value from SOTI has several layers.  For example:

\\Staging\USIC\Field Supervisors\Phones - Field Supervisors
\\Staging\Reconn\Field Supervisors\Tablets - Field Supervisors
\\Staging\Test\Test Phones
\\Staging\Field Overhead - Excluding Sups
\\Staging\On Target\Field Supervisors\Phones - Field Supervisors
\\Staging\Corporate\Tablets - Corporate
\\Staging\Corporate\Phones - Corporate
\\Staging\Bloodhound\Field Supervisors\Tablet - Field Supervisors
\\Staging\Alabama\Alabama Tablets
\\Staging\Field Overhead - Excluding Sups\Tablets - Field Overhead
\\Staging\Reconn\Field Technicians\Tablets - Field Technicians
\\Staging\USIC\Field Supervisors
\\Staging\Reconn\Field Technicians\Phones - Field Technicians
\\Staging\Florida\Florida Tablets
\\Staging\Bloodhound\Field Technicians\Tablet - Field Technicians
\\Staging\USIC\Field Technicians
\\Staging\Protek\Field Technicians\Phones
\\Staging\On Target\Field Technicians\Tablets - Field Technicians
\\Staging\Corporate\Apple - Corporate
\\Staging\Test\Test Tablets
\\Staging\Reconn\Field Technicians
\\Staging\Alabama\Alabama Phones
\\Staging
\\Staging\On Target\Field Technicians\Phones - Field Technicians
\\Staging\Field Overhead - Excluding Sups\Phones - Field Overhead
\\Staging\USIC\Field Technicians\Phones - Field Technicians
\\Staging\Florida\Florida Phones
\\Staging\Bloodhound\Field Technicians\Phones - Field Technicians
\\Staging\USIC\Field Supervisors\Tablets - Field Supervisors
\\Staging\Reconn\Apple Devices
\\Staging\Bloodhound\Field Supervisors\Phones - Field Supervisors
\\Staging\USIC\Field Technicians\Tablets  - Field Technicians
\\Staging\Reconn\Field Supervisors\Phones - Field Supervisors

We'll use a converter to remove that prefix for display purposes
*/
const deviceGroupConverter = {
  mask: (value: string) => {
    const prefixEnd = value.lastIndexOf('\\') + 1;
    return prefixEnd > 0 ? value.slice(prefixEnd) : value;
  },
  unmask: (value: string) => value
};

/*****************************************
 * MdmDevice
 *****************************************/
@JsonApiModelConfig({
  type: 'mdmDevices'
})
export class MdmDevice extends JsonApiModel {

  @Attribute({ serializedName: 'device_group', converter: deviceGroupConverter })
    deviceGroup: string;

  @Attribute({ converter })
    username: string;

  @Attribute({ serializedName: 'serial_number', converter })
    serialNumber: string;

  @BelongsTo()
    employee: Employee;

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @Attribute({ converter })
    district: string;

  @Attribute({ converter })
    network: string;

  @Attribute({ serializedName: 'phone_number', converter })
    phoneNumber: string;

  @Attribute({ converter })
    iccid: string;

  @Attribute({ serializedName: 'device_name', converter })
    deviceName: string;

  @Attribute({ converter })
    model: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

}
