import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * PhotoCompliance
 *****************************************/
@JsonApiModelConfig({
  type: 'photoCompliances'
})
export class PhotoCompliance extends JsonApiModel {


  @Attribute({ serializedName: 'purpose', converter })
    purpose: string;

  @Attribute({ serializedName: 'message', converter })
    message: string;

  @Attribute({ serializedName: 'distance', converter })
    distance: number;

  @Attribute({ serializedName: 'perimission', converter })
    perimission: string;

  @Attribute({ serializedName: 'customer_name', converter })
    customerName: string;

  @Attribute({ serializedName: 'customer', converter })
    customer: string;

  @Attribute({ serializedName: 'status_codes', converter })
    statusCodes: string;

  @Attribute({ serializedName: 'model', converter })
    model: string;

  @Attribute({ serializedName: 'check_visit_boundary', converter })
    checkVisitBoundary: boolean;

  @Attribute({ serializedName: 'deleted', converter })
    deleted: boolean;

  @Attribute({ serializedName: 'model_version', converter })
    modelVersion: number;

  @Attribute({ serializedName: 'customer_facility_type', converter })
    customerFacilityType: string;

}
