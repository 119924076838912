import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { AccrualSummary } from './accrual-summary';
import { Accrual } from './accrual';
import { PolicyDropOffEstimate } from './policy-drop-off-estimate';

/*****************************************
 * CmsRequest
 *****************************************/
@JsonApiModelConfig({
  type: 'cmsRequests',
  modelEndpointUrl: 'requests',
})
export class CmsRequest extends JsonApiModel {

  @Attribute({ serializedName: 'emp_id', converter })
    emp_id: number;

  @Attribute({ serializedName: 'userid', converter })
    userid: number;

  @Attribute({ serializedName: 'username', converter })
    username: string;

  @Attribute({ serializedName: 'session_id', converter })
    sessionId: number;

  @Attribute({ serializedName: 'start_time', converter: dateConverter })
    startTime: Date;

  @Attribute({ serializedName: 'end_time', converter: dateConverter })
    endTime: Date;

  @Attribute({ serializedName: 'request_name', converter })
    request_name: string;

  @Attribute({ serializedName: 'request_type', converter })
    request_type: string;

  @Attribute({ serializedName: 'completed_status', converter })
    completed_status: string;

  @Attribute({ serializedName: 'param1', converter })
    param1: string;

  @Attribute({ serializedName: 'param2', converter })
    param2: string;

  @Attribute({ serializedName: 'param3', converter })
    param3: string;

  @Attribute({ serializedName: 'param4', converter })
    param4: string;

  @Attribute({ serializedName: 'param5', converter })
    param5: string;

  @Attribute({ serializedName: 'param6', converter })
    param6: string;

  @Attribute({ serializedName: 'param7', converter })
    param7: string;

  @Attribute({ serializedName: 'param8', converter })
    param8: string;

  @Attribute({ serializedName: 'param9', converter })
    param9: string;

  @Attribute({ serializedName: 'param10', converter })
    param10: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    endDate: Date;

  @HasMany()
    accrualSummaries: AccrualSummary[];

  @HasMany()
    accruals: Accrual[];

  @HasMany()
    policyDropOffEstimates: PolicyDropOffEstimate[];

}
