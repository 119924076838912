<!--
<mat-tab-group>
    <mat-tab label="USIC Login">
        <div id="login-form">
            <lib-login-page appName="Login" method="jwt"></lib-login-page>
        </div>
    </mat-tab>
    <mat-tab label="Customer Portal">
        <div id="login-form">
            <lib-login-page appName="Customer Portal Login" signInMsg="Sign In to Your Customer Account" method="customerPortal"></lib-login-page>
        </div>
    </mat-tab>
</mat-tab-group>
<br/>
-->
<lib-login-page appName="Login" method="jwt" (forgotPassword)="forgotPassword($event)"></lib-login-page>