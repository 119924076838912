import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';

/*****************************************
 * DamagePhoto
 *****************************************/
@JsonApiModelConfig({
  type: 'damagePhotos'
})
export class DamagePhoto extends JsonApiModel {

  @Attribute({ serializedName: 'volume_id', converter })
    volume_id: number;

  @Attribute({ serializedName: 'priv', converter })
    priv: boolean;

  @Attribute({ serializedName: 'path', converter })
    path: string;

  @Attribute({ serializedName: 'filetype', converter })
    filetype: string;

  @Attribute({ serializedName: 'filename', converter })
    filename: string;

  @Attribute({ serializedName: 'filesize', converter })
    filesize: string;

  @Attribute({ serializedName: 'attachment_entity_id', converter })
    attachment_entity_id: number;

  @Attribute({ serializedName: 'pk1_int', converter })
    pk1_int: number;

  @Attribute({ serializedName: 'EntityID', converter })
    entityID: number;

  @Attribute({ serializedName: 'attachment_entity_name', converter })
    attachment_entity_name: string;

  @Attribute({ serializedName: 'entity_table', converter })
    entity_table: string;

  @Attribute({ serializedName: 'Vol', converter })
    vol: number;

  @Attribute({ serializedName: 'Host', converter })
    host: string;

  @Attribute({ serializedName: 'protocol', converter })
    protocol: string;

  @Attribute({ serializedName: 'fullUrl', converter })
    fullUrl: string;

  @Attribute({ serializedName: 'fulltnUrl', converter })
    fulltnUrl: string;

  @Attribute({ serializedName: 'number', converter })
    'number': string;

  get url() {
    return this.fullUrl.replace(/\\/g, '/').replace(/\/\/slstr009/, '');
  }

  // The files are stored in paths with the convetion "yyyy/MM/dd/" so we can
  // utilize that to determine the file date
  get fileDate() {
    return new Date(Date.parse(this.path));
  }
}
