
<div mat-dialog-title [ngClass]="{'bg-usic-blue' : parent.titleBg === 'blue'}"
  [ngStyle]="{padding: (parent.title) ? 'default' : 0}">

  @if (parent.showBack) {
    <button mat-icon-button>
      <mat-icon> keyboard_backspace </mat-icon>
    </button>
  }


  @if (parent.title) {

    <h1 [ngStyle]="breakpoints.screen('xs') && {fontSize:'20px'}"
        class="layout layout-align-center-center flex">

      <span> {{ parent.title }} </span>

      @if (parent.canEdit !== null && parent.canEdit===false) {
        <!-- renders a conditional 'read-only' icon with message  -->
        <button mat-icon-button [mat-dialog-close]
                [matTooltip]="parent.readOnlyTooltip || 'READ ONLY'">
          <mat-icon color="warn"> not_interested </mat-icon>
        </button>
      }

    </h1>

  } @else {

    <div class="layout layout-align-center-center flex">&nbsp;</div>

  }

  <button class="title-close-button" mat-icon-button mat-dialog-close="Closed.">
    <mat-icon> close </mat-icon>
  </button>

</div>
