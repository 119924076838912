/********************************************************************************
 * AuthenticationService
 *
 * Service to provide user authentication and supporting functions.
 * It can authenticate from several sources all of which return a JWT token:
 *   + JSONAPI /auth API
 *   + CustomerPortal API
 *   + Routing API
 * Once authenticated, it provides method to read values from the JWT token such
 * as the user name and authorized roles.
 * It also provide the function to renew an expiring JWT token of for admins to
 * become another user to testing and support.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
// import * as CryptoJS from 'crypto-js';
import { jwtDecode, JwtPayload } from 'jwt-decode';

import { UserAuth } from '../models/user';
import { environment } from '../../environments/environment';
import { PersistentSettingsService } from './persistent-settings.service';

import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { OAuthSettings } from '@usic/environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserAuth>;
  public currentUser: Observable<UserAuth>;
  public loginMethod: string;

  /**
   * List of supported roles in the angular-workspace and usicjsonapi
   * Keep in sync with list in websecurity.properties::ldap.allowedGroups
   */
  public availableRoles = [
    'ROLE_ADMINASSISTANTS',
    'ROLE_ALLSUPS',
    'ROLE_ASSET MANAGER - ADMINS',
    'ROLE_ASSET MANAGER - PRIVILEGED USERS',
    'ROLE_ASSET MANAGER - USERS',
    'ROLE_CAREER PROGRESSION - ADMINS',
    'ROLE_CAREER PROGRESSION - USERS',
    'ROLE_CMS_REPORTING',
    'ROLE_CMS_ADMIN_REPORTS',
    'ROLE_CMS_FINANCIAL_REPORTS',
    'ROLE_CMS_LEGAL_REPORTS',
    'ROLE_CMS_OPERATIONAL_REPORTS',
    'ROLE_CMS_REPORTING_ADMINREPORTS',
    'ROLE_CMS_REPORTING_FINANCIALREPORTS',
    'ROLE_CMS_REPORTING_LEGALREPORTS',
    'ROLE_CMS_REPORTING_OPERATIONALREPORTS',
    'ROLE_CORPORATE - EXECUTIVE',
    'ROLE_CORPORATE - HR',
    'ROLE_CP_ADMIN',
    'ROLE_CP_SHOW_DAMAGES',
    'ROLE_CP_USER',
    'ROLE_CP_USER_PASSWORD',
    'ROLE_DISTRICT MANAGERS',
    'ROLE_EHS MANAGERS',
    'ROLE_FIELD SUPERVISORS - ALL',
    'ROLE_FINANCE CSH',
    'ROLE_FIELD HR',
    'ROLE_MESSENGER - ADMINS',
    'ROLE_MESSENGER - USERS',
    'ROLE_OPS CLAIMS MANAGERS',
    'ROLE_OPS COORDINATORS - ALL',
    'ROLE_OPS MANAGERS',
    'ROLE_REGIONAL DIRECTORS',
    'ROLE_REPORT - ADMINS',
    'ROLE_REPORT - USERS',
    'ROLE_SP_TELECOM',
    'ROLE_SUPERVISORS - BHUG',
    'ROLE_SUPPORT',
    'ROLE_WEBAPP - ADMINS',
  ];


  constructor(
    private http: HttpClient,
    private persistentSettings: PersistentSettingsService,
    private msalService: MsalService,
  ) {
    this.currentUserSubject = new BehaviorSubject<UserAuth>(JSON.parse(localStorage.getItem('currentUser')));
    this.loginMethod = localStorage.getItem('loginMethod');
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserAuth {
    return this.currentUserSubject.value;
  }

  public get currentUserRoles(): string[] {
    if (this.currentUserValue &&
      this.currentUserValue.token &&
      jwtDecode(this.currentUserValue.token)['roles']) {
      return jwtDecode(this.currentUserValue.token)['roles'].split(',').map((r: string) => r?.trim());
    } else {
      return [];
    }
  }

  public currentUserHasRole(role: string): boolean {
    return this.currentUserRoles.indexOf(role) > -1;
  }

  public currentUserHasAnyRole(roles: string[]): boolean {
    return this.currentUserRoles.some(r => roles.includes(r));
  }

  public get currentUserExpire(): Date {
    if (this.currentUserValue &&
      this.currentUserValue.token &&
      (jwtDecode(this.currentUserValue.token) as JwtPayload).exp) {
      return new Date((jwtDecode(this.currentUserValue.token) as JwtPayload).exp * 1000);
    } else {
      // if there's no expiration date in the JWT, then assume it expired yesterday
      return new Date((new Date()).setDate((new Date()).getDate() - 1));
    }
  }

  public get currentUserIsValid(): boolean {
    return this.currentUserExpire > (new Date());
  }

  public get currentUserName(): string {
    if (this.currentUserValue &&
      this.currentUserValue.token &&
      jwtDecode(this.currentUserValue.token)['name']) {
      return jwtDecode(this.currentUserValue.token)['name'];
    } else {
      return this.currentUserValue?.loginName;
    }
  }

  public get currentUserId(): number {
    if (this.currentUserValue &&
      this.currentUserValue.token &&
      jwtDecode(this.currentUserValue.token)['id']) {
      return jwtDecode(this.currentUserValue.token)['id'];
    } else {
      return -1;
    }
  }

  public get currentUserLogin(): string {
    if (this.currentUserValue &&
      this.currentUserValue.token &&
      (jwtDecode(this.currentUserValue.token) as JwtPayload).sub) {
      return (jwtDecode(this.currentUserValue.token) as JwtPayload).sub;
    } else {
      return this.currentUserValue?.loginName;
    }
  }

  // Set the currentUserValue from the contents of the token
  public setCurrentUserFromJWT(token: string) {
    const jwt = jwtDecode(token);
    const user: UserAuth = {
      id: jwtDecode(token)['id'],
      loginName: (jwt as JwtPayload).sub,
      token
    };
    this.currentUserSubject.next(user);
  }


  // default login will use JWT
  login({ username, password }: { username: string; password: string }) {
    return this.loginJWT(username, password);
  }

  // JWT login
  // authenticates using JSON:API /auth against ActiveDirectory
  loginJWT(username: string, password: string) {
    return this.http.post<any>(`${environment.jsonApiUrl}/auth`, { username, password })
      .pipe(map(user => {
        user.loginName = username;
        user.user = { name: username };

        // store user details in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        localStorage.setItem('loginMethod', 'JWT');
        this.loginMethod = 'JWT';
        return user;
      }));
  }

  // ActiveDirectory login
  // currently uses Node.js RoutingAPI
  loginAD(username: string, password: string) {
    return this.http.post<any>(`${environment.routingApiUrl}/login`, { username, password })
      .pipe(map(user => {
        user.loginName = username;

        // store user details in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        localStorage.setItem('loginMethod', 'AD');
        this.loginMethod = 'AD';
        return user;
      }));
  }

  /*
  // CustomerPortal login
  // uses the Customer API
  loginCP(username: string, password: string) {
    const privateKey = 'F24ECC3DF6C9BD07A439ECC479902A09';
    const aesIV = '615F6903F5CC03C3';

    // Setup the key and iv
    const key = CryptoJS.enc.Utf8.parse(privateKey);
    const iv = CryptoJS.enc.Utf8.parse(aesIV);

    // Hash the username and password using AES
    const userPassHash = CryptoJS.AES.encrypt(username + ':' + password, key, {
      keySize: 128 / 8, iv,
      mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
    });
    const userAuth = {
      userToken: userPassHash.toString(),
      username
    };

    return this.http.post<any>(`${environment.customerApiUrl}/CPUsers/AuthenticateUser`, userAuth)
      .pipe(map(user => {
        user.loginName = username;
        user.user = {
          name: username
        };
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        localStorage.setItem('loginMethod', 'CP');
        this.loginMethod = 'CP';
        return user;
      }));
  }
  */

  async loginAzure(): Promise<void | AuthenticationResult> {
    const result = await this.msalService
      .loginPopup(Object.assign(OAuthSettings, { redirectUri: window.location.origin + window.location.pathname }))
      .toPromise()
      .catch((reason) => {
        console.error('Azure Login, failed',
          JSON.stringify(reason, null, 2));
      });

    return result;
  }

  validateAzureResult(result: void | AuthenticationResult) {
    if (result) {

      // Convert the Azure ID token to our app JWT token
      const url = `${environment.jsonApiUrl}/validate?accessToken=${result.accessToken}`;

      // remove the current token so that it is not added as a Bearer token in the POST
      this.currentUserSubject.next({
        loginName: result.account.username,
        user: { name: result.account.username },
        token: undefined
      });

      return this.http.get(url)
        .pipe(retry(2))
        .pipe(map((user: any) => {

          user.loginName = result.account.username;
          user.user = { name: result.account.username };

          // store user details in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          localStorage.setItem('loginMethod', 'Azure');
          this.loginMethod = 'Azure';
        }));


    }

  }

  // renewToken
  // Get a fresh JWT token when the current one is about to expire
  renewToken(): Observable<boolean> {
    const username = this.currentUserValue.loginName;
    const token = this.currentUserValue.token;

    /*
    if (this.loginMethod === 'CP') {
      // Rewnew for old CustomerPortal API
      url = `${environment.customerApiUrl}/CPUsers/RenewUserToken`;
      body = {
        userToken: token,
        username
      };
    } else {
    */
    // Renew for JSONAPI
    const url = `${environment.jsonApiUrl}/renew`;
    const body = { token };

    // remove the current token so that it is not added as a Bearer token in the POST
    this.currentUserSubject.next({
      loginName: username,
      user: { name: username },
      token: undefined
    });
    // }

    console.log('renewToken', url, body);
    return this.http.post<any>(url, body)
      .pipe(map(user => {

        user.loginName = username;
        user.user = {
          name: username
        };
        // store user details in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return true;
      }));
  }

  // Become
  // Become a different user (admin authority is required in API)
  become(username: string) {
    /*
    if (this.loginMethod === 'CP') {
      // Impersonate for old CustomerPortal API
      url = `${environment.customerApiUrl}/CPUsers/ImpersonateUser`;

      const currentusername = this.currentUserValue.loginName;
      const token = this.currentUserValue.token;

      // Rewnew for old CustomerPortal API
      body = {
        userToken: token,
        username: currentusername,
        newUsername: username
      };
    } else {
    */
    // Impersonate for JSONAPI
    const url = `${environment.jsonApiUrl}/impersonate`;
    const body = { username };
    // }

    console.log('become', url, body);
    return this.http.post<any>(url, body)
      .pipe(map(user => {
        user.loginName = username;
        user.user = {
          name: username
        };
        // store user details in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));

        // Clear any old scoping values
        this.persistentSettings.clearSetting('filter-regionName');
        this.persistentSettings.clearSetting('filter-districtNo');
        this.persistentSettings.clearSetting('filter-supGroupCode');
        this.persistentSettings.clearSetting('filter-employeeId');

        this.currentUserSubject.next(user);
        return user;
      }));
  }

  // logout
  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    if (this.loginMethod === 'Azure') {
      this.msalService.logoutPopup();
    }
  }
}
