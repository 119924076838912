import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { EligibleAttributeValue } from '.';

/*****************************************
 * EligibleAttribute
 *****************************************/
@JsonApiModelConfig({
  type: 'eligibleAttributes'
})
export class EligibleAttribute extends JsonApiModel {

  @Attribute({ converter })
    name: string;

  @Attribute({ converter })
    type: string;

  @Attribute({ serializedName: 'eff_start_date', converter: dateConverter })
    effStartDate: Date;

  @Attribute({ serializedName: 'eff_end_date', converter: dateConverter })
    effEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    eligibleAttributeValues: EligibleAttributeValue[];

}
