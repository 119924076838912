import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Claim } from './claim';

/*****************************************
 * ClaimDiscount
 *****************************************/
@JsonApiModelConfig({
  type: 'claimDiscounts',
})
export class ClaimDiscount extends JsonApiModel {

  @Attribute({ serializedName: 'claim_id', converter })
    claimId: number;

  @Attribute({ serializedName: 'discount_reason', converter })
    discountReason: number;

  @Attribute({ serializedName: 'discount_amount', converter })
    discountAmount: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @BelongsTo()
    claim: Claim;

}
