/* eslint-disable no-underscore-dangle */
import {Component, Input} from '@angular/core';
import {UsicTableComponent} from '../usic-table.component';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'checkbox-header',
  template: `

    <mat-checkbox aria-label="Select All"
      (change)="$event ? parent.masterToggle(parent.selectionModel.hasValue()) : null"
      [checked]="parent.selectionModel.hasValue()" [indeterminate]="parent.selectionModel.hasValue()"></mat-checkbox>

  `,
  imports: [MatCheckbox]
})
export class CheckboxHeaderComponent {

  private _parent: UsicTableComponent;
  @Input({ required: true }) set parent(value: UsicTableComponent ) {
    this._parent = value;
  }
  get parent(): UsicTableComponent {
    return this._parent;
  }

  constructor() { }

}
