import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { District, Employee } from '../shareddata';


/*****************************************
 * WirelessInventory
 *****************************************/
@JsonApiModelConfig({
  type: 'wirelessInventories'
})
export class WirelessInventory extends JsonApiModel {

  @Attribute({ serializedName: 'wireless_number', converter })
    wirelessNumber: string;

  @Attribute({ serializedName: 'wireless_vendor', converter })
    wirelessVendor: string;

  @Attribute({ serializedName: 'wireless_user_full_name', converter })
    wirelessUserFullName: string;

  @Attribute({ serializedName: 'wireless_user_status', converter })
    wirelessUserStatus: string;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @Attribute({ serializedName: 'truck_vin', converter })
    truckVin: number;

  @Attribute({ serializedName: 'imei', converter })
    imei: string;

  @Attribute({ serializedName: 'sim_number', converter })
    simNumber: string;

  @Attribute({ serializedName: 'device_model', converter })
    deviceModel: string;

  @Attribute({ serializedName: 'contract_end_date', converter: dateConverter })
    contractEndDate: Date;

  @Attribute({ serializedName: 'data_usage', converter })
    dataUsage: string;

  @Attribute({ serializedName: 'voice_usage', converter })
    voiceUsage: string;

  @Attribute({ serializedName: 'text_usage', converter })
    textUsage: string;

  @Attribute({ serializedName: 'total_charges', converter })
    totalCharges: string;

  @Attribute({ serializedName: 'device_last_updated_on', converter: dateConverter })
    deviceLastUpdatedOn: Date;

  @Attribute({ serializedName: 'usage_last_updated_on', converter: dateConverter })
    usageLastUpdatedOn: Date;

  @BelongsTo()
    employee: Employee;

  @BelongsTo()
    dist: District;
}
