import {Component, Input, OnInit} from '@angular/core';
import { BreakpointsService } from '../../services/breakpoints.service';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'lib-usic-page-header',
  templateUrl: './usic-page-header.component.html',
  styleUrls: ['./usic-page-header.component.scss'],
  imports: [MatIcon, MatTooltip]
})
export class UsicPageHeaderComponent implements OnInit {

  @Input() pageName: string;
  @Input() pageInfo: string;

  constructor(
    public breakpoints: BreakpointsService,
  ) { }

  ngOnInit(): void {
  }

}
