import {
  JsonApiModelConfig,
} from '@michalkotas/angular2-jsonapi';
import { Employee } from './employee';


/*****************************************
 * EmployeeActiveNoAssets
 *****************************************/
@JsonApiModelConfig({
  type: 'employeesActiveNoAssets'
})
export class EmployeeActiveNoAssets extends Employee {
}

