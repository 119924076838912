/********************************************************************************
 * WebServiceService (a JsonApiDatastore)
 *
 * Implemenation of a JsonApiDatastore which is part of the angular2-jsonapi package
 * see https://www.npmjs.com/package/angular2-jsonapi
 * This defines the connection to the WebService API and what models the
 * API provides access to.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonApiDatastoreConfig, JsonApiDatastore, DatastoreConfig } from '@michalkotas/angular2-jsonapi';

import { environment } from '../../environments/environment';
import { PersistentSettingsService } from './persistent-settings.service';
import {
  Token,
  WsUser,
  WsUserAccess,
} from '../models/webservices';


const referencedModels = {
};

const config: DatastoreConfig = {
  baseUrl: `${environment.jsonApiUrl}/webservices/1.0`,
  models: {
    tokens: Token,
    wsUsers: WsUser,
    wsUserAccesses: WsUserAccess,

    // Add any additional models that the Shared Data service models
    // reference here
    ...referencedModels
  }
};

@Injectable({ providedIn: 'root' })
@JsonApiDatastoreConfig(config)
export class WebServicesService extends JsonApiDatastore {

  constructor(
    http: HttpClient,
    private persistentSettings: PersistentSettingsService,
  ) {
    super(http);
  }

  get pageSize(): number {
    return Number(this.persistentSettings.getSetting('WebServices-page-size')) || 5;
  }
  set pageSize(size: number) {
    // Save new page size preference if it's one of the valid selections (not a temporary
    // value used for select all actions)
    if ([5, 10, 25, 100, 250, 500, 1000].includes(size)) {
      this.persistentSettings.setSetting('WebServices-page-size', size.toString());
    }
  }

  /**
   * Get the saved page size for a specific component (based on prefix)
   * @param prefix
   */
  savedPageSize(prefix: string): number {
    return Number(this.persistentSettings.getSetting(`${prefix}${prefix ? '-' : ''}page-size`)) || this.pageSize;
  }

  providesModel(modelName: string) {
    // eslint-disable-next-line security/detect-object-injection
    return (config.models[modelName] && !referencedModels[modelName]);
  }



}
