import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { QuestionnaireQuestion } from './questionnaire-question';
import { Investigation } from './investigation';
import { QuestionnaireAnswersVersion } from './questionnaire-answers-version';

/*****************************************
 * QuestionnaireAnswer
 *****************************************/
@JsonApiModelConfig({
  type: 'questionnaireAnswers'
})
export class QuestionnaireAnswer extends JsonApiModel {

  @Attribute({ serializedName: 'investigation_id', converter })
    investigationId: number;

  @Attribute({ serializedName: 'investigation_detail_id', converter })
    investigationDetailId: number;

  @Attribute({ serializedName: 'question_id', converter })
    questionId: number;

  @Attribute({ serializedName: 'answer', converter })
    answer: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @BelongsTo()
    investigation: Investigation;

  @BelongsTo()
    questionnaireQuestion: QuestionnaireQuestion;

  @HasMany()
    questionnaireAnswersVersions: QuestionnaireAnswersVersion[];

}
