/********************************************************************************
 * DataFormatterPipe
 *
 * Angular pipe to attempt inteligently format data based on user defined or
 * auto determined data type.
 *
 * parameters
 *   type : (optional) data type to format for (string, html, htmlPreview, uri,
 *          number, currency, phonem datetime, date, monthYear, truthy or boolean,
 *          cron, filteredList, or object).
 *          Provides better results than when it tries to determine the type itself.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { HtmlToTextPipe } from './htmlToText.pipe';
import cronstrue from 'cronstrue';

@Pipe({ name: 'dataFormatter' })
export class DataFormatterPipe implements PipeTransform {

  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  transform(value: any, type: any, filter?: any): any {

    if (value === undefined || value === null) {
      if ((type === 'boolean') || (type === 'truthy')) {
        return 'No';
      } else {
        return '';
      }
    }

    if (type) {
      // if the type is specified, use it

      switch (type) {
        case 'string':
          if (filter === 'excel') {
            return String(value).slice(0, 32767);
          } else {
            return String(value);
          }

        case 'html': {
          const htmlToText = new HtmlToTextPipe();
          const text = htmlToText.transform(value);
          if (filter === 'excel') {
            return text.slice(0, 32767);
          } else {
            return text;
          }
        }

        case 'htmlPreview': {
          const htmlToText = new HtmlToTextPipe();
          const text = htmlToText.transform(value);
          return text.length > 80 ? `${text.substring(0, 80)}...` : text;
        }

        case 'uri': {
          return decodeURIComponent( value.replace(/\+/g, '%20') );
        }

        case 'number':
          if (!isNaN(Number(value))) {
            return Number(value).toLocaleString();
          } else {
            console.error('Tried to format non-number as a number.', value);
            return value;
          }

        // The "toLocaleString()" method in 'number' will round numbers to 3 digits
        // If we want full prevision (like for coordinates), use 'float' instead
        case 'float':
          if (!isNaN(Number(value))) {
            return Number(value).toString();
          } else {
            console.error('Tried to format non-number as a number.', value);
            return value;
          }

        case 'currency':
          if (!isNaN(Number(value))) {
            const currency = new CurrencyPipe('en-US');
            return currency.transform(value, 'USD');
          } else {
            console.error('Tried to format non-number as currency.', value);
            return value;
          }

        case 'phone':
          if (!isNaN(Number(value))) {
            const cleaned = ('' + value).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
              return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            } else {
              return value;
            }
          } else {
            console.error('Tried to format non-number as a phone number.', value);
            return value;
          }

        case 'datetime': {
          const datetimeMS = Date.parse(value);
          if (!isNaN(datetimeMS)) {
            // Don't show dates that are essentially open-ended
            // 1/1/4700 = 86150494800000
            if (datetimeMS > 86150494800000) {
              return undefined;
            } else {
              const date = new Date(datetimeMS);
              switch (filter) {
                case 'csv':
                  return date.toLocaleString().replace(/,/g, ' ');
                case 'excel':
                  return date;
                default:
                  return date.toLocaleString();
              }
            }
          } else {
            console.error('Date?', value);
          }
          break;
        }

        case 'date': {
          const dateMS = Date.parse(value);
          if (!isNaN(dateMS)) {
            // Don't show dates that are essentially open-ended
            // 1/1/4700 = 86150494800000
            if (dateMS > 86150494800000) {
              return undefined;
            } else {
              const date = new Date(dateMS);
              // Fix timezone offset so that dates with no minutes don't show up as the
              // previous day.  For example:
              // new Date(Date.parse('2021-03-29'))
              // Sun Mar 28 2021 20:00:00 GMT-0400 (Eastern Daylight Time)
              if (typeof value === 'string' &&
                (value.endsWith('00:00:00.000+0000') || /^\d{4}-\d{2}-\d{2}$/.test(value))) {
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
              }

              if (filter === 'excel') {
                return date;
              } else {
                const year = date.getFullYear();
                const month = (1 + date.getMonth()).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');

                return month + '/' + day + '/' + year;
              }
            }
          } else {
            console.error('Date?', value);
          }
          break;
        }

        case 'monthYear':
          if (!isNaN(Date.parse(value))) {
            const aDate = new Date(Date.parse(value));
            return `${this.months[aDate.getMonth()]} ${aDate.getFullYear()}`;
          } else {
            console.error('MonthYear?', value);
          }
          break;

        case 'boolean':
        case 'truthy':
          try {
            if (value === 't' || value === 'Pass' || value === 'Yes' || Number(value) === 1) {
              // this is a boolean t/f
              return 'Yes';
            } else if (value === 'f' || value === 'Fail' || value === 'No' || value === 'Not Eligible' ||
              Number(value) === 0) {
              // this is a boolean t/f
              return 'No';
            } else if (JSON.parse(value) === true) {
              return 'Yes';
            } else if (JSON.parse(value) === false) {
              return 'No';
            } else {
              return value;
            }
          } catch (SyntaxError) {
            // if JSON.parse tried to parse a value besides 'true' or 'false' it will
            // throw this error
            console.error('Non-boolean value', value);
            return value;
          }

        case 'cron':
          // See https://www.npmjs.com/package/cronstrue for options
          return cronstrue.toString(value, { verbose: true });

        case 'filteredList':
          if (filter) {
            const filters = filter.LIKE?.replace(/%/g, '').split(',');
            const values = String(value).split(',');
            const filteredValues = values
              .filter(v => filters.some(f => v.toUpperCase().includes(f.toUpperCase())))
              .join(',');
            return filteredValues;
          } else {
            return String(value);
          }

        case 'object':
          return JSON.stringify(value);

        default:
          return value;
      }
    } else {
      // if no type was specified, try to figure it out.

      if (!isNaN(Date.parse(value))) {
        // this is a date string
        return new Date(Date.parse(value)).toLocaleString();
      } else if (typeof value === 'number') {
        // this is a number
        return value.toLocaleString();
      } else if (value === 't') {
        // this is a boolean t/f
        return 'Yes';
      } else if (value === 'f') {
        // this is a boolean t/f
        return 'No';
      } else {
        return value;
      }
    }
  }



}

