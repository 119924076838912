/* eslint-disable security/detect-object-injection */
/********************************************************************************
 * PersistentSettingsService
 *
 * Service to store any key/value pairs of data for the UI in persistent
 * localStorage if available.  If unavailable, it will at least persist them
 * for the duration of the user session.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PersistentSettingsService {
  private settings = {};

  constructor() {
  }

  public getSetting(key: string): string {
    if ((typeof (Storage) !== 'undefined') && localStorage.getItem(key)) {
      return localStorage.getItem(key);
    } else {
      return this.settings[key];
    }
  }

  public setSetting(key: string, value: string) {
    if ((typeof (Storage) !== 'undefined')) {
      localStorage.setItem(key, value);
    } else {
      this.settings[key] = value;
    }
  }

  public clearSetting(key: string) {
    if ((typeof (Storage) !== 'undefined')) {
      localStorage.removeItem(key);
    } else {
      delete this.settings[key];
    }

  }
}
