import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { CustomerAccount } from './customer-account';


/*****************************************
 * CustomerAccountActivity
 *****************************************/
@JsonApiModelConfig({
  type: 'customerAccountActivities',
})
export class CustomerAccountActivity extends JsonApiModel {

  get accountId() {
    return this.id;
  }

  @Attribute({ serializedName: 'account_number', converter })
    accountNumber: string;

  @Attribute({ serializedName: 'account_name', converter })
    accountName: string;

  @Attribute({ serializedName: 'num_users', converter })
    numUsers: number;

  @Attribute({ serializedName: 'show_tickets', converter })
    showTickets: string;

  @Attribute({ serializedName: 'show_damages', converter })
    showDamages: string;

  @Attribute({ serializedName: 'last_logged_on', converter: dateConverter })
    lastLoggedOn: Date;

  @Attribute({ serializedName: 'days', converter })
    days: number;

  @BelongsTo()
    customerAccount: CustomerAccount;
}
