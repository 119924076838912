import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { environment } from '@usic/environments/environment';

import {
  UserAuth,
  AuthenticationService,
  BreakpointsService,
  ColorSchemeService,
} from '@usic/public-api';
import { NgClass } from '@angular/common';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass, MatCard, MatCardHeader, MatCardTitle, MatCardContent]
})
export class HomeComponent implements OnInit {
  currentUser: UserAuth;
  showCustomerPortal = false;
  showAssetManager = false;
  showCareerProgession = false;
  showReporting = false;
  showBilling = false;
  showMessenger = false;
  showContacts = false;

  constructor(
    private authentication: AuthenticationService,
    public breakpoints: BreakpointsService,
    public colorScheme: ColorSchemeService,
  ) {
    this.currentUser = this.authentication.currentUserValue;
  }

  ngOnInit() {
    if (this.authentication.loginMethod === 'CP') {
      this.showCustomerPortal = true;
      this.showAssetManager = false;
      this.showCareerProgession = false;
      this.showReporting = false;
      this.showBilling = false;
      this.showContacts = true;
    } else {
      this.showCustomerPortal = this.authentication.currentUserHasAnyRole([
        'ROLE_CP_ADMIN',
        'ROLE_CP_USER',
      ]);
      this.showAssetManager = this.authentication.currentUserHasAnyRole([
        'ROLE_ASSET MANAGER - ADMINS',
        'ROLE_ASSET MANAGER - PRIVILEGED USERS',
        'ROLE_ASSET MANAGER - USERS',
        'ROLE_DISTRICT MANAGERS',
        'ROLE_EHS MANAGERS',
        'ROLE_FIELD SUPERVISORS - ALL',
        'ROLE_OPS CLAIMS MANAGERS',
        'ROLE_OPS COORDINATORS - ALL',
        'ROLE_OPS MANAGERS',
        'ROLE_REGIONAL DIRECTORS',
        'ROLE_SP_TELECOM',
        'ROLE_SUPERVISORS - BHUG',
        'ROLE_SUPPORT',
      ]);
      this.showCareerProgession = this.authentication.currentUserHasAnyRole([
        'ROLE_CAREER PROGRESSION - ADMINS',
        'ROLE_CAREER PROGRESSION - USERS',
        'ROLE_DISTRICT MANAGERS',
        'ROLE_FIELD SUPERVISORS - ALL',
        'ROLE_FIELD HR',
        'ROLE_OPS MANAGERS',
        'ROLE_SUPERVISORS - BHUG',
      ]);
      this.showReporting = this.authentication.currentUserHasAnyRole([
        'ROLE_CMS_REPORTING',
        'ROLE_CMS_ADMIN_REPORTS',
        'ROLE_CMS_FINANCIAL_REPORTS',
        'ROLE_CMS_LEGAL_REPORTS',
        'ROLE_CMS_OPERATIONAL_REPORTS',
        'ROLE_CMS_REPORTING_ADMINREPORTS',
        'ROLE_CMS_REPORTING_FINANCIALREPORTS',
        'ROLE_CMS_REPORTING_LEGALREPORTS',
        'ROLE_CMS_REPORTING_OPERATIONALREPORTS',
        'ROLE_REPORT - ADMINS',
        'ROLE_REPORT - USERS',
        'ROLE_SUPPORT',
      ]);
      this.showBilling = !environment.production && this.authentication.currentUserHasRole('ROLE_WEBAPP - ADMINS');
      this.showMessenger = this.authentication.currentUserHasAnyRole([
        'ROLE_CORPORATE - EXECUTIVE',
        'ROLE_DISTRICT MANAGERS',
        'ROLE_FIELD SUPERVISORS - ALL',
        'ROLE_FIELD_TECHNICIANS - ALL',
        'ROLE_MESSENGER - ADMINS',
        'ROLE_MESSENGER - USERS',
        'ROLE_OPS COORDINATORS - ALL',
        'ROLE_OPS MANAGERS',
        'ROLE_REGIONAL DIRECTORS',
        'ROLE_SUPERVISORS - BHUG',
      ]);
      this.showContacts = true;
    }
  }

}
