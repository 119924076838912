import { Component, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuth, AuthenticationService, NavItem, SharedDataService, BreakpointsService } from '@usic/public-api';
import { APP_CONFIG, AppConfig } from './app-config.module';
import { environment } from '@usic/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { HelpComponent } from './help/help.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent {
  title = 'usic-apps';
  currentUser: UserAuth;
  navItems: NavItem[];
  offline = !navigator.onLine;
  env = environment.name;

  // what to do when someone clicks on their name
  userActions: NavItem[] = [{
    name: 'Change Password',
    linkRoute: '/changePassword',
    title: 'Change your password',
    icon: 'password',
    isAdmin: false
  }];

  private promptEvent: any;

  constructor(
    private router: Router,
    private authentication: AuthenticationService,
    private sharedData: SharedDataService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private dialog: MatDialog,
    public breakpoints: BreakpointsService,
  ) {

    this.authentication.currentUser.subscribe({
      next: (x: UserAuth) => {
        this.currentUser = x;

        if (!this.currentUser) {
          this.router.navigate(['/login']);

        } else if (this.authentication.currentUserIsValid ) {

          // Record that user is using the USIC-APPS application
          this.sharedData.updateUserLogin(this.sharedData.getApplicationId('usic-apps') || 8);
        }
      },
      error: (error) => { console.error(error); },
    });

    this.navItems = this.config.navigation;
  }

  // Get Help Lassie!
  getHelp() {
    this.dialog.open(HelpComponent, {
      data: { appName: this.title },
      disableClose: false,
    });
  }

  logout() {
    this.authentication.logout();
    this.currentUser = undefined;
    this.router.navigate(['/login']);
  }


  /**
   * onBeforeInstallPrompt
   * Capture the beforeinstallprompt event so that it can be triggered later
   * when the user chooses to "Install App"
   *
   * @param event
   */
  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event) {
    this.promptEvent = event;
  }

  /**
   * installPWA
   * Installs the application as a Progressive  Web Application (PWA).
   * This is called by clicking the "Install App" button in the header of the page.
   *
   */
  public installPWA() {
    this.promptEvent.prompt();
  }

  /**
   * shouldInstall: boolean
   * Indicates whether the "Install App" button should be shown
   */
  public get shouldInstall(): boolean {
    return !this.isRunningStandalone && this.promptEvent;
  }

  /**
   * isRunningStandalone: boolean
   * Determines if the application is already running as a PWA
   */
  private get isRunningStandalone(): boolean {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  @HostListener('window:online')
  windowOnline() {
    console.log(`[${new Date().toLocaleString()}] You are now online.`);
    this.offline = false;
  }

  @HostListener('window:offline')
  windowOffline() {
    console.log(`[${new Date().toLocaleString()}] You are now offline.`);
    this.offline = true;
  }

}
