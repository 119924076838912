import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Claim } from './claim';
import { District } from '../shareddata/district';
import { Employee } from '../shareddata/employee';
import { Ticket } from '../ticketpro/ticket';
import { State } from '../shareddata/state';
import { Investigation } from './investigation';
import { HereMapMarkerData } from '../../components/here-map/here-map-marker-data';
import { DamageListing } from './damage-listing';
import { DamagePhoto } from './damage-photo';
import { VALID_PHOTO_EXTENSIONS, VALID_VIDEO_EXTENSIONS } from '../ticketpro/locate';
import { ClaimDetail } from './claim-detail';
import { InvestigationVersion } from './investigation-version';
import { PolicyDropOffEstimate } from './policy-drop-off-estimate';
import { LegalDamage } from './legal-damage';

const VALID_ATTACHMENT_EXTENSIONS = ['.pdf', '.txt', '.mp3', '.eml', '.gml', '.xlsx'];
// const VALID_PHOTO_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.JPEG', '.gif', '.heic'];
// const VALID_VIDEO_EXTENSIONS = ['.mp4', '.m4v', '.mpg', '.mpeg', '.mov', '.avi'];

/*****************************************
 * Damage
 *****************************************/
@JsonApiModelConfig({
  type: 'damages'
})
export class Damage extends JsonApiModel implements HereMapMarkerData {

  get damageId() {
    return this.id;
  }

  @Attribute({ serializedName: 'damage_reported_date', converter: dateConverter })
    damageReportedDate: Date;

  @Attribute({ serializedName: 'damage_create_date', converter: dateConverter })
    damageCreateDate: Date;

  @Attribute({ serializedName: 'damage_date_time', converter: dateConverter })
    damageDateTime: Date;

  @Attribute({ serializedName: 'dispatcher_id', converter })
    dispatcherId: number;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'excavator_id', converter })
    excavatorId: number;

  @Attribute({ serializedName: 'facility_type_id', converter })
    facilityTypeId: number;

  @Attribute({ serializedName: 'notified_category_id', converter })
    notifiedCategoryId: number;

  @Attribute({ serializedName: 'cdi_id', converter })
    cdiId: number;

  @Attribute({ serializedName: 'one_call_request_num', converter })
    oneCallRequestNum: string;

  @Attribute({ serializedName: 'notified_by_name', converter })
    notifiedByName: string;

  @Attribute({ serializedName: 'add_1', converter })
    add1: string;

  @Attribute({ serializedName: 'add_2', converter })
    add2: string;

  @Attribute({ serializedName: 'city', converter })
    city: string;

  @Attribute({ serializedName: 'zip', converter })
    zip: string;

  @Attribute({ serializedName: 'cross_str1', converter })
    crossStr1: string;

  @Attribute({ serializedName: 'ticket_id', converter })
    ticketId: number;

  @Attribute({ serializedName: 'ticket_number', converter })
    ticketNumber: string;

  get originalTicketNumber() {
    return this.investigation?.ticketNumber ? this.investigation?.ticketNumber : this.ticketNumber;
  }

  @Attribute({ serializedName: 'caller_stmt_call_notes', converter })
    callerStmtCallNotes: string;

  @Attribute({ serializedName: 'damage_number', converter })
    damageNumber: string;

  @Attribute({ serializedName: 'stateid', converter })
    stateId: number;

  @Attribute({ serializedName: 'countryid', converter })
    countryId: number;

  @Attribute({ serializedName: 'ticket_start_date', converter: dateConverter })
    ticketStartDate: Date;

  @Attribute({ serializedName: 'ticket_closed_date', converter: dateConverter })
    ticketClosedDate: Date;

  @Attribute({ serializedName: 'notified_by_phone', converter })
    notifiedByPhone: string;

  @Attribute({ serializedName: 'notified_by_email', converter })
    notifiedByEmail: string;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'excavator', converter })
    excavator: string;

  @Attribute({ serializedName: 'locator_id', converter })
    locatorId: number;

  @Attribute({ serializedName: 'locator_first_nm', converter })
    locatorFirstNm: string;

  @Attribute({ serializedName: 'locator_last_nm', converter })
    locatorLastNm: string;

  @Attribute({ serializedName: 'cross_str2', converter })
    crossStr2: string;

  @Attribute({ serializedName: 'county', converter })
    county: string;

  @Attribute({ serializedName: 'ticket_type', converter })
    ticketType: string;

  @Attribute({ serializedName: 'type_of_work', converter })
    typeOfWork: string;

  @Attribute({ serializedName: 'boring', converter })
    boring: boolean;

  @Attribute({ serializedName: 'wf_item_id', converter })
    wfItemId: number;

  @Attribute({ serializedName: 'source', converter })
    source: string;

  @Attribute({ serializedName: 'notified_by_extension', converter })
    notifiedByExtension: number;

  @Attribute({ serializedName: 'search_text', converter })
    searchText: string;

  @Attribute({ serializedName: 'caller_company', converter })
    callerCompany: string;

  @Attribute({ serializedName: 'paged_date_time', converter: dateConverter })
    pagedDateTime: Date;

  @Attribute({ serializedName: 'confirmaton_date_time', converter: dateConverter })
    confirmatonDateTime: Date;

  @Attribute({ serializedName: 'customer_rep', converter })
    customerRep: string;

  @Attribute({ serializedName: 'cdi_confirmation', converter })
    cdiConfirmation: string;

  @Attribute({ serializedName: 'latitude', converter })
    latitude: string;

  @Attribute({ serializedName: 'longitude', converter })
    longitude: string;

  @Attribute({ serializedName: 'geoLocation', converter })
    geoLocation: string;

  @Attribute({ serializedName: 'utility_type_id', converter })
    utilityTypeId: number;

  @Attribute({ serializedName: 'facility_id', converter })
    facilityId: number;

  @Attribute({ serializedName: 'dots_number', converter })
    dotsNumber: string;

  @Attribute({ serializedName: 'customer_claim_number', converter })
    customerClaimNumber: string;

  @Attribute({ serializedName: 'customer_claim_number_2', converter })
    customerClaimNumber2: string;

  @Attribute({ serializedName: 'supgroup', converter })
    supgroup: string;

  @Attribute({ serializedName: 'one_call_request_ticket_id', converter })
    oneCallRequestTicketId: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    claims: Claim[];

  @HasMany()
    claimDetails: ClaimDetail[];

  @HasMany()
    legalDamages: LegalDamage[];

  @BelongsTo()
    investigation: Investigation;

  @HasMany()
    policyDropOffEstimates: PolicyDropOffEstimate[];

  @BelongsTo()
    district: District;

  // This is the CDI
  @BelongsTo()
    employee: Employee;

  @BelongsTo()
    state: State;

  @HasMany()
    damageListings: DamageListing[];

  @HasMany()
    investigationVersions: InvestigationVersion[];

  @HasMany()
    damagePhotos: DamagePhoto[];

  // The ticket cannot be included through a relationship because it is in
  // a different DB of a different type (PostgreSQL vs SQL Server).  If
  // we want the ticket associated with the damage, it has to be fetched
  // separately.
  // This attribute provides a placeholder to assign it to, and will be
  // used by the 'area' function if available.
  ticket: Ticket;

  // Some helper methods to allow locates to be displayed in table and on the map
  //
  // lat,lon are essential to add the marker.
  // address (optional) is used when available in the info bubble
  // area (options) will draw a polygon if it's available.

  get lat() {
    return +this.latitude || this.ticket?.lat;
  }

  get lon() {
    return +this.longitude || this.ticket?.lon;
  }

  get address() {
    return `${this.add1}, ${this.city}, ${this.state?.abbrev}`.trim();
  }

  get area() {
    return this.ticket?.transmissionDetail?.digAreas?.map(a => ({ lat: a.lat, lon: a.lon }));
  }

  get photos() {
    return this.damagePhotos ? this.damagePhotos
      .filter(photo => VALID_PHOTO_EXTENSIONS.some(extension => photo.filename.endsWith(extension)))
      .filter((value, index, self) => index === self.findIndex((a) => (a.id === value.id))) : null;
  }

  get videos() {
    return this.damagePhotos ? this.damagePhotos
      .filter(photo => VALID_VIDEO_EXTENSIONS.some(extension => photo.filename.endsWith(extension)))
      .filter((value, index, self) => index === self.findIndex((a) => (a.id === value.id))) : null;
  }

  get attachments() {
    return this.damagePhotos ? this.damagePhotos
      .filter(attachment => VALID_ATTACHMENT_EXTENSIONS.some(extension => attachment.filename.endsWith(extension)))
      .filter((value, index, self) => index === self.findIndex((a) => (a.id === value.id))) : null;
  }

}
