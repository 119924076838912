import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Customer } from './customer';

/*****************************************
 * Contract
 *****************************************/
@JsonApiModelConfig({
  type: 'contracts'
})
export class Contract extends JsonApiModel {

  @Attribute({ serializedName: 'contract_name', converter })
    contractName: string;

  @Attribute({ serializedName: 'status', converter })
    status: string;

  @Attribute({ serializedName: 'customer_contract_number', converter })
    customerContractNumber: string;

  @Attribute({ serializedName: 'usic_contract_number', converter })
    usicContractNumber: string;

  @Attribute({ serializedName: 'account_name', converter })
    accountName: string;

  @Attribute({ serializedName: 'account_number', converter })
    accountNumber: string;

  @Attribute({ serializedName: 'contract_effective_date', converter })
    contractEffectiveDate: Date;

  @Attribute({ serializedName: 'contract_expiration_date', converter })
    contractExpirationDate: Date;

  @Attribute({ serializedName: 'contract_stage', converter })
    contractStage: string;

  @Attribute({ serializedName: 'contract_type', converter })
    contractType: string;

  @Attribute({ serializedName: 'primary_utility_type', converter })
    primaryUtilityType: string;

  @Attribute({ serializedName: 'utility_type', converter })
    utilityType: string;

  @Attribute({ serializedName: 'quote', converter })
    quote: string;

  @Attribute({ serializedName: 'usic_contract_type', converter })
    usicContractType: string;

  @Attribute({ serializedName: 'damage_liability', converter })
    damageLiability: string;

  @Attribute({ serializedName: 'master_contract_number', converter })
    masterContractNumber: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'eff_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'eff_end_date', converter: dateConverter })
    effectiveEndDate: Date;


  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  @BelongsTo()
    customer: Customer;

}
