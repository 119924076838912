import {
  JsonApiModelConfig,
} from '@michalkotas/angular2-jsonapi';
import { TkpAttachment } from './tkp-attachment';

/*****************************************
 * AffectedAttachment
 *****************************************/
@JsonApiModelConfig({
  type: 'affectedAttachments'
})
export class AffectedAttachment extends TkpAttachment {

  get ticketId() {
    return this.entityKind === 'TICKET' ? this.entityId : undefined;
  }

  // The TkpAttachments cdome from a view where the createdTime has been
  // added to the created date already, but in the raw attachments table
  // the date and time are separated, so join them here.
  // The problem with this is that when it's done in the view, it happens
  // before the time is translated from the DB time, but when done here
  // it's setting the time assuming it's a local time, so there's no way
  // to correctly translate it from the timzeone where it was taken.
  get whenCreated() {
    const whenCreated = new Date(this.createdDate);
    whenCreated.setSeconds(this.createdTime);
    return whenCreated;
  }
}

