import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { Report } from './report';

/*****************************************
 * ReportsSectionOrder
 *****************************************/
@JsonApiModelConfig({
  type: 'reportsSectionOrders'
})
export class ReportsSectionOrder extends JsonApiModel {

  @Attribute({ serializedName: 'report_group_section_id', converter })
    reportGroupSectionId: number;

  @Attribute({ serializedName: 'report_id', converter })
    reportId: number;

  @Attribute({ serializedName: 'seq', converter })
    seq: number;

  @BelongsTo()
    report: Report;

  // Circular dependency
  // @BelongsTo()
  //   reportGroupSection: ReportGroupSection;

}
