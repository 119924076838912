import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { CMSInvestigationDetail } from './cms-investigation-detail';

/*****************************************
 * InvestigationDetailLine
 *****************************************/
@JsonApiModelConfig({
  type: 'investigationDetailLines'
})
export class InvestigationDetailLine extends JsonApiModel {

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'detail_id', converter })
    detailId: number;

  @Attribute({ serializedName: 'contract_number', converter })
    contractNumber: string;

  @Attribute({ serializedName: 'report_sent', converter })
    reportSent: boolean;

  @Attribute({ serializedName: 'billable_to_customer', converter })
    billableToCustomer: boolean;

  @Attribute({ serializedName: 'count_against_quality_ratio', converter })
    countAgainstQualityRatio: boolean;

  @Attribute({ serializedName: 'customer_claim_number', converter })
    customerClaimNumber: string;

  @Attribute({ serializedName: 'accepted_date', converter: dateConverter })
    acceptedDate: Date;

  @Attribute({ serializedName: 'report_sent_date', converter: dateConverter })
    reportSentDate: Date;

  @Attribute({ serializedName: 'att1', converter })
    att1: string;

  @Attribute({ serializedName: 'att2', converter })
    att2: string;

  @Attribute({ serializedName: 'att3', converter })
    att3: string;

  @Attribute({ serializedName: 'att4', converter })
    att4: string;

  @Attribute({ serializedName: 'att5', converter })
    att5: string;

  @Attribute({ serializedName: 'contract_id', converter })
    contractId: string;

  @Attribute({ serializedName: 'cdc_code', converter })
    cdcCode: string;

  @Attribute({ serializedName: 'adjuster_time', converter })
    adjusterTime: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attr1,
      attribute2: this.attr2,
      attribute3: this.attr3,
      attribute4: this.attr4,
      attribute5: this.attr5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attr1 = v.attribute1;
    this.attr2 = v.attribute2;
    this.attr3 = v.attribute3;
    this.attr4 = v.attribute5;
    this.attr5 = v.attribute4;
  }

  @BelongsTo()
    cMSInvestigationDetail: CMSInvestigationDetail [];

}
