import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { DamagePhoto } from './damage-photo';
import { Investigation } from './investigation';
import { ClaimDetail } from './claim-detail';
import { InvestigationDetailsVersion } from './investigation-details-version';
import { PolicyDropOffEstimate } from './policy-drop-off-estimate';

/*****************************************
 * InvestigationDetail
 *****************************************/
@JsonApiModelConfig({
  type: 'investigationDetails'
})
export class InvestigationDetail extends JsonApiModel {

  @Attribute({ serializedName: 'id_investigation_id', converter })
    investigationId: number;

  @Attribute({ serializedName: 'id_notification_id', converter })
    notificationId: string;

  @Attribute({ serializedName: 'id_damage_id', converter })
    damageId: number;

  @Attribute({ serializedName: 'id_customer_id', converter })
    customerId: number;

  @Attribute({ serializedName: 'id_account_level_customer_id', converter })
    accountLevelCustomerId: number;

  @Attribute({ serializedName: 'id_damage_customer', converter })
    damageCustomer: string;

  @Attribute({ serializedName: 'id_utility', converter })
    utility: string;

  @Attribute({ serializedName: 'id_facility', converter })
    facility: string;

  @Attribute({ serializedName: 'id_facility_type', converter })
    facilityType: string;

  @Attribute({ serializedName: 'id_locator_name', converter })
    locatorName: string;

  @Attribute({ serializedName: 'id_cause_factor', converter })
    causeFactor: string;

  @Attribute({ serializedName: 'id_were_markings_visible', converter })
    wereMarkingsVisible: string;

  @Attribute({ serializedName: 'id_other_indicators', converter })
    otherIndicators: string;

  @Attribute({ serializedName: 'id_tolerence_zone', converter })
    tolerenceZone: string;

  @Attribute({ serializedName: 'id_facility_damage', converter })
    facilityDamage: string;

  @Attribute({ serializedName: 'id_replacement_footage', converter })
    replacementFootage: string;

  @Attribute({ serializedName: 'id_contractor_assistance', converter })
    contractorAssistance: string;

  @Attribute({ serializedName: 'id_contractor_equipment', converter })
    contractorEquipment: number;

  @Attribute({ serializedName: 'id_facility_shown', converter })
    facilityShown: string;

  @Attribute({ serializedName: 'id_record_numbers', converter })
    recordNumbers: number;

  @Attribute({ serializedName: 'inv_report_attachment_id', converter })
    invReportAttachmentId: number;

  @BelongsTo()
    investigation: Investigation;

  @BelongsTo()
    investigationReport: DamagePhoto;

  @HasMany()
    investigationDetailsVersions: InvestigationDetailsVersion[];

  @HasMany()
    policyDropOffEstimates: PolicyDropOffEstimate[];

  @HasMany()
    claimDetails: ClaimDetail[];

}
