import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * UserState
 *****************************************/
@JsonApiModelConfig({
  type: 'userStates'
})
export class UserState extends JsonApiModel {

  @Attribute({ serializedName: 'cp_user_name', converter })
    cpUserName: string;

  @Attribute({ converter })
    state: string;
}
