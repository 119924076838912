/* eslint-disable max-len */
/********************************************************************************
 * HereMapComponent (lib-here-map)
 *
 * Angular component to show a Here map
 *
 * When utilizing a map in a project, be sure to include the Here Maps CSS file
 * in the projects index.html file
 *
 *   <!-- Here Map -->
 *   <link rel="stylesheet" type="text/css" href="https://js.api.here.com/v3/3.1/mapsjs-ui.css" />
 *
 * As well as the Here Maps JS files
 *
 *   <script type="module" src="https://js.api.here.com/v3/3.1/mapsjs.bundle.js"></script>
 *     - or -
 *   <script type="text/javascript" src="https://js.cit.api.here.com/v3/3.1/mapsjs-core.js"></script>
 *   <script type="text/javascript" src="https://js.cit.api.here.com/v3/3.1/mapsjs-service.js"></script>
 *   <script type="text/javascript" src="https://js.cit.api.here.com/v3/3.1/mapsjs-ui.js"></script>
 *   <script type="text/javascript" src="https://js.cit.api.here.com/v3/3.1/mapsjs-mapevents.js"></script>
 *
 * If using clustering add
 *
 *   <script type="text/javascript" charset="utf-8" src="https://js.api.here.com/v3/3.1/mapsjs-clustering.js"></script>
 *
 * Alternatively, there is an NPM package for the Here JS API which can be installed with:
 *  npm install @here/maps-api-for-javascript --registry=https://repo.platform.here.com/artifactory/api/npm/maps-api-for-javascript/
 *
 * Instructions for using the NPM package in Angular can be found here:
 *  https://www.here.com/docs/bundle/maps-api-for-javascript-developer-guide/page/topics/angular-practices.html
 *  also see: https://knowledge.here.com/csm_kb/en?id=public_kb_csm_details&number=KB0021262
 *
 * If using the NPM package, you could add the following to the styles array in angular.json
 * instead of the CSS link in the index.html file, but it seems to cause some issues with
 * the info bubble rendering correctly on mouse clicks.
 *   "./node_modules/@here/maps-api-for-javascript/bin/mapsjs-ui.css"
 *
 * parameters
 *   locates        : Observable for array of locatable objects. { lat, lon, ... }
 *   drivers        : Observable for array object for the drivers
 *                    { loginname : { name, lat, lon, color, stops } }
 *   photos         : Observable for array of photo objects. { lat, lon, ... }
 *   videos         : Observable for array of video objects. { lat, lon, ... }
 *   userRequests   : Observable for array of UserRequest objects. { lat, lon, ... } rendered
 *                    as clusters on the map
 *   districts      : Array of DistrictBoundaries.
 *   album          : [ { src, caption }, ... ] Array of images to show in a lightbox album
 *
 *   locate         : { lat, lon } pair to add a locate pin at
 *   driver         : { lat, lon } pair to add a driver pin at
 *   area           : [ { lat, lon }, { lat, lon }, ... ] Array of polygon coordinates
 *                    to draw an area for.  If only 2 are given the are assumed to be
 *                    top-left, and bottom-right coordinates for a rectangle.
 *
 *   selectByKey    : string name of attribute (key) to select a locate by
 *                    default: 'ticketId'
 *   selectByValue  : value of a locate key that should be selected
 *
 *   disableShowAll : boolean to control addition of the "Show All" control button
 *                    default: false
 *   disableZoom    : boolean to control addition of the "+/-" zoom control buttons
 *                    default: false
 *   autoZoom       : boolean to control if map should automatically zoom to show all objects
 *                    default: true
 *
 *   maxRadius      : number of miles for maximum radius
 *                    default: 10
 *
 * TWO-WAY [(Banana in a Box)]
 *   center        : { lat, lon } pair to center map at
 *   address       : string for the center location
 *   state         : string state code for current position
 *   position      : { lat, lon } pair to add a locate pin at
 *   radius        : number of miles from position to highlight
 *
 * events
 *   mapRendered     : event emitter when map is fully rendered
 *   clickMarker     : event emitter when a marker is clicked
 *   clickCluster    : event emitter when a cluster (or noise marker) is clicked
 *   clickPolygon    : event emitter when a polygon is clicked
 *   dlbClick        : event emitter to provide coordinates of a double click
 *   hoverMarker     : event emitter when a marker is hovered over
 *   markerInPolygon : event emitter when a marker is placed in a district
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

// import H from '@here/maps-api-for-javascript';
// until the HARP engine is the default, use the following import
import '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp';

// if using the script imports in index.html, uncomment the following line
// declare let H: any;

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { IEvent, LIGHTBOX_EVENT, Lightbox, LightboxEvent, LightboxModule } from 'ngx-lightbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import CryptoJS from 'crypto-js';

import { Here, environment } from '@usic/environments/environment';
import { HereMapMarkerData } from './here-map-marker-data';
import { LatLon } from '../../models/general';
import { UserRequest } from '../../models/shareddata';
import { DistrictBoundary, Locate, Transmission } from '../../models/ticketpro';
import { DataFormatterPipe } from '../../pipes/data-formatter.pipe';

const DEFAULT_POLYGON_COLOR = 'rgba(0, 255, 0, 0.5)';
const DEFAULT_POLYGON_FILLCOLOR = 'rgba(0, 255, 0, 0.2)';

const DEFAULT_POLYLINE_COLOR = 'rgba(0, 0, 255, 0.5)';

const DEFAULT_CIRCLE_COLOR = 'rgba(149, 201, 61, 0.5)';
const HIGHLIGHT_CIRCLE_COLOR = 'rgba(242, 92, 15, 0.5)';
const DEFAULT_CIRCLE_FILLCOLOR = 'rgba(55, 55, 55, 0.1)';

const METERS_PER_MILE = 1609.34;

export type MapPhoto = {
  lat: number;
  lon: number;
  fileName: string;
  whenCreated: Date;
  whenUploaded: Date;
  url: string;
  id?: any;
  attachmentId?: string;
};

export type Coordinates = {
  lat: number;
  lon: number;
};

@Component({
  selector: 'lib-here-map',
  templateUrl: './here-map.component.html',
  styleUrls: ['./here-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [ LightboxModule ]
})
export class HereMapComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() locates: Observable<any[]>;
  @Input() drivers: Observable<any[]>;
  @Input() photos: MapPhoto[];
  @Input() videos: MapPhoto[];
  @Input() userRequests: Observable<UserRequest[]>;
  @Input() locate: LatLon;
  @Input() driver: LatLon;
  @Input() area: LatLon[];
  @Input() trip: LatLon[];
  @Input() districts: DistrictBoundary[];
  @Input() album: Array<any>;
  @Input() selectByKey = 'ticketId';
  @Input() selectByValue: Observable<any>;
  @Input() selectByCoord: Observable<Coordinates>;
  @Input() disableShowAll = false;
  @Input() disableZoom = false;
  @Input() autoZoom = true;
  @Input() showOpenLocates = true;
  @Input() showClosedLocates = true;

  @Output() mapRendered = new EventEmitter();
  @Output() clickMarker = new EventEmitter();
  @Output() clickCluster = new EventEmitter();
  @Output() clickPolygon = new EventEmitter();
  @Output() dblClick = new EventEmitter();
  @Output() hoverMarker = new EventEmitter();
  @Output() markerInPolygon = new EventEmitter();

  @Input() center: LatLon = { lat: 39.8097343, lon: -98.5556199 };
  @Output() centerChange = new EventEmitter<LatLon>();

  @Input() address: string;
  @Output() addressChange = new EventEmitter<string>();

  @Input() position: LatLon;
  @Output() positionChange = new EventEmitter<LatLon>();

  @Input() radius: number;
  @Output() radiusChange = new EventEmitter<number>();
  @Input() maxRadius = 10;

  @Input() state: string;
  @Output() stateChange = new EventEmitter<string>();

  @Input() zoom = 4.75;
  @Output() zoomChange = new EventEmitter<number>();
  @Input() maxZoom: number;

  private platform: any;
  private map: any;
  private ui: any;
  private bubble: any;
  private group: any;
  private selectedMarker: any;
  private hoveredMarker: any;
  private resizeObserver: ResizeObserver;
  private isMasterAlbum = false;
  private tripPolyline: any;
  private clusterLayer: any;
  private clusteredDataProvider: any;
  private districtPolygons: any[] = [];

  @ViewChild('map')
  public mapElement: ElementRef;

  private hereConfig: {
    apikey: string;
    useCIT: boolean;
    useHTTPS: boolean;
  };

  constructor(
    private lightbox: Lightbox,
    private lightboxEvent: LightboxEvent,
    private ngZone: NgZone
  ) {

    // In order to generate the encoded apiKey, uncomment these lines to run it
    // with the decrypted key, then copy and paste into your environment.ts file.
    // Then re-comment this code
    // const encrypted = CryptoJS.AES.encrypt(Here.apiKey, environment.jsonApiUrl).toString();
    // console.log('encApiKey', encrypted);

    this.hereConfig = {
      apikey: CryptoJS.AES.decrypt(Here.encApiKey, environment.jsonApiUrl).toString(CryptoJS.enc.Utf8),
      useCIT: Here.useCIT,
      useHTTPS: Here.useHTTPS
    };

  }

  ngOnInit() {
    window['hereMapRef'] = {
      component: this,
      zone: this.ngZone,
      openAlbum: this.openAlbum.bind(this),
      showDistrict: this.showDistrict.bind(this),
    };

    this.isMasterAlbum = this.album ? true : false;
  }

  ngAfterViewInit() {

    // Needed for ResizeObserver
    function debounce(ms, fn) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        args.unshift(this);
        timer = setTimeout(fn.bind(...args), ms);
      };
    }

    if (H && !this.map && this.mapElement) {

      this.platform = new H.service.Platform(this.hereConfig);

      const engineType = H.Map.EngineType['HARP'];
      const defaultLayers = this.platform.createDefaultLayers({ engineType });

      this.map = new H.Map(
        this.mapElement.nativeElement,
        defaultLayers.vector.normal.map,
        {
          engineType,
          center: { lat: this.center.lat, lng: this.center.lon },
          pixelRatio: Math.min(2, devicePixelRatio),
          zoom: this.zoom
        }
      );

      // Send an event when the map is first rendered
      let renderNotified = false;
      this.map.getEngine().addEventListener('render', (evt) => {
        if (this.map.getEngine() === evt.target && !renderNotified) {
          this.mapRendered.emit(evt);
          renderNotified = true;
        }
      });

      // add a resize listener to make sure that the map occupies the whole container
      // window.addEventListener('resize', () => this.map.getViewPort().resize());

      this.resizeObserver = new ResizeObserver(debounce(100, () => {
        this.map.getViewPort().resize();
        if (this.autoZoom) {
          this.showAll();
        }
      }));

      this.resizeObserver.observe(this.mapElement.nativeElement);


      // make the map interactive
      // MapEvents enables the event system
      // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

      // If we want to define the map controls, setup Map Settings and other UI controls.
      // See https://www.here.com/docs/bundle/maps-api-for-javascript-api-reference/page/H.service.Platform.html#createDefaultLayers
      // for layer options
      //
      // const mapSettingsControl = new H.ui.MapSettingsControl({
      //   baseLayers: [
      //     {
      //       label: 'Map',
      //       layer: defaultLayers.vector.normal.map
      //     },
      //     {
      //       label: 'Satellite',
      //       layer: [defaultLayers.hybrid.day.raster, defaultLayers.hybrid.day.vector]
      //     }
      //   ],
      //   layers: [
      //     {
      //       label: 'Traffic',
      //       layer: defaultLayers.vector.traffic.map
      //     },
      //   ],
      //   alignment: H.ui.LayoutAlignment.BOTTOM_RIGHT
      // });
      // const zoomControl = new H.ui.ZoomControl({
      //   alignment: H.ui.LayoutAlignment.TOP_LEFT
      // });
      //
      // // Create the custom UI and add the controls
      // this.ui = new H.ui.UI(this.map);
      // this.ui.addControl('mapsettings', mapSettingsControl);
      // if (!this.disableZoom) {
      //   this.ui.addControl('zoom', zoomControl);
      // }

      // create the default UI
      this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
      this.ui.setUnitSystem(H.ui.UnitSystem.IMPERIAL);
      if (this.disableZoom) {
        this.ui.removeControl('zoom');
      } else {
        this.ui.getControl('zoom').setAlignment('top-left');
      }

      // setup a groups to hold the markers
      this.group = new H.map.Group({
        volatility: true, // mark the group as volatile for smooth dragging of all it's objects
      } as H.map.Group.Options);
      this.map.addObject(this.group);


      // add 'pointermove' event listener that simulates :hover events
      this.map.addEventListener('pointermove', (evt) => {
        if (evt.target instanceof H.map.Marker || evt.target instanceof H.map.Polyline) {
          const marker = evt.target;
          if (this.hoveredMarker?.id !== marker.id) {
            this.hoveredMarker = marker;
            this.hoverMarker.emit({ event: evt, markerId: marker.id });
            if (marker.title) {
              this.showTooltip(evt, marker.title);
            }
            if (marker.draggable) {
              document.body.style.cursor = 'pointer';
            }
            if (marker instanceof H.map.Polyline) {
              const currentStyle = marker.getStyle();
              const newStyle = currentStyle.getCopy({
                strokeColor: HIGHLIGHT_CIRCLE_COLOR
              });
              marker.setStyle(newStyle);
            }
          }
        } else {
          if (this.hoveredMarker) {
            if (this.hoveredMarker instanceof H.map.Polyline) {
              const currentStyle = this.hoveredMarker.getStyle();
              const newStyle = currentStyle.getCopy({
                strokeColor: DEFAULT_CIRCLE_COLOR
              });
              this.hoveredMarker.setStyle(newStyle);
            }

            this.hoverMarker.emit({});
            this.hoveredMarker = undefined;
            this.hideTooltip();
            document.body.style.cursor = 'default';
          }
        }

      });

      // add 'tap' event listener that opens info bubble to the group
      this.map.addEventListener('tap', (evt) => {
        if (evt.target instanceof H.map.Marker) {
          const marker = evt.target;

          if (typeof marker.getData() === 'object' &&
            typeof marker.getData().isCluster === 'function') {

            this.showInfoBubbleForCluster(marker, evt);

          } else {

            this.showInfoBubbleForMarker(marker);
            this.clickMarker.emit({ event: evt, markerId: marker.id });

          }

        } else if (evt.target instanceof H.map.Polygon) {

          this.showInfoBubbleForPolygon(evt.target, evt.currentPointer);
          this.clickPolygon.emit({ event: evt, id: evt.target.id });

        } else {

          // if a prior info bubble is open, close it
          if (this.bubble && this.bubble.getState() === H.ui.InfoBubble.State.OPEN) {
            this.bubble.close();
          }

        }

      }, false);

      // Attach an double click event listener to map display
      // obtain the coordinates
      this.map.addEventListener('dbltap', (evt) => {
        if (evt.target instanceof H.map.Marker) {
          const marker = evt.target;

          this.showInfoBubbleForMarker(marker);

          // Center the map at the selected marker to help bubble show correctly
          // if the map is wide enough to accomodate it
          this.map.setCenter(marker.getGeometry());

          // Zoom into the selected marker
          this.map.setZoom(this.maxZoom || 15);

        } else if (evt.target instanceof H.map.Polygon) {

          if (this.districtPolygons?.length) {
            this.showDistrict(evt.target.id);
          }

        }
        const coord = this.map.screenToGeo(evt.currentPointer.viewportX,
          evt.currentPointer.viewportY);
        const location: LatLon = { lat: coord.lat.toFixed(7), lon: coord.lng.toFixed(7) };
        this.dblClick.emit({ event: evt, location });
      }, false);

      // disable the default draggability of the underlying map
      // when starting to drag a marker object:
      this.map.addEventListener('dragstart', (evt) => {
        const target = evt.target;
        if (target instanceof H.map.Marker || target instanceof H.map.Polyline) {
          behavior.disable();
        }
      }, false);

      // re-enable the default draggability of the underlying map
      // when dragging has completed
      this.map.addEventListener('dragend', (evt) => {
        const target = evt.target;
        if (target instanceof H.map.Marker || target instanceof H.map.Polyline) {
          behavior.enable();

          if (target instanceof H.map.Marker) {
            const point = target.getGeometry() as H.geo.Point;
            this.positionChange.emit({ lat: point.lat, lon: point.lng });
          }

          if (target instanceof H.map.Polyline) {
            const distanceFromCenterInMeters = target['circle'].getRadius();
            this.radius = distanceFromCenterInMeters / METERS_PER_MILE;
            this.radiusChange.emit(this.radius);
          }

        }
      }, false);

      // Listen to the drag event and move the position of the marker
      // as necessary
      this.map.addEventListener('drag', (evt) => {
        const target = evt.target;
        const pointer = evt.currentPointer;

        if (target instanceof H.map.Marker) {
          target.setGeometry(this.map.screenToGeo(pointer.viewportX, pointer.viewportY));
        }

        if (evt.target instanceof H.map.Polyline) {
          const distanceFromCenterInMeters = target.circle.getCenter().distance(this.map.screenToGeo(pointer.viewportX, pointer.viewportY));
          if (distanceFromCenterInMeters <= (METERS_PER_MILE * this.maxRadius)) {
            target.circle.setRadius(distanceFromCenterInMeters);
          } else {
            target.circle.setRadius(METERS_PER_MILE * this.maxRadius);
          }
        }

      }, false);

      // Listen to the mapviewchangeend event and update the zoom if changed
      // see https://www.here.com/docs/bundle/maps-api-for-javascript-developer-guide/page/topics/best-practices.html
      this.map.addEventListener('mapviewchangeend', () => {
        let zoom = this.map.getZoom();

        if (this.maxZoom && zoom > this.maxZoom) {
          this.map.setZoom(this.maxZoom);
          zoom = this.map.getZoom();
        }

        if (zoom !== this.zoom) {
          this.zoom = zoom;
          this.zoomChange.emit(zoom);
        }
      });


      // show locate markers whenever the locate data is updated
      if (this.locates) {
        this.locates.subscribe(locateData => {
          // if a prior info bubble is open, close it
          if (this.bubble && this.bubble.getState() === H.ui.InfoBubble.State.OPEN) {
            this.bubble.close();
          }

          // Remove old markers
          this.group.forEach((obj) => {
            obj.dispose();
          });
          this.group.removeAll();

          this.addLocateMarkersToMap(locateData);

          // If a position marker was specified, ensure it remains after new data arrives
          if (this.position) {
            this.addMarkerAtLocation(this.position, createSvgMarker, 'position');
          }

          // CP-228
          // If an area was specified, ensure it remains after new data arrives
          if (this.area) {
            this.drawPolygonForArea(this.area);
          }

          if (this.trip) {
            this.drawPolylineForTrip(this.trip);
          }

        });
      }

      // show driver markers whenever the driver data is updated
      if (this.drivers) {
        this.drivers.subscribe(driverData => {
          this.group.removeAll();
          this.addDriverMarkersToMap(driverData);
        });
      }

      // show photo markers whenever the photo data is updated
      if (this.photos) {
        this.group.removeAll();
        this.addPhotoMarkersToMap(this.photos);
      }

      if (this.videos) {
        this.addVideoMarkersToMap(this.videos);
      }

      // show UserRequests markers whenever the request data is updated
      if (this.userRequests) {
        this.userRequests.subscribe(requestData => {
          if (this.clusterLayer) {
            this.map.removeLayer(this.clusterLayer);
          }

          // if a prior info bubble is open, close it
          if (this.bubble && this.bubble.getState() === H.ui.InfoBubble.State.OPEN) {
            this.bubble.close();
          }

          this.addUserRequestClustersToMap(requestData);
        });
      }

      if (this.position) {
        this.reverseGeocodePosition();
      }

      if (this.driver) {
        this.addMarkerAtLocation(this.driver, createDriverSvgMarker, 'driver');
      }

      if (this.locate) {
        this.addMarkerAtLocation(this.locate, createLocateSvgMarker, 'locate');
      }

      if (this.area) {
        this.drawPolygonForArea(this.area);
      }

      if (this.trip) {
        this.drawPolylineForTrip(this.trip);
      }

      if (this.districts) {
        this.drawMultiPolygonForDistricts(this.districts);
      }

      if (this.selectByValue !== undefined) {
        this.selectByValue.subscribe(value => this.selectMarkerById(value));
      }

      if (this.selectByCoord !== undefined) {
        this.selectByCoord.subscribe(coord => this.selectClusterByCoord(coord));
      }



    }

  }

  ngOnChanges(changes: SimpleChanges) {

    // If the parent component changes the address, update the map position location
    if (changes.address) {
      this.geocodeLocation(changes.address.currentValue);
    }
    if (changes.position?.currentValue) {
      this.reverseGeocodePosition();
    }
    if (changes.radius?.currentValue) {
      this.drawCircleForRadius();
    }
    if (changes.showClosedLocates || changes.showOpenLocates) {
      this.toggleVisibilities();
    }
    if (changes.zoom?.currentValue) {
      this.map?.setZoom((this.maxZoom && changes.zoom.currentValue > this.maxZoom) ? this.maxZoom : changes.zoom.currentValue);
    }
    if (changes.locate?.currentValue) {
      this.addMarkerAtLocation(changes.locate?.currentValue, createLocateSvgMarker, 'locate');
    }

  }

  ngOnDestroy() {
    delete window['hereMapRef'];

    this.resizeObserver?.unobserve(this.mapElement.nativeElement);
  }

  showInfoBubbleForMarker(marker) {
    if (marker && marker !== this.selectedMarker &&
      (this.selectedMarker === undefined || marker.id !== this.selectedMarker.id)) {

      // if a prior info bubble is open, reuse it
      if (this.bubble) {
        this.bubble.setPosition(marker.getGeometry());
        this.bubble.setContent(marker.getData());
        this.bubble.open();

      } else {
        // create a new InfoBubble

        // event target is the marker itself, group is a parent event target
        // for all objects that it contains

        // read custom data
        const content = marker.getData();
        this.bubble = new H.ui.InfoBubble(marker.getGeometry(), { content });

        // extend the bubble close action to de-select the marker when closed
        const bubbleClose = this.bubble.close;
        this.bubble.close = () => {
          if (this.bubble) {
            bubbleClose.apply(this.bubble);
            this.ui.removeBubble(this.bubble);
            this.bubble = undefined;
          }
          this.clickMarker.emit({});
          this.selectedMarker = undefined;
          if (!this.isMasterAlbum) {
            this.album = undefined;
          }
        };

        // show info bubble
        this.ui.addBubble(this.bubble);
      }

      if (!this.isMasterAlbum) {
        this.album = marker.album;
      }

      this.selectedMarker = marker;
      this.clickMarker.emit({ markerId: marker.id });

    }
  }

  showInfoBubbleForPolygon(polygon, pointer) {
    const point = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);

    // If this polygon is part of a larger district polygon, select the full multi-polygon
    if (polygon instanceof H.map.Polygon && this.districtPolygons?.length) {
      const multiPolygon = this.districtPolygons.find(p => p.id === polygon.id);
      if (multiPolygon) {
        polygon = multiPolygon;
      }
    }

    if (polygon.data) {

      // if a prior info bubble is open, reuse it
      if (this.bubble) {
        this.bubble.setPosition(point);
        this.bubble.setContent(`<div class="bubble-data">${polygon.data}</div>`);
        this.bubble.open();

      } else {
      // create a new InfoBubble

        // read custom data
        const content = `<div class="bubble-data">${polygon.data}</div>`;
        this.bubble = new H.ui.InfoBubble(point, { content });

        // show info bubble
        this.ui.addBubble(this.bubble);
      }

    }

    this.selectedMarker = undefined;

  }

  showInfoBubbleForCluster(marker, evt?) {
    const point = marker.getData();
    let noisePoint;

    // Is the marker a cluster?
    const isCluster = point.isCluster();

    if (isCluster) {

      const allPoints = [];
      const dataPoints = [];
      point.forEachDataPoint((dataPoint) => {
        if (allPoints.findIndex(p => p.lat === dataPoint.getPosition().lat && p.lng === dataPoint.getPosition().lng) === -1) {
          allPoints.push(dataPoint.getPosition());
        }
        dataPoints.push(dataPoint);
      });

      if (evt) {
        this.clickCluster.emit({ event: evt, positions: allPoints });
      }

      // Randomly pick an index from [0, dataPoints.length) range for InfoBubble
      // Note how we use bitwise OR ("|") operator for that instead of Math.floor
      // eslint-disable-next-line no-bitwise
      noisePoint = dataPoints[Math.random() * dataPoints.length | 0];

    } else {

      if (evt) {
        this.clickCluster.emit({ event: evt, positions: [point.getPosition()] });
      }

      noisePoint = point;

    }

    // if a prior info bubble is open, reuse it
    if (this.bubble) {
      this.bubble.setPosition(marker.getGeometry());
      this.bubble.setContent(noisePoint.getData());
      this.bubble.open();

    } else {
      // show new info bubble
      this.bubble = new H.ui.InfoBubble(marker.getGeometry(), { content: noisePoint.getData() });

      // extend the bubble close action to make sure it cleans up after itself
      const bubbleClose = this.bubble.close;
      this.bubble.close = () => {
        bubbleClose.apply(this.bubble);
        this.ui.removeBubble(this.bubble);
        this.bubble = undefined;
      };

      this.ui.addBubble(this.bubble);
    }

  }

  zoomToMarker(marker) {
    const { map, selectedMarker } = this;

    if (marker && marker !== selectedMarker &&
      (selectedMarker === undefined || marker.id !== selectedMarker.id)) {

      this.showInfoBubbleForMarker(marker);

      // Center the map at the selected marker to help bubble show correctly
      // if the map is wide enough to accomodate it
      map.setCenter(marker.getGeometry());

      // Zoom into the selected marker
      map.setZoom(this.maxZoom || 15);
    }
  }

  zoomToCluster(cluster) {
    const { map } = this;

    if (cluster) {

      this.showInfoBubbleForCluster(cluster);

      // Center the map at the selected marker to help bubble show correctly
      // if the map is wide enough to accomodate it
      map.setCenter(cluster.getGeometry());

      // Zoom into the selected marker
      map.setZoom(this.maxZoom || 15);
    }
  }

  addMarkerAtLocation(location: LatLon | Locate, createMarkerFn = createSvgMarker, id: string = undefined) {
    const { map, group } = this;
    let districtPolygon;

    // if a marker exists with this id, remove the old one
    if (id) {
      const marker = this.getMarkerById(id);
      if (marker) {
        try {
          group.removeObject(marker);
        } catch (error) {
          console.error(error);
        }
      }
    }

    // add a marker for the location
    if (map && location) {
      if (location.lat && location.lon) {
        const point = new H.geo.Point(location.lat, location.lon);

        // Check if the marker point is in a district polygon
        districtPolygon = this.districtPolygons?.find(polygon =>
          booleanPointInPolygon((point.toGeoJSON() as any), polygon.toGeoJSON())
        );

        let locationMarker;
        let data;
        if (id === 'position') {

          data = `<div class="bubble-data">
                    <table>
                      ${this.address ? `<tr><th>Address:</th><td>${this.address}</td></tr>` : ''}
                      <tr><th>Coordinates:</th><td>${location.lat}, ${location.lon}</td></tr>
                      <tr><th></th><td>Drag marker to reposition</td></tr>
                      </table>
                      ${districtPolygon ? `<br/>${districtPolygon.data}` : ''}
                  </div>`;

          locationMarker = new H.map.Marker(point, {
            icon: createMarkerFn({ color: '#F25C0F', label: '•' }),  // USIC-orange
            data
          });
          locationMarker.title = `${this.address}
            Drag marker to reposition`;
          locationMarker.draggable = true;

        } else if (id === 'locate' && location instanceof Locate) {

          const type = location.closedDate || location.damageDateTime ? 'closed' : 'open';
          const closedIcon = createLocateSvgMarker({ color: 'blue' });
          const openIcon = createLocateSvgMarker({ color: '#95c93d' });  // USIC-green

          locationMarker = new H.map.Marker(point, {
            icon: (type === 'closed') ? closedIcon : openIcon,
            data: `<div class="bubble-data">
                    <table>
                      ${location.damageNumber ? `<tr><th>Notification ID:</th><td>${location.damageNumber}</td></tr>` : ''}
                      ${location.ticketNumber ? `<tr><th>Ticket #:</th><td><a href="#/tickets/search/${location.ticketNumber}" title="View locates for this ticket">${location.ticketNumber}</a></td></tr>` : ''}
                      ${location.ourDueDate ? `<tr><th>Due Date:</th><td>${location.ourDueDate?.toLocaleString()}</td></tr>` : ''}
                      ${location.damageDateTime ? `<tr><th>Damage Date:</th><td>${location.damageDateTime?.toLocaleString()}</td></tr>` : ''}
                      ${location.address ? `<tr><th>Address:</th><td>${location.address}</td></tr>` : ''}
                    </table>
                    <!-- nosemgrep: javascript.browser.security.raw-html-concat.raw-html-concat -->
                    ${location.photos ? '<div class="photos" onclick="viewImageFullscreen(this)" title="Show all images fullscreen">' +
                (location.photos.length > 1 ? '<button class="albumBtn" onclick="window.hereMapRef.zone.run(() => {window.hereMapRef.openAlbum(event);})" title="View photo album">' +
                  '<span class="material-symbols-outlined">photo_library</span>' +
                  '</button>' : '') +
                location.photos.map(photo => `<img src="${photo.url_sm}" class="thumbnail" id="${photo.id}" onclick="viewImageFullscreen(this)" alt="" title="Show image fullscreen">`).join(' ') +
                '</div>' : ''}
                  </div>`
          });

        } else if (id === 'locate' && location instanceof Transmission) {

          const icon = createLocateSvgMarker({ color: '#95c93d' });  // USIC-green

          locationMarker = new H.map.Marker(point, {
            icon,
            data: `<div class="bubble-data">
                    <table>
                      ${location.ticketNumber ? `<tr><th>Ticket #:</th><td>${location.ticketNumber}</td></tr>` : ''}
                      ${location.address ? `<tr><th>Address:</th><td>${location.address}</td></tr>` : ''}
                      <tr><th>Coordinates:</th><td>${location.lat}, ${location.lon}</td></tr>
                      ${location.workDate ? `<tr><th>Work Date:</th><td>${location.workDate?.toLocaleString()}</td></tr>` : ''}
                      ${location.typeofwork ? `<tr><th>Type of Work:</th><td>${location.typeofwork}</td></tr>` : ''}
                      ${location.excavator ? `<tr><th>Excavator:</th><td>${location.excavator}</td></tr>` : ''}
                    </table>
                    ${districtPolygon ? `<br/>${districtPolygon.data}` : ''}
                  </div>`
          });

        } else if (id === 'start') {
          data = `<div class="bubble-data"><span>${location.lat}, ${location.lon}</span></div>`;

          locationMarker = new H.map.Marker(point, {
            icon: createMarkerFn({ color: 'green', label: '▸' }),
            data
          });

        } else {
          data = `<div class="bubble-data"><span>${location.lat}, ${location.lon}</span></div>`;

          locationMarker = new H.map.Marker(point, {
            icon: createMarkerFn({ color: 'blue', label: '•' }),
            data
          });

        }

        locationMarker.id = id;

        try {
          group.addObject(locationMarker);
        } catch (error) {
          console.error('Could not add location marker', locationMarker.id, error);
          group.removeObject(locationMarker);
        }

      }
    }

    if (this.radius) {
      this.drawCircleForRadius();
    }

    // ensure map shows the driver
    if (this.autoZoom) {
      this.showAll();
    }

    // If we're showing associated districts, notify that a marker was added to
    // district.  This is done after the showAll() in case we want to show the
    // whole district as a result
    if (districtPolygon) {
      this.markerInPolygon.emit({markerId: id, districtId: districtPolygon.id});
    }


  }

  addLocateMarkersToMap(locateData: HereMapMarkerData[]) {
    const closedIcon = createLocateSvgMarker({ color: 'blue' });
    const openIcon = createLocateSvgMarker({ color: '#95c93d' });  // USIC-green
    locateData?.forEach(locate => {
      if (locate.lat && locate.lon) {
        const coords = { lat: locate.lat, lng: locate.lon };
        const type = locate.closedDate || locate.damageDateTime ? 'closed' : 'open';

        const marker = new H.map.Marker(coords, {
          icon: (type === 'closed') ? closedIcon : openIcon,
          data: `<div class="bubble-data">
                   <table>
                      ${locate.damageNumber ? `<tr><th>Notification ID:</th><td>${locate.damageNumber}</td></tr>` : ''}
                      ${locate.ticketNumber ? `<tr><th>Ticket #:</th><td><a href="#/tickets/search/${locate.ticketNumber}" title="View locates for this ticket">${locate.ticketNumber}</a></td></tr>` : ''}
                      ${locate.ourDueDate ? `<tr><th>Due Date:</th><td>${locate.ourDueDate?.toLocaleString()}</td></tr>` : ''}
                      ${locate.damageDateTime ? `<tr><th>Damage Date:</th><td>${locate.damageDateTime?.toLocaleString()}</td></tr>` : ''}
                      ${locate.address ? `<tr><th>Address:</th><td>${locate.address}</td></tr>` : ''}
                    </table>
                    <!-- nosemgrep: javascript.browser.security.raw-html-concat.raw-html-concat -->
                    ${locate.photos ? '<div class="photos" onclick="viewImageFullscreen(this)" title="Show all images fullscreen">' +
              (locate.photos.length > 1 ? '<button class="albumBtn" onclick="window.hereMapRef.zone.run(() => {window.hereMapRef.openAlbum(event);})" title="View photo album">' +
                '<span class="material-symbols-outlined">photo_library</span>' +
                '</button>' : '') +
              locate.photos.map(photo => `<img src="${photo.url_sm || photo.url}" class="thumbnail" id="${photo.id}" onclick="viewImageFullscreen(this)" alt="" title="Show image fullscreen">`).join(' ') +
              '</div>' : ''}
                </div>`
        });
        marker['id'] = locate[this.selectByKey];
        marker['area'] = locate.area;
        marker['markerType'] = type;

        try {
          this.group.addObject(marker);
        } catch (error) {
          console.error('Could not add marker for locate. Marker ID ', marker['id'], error);
          this.group.removeObject(marker);
        }

        if (marker['area']?.length) {
          const polygon = this.drawPolygonForArea(marker['area']);
          if (polygon) {
            polygon['markerType'] = type;
          }
        }

        if (locate.photos?.length) {
          marker['album'] = [];
          locate.photos?.forEach(photo => {
            const src = photo.url;
            const caption = `${photo.fileName || photo.filename}`;
            const locatePhoto = { src, caption };
            marker['album'].push(locatePhoto);
          });
        }

      }
    });

    this.toggleVisibilities();

    if (this.autoZoom) {
      this.showAll();
    }
  }

  addDriverMarkersToMap(driverData: any[]) {

    driverData?.forEach(driver => {

      let driverLocation;
      let icon;

      if (driver.current_lat && driver.current_lon) {
        driverLocation = new H.geo.Point(parseFloat(driver.current_lat), parseFloat(driver.current_lon));
        icon = createDriverSvgMarker({
          color: driver.color || 'blue',
          labelColor: driver.labelColor || 'white'
        });

      } else if (driver.home_lat && driver.home_lon) {
        driverLocation = new H.geo.Point(parseFloat(driver.home_lat), parseFloat(driver.home_lon));
        icon = createHomeSvgMarker({
          color: driver.color || 'blue',
          labelColor: driver.labelColor || 'white'
        });

      }

      if (driverLocation) {
        const marker = new H.map.Marker(driverLocation, {
          icon,
          data: `<div class="bubble-data"><span>${driver.name}</span></div>`
        });
        marker['id'] = driver.name;

        try {
          this.group.addObject(marker);
        } catch (error) {
          console.error('Could not add marker for driver.', error);
          this.group.removeObject(marker);
        }
      }
    });

    if (this.autoZoom) {
      this.showAll();
    }
  }

  addPhotoMarkersToMap(photos: MapPhoto[]) {
    const icon = createPhotoSvgMarker({ color: 'blue' });
    photos?.forEach(photo => {
      if (photo.lat && photo.lon) {
        const coords = { lat: photo.lat, lng: photo.lon };
        const marker = new H.map.Marker(coords, {
          icon,
          data: `<div class="bubble-data"><table>
                      ${photo.whenCreated ||
              photo.whenUploaded ? `<tr><th>Taken:</th><td>${photo.whenCreated?.toLocaleString() ||
              photo.whenUploaded?.toLocaleString()}</td></tr>` : ''}
                      <tr><th>Filename:</th><td>${photo.fileName}</td></tr>
                      <tr><th>Location:</th><td>${photo.lat}, ${photo.lon}</td></tr>
                      </table>
                      <div class="photos"><img src="/attachment-sm/${photo.attachmentId}" class="thumbnail" onclick="viewImageFullscreen(this)"
                      id="${photo.attachmentId}"></div>
                  </div>`
        });
        marker['id'] = photo.url;

        try {
          this.group.addObject(marker);
        } catch (error) {
          console.error('Could not add marker for photo.', marker['id'], error);
          this.group.removeObject(marker);
        }

      }
    });

    if (this.autoZoom) {
      this.showAll();
    }

  }


  addVideoMarkersToMap(videos: MapPhoto[]) {
    const icon = createSvgMarker({ color: 'blue', label: '▶' });
    videos?.forEach(video => {
      if (video.lat && video.lon) {
        const coords = { lat: video.lat, lng: video.lon };
        const marker = new H.map.Marker(coords, {
          icon,
          data: `<div class="bubble-data"><table>
                      ${video.whenCreated ||
              video.whenUploaded ? `<tr><th>Taken:</th><td>${video.whenCreated?.toLocaleString() ||
              video.whenUploaded?.toLocaleString()}</td></tr>` : ''}
                      <tr><th>Filename:</th><td>${video.fileName}</td></tr>
                      </table>
                      <div class="photos">
                      <video preload="metadata" controls id="${video.attachmentId}"
                      onclick="viewImageFullscreen(this)">
                        <source src="${video.url}">
                      Your browser does not support HTML5 video.
                      </video>
                      </div>
                  </div>`
        });
        marker['id'] = video.url;

        try {
          this.group.addObject(marker);
        } catch (error) {
          console.error('Could not add marker for video.', marker['id'], error);
          this.group.removeObject(marker);
        }

      }
    });

    if (this.autoZoom) {
      this.showAll();
    }

  }

  /**
   * addUserRequestClustersToMap
   * Since the UserRequests usually come in groups from the same person, we show them
   * in clusers instead of individual markers.  This also provides a nice visualization
   * of how much activity a given user is doing.
   *
   * @param userRequests
   */
  addUserRequestClustersToMap(userRequests: UserRequest[]) {
    const clusteringDataPoints = [];
    userRequests?.forEach(request => {
      if (request.lat && request.lon) {
        // create a data point for each request
        // see https://www.here.com/docs/bundle/maps-api-for-javascript-api-reference/page/H.clustering.DataPoint.html
        clusteringDataPoints.push(new H.clustering.DataPoint(request.lat, request.lon, 1,
          `<div class="bubble-data">
            <table>
            ${(request.requestUser && request.requestUser !== '-1') ? `<tr><th>User:</th><td>${request.requestUser}</td></tr>` : ''}
            ${request.requestXRealIp ? `<tr><th>IP:</th><td>${request.requestXRealIp}</td></tr>` : ''}
            ${(request.userPlatform && request.userPlatform !== 'Unknown') ? `<tr><th>Platform:</th><td>${request.userPlatform}</td></tr>` : ''}
            ${request.browserName ? `<tr><th>Browser:</th><td>${request.browserName}</td></tr>` : ''}
            ${request.address ? `<tr><th>Location:</th><td>${request.address}</td></tr>` : ''}
            ${(request.lat && request.lon) ? `<tr><th></th><td>${request.lat}, ${request.lon}</td></tr>` : ''}
            </table>
          </div>`));
      }
    });

    // Create a data provider
    // see https://www.here.com/docs/bundle/maps-api-for-javascript-api-reference/page/H.clustering.Provider.html
    this.clusteredDataProvider = new H.clustering.Provider(clusteringDataPoints, {
      clusteringOptions: {
        // Maximum radius of the neighborhood
        // it must not exceed 256 and must take values that are a power of 2
        eps: 2,
      }
    });

    // Create a layer that includes the data provider and its data points:
    this.clusterLayer = new H.map.layer.ObjectLayer(this.clusteredDataProvider);

    // Add the layer to the map:
    try {
      this.map.addLayer(this.clusterLayer);
    } catch (error) {
      console.error('Could not add layer for clusters', error);
      this.map.removeLayer(this.clusterLayer);
    }

  }


  /**
   * Draw a filled in polygon
   * This will simply draw the polygon but it doesn't add the polygon
   * to the map group and resize the map to show it all.
   *
   * @param polygonCoords
   * @param color
   * @param fillColor
   * @returns polygon
   */
  drawPolygon(polygonCoords: LatLon[], color = DEFAULT_POLYGON_COLOR, fillColor = DEFAULT_POLYGON_FILLCOLOR) {
    if (polygonCoords && polygonCoords.length > 0) {

      const aPath = new H.geo.LineString();
      if (polygonCoords.length === 2) {
        // only the topLeft and bottomRight coordinates provided so add the topRight and bottomLeft;
        const topLeft = polygonCoords[0];
        const bottomRight = polygonCoords[1];
        polygonCoords.splice(1, 0, { lat: topLeft.lat, lon: bottomRight.lon });
        polygonCoords.splice(3, 0, { lat: bottomRight.lat, lon: topLeft.lon });
      }

      for (const coordinate of polygonCoords) {
        aPath.pushPoint({
          lat: coordinate.lat,
          lng: coordinate.lon
        });
      }

      const polygon = new H.map.Polygon(aPath, {
        style: {
          strokeColor: color,
          fillColor,
          lineWidth: 2,
        }
      } as H.map.Polygon.Options);

      return polygon;
    }
  }

  /**
   * Draw GeoJSON polygon(s)
   * This will parse GeoJSON data and render it as a layer on the map.
   * It returns a multipolygon that contains the rendered polygons in the layer.
   *
   * @param geoJSON
   * @param color
   * @param fillColor
   * @returns multiPolygon (H.geo.MultiPolygon)
   */
  drawGeoJSON(id: number, geoJSON: string, color = DEFAULT_POLYGON_COLOR, fillColor = DEFAULT_POLYGON_FILLCOLOR) {

    const polygons = [];

    const reader = new H.data.geojson.Reader(undefined, {
      disableLegacyMode: true,
      // This function is called each time parser detects a new map object
      style: (mapObject) => {

        // Give these mapObjects ids so they can be identified when clicked on
        mapObject['id'] = id;

        // Parsed geo objects can be styled using setStyle method
        if (mapObject instanceof H.map.Polygon) {
          mapObject.setStyle({
            strokeColor: color,
            fillColor,
            lineWidth: 2
          });

          // Keep a handle to the individual polygon objects to
          // collect into a MultiPolygon
          polygons.push(mapObject.getGeometry());

        } else if (mapObject instanceof H.map.Polyline) {
          mapObject.setStyle({
            strokeColor: color,
            lineWidth: 5,
          });

        }

        // See note below about addLayer
        this.map.addObject(mapObject);

      }
    });

    reader.parseData(geoJSON);

    // The documented way to add the GeoJSON objects is using the resulting
    // layer from the reader, but this has terrrible performance.  So we
    // added each geo map object to the map during the styling function above.
    //
    // const layer = reader.getLayer();
    // this.map.addLayer(layer);

    let multiPolygon;
    if (polygons.length === 1 && polygons[0] instanceof H.geo.MultiPolygon) {
      multiPolygon = polygons[0];
    } else {
      multiPolygon = new H.geo.MultiPolygon(polygons);
    }
    multiPolygon.id = id;
    return multiPolygon;
  }


  /**
   * Draw a filled in polygon for a dig area and show it
   *
   * @param digArea
   * @param color
   * @param fillColor
   * @returns polygon
   */
  drawPolygonForArea(digArea: LatLon[], color = DEFAULT_POLYGON_COLOR, fillColor = DEFAULT_POLYGON_FILLCOLOR) {
    if (digArea && digArea.length > 0) {

      const polygon = this.drawPolygon(digArea, color, fillColor);

      try {
        this.group.addObject(polygon);
      } catch (error) {
        console.error('Could not add polygon for area.', digArea, error);
        this.group.removeObject(polygon);
      }

      // ensure map shows the polygon
      if (this.autoZoom) {
        this.showAll();
      }

      return polygon;
    }
  }

  /**
   * Draw a polyline for a given trip path
   *
   * @param trip
   * @param color
   * @returns polyline
   */
  drawPolylineForTrip(trip: LatLon[], color = DEFAULT_POLYLINE_COLOR) {
    if (trip && trip.length > 0) {

      // Allow a new trip to be drawn and the old one removed
      // This is done so we can obtain a route snapped to the map asynchronously
      if (this.tripPolyline) {
        this.group.removeObject(this.tripPolyline);
        this.trip = trip;
      }

      const lineString = new H.geo.LineString();

      for (const coordinate of trip) {
        lineString.pushPoint({
          lat: coordinate.lat,
          lng: coordinate.lon
        });
      }

      this.tripPolyline = new H.map.Polyline(lineString, {
        style: {
          strokeColor: color,
          lineWidth: 5,
        }
      } as H.map.Polyline.Options);

      try {
        this.group.addObject(this.tripPolyline);
      } catch (error) {
        console.error('Could not add polyline for trip.', trip, error);
        this.group.removeObject(this.tripPolyline);
      }

      // ensure map shows the polyline
      if (this.autoZoom) {
        this.showAll();
      }

      return this.tripPolyline;
    }
  }

  /**
   * Draw a circle to show an area of interest
   *
   * @param color color of the outline
   * @param fillColor color of the circle contents
   * @returns H.map.Circle
   */
  drawCircleForRadius(color = DEFAULT_CIRCLE_COLOR, fillColor = DEFAULT_CIRCLE_FILLCOLOR) {
    const { position, radius, group } = this;

    if (position && radius && group) {

      // if an existing radius circle exists, remove it
      this.group?.forEach((item) => {
        if (item instanceof H.map.Circle || item instanceof H.map.Polyline) {
          if (String(item['id']) === 'radius') {
            group.removeObject(item);
          }
        }
      });

      const circle = new H.map.Circle({ lat: position.lat, lng: position.lon },
        (radius * METERS_PER_MILE),
        {
          style: {
            fillColor,
            // To add outline without a separate polyline object
            // strokeColor: color,
            // lineWidth: 2
          } as H.map.SpatialStyle,
        } as H.map.Circle.Options);
      circle['id'] = 'radius';
      try {
        group.addObject(circle);
      } catch (error) {
        console.error('Could not add radius circle.');
        group.removeObject(circle);
      }

      // Add the outline separately in order to be able to drag it separately for re-sizing
      const circleOutline = new H.map.Polyline(
        (circle.getGeometry() as H.geo.Polygon).getExterior(),
        {
          style: {
            strokeColor: color,
            lineWidth: 4,
          },
        } as H.map.Polyline.Options
      );
      circleOutline['circle'] = circle;
      circleOutline['id'] = 'radius';
      circleOutline['title'] = `Showing a ${radius} mile radius from
        ${this.address}
        Drag circle outline to change radius`;
      circleOutline.draggable = true;

      // extract first point of the circle outline polyline's LineString and
      // push it to the end, so the outline has a closed geometry
      // per https://developer.here.com/documentation/examples/maps-js/resizable-geoshapes/resizable-circle
      const circleLineString = circleOutline.getGeometry() as H.geo.LineString;
      circleLineString.pushPoint(circleLineString.extractPoint(0));
      try {
        group.addObject(circleOutline);
      } catch (error) {
        console.error('Could not add radius circle outline.', error);
        group.removeObject(circleOutline);
      }

      // ensure map shows the circle
      if (this.autoZoom) {
        this.showAll();
      }

      return circle;
    }
  }

  /**
   * Draw polygons for district boundaries.
   * Each district is defined by a collection of smaller polygons (locator regions)
   * that together form a district polygon.
   * In the DB the boundaries as stored as a PostGIS geometry but will be returned
   * as GeoJSON data.
   *
   * @param districts
   * @returns multiPolygon[]
   */
  drawMultiPolygonForDistricts(districts: DistrictBoundary[]) {
    if (districts && districts.length > 0) {

      for (const district of districts) {
        if (district.boundaries) {
          try {
            const multiPolygon = this.drawGeoJSON(
              district.districtId,
              district.boundaries,
              district.color,
              district.color.replace(/[\d.]+\)$/g, '0.2)'));

            const contactInfoJSON = district.contactInfo;
            let contactInfoHTML = `<h3 onclick="window.hereMapRef.zone.run(() => {window.hereMapRef.showDistrict(${district.districtId});})">${district.districtName} District</h3>
              <p>Please contact Dispatch first at <a href="tel:18007789140">1-800-778-9140</a>, and then leverage the contacts noted.</p>
              <table style="border-collapse: collapse;">`;

            if (contactInfoJSON instanceof Array) {
              const pipe = new DataFormatterPipe();
              contactInfoJSON.forEach(contactInfo => {
                contactInfoHTML += `
                <tr><th colspan=2 style="padding: 0; text-align: left;">${contactInfo.role}</th></tr>
                <tr><th style="padding-left: 1rem; text-align: left;">Name:</th><td>${contactInfo.displayName.replace(/^(.+), ([^\s]+)\s?(.*?)$/i, '$2 $1')}</td></tr>
                <tr><th style="padding-left: 1rem; text-align: left;">Email:</th><td><a href="mailto:${contactInfo.email}">${contactInfo.email}</a></td></tr>
                <tr><th style="padding-left: 1rem; text-align: left;">Phone:</th><td><a href="tel:${contactInfo.phone}">${pipe.transform(contactInfo.phone, 'phone')}</a></td></tr>
                `;
              });
              contactInfoHTML += '</table>';
            } else {
              contactInfoHTML = JSON.stringify(contactInfoJSON, undefined, 2);
            }
            multiPolygon.data = contactInfoHTML;

            this.districtPolygons.push(multiPolygon);
          } catch (error) {
            console.error(`Unable to add district ${district.districtId} to map.`);
          }
        }
      }

      return this.districtPolygons;
    }
  }


  toggleVisibilities() {
    // hide/show open and closed markers
    this.group?.forEach((item) => {
      if (item instanceof H.map.Marker || item instanceof H.map.Polygon) {
        if (item['markerType'] === 'open') {
          item.setVisibility(this.showOpenLocates);
        } else if (item['markerType'] === 'closed') {
          item.setVisibility(this.showClosedLocates);
        }
      }
    });
  }

  getMarkerById(id: string) {
    let marker;
    if (id) {
      this.group?.forEach((item) => {
        if (item instanceof H.map.Marker) {
          if (String(item['id']) === id) {
            marker = item;
          }
        }
      });
    }
    return marker;
  }

  getClusterAtCoord(coord: Coordinates) {
    let cluster;
    this.clusteredDataProvider?.getRootGroup().forEach((item) => {
      const point = item.getGeometry();
      if (!cluster &&
          Math.round(coord.lat * 1000) / 1000 === Math.round(point.lat * 1000) / 1000 &&
          Math.round(coord.lon * 1000) / 1000 === Math.round(point.lng * 1000) / 1000) {
        cluster = item;
      }
    });
    return cluster;
  }

  selectMarkerById(id) {
    if (this.hoveredMarker === undefined) {
      if (id) {
        const marker = this.getMarkerById(String(id));
        if (marker) {
          this.zoomToMarker(marker);
        }
      } else {
        // if a prior info bubble is open, close it
        if (this.bubble && this.bubble.getState() === H.ui.InfoBubble.State.OPEN) {
          this.bubble.close();
        }
      }
    }
  }

  selectClusterByCoord(coord: Coordinates) {
    if (coord) {
      const cluster = this.getClusterAtCoord(coord);
      if (cluster) {
        this.zoomToCluster(cluster);
      }
    } else {
      // if a prior info bubble is open, close it
      if (this.bubble && this.bubble.getState() === H.ui.InfoBubble.State.OPEN) {
        this.bubble.close();
      }
    }
  }

  clickShowAll(event: any) {
    event.preventDefault();
    this.showAll();
    // A single run doesn't seem to set the viewport padding correctly,
    // so we need two runs for the map viewport to update properly.
    this.showAll();
  }

  showAll() {
    // const bounds = this.group.getBounds();
    // if (bounds) { this.map.setViewBounds(bounds); }
    // this.map.getViewPort().resize();

    // https://stackoverflow.com/questions/59267896/here-maps-api-javascript-zoom-into-bounds-with-margin
    const bounds = this.clusteredDataProvider?.getRootGroup().getBoundingBox() || this.group?.getBoundingBox();

    if (bounds) {
      // Here Maps v3.0
      //   const cameraData = this.map.getCameraDataForBounds(bounds);
      //   this.map.setCenter(cameraData.position, true);
      //   this.map.setZoom(cameraData.zoom - 0.5, true);
      // Here Maps v3.1
      this.map.getViewModel().setLookAtData({ bounds });
      this.map.getViewPort().setPadding(75, 75, 75, 75);

      this.zoom = this.map.getZoom();
      if (this.maxZoom && this.zoom > this.maxZoom) {
        this.map.setZoom(this.maxZoom);
        this.zoom = this.map.getZoom();
      }
      this.zoomChange.emit(this.zoom);
    }
  }

  showDistrict(districtId: number) {

    if (this.districtPolygons?.length) {
      const multiPolygon = this.districtPolygons.find(polygon => polygon.id === districtId);
      const bounds = multiPolygon?.getBoundingBox();

      if (bounds) {
        this.map.getViewModel().setLookAtData({ bounds });
        this.map.getViewPort().setPadding(75, 75, 75, 75);

        this.zoom = this.map.getZoom();
        if (this.maxZoom && this.zoom > this.maxZoom) {
          this.map.setZoom(this.maxZoom);
          this.zoom = this.map.getZoom();
        }
        this.zoomChange.emit(this.zoom);
      }
    }

  }

  /* open the lightbox for any photos */
  openAlbum(event: PointerEvent) {
    if (event?.isTrusted) {
      event.stopPropagation();
    }
    if (this.album?.length) {
      this.lightbox.open(this.album, 0, {
        centerVertically: true,
        showZoom: true,
        showRotate: true,
        showDownloadButton: true
      });

      const lightboxSubscription = this.lightboxEvent.lightboxEvent$
        .subscribe((lightboxEvent: IEvent) => {
          // remember to unsubscribe the event when lightbox is closed
          if (lightboxEvent.id === LIGHTBOX_EVENT.CLOSE) {
            // event CLOSED is fired
            lightboxSubscription.unsubscribe();
          }

          if (lightboxEvent.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
            // event change page is fired
            const audio = new Audio();
            audio.volume = 0.5;
            audio.src = './assets/audio/iphone-camera-capture-6448.mp3';
            audio.load();
            audio.play();
          }
        });

    }
  }

  /* close the lightbox */
  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  geocodeLocation(address = this.address) {
    // geocode an address to try to find the coordinates for it
    const platform = this.platform;
    if (this.platform && address) {
      const geocoder = platform.getSearchService();

      geocoder.geocode({
        q: address
      },
      (result) => {
        try {
          const position = result.items[0].position;
          this.position = { lat: position.lat, lon: position.lng };

          this.address = result.items[0].address.label;

          this.state = result.items[0].address.stateCode;
          this.stateChange.emit(this.state);

          this.map.setCenter(position);
          this.addMarkerAtLocation(this.position, createSvgMarker, 'position');

          this.positionChange.emit(this.position);
        } catch (error) {
          console.error(error);
        }
      },
      (error) => console.error(error)
      );
    } else {
      const marker = this.getMarkerById('position');
      if (marker) {
        try {
          this.group.removeObject(marker);
        } catch (error) {
          console.error(error);
        }
      }
    }
  }


  reverseGeocodePosition(location = this.position) {
    const { lat, lon } = location;
    const { platform } = this;

    // reverse geocode a selected coordinate to try to give an address for it in the search box
    if (platform) {
      const geocoder = platform.getSearchService();

      geocoder.reverseGeocode({
        at: `${lat},${lon}`
      },
      (result) => {
        try {
          this.address = result.items[0].address.label;
          this.state = result.items[0].address.stateCode;

          this.addressChange.emit(this.address);
          this.stateChange.emit(this.state);
        } catch (error) {
          console.error(error);
        }
      },
      (error) => console.error(error)
      );
    }

  }

  showTooltip(evt, text) {
    const tooltip = document.getElementById('tooltip');
    tooltip.textContent = text;
    tooltip.style.display = 'block';
    tooltip.style.left = evt.currentPointer.viewportX + 10 + 'px';
    tooltip.style.top = evt.currentPointer.viewportY + 10 + 'px';
  }

  hideTooltip() {
    const tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'none';
  }

}


const flag = 'https://maps.google.com/mapfiles/kml/shapes/flag_maps.png';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const flagIcon = (typeof H !== 'undefined') ? new H.map.Icon(flag) : undefined;

export const createSvgMarker = (svgParams) => new H.map.Icon(svgMarker(svgParams));
const createPhotoSvgMarker = (svgParams) => new H.map.Icon(svgPhotoMarker(svgParams));
const createLocateSvgMarker = (svgParams) => new H.map.Icon(svgLocateMarker(svgParams));
const createHomeSvgMarker = (svgParams) => new H.map.Icon(svgHomeMarker(svgParams));
const createDriverSvgMarker = (svgParams) => new H.map.Icon(svgDriverMarker(svgParams));
// const createMeetingSvgMarker = (svgParams) => new H.map.Icon(svgMeetingMarker(svgParams));

const svgMarker = ({ color, label, labelColor }) => `<svg style="left:-14px;top:-36px;" xmlns="http://www.w3.org/2000/svg" width="28px" height="36px" >
    <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"></path>
    <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"></path>
    <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${color || 'red'}"></path>
    <text transform="matrix( 1 0 0 1 13 18 )" x="0" y="0" fill-opacity="1" fill="${labelColor || '#fff'}" text-anchor="middle"
    font-weight="bold" font-size="13px" font-family="arial">${label ? label : ''}</text>
    </svg>`;

const svgPhotoMarker = ({ color, labelColor }) => `<svg style="left:-14px;top:-36px;" xmlns="http://www.w3.org/2000/svg" width="28px" height="36px" >
    <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"></path>
    <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"></path>
    <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${color || 'red'}"></path>
    <g transform="matrix( 1 0 0 1 4 4 ) scale(0.75 0.75)">
    <circle cx="12" cy="12" r="3.2"  fill="${labelColor || 'white'}"/><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" fill="${labelColor || 'white'}"/><path d="M0 0h24v24H0z" fill="none"/></g>
    </svg>`;

const svgLocateMarker = ({ color, labelColor }) => `<svg style="left:-14px;top:-36px;" xmlns="http://www.w3.org/2000/svg" width="28px" height="36px" >
  <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"></path>
  <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"></path>
  <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${color || 'red'}"></path>
  <g transform="matrix( 1 0 0 1 4 4 ) scale(0.75 0.75)">
	<path d="M0 0h24v24H0z" fill="none"/><path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z" fill="${labelColor || 'white'}"/>
  </g>
</svg>`;


const svgHomeMarker = ({ color, labelColor }) => `<svg style="left:-14px;top:-36px;" xmlns="http://www.w3.org/2000/svg" width="28px" height="36px" >
    <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"></path>
    <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"></path>
    <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${color || 'red'}"></path>
    <g transform="matrix( 1 0 0 1 4 4 ) scale(0.75 0.75)">
    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" fill="${labelColor || 'white'}"/><path d="M0 0h24v24H0z" fill="none"/>
    </g>
    </svg>`;


const svgDriverMarker = ({ color, labelColor }) => `<svg style="left:-14px;top:-36px;" xmlns="http://www.w3.org/2000/svg" width="28px" height="36px" >
    <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"></path>
    <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"></path>
    <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${color || 'red'}"></path>
    <g transform="matrix( 1 0 0 1 4 4 ) scale(0.75 0.75)">
    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" fill="${labelColor || 'white'}"/><path d="M0 0h24v24H0z" fill="none"/>
    </g>
    </svg>`;

/*
const svgMeetingMarker = ({ color, labelColor }) => `<svg style="left:-14px;top:-36px;" xmlns="http://www.w3.org/2000/svg" width="28px" height="36px" >
    <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"></path>
    <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"></path>
    <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${color || 'red'}"></path>
<g transform="matrix( 1 0 0 1 4 4 ) scale(0.75 0.75)">
<path d="M0 0h24v24H0z" fill="none"/><path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" fill="${labelColor || 'white'}"/>
</g>
    </svg>`;
*/
