import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Investigation } from './investigation';
import { InvestigationDetail } from './investigation-detail';
import { InvestigationVersion } from './investigation-version';
import { Employee } from '../shareddata';
import { CmsCustomer } from './cms-customer';

/*****************************************
 * InvestigationDetailsVersion
 *****************************************/
@JsonApiModelConfig({
  type: 'investigationDetailsVersions',
})
export class InvestigationDetailsVersion extends JsonApiModel {

  @Attribute({ serializedName: 'detail_id', converter })
    detailId: number;

  @Attribute({ serializedName: 'investigation_id', converter })
    investigationId: number;

  @Attribute({ serializedName: 'customer_id', converter })
    customerId: number;

  @Attribute({ serializedName: 'utility_type_id', converter })
    utilityTypeId: number;

  @Attribute({ serializedName: 'facility_type_id', converter })
    facilityTypeId: number;

  @Attribute({ serializedName: 'facility_id', converter })
    facilityId: number;

  @Attribute({ serializedName: 'locator_id', converter })
    locatorId: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'wf_item_id', converter })
    wfItemId: number;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'investigation_root_cause_id', converter })
    investigationRootCauseId: number;

  @Attribute({ serializedName: 'completed', converter })
    completed: boolean;

  @Attribute({ serializedName: 'completed_by', converter })
    completedBy$: number;

  @Attribute({ serializedName: 'completed_date', converter: dateConverter })
    completedDate: Date;

  @Attribute({ serializedName: 'search_text', converter })
    searchText: string;

  @Attribute({ serializedName: 'att1', converter })
    att1: string;

  @Attribute({ serializedName: 'att2', converter })
    att2: string;

  @Attribute({ serializedName: 'att3', converter })
    att3: string;

  @Attribute({ serializedName: 'att4', converter })
    att4: string;

  @Attribute({ serializedName: 'att5', converter })
    att5: string;

  @Attribute({ serializedName: 'changed_date', converter: dateConverter })
    changedDate: Date;

  @Attribute({ serializedName: 'changed_by', converter })
    changedBy$: number;

  @Attribute({ serializedName: 'investigation_version_id', converter })
    investigationVersionId: number;

  @BelongsTo()
    investigationVersion: InvestigationVersion;

  @BelongsTo()
    investigationDetail: InvestigationDetail;

  @BelongsTo()
    investigation: Investigation;

  @BelongsTo()
    locator: Employee;

  @BelongsTo()
    completedBy: Employee;

  @BelongsTo()
    changedBy: Employee;

  @BelongsTo()
    cmsCustomer: CmsCustomer;

}
