import { Component, OnInit, signal } from '@angular/core';
import { CellValue } from '@usic/lib/components/usic-table/usic-table-components/cell-value.service';
import {
  SchedulerJobStatus,
  SchedulerJob,
} from '@usic/public-api';
import { SharedDataService} from '../../services/shared-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { DataFormatterPipe } from '../../pipes/data-formatter.pipe';
import { ResolvePipe } from '../../pipes/resolve.pipe';

@Component({
  selector: 'app-change-status',
  template: `

    <div class="layout layout-align-space-between-center">

      <span> {{ selected() || (cellValue.element | resolve:(cellValue.colName) | dataFormatter:(cellValue.type) )}} </span>
      <button mat-icon-button [mat-menu-trigger-for]="changeStatus">
        <mat-icon> arrow_drop_down </mat-icon>
      </button>

      <mat-menu #changeStatus="matMenu">

        @for (status of statuses; track status.code) {
          <button mat-menu-item (click)="updateStatus(status)"> {{ status.code }} </button>
        }

      </mat-menu>

    </div>

  `,
  imports: [MatIconButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, DataFormatterPipe, ResolvePipe]
})
export class ChangeStatusComponent implements OnInit {

  selected = signal<string>(undefined);

  currentModel: SchedulerJob;
  statuses: SchedulerJobStatus[];

  constructor(
    public cellValue: CellValue,
    private sharedData: SharedDataService,
    private snackBar: MatSnackBar ) { }

  ngOnInit(): void {

    this.currentModel = this.cellValue.element;
    this.statuses = this.sharedData.schedulerJobStatuses;

  }

  updateStatus( status: SchedulerJobStatus ) {

    this.selected.set(status.code);
    this.currentModel.statusId = Number(status.id);

    if( this.currentModel.hasDirtyAttributes ) {

      this.currentModel.save().subscribe({
        next: () => {
          this.snackBar.open('Status saved!', 'OK' );
        },
        error: error => {
          console.error( error );
          this.snackBar.open('Error! Status not saved.', 'OK' );
          this.selected.set(undefined);
        }
      });

    }

  }
}
