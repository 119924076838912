/* eslint-disable no-underscore-dangle */
import { Component, Input } from '@angular/core';
import { UsicDialogComponent } from '../usic-dialog.component';
import { BreakpointsService } from '../../../services/breakpoints.service';
import { MatDialogTitle, MatDialogClose } from '@angular/material/dialog';
import { NgClass, NgStyle } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'usic-dialog-title',
  templateUrl: './dialog-title.component.html',
  imports: [MatDialogTitle, NgClass, NgStyle, MatIconButton, MatIcon, MatDialogClose, MatTooltip]
})
export class DialogTitleComponent {

  private _parent: UsicDialogComponent;
  @Input({ required: true }) set parent(value: UsicDialogComponent ) {
    this._parent = value;
  }
  get parent(): UsicDialogComponent {
    return this._parent;
  }

  constructor(
    public breakpoints: BreakpointsService,
  ) { }

}
