/********************************************************************************
 * HighlightTextPipe
 *
 * Angular pipe to highlight a matching staing in some text.
 *
 * NOTE: Since Angular will try to sanitize the HTML, this pipe must be used
 * with an [innerHtml] attribute.  For example, instead of:
 *
 *   <p>{{value | highlightText : searchValue}}</p>
 *
 * it must be written as:
 *
 *   <p [innerHtml]="value | highlightText : searchValue"></p>
 *
 * Additionally a 'highlight-text' CSS class must be defined to perform the
 * highlighting.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Pipe, PipeTransform } from '@angular/core';
import { checkSync } from 'recheck';

@Pipe({ name: 'highlightText' })
export class HighlightTextPipe implements PipeTransform {

  transform(value: string, filter: string | Date): string {
    // Fix for reported error 'A?.split is not a function' which is due to
    // normal-header utilizing matDatePicker for date columns which then
    // sends the filter value as a Date.
    // Convert the date to a string
    if (filter instanceof Date) {
      filter = filter.toLocaleDateString();
    }

    const trimmedFilter = filter?.split('|')
      .map(v => v?.trim()?.length > 0 ? v?.trim() : undefined)
      .filter(v => v)
      .join('|');

    if (!value || !trimmedFilter || trimmedFilter.length === 0) {
      return value;
    }

    const check = checkSync(trimmedFilter, 'ig');

    if (check.status === 'safe') {
      // nosemgrep: javascript.lang.security.audit.detect-non-literal-regexp.detect-non-literal-regexp
      // eslint-disable-next-line security/detect-non-literal-regexp
      const search = new RegExp(trimmedFilter, 'ig');
      return String(value).replace(search, (match) => `<span class="highlight-text">${match}</span>`);

    } else {
      return value;
    }

  }

}
