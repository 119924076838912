import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { LegalCase } from './legal-case';
import { LegalParty } from './legal-party';

/*****************************************
 * LawFirm
 *****************************************/
@JsonApiModelConfig({
  type: 'lawFirms',
})
export class LawFirm extends JsonApiModel {

  @Attribute({ serializedName: 'name', converter })
    name: string;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'attribute1', converter })
    attribute1: string;

  @Attribute({ serializedName: 'attribute2', converter })
    attribute2: string;

  @Attribute({ serializedName: 'attribute3', converter })
    attribute3: string;

  @HasMany()
    legalCases: LegalCase[];

  @HasMany()
    legalParties: LegalParty[];

}
