/********************************************************************************
 * LoginPageComponent (lib-login-page)
 *
 * Angular component to provide the common login page including a lib-login-form
 * and option to register
 *
 * parameters
 *   method    : string indicating which method of the authentication to use
 *               current options are
 *               'jwt' for JWT login via JSON:API
 *               'customerportal' for Customer Portal login via the CP API
 *   showAzure : boolean indicating if the 'Microsoft Login' option should be available
 *   appName   : string to provide the name of the application to login to
 *   signInMsg : string for optional signin message
 *
 * events
 *   forgotPassword : event emitter indicating when "Forgot Password" is clicked
 *   help           : event emitter indicating when "Need Help?" is clicked
 *   register       : event emitter indicating when "Register Now" is clicked
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BreakpointsService } from '../../services/breakpoints.service';
import { NgClass } from '@angular/common';
import { LoginFormComponent } from '../login-form/login-form.component';
import { MatAnchor } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
  selector: 'lib-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  imports: [NgClass, LoginFormComponent, MatAnchor, MatProgressBar]
})
export class LoginPageComponent {
  @Input() method = 'jwt';
  @Input() appName = 'USIC Application';
  @Input() signInMsg = 'Sign In to Your USIC Account';
  @Input() allowRegister = false;
  @Input() showAzure = true;

  @Output() forgotPassword = new EventEmitter();
  @Output() help = new EventEmitter();
  @Output() register = new EventEmitter();

  errorText: string;
  isLoading = false;

  constructor( public breakpoints: BreakpointsService ) { }

  loading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  loginError(errorText: string) {
    this.errorText = errorText;
  }

  clickForgotPassword(event: MouseEvent) {
    this.forgotPassword.emit({ event });
  }

  clickHelp(event: MouseEvent) {
    this.help.emit({ event });
  }

  clickRegister(event: MouseEvent) {
    this.register.emit({ event });
  }

}
