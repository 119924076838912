import {
  JsonApiModelConfig,
} from '@michalkotas/angular2-jsonapi';
import { Employee } from './employee';


/*****************************************
 * EmployeesOnLoan
 *****************************************/
@JsonApiModelConfig({
  type: 'employeesOnLoan'
})
export class EmployeeOnLoan extends Employee {
}
