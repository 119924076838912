@if (parent.parentForm?.invalid && parent.parentForm?.dirty) {
  <button mat-button color="warn"
          type="button"
          (click)="parent.debug=!parent.debug"> {{ parent.debug ? 'Hide' : 'Show' }} Errors </button>
}


<!-- disables if the parentForm hasn't changed or is invalid and
     sets the button color to 'warn' if the parentForm is invalid -->
<button mat-flat-button type="submit"
        [color]="parent.parentForm?.invalid ? 'warn' : 'accent'"
        [disabled]="( parent.childForm || parent.dialogForm )?.pristine || ( parent.parentForm || parent.dialogForm )?.invalid || parent.submitBtnDisabled">

  @if (parent.parentForm?.valid || parent.parentForm?.pristine) {

    {{ parent.submitButtonText }}

  } @else {

    <ng-container>

      <mat-icon matPrefix> error </mat-icon>
      <span> Form Errors </span>

      <!-- if there are form errors, display a list of the fields that are invalid -->
      @if (parent.debug) {
        <div class="debug-panel layout layout-column">

          <div class="title layout layout-align-space-between-center flex">
            <h4 class="no-margin"> Form Errors</h4>
            <!-- TODO hidden until I can figure out how to get click event
                wrapping it in a <button mat-icon-button> doesn't help
                nor does making a click function -->
            <mat-icon class="hide" (click)="parent.debug=false"> close </mat-icon>
          </div>

          <ol class="mat-caption">
            @for (ctrl of parent.findInvalidControls(parent.parentForm); track ctrl) {
              <li>
                <span class="text-usic-red titleized">{{ctrl | titleize}}</span>
              </li>
            }
          </ol>
        </div>
      }

    </ng-container>
  }

</button>

<!-- additional custom buttons -->
<ng-content></ng-content>

<button mat-button mat-dialog-close="Canceled." (click)="parent.onCancel()">

  {{ parent.cancelButtonText }}

</button>
