import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Customer } from '../shareddata/customer';
import { ClaimCategory } from './claim-category';
import { ClaimDetail } from './claim-detail';
import { ClaimSpendCategory } from './claim-spend-category';
import { Damage } from './damage';
import { FinancialPeriod } from './financial-period';
import { PayeeAddress } from './payee-address';
import { ClaimDiscount } from './claim-discount';

/*****************************************
 * ClaimCategory
 *****************************************/
@JsonApiModelConfig({
  type: 'claims'
})
export class Claim extends JsonApiModel {

  get claimId() {
    return this.id;
  }

  @Attribute({ serializedName: 'customer_id', converter })
    customerId: NumberConstructor;

  @Attribute({ serializedName: 'claim_category_id', converter })
    claimCategoryId: number;

  @Attribute({ serializedName: 'invoice_number', converter })
    invoiceNumber: string;

  @Attribute({ serializedName: 'invoice_date', converter: dateConverter })
    invoiceDate: Date;

  @Attribute({ serializedName: 'invoice_receipt_date', converter: dateConverter })
    invoiceReceiptDate: Date;

  @Attribute({ serializedName: 'payment_request_date', converter: dateConverter })
    paymentRequestDate: Date;

  @Attribute({ serializedName: 'invoice_amount', converter })
    invoiceAmount: number;

  @Attribute({ serializedName: 'payment_amount', converter })
    paymentAmount: number;

  @Attribute({ serializedName: 'cost_center_id', converter })
    costCenterId: number;

  @Attribute({ serializedName: 'accrued', converter })
    accrued: boolean;

  @Attribute({ serializedName: 'accrued_amount', converter })
    accruedAmount: number;

  @Attribute({ serializedName: 'accrued_period_id', converter })
    accruedPeriodId: number;

  @Attribute({ serializedName: 'request_id', converter })
    requestId: number;

  @Attribute({ serializedName: 'payment_mode_id', converter })
    paymentModeId: number;

  @Attribute({ serializedName: 'disputed', converter })
    disputed: boolean;

  @Attribute({ serializedName: 'dispute_reason_id', converter })
    disputeReasonId: number;

  @Attribute({ serializedName: 'period_id', converter })
    periodId: number;

  @Attribute({ serializedName: 'spend_category_id', converter })
    spendCategoryId: number;

  @Attribute({ serializedName: 'final_disposition_id', converter })
    finalDispositionId: number;

  @Attribute({ serializedName: 'status_id', converter })
    statusId: number;

  @Attribute({ serializedName: 'completed_by', converter })
    completedBy: number;

  @Attribute({ serializedName: 'completed_date', converter: dateConverter })
    completedDate: Date;

  @Attribute({ serializedName: 'approved', converter })
    approved: boolean;

  @Attribute({ serializedName: 'approved_by', converter })
    approvedBy: number;

  @Attribute({ serializedName: 'approved_date', converter: dateConverter })
    approvedDate: Date;

  @Attribute({ serializedName: 'att1_int', converter })
    att1Int: number;

  @Attribute({ serializedName: 'att2_int', converter })
    att2Int: number;

  @Attribute({ serializedName: 'att3_int', converter })
    att3Int: number;

  @Attribute({ serializedName: 'att1_var', converter })
    att1Var: string;

  @Attribute({ serializedName: 'att2_var', converter })
    att2Var: string;

  @Attribute({ serializedName: 'att3_var', converter })
    att3Var: string;

  @Attribute({ serializedName: 'claim_payee_id', converter })
    claimPayeeId: number;

  @Attribute({ serializedName: 'claim_payee_address_id', converter })
    claimPayeeAddressId: number;

  @Attribute({ serializedName: 'wf_item_id', converter })
    wfItemId: number;

  @Attribute({ serializedName: 'search_text', converter })
    searchText: string;

  @Attribute({ serializedName: 'reviewed_by', converter })
    reviewedBy: number;

  @Attribute({ serializedName: 'review_date', converter: dateConverter })
    reviewDate: Date;

  @Attribute({ serializedName: 'notes', converter })
    notes: string;

  @Attribute({ serializedName: 'paid', converter })
    paid: boolean;

  @Attribute({ serializedName: 'damage_id', converter })
    damageId: number;

  @Attribute({ serializedName: 'case_id', converter })
    caseId: number;

  @Attribute({ serializedName: 'docket_number', converter })
    docketNumber: string;

  @Attribute({ serializedName: 'retain_reserve_balance', converter })
    retainReserveBalance: boolean;

  @Attribute({ serializedName: 'settlement_pay_amount', converter })
    settlementPayAmount: number;

  @Attribute({ serializedName: 'should_cost_estimate', converter })
    shouldCostEstimate: number;

  @Attribute({ serializedName: 'wd_supplier_invoice_reference', converter })
    wdSupplierInvoiceReference: string;

  @Attribute({ serializedName: 'should_cost_variance_id', converter })
    shouldCostVarianceId: number;

  @Attribute({ serializedName: 'original_claim_id', converter })
    originalClaimId: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @BelongsTo()
    customer: Customer;

  @BelongsTo()
    claimCategory: ClaimCategory;

  @BelongsTo()
    damage: Damage;

  @BelongsTo()
    payeeAddress: PayeeAddress;

  @BelongsTo()
    claimSpendCategory: ClaimSpendCategory;

  @BelongsTo()
    financialPeriod: FinancialPeriod;

  @HasMany()
    claimDetails: ClaimDetail[];

  @HasMany()
    claimDiscounts: ClaimDiscount[];

}
