import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import { ReportGroupSection } from './report-group-section';

/*****************************************
 * ReportGroup
 *****************************************/
@JsonApiModelConfig({
  type: 'reportGroups'
})
export class ReportGroup extends JsonApiModel {

  @Attribute({ serializedName: 'name', converter })
    name: string;

  @Attribute({ serializedName: 'title', converter })
    title: string;

  @Attribute({ serializedName: 'icon', converter })
    icon: string;

  @Attribute({ serializedName: 'linkRoute', converter })
    linkRoute: string;

  @Attribute({ serializedName: 'info', converter })
    info: string;

  @Attribute({ serializedName: 'required_roles', converter })
    requiredRolesStr: string;

  get requiredRoles(): string[] {
    return this.requiredRolesStr?.split(',');
  }

  set requiredRoles(requiredRoles: string[]) {
    this.requiredRolesStr = requiredRoles?.join(',');
  }

  @Attribute({ serializedName: 'num_columns', converter })
    numColumns: number;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;


  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'eff_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'eff_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  @HasMany()
    reportGroupSections: ReportGroupSection[];

}
