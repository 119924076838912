import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Asset } from './asset';

/*****************************************
 * ChangeLog
 *****************************************/
@JsonApiModelConfig({
  type: 'changeLogs'
})
export class ChangeLog extends JsonApiModel {

  @Attribute({ serializedName: 'asset_id', converter })
    assetId: number;

  @Attribute({ serializedName: 'version_number', converter })
    versionNumber: number;

  @Attribute({ converter })
    active: boolean;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ converter })
    type: string;

  @Attribute({ serializedName: 'fieldname', converter })
    fieldname: string;

  @Attribute({ serializedName: 'old_value', converter })
    oldValue: string;

  @Attribute({ serializedName: 'new_value', converter })
    newValue: string;

  @Attribute({ serializedName: 'reference_id', converter })
    referenceId: string;

  @BelongsTo()
    asset: Asset;
}
