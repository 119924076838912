import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';
import { Activity } from './activity';


/*****************************************
 * StatusCode
 *****************************************/
@JsonApiModelConfig({
  type: 'statusesCode',
})
export class StatusCode extends JsonApiModel {

  @Attribute({ converter })
    code: string;

  @Attribute({ converter })
    category: string;

  @Attribute({ converter })
    description: string;

  get sanitizedDescription() {
    return this.description.startsWith('Send to ') ? 'Processing' : this.description;
  }

  @Attribute({ serializedName: 'on_site', converter })
    onSite: boolean;

  @Attribute({ serializedName: 'notify_excavator', converter })
    notifyExcavator: boolean;

  @Attribute({ serializedName: 'next_status_id', converter })
    nextStatusId: number;

  @Attribute({ converter })
    deleted: boolean;

  @Attribute({  converter })
    reschedule: boolean;

  @Attribute({ converter })
    screening: boolean;

  @HasMany()
    activites: Activity[];
}
