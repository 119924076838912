/********************************************************************************
 * AssetManagerService (a JsonApiDatastore)
 *
 * Implemenation of a JsonApiDatastore which is part of the angular2-jsonapi package
 * see https://www.npmjs.com/package/angular2-jsonapi
 * This defines the connection to the AssetManager API and what models the
 * API provides access to.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {
  JsonApiDatastoreConfig,
  JsonApiDatastore,
  DatastoreConfig,
  JsonApiModel,
  ModelType,
  JsonApiQueryData
} from '@michalkotas/angular2-jsonapi';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../../environments/environment';
import {
  Announcement,
  Asset,
  AssetAssignment,
  AssetAttribute,
  AssetAttributeValue,
  AssetCategory,
  AssetCategoryType,
  AssetMake,
  AssetModel,
  AssetStatus,
  AssetTrackMaintenance,
  AssetTransferException,
  AssetType,
  AttAccount,
  AttProductCatalogItem,
  AttWirelessInventory,
  AttWirelessInventoryUsage,
  AttributeMapping,
  CategoryTypeMakeMapping,
  ChangeLog,
  DeletionType,
  DistrictLocation,
  LoanedEmployeeAssets,
  Location,
  LocatorCheckin,
  MdmDevice,
  NetmotionDevice,
  Profile,
  ProfileLevel,
  ProfileValue,
  PumpDevice,
  QuickCode,
  QuickCodeValue,
  RepairReason,
  RoleAccess,
  RoleAction,
  UserRole,
  Vehicle,
  VerizonWirelessInventory,
  VerizonWirelessInventorySfo,
  VerizonWirelessInventoryUsage,
  VerizonWirelessInventoryUsageSummary,
  WirelessInventory,
  WriteoffLine,
} from '../models/assetmanager';
import {
  Country,
  District,
  Employee,
  EmployeeOnLoan,
  State,
  SupervisorGroup,
} from '../models/shareddata';
import { AuthenticationService } from './authentication.service';
import { Observable, Subscription } from 'rxjs';
import { MAX_PAGE_LIMIT } from '../datasources/jsonapi.datasource';
import { PersistentSettingsService } from './persistent-settings.service';
import { catchError, map, retry } from 'rxjs/operators';
import { UserAuth } from '../models/user';
import { SchedulerInfo } from '../models/schedulable';


export type FoundAsset = {
  'asset_id': number;
  'version_number': number;
  'mapping_id': number;
  'make_id': number;
  'model_id': number;
  'serial_number': string;
  'usic_tag': string;
  'status_id': number;
};

const referencedModels = {
  districts: District,
  employees: Employee,
  employeesOnLoan: EmployeeOnLoan,
  states: State,
  supervisorGroups: SupervisorGroup,
  countries: Country,
};

const config: DatastoreConfig = {
  baseUrl: `${environment.jsonApiUrl}/assetmanager/1.0`,
  models: {
    announcements: Announcement,
    assetAssignments: AssetAssignment,
    assetAttributeValues: AssetAttributeValue,
    assetAttributes: AssetAttribute,
    assetCategories: AssetCategory,
    assetCategoryTypes: AssetCategoryType,
    assetMakes: AssetMake,
    assetModels: AssetModel,
    assetStatuses: AssetStatus,
    assetTrackMaintenances: AssetTrackMaintenance,
    assetTransferExceptions: AssetTransferException,
    assetTypes: AssetType,
    assets: Asset,
    attAccounts: AttAccount,
    attProductCatalogItems: AttProductCatalogItem,
    attWirelessInventories: AttWirelessInventory,
    attWirelessInventoryUsages: AttWirelessInventoryUsage,
    attributeMappings: AttributeMapping,
    categoryTypeMakeMappings: CategoryTypeMakeMapping,
    changeLogs: ChangeLog,
    deletionTypes: DeletionType,
    districtLocations: DistrictLocation,
    loanedEmployeeAssets: LoanedEmployeeAssets,
    locations: Location,
    locatorCheckins: LocatorCheckin,
    mdmDevices: MdmDevice,
    netmotionDevices: NetmotionDevice,
    profileLevels: ProfileLevel,
    profileValues: ProfileValue,
    profiles: Profile,
    pumpDevices: PumpDevice,
    quickCodeValues: QuickCodeValue,
    quickCodes: QuickCode,
    repairReasons: RepairReason,
    roleAccesses: RoleAccess,
    roleActions: RoleAction,
    userRoles: UserRole,
    vehicles: Vehicle,
    verizonWirelessInventories: VerizonWirelessInventory,
    verizonWirelessInventorySfos: VerizonWirelessInventorySfo,
    verizonWirelessInventoryUsages: VerizonWirelessInventoryUsage,
    verizonWirelessInventoryUsageSummaries: VerizonWirelessInventoryUsageSummary,
    wirelessInventories: WirelessInventory,
    writeoffLines: WriteoffLine,

    // Add any additional models that the Asset Manager service models
    // reference here (most likely from the SharedData DB)
    ...referencedModels
  }
};

// Roles required to access and cache asset manager data
const REQUIRED_ROLES = [
  'ROLE_ASSET MANAGER - ADMINS',
  'ROLE_ASSET MANAGER - PRIVILEGED USERS',
  'ROLE_DISTRICT MANAGERS',
  'ROLE_FINANCE CSH',
  'ROLE_OPS COORDINATORS - ALL',
  'ROLE_OPS MANAGERS',
  'ROLE_REGIONAL DIRECTORS',
  'ROLE_SP_TELECOM',
  'ROLE_SUPPORT',
  'ROLE_WEBAPP - ADMINS'
];

@Injectable({ providedIn: 'root' })
@JsonApiDatastoreConfig(config)
export class AssetManagerService extends JsonApiDatastore implements OnDestroy {
  protected subscriptions: Subscription = new Subscription();
  private renderer: Renderer2;


  constructor(
    http: HttpClient,
    private authentication: AuthenticationService,
    private persistentSettings: PersistentSettingsService,
    private rendererFactory: RendererFactory2,
    private snackBar: MatSnackBar,
  ) {
    super(http);

    // reload any cached data whenever the user changes
    this.subscriptions.add(this.authentication.currentUser.subscribe((currentUser: UserAuth) => {
      if (currentUser && this.authentication.currentUserIsValid &&
        this.authentication.currentUserHasAnyRole(REQUIRED_ROLES)) {
        this.cacheData();
      }
    }));

    // Get an instance of Angular's Renderer2
    this.renderer = this.rendererFactory.createRenderer(null, null);

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get pageSize(): number {
    return Number(this.persistentSettings.getSetting('AssetManager-page-size')) || 5;
  }
  set pageSize(size: number) {
    // Save new page size preference if it's one of the valid selections (not a temporary
    // value used for select all actions)
    if ([5, 10, 25, 100, 250, 500, 1000].includes(size)) {
      this.persistentSettings.setSetting('AssetManager-page-size', size.toString());
    }
  }

  /**
   * Get the saved page size for a specific component (based on prefix)
   * @param prefix
   */
  savedPageSize(prefix: string): number {
    return Number(this.persistentSettings.getSetting(`${prefix}${prefix ? '-' : ''}page-size`)) || this.pageSize;
  }


  providesModel(modelName: string) {
    // eslint-disable-next-line security/detect-object-injection
    return (config.models[modelName] && !referencedModels[modelName]);
  }

  // Preload some data
  // The angular2-jsonapi library will keep them cached
  cacheData() {
    if (this.authentication.currentUserIsValid &&
      this.authentication.currentUserHasAnyRole(REQUIRED_ROLES)) {

      // The Profiles in the DB are essentially a properties file or config file
      // So we load the profile values at the start of the application.
      this.subscriptions.add(this.findAll(Profile, {
        include: 'profileValues,profileValues.profileLevel',
        filter: {
          profileValues: {
            effective_start_date: { le: ((new Date()).toISOString().substring(0, 10)) },
            effective_end_date: { ge: ((new Date()).toISOString().substring(0, 10)) },
          }
        },
        page: { limit: MAX_PAGE_LIMIT }
      })
        .subscribe({
          error: (error) => {
            console.error('Error caching profiles (retrying) =>', error);
            if (error.message !== 'Forbidden' &&
                error.message !== 'NG0205: Injector has already been destroyed.') {
              setTimeout(() => this.cacheData(), Math.round(Math.random() * 30000));
            }
          },

          complete: () => {
            // Similarly, since we'll be pulling in quick codes frequently, preload them as well.
            this.subscriptions.add(this.findAll(QuickCode, {
              include: 'quickCodeValues',
              sort: 'display_name',
              filter: {
                quickCodeValues: {
                  effective_start_date: { le: ((new Date()).toISOString().substring(0, 10)) },
                  effective_end_date: { ge: ((new Date()).toISOString().substring(0, 10)) },
                },
                effective_end_date: { ge: ((new Date()).toISOString().substring(0, 10)) },
              },
              page: { limit: MAX_PAGE_LIMIT }
            })
              .subscribe({
                error: (error) => {
                  console.error('Error caching quick codes (retrying) =>', error);
                  if (error.message !== 'Forbidden' &&
                      error.message !== 'NG0205: Injector has already been destroyed.') {
                    setTimeout(() => this.cacheData(), Math.round(Math.random() * 30000));
                  }
                }
              }));
          }

        })

      );
    }
  }


  // Access the preloaded profile values and return a desired profile value.
  getProfileValue(name: string, level?: string) {
    const profiles = this.peekAll(Profile);
    const profile = profiles.find(p => p.name === name);
    if (profile && profile.profileValues) {
      // return the first profile value by default
      let profileValue = profile.profileValues[0];

      // if there are multiple profile values, find the one for the desired level
      if (profile.profileValues.length > 1) {
        const profileValueForLevel = profile.profileValues.find(pv => pv.profileLevel.levelNumber === level);
        if (profileValueForLevel) {
          profileValue = profileValueForLevel;
        }
      }
      return profileValue.value;
    }
  }

  // Access the preloaded quickCodes and return a desired quickCode.
  getQuickCode(code: string): QuickCode {
    const quickCodes = this.peekAll(QuickCode);
    const quickCode = quickCodes.find(q => q.code === code);
    if (quickCode) {
      return quickCode;
    }
  }

  // Access the preloaded quickCodes and return a desired quickCode values.
  getQuickCodeValues(code: string): QuickCodeValue[] {
    const quickCodes = this.peekAll(QuickCode);
    const quickCode = quickCodes.find(q => q.code === code);
    let quickCodeValues: QuickCodeValue[] = [];
    if (quickCode) {
      quickCodeValues = quickCode.quickCodeValues;
    }
    return quickCodeValues;
  }

  /**
   * Upload a file of assets to create.
   * Returns an array of BulkResponse objects
   */
  bulkUpload({ mapping_id, model_id, district_id, supgroup_code, location_id, purchase_date, purchase_price, file }: {
    mapping_id: number;
    model_id: number;
    district_id: number;
    supgroup_code?: string;
    location_id: number;
    purchase_date?: string;
    purchase_price?: number;
    file: File;
  }): Observable<any> {
    const endpoint = `${config.baseUrl}/assetBulk`;

    const formData: FormData = new FormData();
    formData.append('mapping_id', String(mapping_id));
    formData.append('model_id', String(model_id));
    formData.append('district_id', String(district_id));
    if (supgroup_code) { formData.append('supgroup_code', supgroup_code); }
    formData.append('location_id', String(location_id));
    if (purchase_date) { formData.append('purchase_date', String(purchase_date) + ' 00:00:00'); }
    if (purchase_price) { formData.append('purchase_price', String(purchase_price)); }
    formData.append('file', file, file.name);
    return this.http
      .post(endpoint, formData, { headers: { 'ngsw-bypass': 'true' } });
  }

  /**
   * Upload a file of assets assignments or transfers
   * Returns an array of response objects
   */
  bulkAssign({ file }: {
    file: File;
  }): Observable<any> {
    const endpoint = `${config.baseUrl}/assetAssignmentBulk`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      .post(endpoint, formData, { headers: { 'ngsw-bypass': 'true' } });
  }

  /**
   * Upload a file of wireless devices to update.
   * Returns an array of WirelessResponse objects
   */
  bulkWirelessRequest({ requestType, reason, company, file, filename }: {
    requestType: string;
    reason: string;
    company: string;
    file: File | Blob;
    filename?: string;
  }): Observable<any> {
    const endpoint = `${config.baseUrl}/bulkWirelessRequest`;

    const formData: FormData = new FormData();
    formData.append('requestType', String(requestType));
    formData.append('reason', String(reason));
    formData.append('company', String(company));
    if (file instanceof File) {
      formData.append('file', file, file.name);
    } else {
      formData.append('file', file, filename);
    }
    return this.http
      .post(endpoint, formData, { headers: { 'ngsw-bypass': 'true' } });
  }


  /**
   * Get the metrics to display in the dashboard relevant for the current user
   */
  getDashboardData(): Observable<any> {
    const endpoint = `${config.baseUrl}/homeAssets?emp_id=${this.authentication.currentUserId}`;

    return this.http.get(endpoint).pipe(retry(2));

  }

  /**
   * Get all the asset data visible to the current user
   */
  getAllAssets(): Observable<any> {
    const endpoint = `${config.baseUrl}/allAssets?emp_id=${this.authentication.currentUserId}`;

    return this.http.get(endpoint)
      .pipe(retry(2));

    // return new Observable<any[]>(subscriber => {
    //   subscriber.next(
    //     [
    // eslint-disable-next-line max-len
    //       { asset_id: 1084104, workday_id: 'BA-00075244', usic_tag: '10\/TX-10USC-3882', category: 'Non-IT', sub_category: 'Locating Equipment', asset_type: 'Transmitter', make: 'Radiodetection', model: 'Rd8100', serial_number: '10\/TX-10USC-3882', asset_status: 'In Use', region: 'Mid West', district_name: 'East Michigan', sup_group_code: 'MI_EMI_ROY_100651', emp_id: 176020, legal_name: 'Chase, Brett', primary_flag: true, location: '1100 Owendale Dr, Ste H', purchase_date: '2019-03-27T00:00:00', purchase_price: 2004.930, book_value: 1269.790, created_by: 'SMPCORPORATE\\SreekanthGadhiraju', created_on: '2021-01-25T11:47:43.2666667', last_updated_by: 'SMPCORPORATE\\SreekanthGadhiraju', last_updated_on: '2021-01-25T11:47:43.2666667', effective_start_date: '2020-11-16T00:00:00', security_type: 'Non Global', user_emp_id: 138889 },
    // eslint-disable-next-line max-len
    //       { asset_id: 1084159, workday_id: 'BA-00080040', usic_tag: '359263', category: 'Non-IT', sub_category: 'Field Equipment', asset_type: 'Gas Detector', make: 'Gmi', model: 'Ps200-3', serial_number: '359263', asset_status: 'In Use', region: 'Mid West', district_name: 'East Michigan', sup_group_code: 'MI_EMI_ROY_100651', emp_id: 176020, legal_name: 'Chase, Brett', primary_flag: true, location: '1100 Owendale Dr, Ste H', purchase_date: '2019-09-11T00:00:00', purchase_price: 501.160, book_value: 367.520, created_by: 'SMPCORPORATE\\SreekanthGadhiraju', created_on: '2021-01-25T11:47:43.2666667', last_updated_by: 'SMPCORPORATE\\SreekanthGadhiraju', last_updated_on: '2021-01-25T11:47:43.2666667', effective_start_date: '2020-11-17T00:00:00', calibration_date: '2021-03-25', security_type: 'Non Global', user_emp_id: 138889 },
    // eslint-disable-next-line max-len
    //       { asset_id: 1084309, usic_tag: '72469', category: 'Non-IT', sub_category: 'Locating Equipment', asset_type: 'Transmitter', make: 'Radiodetection', model: 'Rd8000', serial_number: '64042', asset_status: 'In Use', region: 'Mid West', district_name: 'Western Michigan', sup_group_code: 'MI_WMI_BIG_100629', emp_id: 166453, legal_name: 'Orban, Jacob', primary_flag: true, location: '760 36th St SE Ste 200',created_by:'SMPCORPORATE\\SreekanthGadhiraju',created_on:'2021 - 01 - 25T11: 47: 43.2666667',last_updated_by:'SMPCORPORATE\\SreekanthGadhiraju',last_updated_on:'2021 - 01 - 25T11: 47: 43.2666667',effective_start_date:'2020 - 11 - 02T00: 00: 00',security_type:'Non Global',user_emp_id:138889},
    // eslint-disable-next-line max-len
    //       { asset_id:1084341,workday_id:'BA - 00034693',usic_tag:'Y55427',category:'Non - IT',sub_category:'Field Equipment',asset_type:'Blower',make:'Allegro',model:'9536',serial_number:'228590',asset_status:'In Use',region:'Mid West',district_name:'East Michigan',sup_group_code:'MI_EMI_PON_100649',emp_id:165332,legal_name:'Frechette, Emma',primary_flag:true,location:'1100 Owendale Dr, Ste H',purchase_date:'2015 - 02 - 28T00: 00: 00',purchase_price:140.000,book_value:0.000,created_by:'SMPCORPORATE\\SreekanthGadhiraju',created_on:'2021 - 01 - 25T11: 47: 43.2666667',last_updated_by:'SMPCORPORATE\\SreekanthGadhiraju',last_updated_on:'2021 - 01 - 25T11: 47: 43.2666667',effective_start_date:'2019 - 11 - 21T00: 00: 00',security_type:'Non Global',user_emp_id:138889},
    // eslint-disable-next-line max-len
    //       { asset_id:1084714,workday_id:'BA - 00082420',usic_tag:'361930',category:'Non - IT',sub_category:'Field Equipment',asset_type:'Gas Detector',make:'Gmi',model:'Ps200',serial_number:'361930',asset_status:'In Use',region:'Mid West',district_name:'Western Michigan',sup_group_code:'MI_WMI_BIG_100629',emp_id:170211,legal_name:'Lohman, Shawn',primary_flag:true,location:'760 36th St SE Ste 200',purchase_date:'2020 - 03 - 13T00: 00: 00',purchase_price:528.560,book_value:440.470,created_by:'SMPCORPORATE\\SreekanthGadhiraju',created_on:'2021 - 01 - 25T11: 47: 43.2666667', last_updated_by:'SMPCORPORATE\\SreekanthGadhiraju',last_updated_on:'2021 - 01 - 25T11: 47: 43.2666667',effective_start_date:'2020 - 11 - 06T00: 00: 00',calibration_date:'2021 - 05 - 05',security_type:'Non Global',user_emp_id:138889},
    //     ]
    //   );
    // });
  }

  /**
   * Get all the asset data visible to the current user as an Excel file
   */
  getAllAssetsSheet(): void {
    const endpoint = `${config.baseUrl}/allAssetsSheet?emp_id=${this.authentication.currentUserId}`;

    this.http.get(endpoint, { responseType: 'blob' as 'json' })
      .subscribe({
        next: (data: any) => {
          const dataType = data.type;
          const binaryData = [];
          binaryData.push(data);

          // create an temporary link to open the file
          const anchor = this.renderer.createElement('a');
          const binaryURL = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          this.renderer.setProperty(anchor, 'href', binaryURL);
          this.renderer.setAttribute(anchor, 'download', 'allAssets.xlsx');
          this.renderer.appendChild(document.body, anchor);
          anchor.click();

          // remove the temporary link
          this.renderer.removeChild(document.body, anchor);

        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        error: (error) => {
          this.snackBar.open('Could not download Excel file', 'OK');
        }
      });
  }


  /**
   * Find any assets with a given serial number
   * Results should be an array of FoundAsset
   */
  findAssetsBySerialNumber(serialNumber: string): Observable<any> {
    if (serialNumber) {
      const endpoint = `${config.baseUrl}/assetFind?serialNumber=${serialNumber}`;
      return this.http.get(endpoint).pipe(retry(2));
    }
  }


  /**
   * Find any assets with a given USIC tag
   * Results should be an array of FoundAsset
   */
  findAssetsByUsicTag(usicTag: string): Observable<any> {
    if (usicTag) {
      const endpoint = `${config.baseUrl}/assetFind?usicTag=${usicTag}`;
      return this.http.get(endpoint).pipe(retry(2));
    }
  }


  /**
   * Find any assets with a given reference id
   * Results should be an array of FoundAsset
   */
  findAssetsByReferenceId(referenceId: string): Observable<any> {
    if (referenceId) {
      const endpoint = `${config.baseUrl}/assetFind?referenceId=${referenceId}`;
      return this.http.get(endpoint).pipe(retry(2));
    }
  }


  // this allows the saves to work
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected getRelationships(data: any): any {
    return undefined;
  }

  /**
   * Schedule the AT&T Wireless Inventory Report that will load
   * the current inventory from AT&T
   *
   */
  scheduleAttWirelessInventoryReport(schedulerInfo: SchedulerInfo) {
    return new Promise((resolve, reject) => {

      const endpoint = `${environment.jsonApiUrl}/assetmanager/1.0/scheduleAttWirelessInventoryReport`;

      this.http
        .post(endpoint, schedulerInfo, { headers: { accept: 'application/text' }, responseType: 'text' })
        .subscribe({
          next: response => resolve(response),
          error: error => reject(error)
        });

    });

  }

  /**
   * Schedule the AT&T Current Usage Report that will load
   * the current usage data from AT&T
   *
   */
  scheduleAttCurrentUsageReport(schedulerInfo: SchedulerInfo) {
    return new Promise((resolve, reject) => {

      const endpoint = `${environment.jsonApiUrl}/assetmanager/1.0/scheduleAttCurrentUsageReport`;

      this.http
        .post(endpoint, schedulerInfo, { headers: { accept: 'application/text' }, responseType: 'text' })
        .subscribe({
          next: response => resolve(response),
          error: error => reject(error)
        });

    });

  }


  /**
   * Schedule the AT&T Catalog Extract that will load
   * the current product catalog from AT&T
   *
   */
  scheduleAttCatalogExtract(schedulerInfo: SchedulerInfo) {
    return new Promise((resolve, reject) => {

      const endpoint = `${environment.jsonApiUrl}/assetmanager/1.0/scheduleAttCatalogExtract`;

      this.http
        .post(endpoint, schedulerInfo, { headers: { accept: 'application/text' }, responseType: 'text' })
        .subscribe({
          next: response => resolve(response),
          error: error => reject(error)
        });

    });

  }

  /**
   * Schedule the Verizon Wireless Inventory Report that will load
   * the current inventory from Verizon
   *
   */
  scheduleVerizonWirelessInventoryReport(schedulerInfo: SchedulerInfo) {
    return new Promise((resolve, reject) => {

      const endpoint = `${environment.jsonApiUrl}/assetmanager/1.0/scheduleVerizonInventory`;

      this.http
        .post(endpoint, schedulerInfo, { headers: { accept: 'application/text' }, responseType: 'text' })
        .subscribe({
          next: response => resolve(response),
          error: error => reject(error)
        });

    });

  }

  /**
   * Schedule the Verizon Current Usage Report that will load
   * the current usage data from Verizon
   *
   */
  scheduleVerizonCurrentUsageReport(schedulerInfo: SchedulerInfo) {
    return new Promise((resolve, reject) => {

      const endpoint = `${environment.jsonApiUrl}/assetmanager/1.0/scheduleVerizonCurrentUsage`;

      this.http
        .post(endpoint, schedulerInfo, { headers: { accept: 'application/text' }, responseType: 'text' })
        .subscribe({
          next: response => resolve(response),
          error: error => reject(error)
        });

    });

  }

  /**
   * Schedule the Verizon Current Usage Summary Report that will summarize
   * the current usage data from Verizon
   *
   */
  scheduleVerizonCurrentUsageSummaryReport(schedulerInfo: SchedulerInfo) {
    return new Promise((resolve, reject) => {

      const endpoint = `${environment.jsonApiUrl}/assetmanager/1.0/scheduleVerizonCurrentUsageSummary`;

      this.http
        .post(endpoint, schedulerInfo, { headers: { accept: 'application/text' }, responseType: 'text' })
        .subscribe({
          next: response => resolve(response),
          error: error => reject(error)
        });

    });

  }


  //
  // The methods below are to extend json-api-datastore.service.ts that
  // is part of the angular2-jsonapi library.  findRecords is a cross
  // between findAll and findRecord.  It accepts multiple ids so instead
  // of returning a single model object, it still returns a set of results.
  // Unfortunately, the buildRequestOptions helper method it uses is private
  // so it is duplicated here (below).
  //

  /**
   * findRecords is a hybrid of findAll and findRecord
   *
   * @param modelType
   * @param id
   * @param params
   * @param headers
   * @param customUrl
   * @returns
   */
  findRecords<T extends JsonApiModel>(
    modelType: ModelType<T>,
    id: string,
    params?: any,
    headers?: HttpHeaders,
    customUrl?: string): Observable<T> {
    const url: string = this.buildUrl(modelType, params, id, customUrl);
    const requestOptions: object = this.buildRequestOptions2({ headers, observe: 'response' });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => this.extractQueryData2(res, modelType, true)),
        catchError((res: any) => this.handleError(res))
      );
  }

  private buildRequestOptions2(customOptions: any = {}): object {
    const httpHeaders: HttpHeaders = this.buildHttpHeaders(customOptions.headers);

    const requestOptions: object = Object.assign(customOptions, {
      headers: httpHeaders
    });

    this.requestOptions = requestOptions;

    //    return Object.assign(this.globalRequestOptions, requestOptions);
    return this.requestOptions;
  }

  protected extractQueryData2<T extends JsonApiModel>(
    response: HttpResponse<object>,
    modelType: ModelType<T>,
    withMeta = false
  ): Array<T> | JsonApiQueryData<T> {
    const body: any = response;
    const models: T[] = [];

    body.data.forEach((data: any) => {
      const model: T = this.deserializeModel(modelType, data);
      this.addToStore(model);

      if (body.included) {
        model.syncRelationships(data, body.included.concat(data));
        this.addToStore(model);
      }

      models.push(model);
    });

    if (withMeta && withMeta === true) {
      return new JsonApiQueryData(models, this.parseMeta(body, modelType));
    }

    return models;
  }


}
