/********************************************************************************
 * USICHeaderComponent (lib-usic-header)
 *
 * Angular component to show the internal website header (once logged in)
 *
 * parameters
 *   appName     : string to display the application name
 *   appLogo     : string path to application logo
 *   dev         : boolean to toggle showing development system indicator
 *   navItems    : array of NavItem objects to contruct the top navigation bar
 *   userActions : NavItem object to provide action when clicking on username
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

import { NavItem } from '../../models/general';
import { UserAuth } from '../../models/user';
import { ComponentType } from '@angular/cdk/portal';
import { BreakpointsService } from '../../services/breakpoints.service';
import { ColorSchemeService } from '../../services/color-scheme.service';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgComponentOutlet, NgClass } from '@angular/common';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';

@Component({
  selector: 'lib-usic-header',
  templateUrl: './usic-header.component.html',
  styleUrls: ['./usic-header.component.scss'],
  imports: [
    MatToolbar,
    MatToolbarRow,
    MatTooltip,
    MatButton,
    RouterLinkActive,
    RouterLink,
    MatIconButton,
    MatIcon,
    NgComponentOutlet,
    NgClass,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem
  ]
})
export class UsicHeaderComponent implements OnInit {
  @Input({ required: true }) appName = '[Provide appName]';
  @Input() appLogo;
  @Input() env = 'prod';
  @Input() navItems: NavItem[] = [];
  @Input() userActions: NavItem[] = [
    {
      name: 'Change Password',
      linkRoute: '/changePassword',
      title: 'Change your password',
      icon: 'password',
      isAdmin: false
    },
    {
      name: 'View profile',
      linkRoute: '/profile',
      title: 'View my profile',
      icon: 'person',
      isAdmin: false
    }
  ];

  @Input() noticesComponent: ComponentType<any>;

  @Output() help = new EventEmitter<any>();

  currentUser: UserAuth;

  constructor(
    public authentication: AuthenticationService,
    private router: Router,
    public breakpoints: BreakpointsService,
    public colorScheme: ColorSchemeService,
  ) {
  }

  ngOnInit() {
    this.authentication.currentUser.subscribe((x: UserAuth) => this.currentUser = x);
  }

  // Get Help Lassie!
  getHelp(event) {
    this.help.emit(event);
  }

  logout() {
    this.authentication.logout();
    this.currentUser = undefined;
    this.router.navigate(['/login']);
  }

  appHome() {
    this.router.navigate(['/']);
  }
}
