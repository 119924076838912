import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * AttAccount
 *****************************************/
@JsonApiModelConfig({
  type: 'attAccounts'
})
export class AttAccount extends JsonApiModel {

  @Attribute({ serializedName: 'account_group', converter })
    accountGroup: string;

  @Attribute({ serializedName: 'account_type', converter })
    accountType: string;

  @Attribute({ serializedName: 'account_group_id', converter })
    accountGroupId: string;

  @Attribute({ serializedName: 'account_level_value', converter })
    accountLevelValue: string;


}
