import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * UserDataAccessView
 *****************************************/
@JsonApiModelConfig({
  type: 'userDataAccessView'
})
export class UserDataAccessView extends JsonApiModel {

  @Attribute({ serializedName: 'emp_id', converter })
    empId: number;

  @Attribute({ serializedName: 'sup_group_code', converter })
    supGroupCode: string;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'district_name', converter })
    districtName: string;

  @Attribute({ converter })
    region: string;

}
