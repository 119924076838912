/********************************************************************************
 * DateRangeSelectComponent (lib-date-range-select)
 *
 * Angular component to show two date fields that work together to define
 * a date range.  The include a drop down for quick selects of
 * Today, Yesterday, This Week, and Last Week
 *
 * parameters
 *   startDateModel       : string field to hold start date value (2-way binding)
 *   startDatePlaceholder : string to put in the text field to indicate what
 *                          the start date is for
 *   endDateModel         : string field to hold end date value (2-way binding)
 *   endDatePlaceholder   : string to put in the text field to indicate what
 *                          the end date is for
 *
 * Also implements reactive form ControlValueAccessor as described
 * https://stackoverflow.com/questions/39661430/angular-2-formcontrolname-inside-component
 *
 *   formControl     : The FormControl from the parent formGroup for this control
 *                     to enable adding validators.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { forwardRef, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, AbstractControl, FormsModule } from '@angular/forms';
import { BreakpointsService } from '../../services/breakpoints.service';
import { NgClass, NgStyle } from '@angular/common';
import { MatFormField, MatLabel, MatSuffix, MatPrefix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInput, MatDatepicker } from '@angular/material/datepicker';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

export const DATE_RANGE_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateRangeSelectComponent),
  multi: true
};

/**
 * This component accepts and returns a >,>= starting date (ge) and a <,<= ending date (le)
 */
export type DateRange = {
  ge: Date;
  le: Date;
};

/**
 * Validator to ensure the start date is before the end date
 */
function startBeforeEnd(c: AbstractControl): { [key: string]: boolean } | null {
  if (c.value !== null &&
    c.value.rangeStartDate &&
    c.value.rangeEndDate &&
    c.value.rangeStartDate >= c.value.rangeEndDate) {
    return { startBeforeEnd: true };
  }
  return null;
}



@Component({
  selector: 'lib-date-range-select',
  templateUrl: './date-range-select.component.html',
  styleUrls: ['./date-range-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DATE_RANGE_CONTROL_VALUE_ACCESSOR],
  imports: [
    NgClass,
    MatFormField,
    MatLabel,
    MatInput,
    MatDatepickerInput,
    FormsModule,
    MatDatepicker,
    MatSuffix,
    MatIconButton,
    MatIcon,
    NgStyle,
    MatPrefix
  ]
})
export class DateRangeSelectComponent implements ControlValueAccessor, AfterViewInit {

  //
  // ngModel equivalent for template-driven forms
  //
  startDateValue: string;
  @Input()
  get startDateModel() {
    return this.startDateValue;
  }
  set startDateModel(val) {
    if (!isNaN(Date.parse(val))) {
      this.innerValue.ge = new Date(Date.parse(val));
      this.writeValue(this.innerValue);
    } else {
      this.startDateValue = val;
    }
    this.startDateModelChange.emit(this.startDateValue);
  }
  @Output() startDateModelChange = new EventEmitter();


  endDateValue: string;
  @Input()
  get endDateModel() {
    return this.endDateValue;
  }
  set endDateModel(val) {
    if (!isNaN(Date.parse(val))) {
      this.innerValue.le = new Date(Date.parse(val));
      this.writeValue(this.innerValue);
    } else {
      this.endDateValue = val;
    }
    this.endDateModelChange.emit(this.endDateValue);
  }
  @Output() endDateModelChange = new EventEmitter();

  //
  // Current form control for this component when using reactive forms.
  //
  @Input() formControl: FormControl = new FormControl();

  // The internal data model for form control value access (reactive forms)
  private innerValue: DateRange = {
    ge: null,
    le: null,
  };
  private onChange: (value: DateRange) => void;
  public onTouched: () => void;


  @Input() startDatePlaceholder;
  @Input() endDatePlaceholder;


  // Define the options for the date range quick select drop down
  quickOptions = [
    { value: 0, label: 'Today' },
    { value: 1, label: 'Yesterday' },
    { value: 2, label: 'This Week' },
    { value: 3, label: 'Last Week' }
  ];

  dropdownToggle = {
    start: false,
    end: false
  };

  constructor(
    public breakpoints: BreakpointsService
  ) { }

  // Lifecycle hook. angular.io for more info
  ngAfterViewInit() {

    // If this is being used in a reactive form, add the validation
    if (this.formControl) {
      const priorValidators = this.formControl.validator;
      if (priorValidators) {
        this.formControl.setValidators([startBeforeEnd, this.formControl.validator]);
      } else {
        this.formControl.setValidators(startBeforeEnd);
      }
      this.formControl.updateValueAndValidity();
    }

  }


  // Auto sets the date range for the passed in fields
  setDateRange(type) {
    const currDate = new Date();
    switch (type) {
      case 0:
        // Set the dates according to the type (see quickOptions object)
        // 0 does nothing since it is using today's date
        this.writeValue({
          ge: currDate,
          le: currDate,
        });

        break;
      case 1: {
        // Yesterday
        const temp = new Date(currDate.setHours(currDate.getHours() - 24));

        this.writeValue({
          ge: temp,
          le: temp,
        });

        break;
      }
      case 2: {
        // This Week
        const first = currDate.getDate() - currDate.getDay();
        const firstDay = new Date(currDate.setDate(first));

        this.writeValue({
          ge: firstDay,
          le: new Date(),
        });

        break;
      }
      case 3: {
        // Last Week
        const lastWeekFirst = currDate.getDate() - 7 - currDate.getDay();
        const lastWeekFirstDay = new Date(currDate.setDate(lastWeekFirst));
        const startDate = new Date(lastWeekFirstDay);
        lastWeekFirstDay.setTime(lastWeekFirstDay.getTime() + (7 * 24 * 60 * 60 * 1000));
        const endDate = lastWeekFirstDay;

        this.writeValue({
          ge: startDate,
          le: endDate,
        });

        break;
      }
    }

    // make the menu hidden again
    this.dropdownToggle.start = false;
    this.dropdownToggle.end = false;

  }


  changeStartDate(event: any) {
    const newDate: Date = event.target.valueAsDate || new Date(Date.parse(event.target.value));
    if (newDate) {
      // Fix timezone problem with date picker
      newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
      this.innerValue.ge = newDate;

      if (this.onChange) {
        this.onChange(this.innerValue);
      }
    }
  }

  changeEndDate(event: any) {
    const newDate: Date = event.target.valueAsDate || new Date(Date.parse(event.target.value));
    if (newDate) {
      // Fix timezone problem with date picker
      newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
      this.innerValue.le = newDate;

      if (this.onChange) {
        this.onChange(this.innerValue);
      }
    }
  }


  // From ControlValueAccessor interface
  writeValue(value: DateRange) {
    this.innerValue = value;

    // To allow template-driven ngModel work without error
    this.startDateValue = this.innerValue.ge?.toISOString();
    this.endDateValue = this.innerValue.le?.toISOString();

    if (this.onChange) {
      this.onChange(this.innerValue);
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: (value: DateRange) => void): void {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // get accessor
  get value(): DateRange {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: DateRange) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

}
