import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { UserRole } from './user-role';
import { RoleAction } from './role-action';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';

/*****************************************
 * RoleAccess
 *****************************************/
@JsonApiModelConfig({
  type: 'roleAccesses'
})
export class RoleAccess extends JsonApiModel {

  @Attribute({ serializedName: 'role_id', converter })
    roleId: number;

  @BelongsTo()
    userRole: UserRole;

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ serializedName: 'action_id', converter })
    actionId: string;

  @BelongsTo()
    roleAction: RoleAction;

  @Attribute({ serializedName: 'p_add', converter })
    pAdd: boolean;

  @Attribute({ serializedName: 'p_delete', converter })
    pDelete: boolean;

  @Attribute({ serializedName: 'p_edit', converter })
    pEdit: boolean;

  @Attribute({ serializedName: 'p_view', converter })
    pView: boolean;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

}
