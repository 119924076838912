import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * TicketInfo
 *****************************************/
@JsonApiModelConfig({
  type: 'tickets-info',
})
export class TicketInfo extends JsonApiModel {

  @Attribute({ serializedName: 'ticket_id', converter })
    ticketId: number;

  @Attribute({ serializedName: 'added_by_id', converter })
    added_by_id: number;

  @Attribute({ serializedName: 'effective_date', converter: dateConverter })
    effectiveDate: Date;

  @Attribute({ converter })
    emergency: boolean;

  @Attribute({ converter })
    meet: boolean;

  @Attribute({ serializedName: 'lot_type', converter })
    lotType: string;

  @Attribute({ serializedName: 'lot_ownership', converter })
    lotOwnership: string;

  @Attribute({ serializedName: 'region', converter })
    region: string;

  @Attribute({ serializedName: 'work_time_type', converter })
    work_time_type: string;

  @Attribute({ serializedName: 'meet_date', converter: dateConverter })
    meetDate: Date;

  @Attribute({ serializedName: 'area_id', converter })
    areaId: number;

  @Attribute({ serializedName: 'master_contractor', converter })
    masterContractor: boolean;

  @Attribute({ serializedName: 'eta', converter: dateConverter })
    eta: Date;

  @Attribute({ serializedName: 'sup_cn_reviewed', converter })
    supCnReviewed: boolean;

  @Attribute({ serializedName: 'rms_cn_reviewed', converter })
    rmsCnReviewed: boolean;

  @Attribute({ serializedName: 'rms_cn_map_sent', converter })
    rmsCnMapSent: boolean;

  @Attribute({ serializedName: 'rms_cn_deferred', converter })
    rmsCnDeferred: boolean;

  @Attribute({ serializedName: 'rms_eet_assigned', converter })
    rmsEetAssigned: boolean;

}
