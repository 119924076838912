import {
  JsonApiModelConfig,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { SmsMessage } from './sms-message';
import { Employee } from '../shareddata/employee';
import { SchedulableJsonApiModel } from '../schedulable';


/*****************************************
 * SmsMessageLog
 *****************************************/
@JsonApiModelConfig({
  type: 'smsMessageLogs',
})
export class SmsMessageLog extends SchedulableJsonApiModel {

  get smsMessageId() {
    return +this.id; // (+) converts string 'id' to a number
  }

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number; // message recipient

  @BelongsTo()
    employee: Employee;

  @Attribute({ serializedName: 'message_id', converter })
    messageId: number;

  @BelongsTo()
    smsMessage: SmsMessage;

  @Attribute({ serializedName: 'device_id', converter })
    deviceId: string;

  @Attribute({ serializedName: 'status_code', converter })
    statusCode: string;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ converter })
    attribute4: string;

  @Attribute({ converter })
    attribute5: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
      attribute4: this.attribute4,
      attribute5: this.attribute5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
    this.attribute2 = v.attribute2;
    this.attribute3 = v.attribute3;
    this.attribute4 = v.attribute4;
    this.attribute5 = v.attribute5;
  }

}
