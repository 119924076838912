import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * VisitLocation
 *****************************************/
@JsonApiModelConfig({
  type: 'visitLocations',
})
export class VisitLocation extends JsonApiModel {

  @Attribute({ serializedName: 'on_site', converter })
    onSite: boolean;

  @Attribute({ converter })
    latitude: number;

  @Attribute({ converter })
    longitude: number;

  @Attribute({ converter })
    distance: number;

  @Attribute({ converter })
    source: string;

}
