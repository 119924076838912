import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import { HereMapMarkerData } from '../../components/here-map/here-map-marker-data';
import {
  converter,
  dateConverter,
} from '../../helpers/jsonapi.convertors';
import { DueDateExtension } from './due-date-extension';
import { Locate } from './locate';
import { ProjectTicket } from './project-ticket';
import { TicketInfo } from './ticket-info';
import { TkpAttachment } from './tkp-attachment';
import { TkpDistrict } from './tkp-district';
import { Transmission } from './transmission';
import { TransmissionBase } from './transmission-base';
import { TransmissionDetail } from './transmission-detail';
import { Visit } from './visit';
import { ServiceStop } from './service-stop';
import { AttachmentCategorized } from './attachment-categorized';
import { TkpAttachmentsWithNotification } from './tkp-attachments-with-notificiation';

export class LinkedTicket {
  ticket_id: number;
  ticket_number: string;
  our_due_date: Date;
  closed_date: Date;
  open: boolean;
  locate_list_cache: string;
  latest_transmission_id: number;
  open_emergency: boolean;
  ticket_contact_id: number;
  excavator_contact_id: number;
  ordered_by_contact_id: number;
  bill_to_contact_id: number;
  ticket_invoice_signature_id: number;
  billed_manually: boolean;
  risk_score: number;
  ticket_district_id: number;
  ticket_sup_group_id: number;
}

/*****************************************
 * Ticket
 *****************************************/
@JsonApiModelConfig({
  type: 'tickets',
})
export class Ticket extends JsonApiModel implements HereMapMarkerData {

  get ticketId() {
    return +this.id;  // (+) converts string 'id' to a number
  }

  @Attribute({ serializedName: 'ticket_number', converter })
    ticketNumber: string;

  @Attribute({ serializedName: 'our_due_date', converter: dateConverter })
    ourDueDate: Date;

  @Attribute({ serializedName: 'closed_date', converter: dateConverter })
    closedDate: Date;

  @Attribute({ converter })
    open: boolean;

  @Attribute({ serializedName: 'locate_list_cache', converter })
    locateListCache: string;

  @Attribute({ serializedName: 'latest_transmission_id', converter })
    latestTransmissionId: number;

  @BelongsTo()
    transmission: Transmission;

  @Attribute({ serializedName: 'open_emergency', converter })
    openEmergency: boolean;

  @Attribute({ serializedName: 'ticket_contact_id', converter })
    ticketContactId: number;

  @Attribute({ serializedName: 'excavator_contact_id', converter })
    excavatorContactId: number;

  @Attribute({ serializedName: 'ordered_by_contact_id', converter })
    orderedByContactId: number;

  @Attribute({ serializedName: 'bill_to_contact_id', converter })
    billToContactId: number;

  @Attribute({ serializedName: 'ticket_invoice_signature_id', converter })
    ticketInvoiceSignatureId: number;

  @Attribute({ serializedName: 'billed_manually', converter })
    billedManually: boolean;

  @Attribute({ serializedName: 'risk_score', converter })
    risk_score: number;

  @Attribute({ serializedName: 'ticket_district_id', converter })
    ticketDistrictId: number;

  @Attribute({ serializedName: 'ticket_sup_group_id', converter })
    ticketSupGroupId: number;

  @HasMany()
    locates: Locate[];

  @HasMany()
    dueDateExtensions: DueDateExtension[];

  @BelongsTo()
    tkpDistrict: TkpDistrict;

  @HasMany()
    visits: Visit[];

  @BelongsTo()
    ticketsInfo: TicketInfo;

  @BelongsTo()
    transmissionDetail: TransmissionDetail;

  @BelongsTo()
    transmissionBase: TransmissionBase;

  @BelongsTo()
    projectTicket: ProjectTicket;

  @HasMany()
    tkpAttachments: TkpAttachment[];

  @HasMany()
    tkpAttachmentsWithNotifications: TkpAttachmentsWithNotification[];

  @HasMany()
    attachmentsCategorized: AttachmentCategorized[];

  @Attribute({ serializedName: 'linkedTickets', converter })
    linkedTickets: LinkedTicket[];

  serviceStops: ServiceStop[];

  // Some helper methods to allow locates to be displayed in table and on the map

  get locateId() {
    if (this.locates.length > 0) {
      return this.locates[0].id;
    }
  }

  get lat() {
    return this.transmissionDetail?.lat || this.transmission?.lat;
  }

  get lon() {
    return this.transmissionDetail?.lon || this.transmission?.lon;
  }

  get address() {
    return this.transmissionBase?.address || this.transmission?.address;
  }

  get area() {
    return this.transmissionDetail?.digAreas?.map(a => ({ lat: a.lat, lon: a.lon }));
  }


}
