import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsicFooterComponent } from './usic-footer.component';
import { FontAwesomeModule, FaIconLibrary  } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    UsicFooterComponent,
  ],
  exports: [
    UsicFooterComponent
  ]
})
export class UsicFooterModule {

  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab);
  }

}
