import { Component, Inject, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { BreakpointsService } from '@usic/public-api';

@Component({
  selector: 'lib-table-help',
  imports: [
    MatDialogActions,
    MatDialogContent,
    MatIcon,
    MatButton,
  ],
  templateUrl: './table-help.component.html',
  styleUrl: './table-help.component.css'
})
export class TableHelpComponent {
  readonly dialogRef = inject(MatDialogRef<TableHelpComponent>);
  readonly breakpoints = inject(BreakpointsService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
