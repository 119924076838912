/********************************************************************************
 * BillingRuleItem
 *
 * Model of an individual billing rule item that knows how to convert itself
 * to TreeNodeData(s)
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/


import { BillingRuleLimit, deepAssign, TreeNodeData } from '@usic/public-api';

export type BillingRuleItemType = 'root' | 'global-rule' | 'rule' | 'flag' | 'leaf' | 'adder' | 'limit';
export type BillingRuleAdderType = 'Tier Wise' | 'Periodic' | 'Slab Base' | 'Custom';

export class BillingRuleItemData {
  sequenceNo: string;
  forRule: string;
  type: BillingRuleItemType;
  description: string;
  condition?: string;
  adder?: {
    type: BillingRuleAdderType;
    units: string;
    field: string;
    from: number;
    to: number;
    repeatAfter: number;
  };

  passed?: {
    subRule?: string;      // first
    workCode?: string;
    limit?: string;        // alt first for WorkCode
    serviceFlag?: string;
  };

  constructor(ruleData?: BillingRuleItemData) {
    if (ruleData) {
      Object.assign(this, ruleData);
    }
  }

}

export class BillingRuleItem extends BillingRuleItemData {

  // structural characteristics
  parent: string;
  prior: string;           // usib
  failed: string;          // lsib

  constructor(ruleData?: BillingRuleItemData) {
    super(ruleData);

    // Ensure adder rule items contain the adder definition
    if (this.type === 'adder' && this.adder === undefined) {
      throw new Error(`Adder ${this.name} missing adder definition`);
    }
  }

  /**
   * Create a deep-copy clone of this billing rule
   *
   * @returns BillingRuleItem
   */
  clone() {
    const item = new BillingRuleItem();
    deepAssign(item, this);
    return item;
  }

  get name() {
    return `${this.type}#${this.sequenceNo}`;
  }

  set name(name: string) {
    const parts = name.split('#');
    this.type = parts[0] as BillingRuleItemType;
    this.sequenceNo = parts[1];
  }


  /**
   * toTreeNodeData converts a BillingRuleItem to a TreeDataNode[]
   * An array is returned since a single billing rule item may become multiple
   * nodes in the visual tree representations (for the results)
   *
   * @returns TreeDataNode[]
   */
  toTreeNodeDatas(limits?: BillingRuleLimit[]): TreeNodeData[] {
    const treeNodeDataArray: TreeNodeData[] = [];

    const treeNodeData = new TreeNodeData();
    treeNodeData.name = this.name;
    treeNodeData.type = this.type;
    treeNodeData.description = this.description;
    treeNodeData.data = this.adder ? this.adder : this.condition;
    treeNodeData.parent = this.parent;
    treeNodeData.usib = this.prior;
    treeNodeData.lsib = this.failed;
    treeNodeData.first = this.passed?.subRule;

    treeNodeDataArray.push(treeNodeData);

    // If the billing rule item has results, then add in the results as another node
    if (this.passed) {
      if (this.passed.serviceFlag) {
        const flagName = `flag-${this.name}`;

        const flagTreeNode = new TreeNodeData();
        flagTreeNode.name = flagName;
        flagTreeNode.type = 'flag';
        flagTreeNode.description = this.passed.serviceFlag;
        flagTreeNode.parent = this.name;
        flagTreeNode.usib = undefined;
        flagTreeNode.lsib = undefined;
        flagTreeNode.first = undefined;

        treeNodeData.first = flagName;

        treeNodeDataArray.push(flagTreeNode);
      }

      if (this.passed.workCode) {
        const leafName = `workCode-${this.name}`;

        const leafTreeNode = new TreeNodeData();
        leafTreeNode.name = leafName;
        leafTreeNode.type = 'leaf';
        leafTreeNode.description = this.passed.workCode;
        leafTreeNode.parent = this.name;
        leafTreeNode.usib = undefined;
        leafTreeNode.lsib = undefined;
        leafTreeNode.first = this.passed.limit;

        treeNodeData.first = leafName;

        treeNodeDataArray.push(leafTreeNode);

        if (limits) {
          const addLimit = (workCodeNode: TreeNodeData): TreeNodeData => {
            const limit = limits.find(l => l.workCode === workCodeNode.description);
            if (limit) {
              const limitName = `limit-${workCodeNode.name}`;

              const limitTreeNode = new TreeNodeData();
              limitTreeNode.name = limitName;
              limitTreeNode.type = 'limit';
              limitTreeNode.description = limit.altWorkCode;
              limitTreeNode.data = `Limit: ${limit.limit}${limit.dayLimit ? `; Days: ${limit.dayLimit}` : ''}`;
              limitTreeNode.parent = workCodeNode.name;
              limitTreeNode.usib = undefined;
              limitTreeNode.lsib = undefined;
              limitTreeNode.first = undefined;

              workCodeNode.first = limitTreeNode.name;

              treeNodeDataArray.push(limitTreeNode);
              return limitTreeNode;
            } else {
              return undefined;
            }
          };

          let workCode = leafTreeNode;
          do {
            workCode = addLimit(workCode);
          }
          while (workCode);
        }

      }

    }

    return treeNodeDataArray;
  }

}
