import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Employee } from '../shareddata';

/*****************************************
 * Journal
 *****************************************/
@JsonApiModelConfig({
  type: 'journals',
})
export class Journal extends JsonApiModel {

  @Attribute({ serializedName: 'journal_date', converter: dateConverter })
    journalDate: Date;

  @Attribute({ serializedName: 'priv', converter })
    priv: boolean;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'category_id', converter })
    categoryId: number;

  @Attribute({ serializedName: 'app_bread_crumbs_id', converter })
    appBreadCrumbsId: number;

  @Attribute({ serializedName: 'pk1', converter })
    pk1: number;

  @Attribute({ serializedName: 'pk2', converter })
    pk2: number;

  @Attribute({ serializedName: 'pk3', converter })
    pk3: number;

  @Attribute({ serializedName: 'journal_text', converter })
    journalText: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy$: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy$: string;

  @Attribute({ serializedName: 'reminder_date', converter: dateConverter })
    reminderDate: Date;

  @Attribute({ serializedName: 'reminder_flag', converter })
    reminderFlag: boolean;

  @Attribute({ serializedName: 'created_by_id', converter })
    createdById: number;

  @Attribute({ serializedName: 'last_updated_by_id', converter })
    lastUpdatedById: number;

  @Attribute({ serializedName: 'due_date', converter: dateConverter })
    dueDate: Date;

  @Attribute({ serializedName: 'completed_date', converter: dateConverter })
    completedDate: Date;

  @BelongsTo()
    createdBy: Employee;

  @BelongsTo()
    updatedBy: Employee;

}
