import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Country } from './country';

/*****************************************
 * State
 *****************************************/
@JsonApiModelConfig({
  type: 'states'
})
export class State extends JsonApiModel {

  get stateId(): number {
    return +this.id;  // (+) converts string 'id' to a number
  }

  @Attribute({ converter })
    name: string;

  @Attribute({ converter })
    abbrev: string;

  @BelongsTo()
    country: Country;

  @Attribute({ serializedName: 'countryid', converter })
    countryId: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_update_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

}
