/********************************************************************************
 * DistrictSupgroupFilterComponent (lib-district-supgroup-filter)
 *
 * Angular component the provides a mechanism to filter down from
 * Region -> District -> SupGroup (CostCenter)
 *
 * Selection events are passed back to allow containing component/page to update
 * displayed data accordingly.
 *
 * parameters
 * INPUT
 *   includeSupgroups        : boolean indicating whether or not to include the supgroup filter
 *                             (default: true)
 *   includeEmployees        : boolean indicating whether or not to include the employee filter
 *                             (default: true, also must includeSupgroups in order to includeEmployees)
 *   includeEmployeeSearch   : boolean indicating whether or not to include the employee search fields
 *                             (default: true, also must includeEmployees in order to includeEmployeeSearch)
 *   districtRequired        : boolean indicating whether or not the district is a required field (cannot be left blank)
 *                             (default: false)
 *   supgroupRequired        : boolean indicating whether or not the supgroup is a required field (cannot be left blank)
 *                             (default: false)
 *   employeeRequired        : boolean indicating whether or not the employee is a required field (cannot be left blank)
 *                             (default: false)
 *   persist                 : boolean indicating whether user selections should persist for subsequent user
 *                             (default true)
 *   allowScoping            : boolean indicating whether region/district/supgroup selections should be scoped (filtered)
 *                             based on the user's role
 *                             (default true)
 *   readOnly                : boolean indicating whether the user can change any selections
 *                             (default false)
 *   readOnlyRegions         : boolean indicating whether the user can change regions selections
 *                             (default false)
 *   readOnlyDistricts       : boolean indicating whether the user can change districts selections
 *                             (default false)
 *   readOnlySupgroups       : boolean indicating whether the user can change supgroups selections
 *                             (default false)
 *   readOnlyEmployees       : boolean indicating whether the user can change employee selections
 *                             (default false)
 *
 *   regionName              : string for initial region name
 *   districtNo              : number for initial district number
 *   supGroupCode            : string for initial supgroup
 *   employeeId              : number for initial employee id
 *
 *   employeesStatus         : string status(es) for employee list (default 'Active')
 *   indicateEmployeeStatus  : string[] status(es) that results in the employee option in the employee list
 *                             to be disabled from selection.  (default ['Leave','Rescided','Terminated']);
 *
 * Also implements reactive form ControlValueAccessor as described
 * https://stackoverflow.com/questions/39661430/angular-2-formcontrolname-inside-component
 *
 *   formControl             : The FormControl from the parent formGroup for this control
 *                             to enable adding validators.
 *
 * OUTPUT
 *   changeFilter            : EventEmitter of DistrictSupgroupFilterValue to notify of a filter change
 *                           : event contains { regionName, districtNo, districtName, supGroupCode, employeeId }
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewChild, signal, forwardRef, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ErrorResponse, JsonApiQueryData } from '@michalkotas/angular2-jsonapi';
import { SharedDataService } from '../../services/shared-data.service';
import { AuthenticationService } from '../../services/authentication.service';
import { PersistentSettingsService } from '../../services/persistent-settings.service';
import { ActivatedRoute } from '@angular/router';
import { MAX_PAGE_LIMIT } from '../../datasources/jsonapi.datasource';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgModel, FormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {
  District,
  SupervisorGroup,
  Employee,
  UserDataAccessView,
  UserEmployeeAccess
} from '../../models/shareddata';
import { BreakpointsService } from '../../services/breakpoints.service';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

export const DISTRICT_SUPGROUP_FILTER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DistrictSupgroupFilterComponent),
  multi: true
};

// eslint-disable-next-line no-shadow
enum Scope {
  DISTRICT = 'district',
  SUPGROUP = 'supgroup',
  REGION = 'region',
}

export type DistrictSupgroupFilterValue = {
  regionName: string;
  districtNo: string | number;
  districtName: string;
  supGroupCode: string;
  employeeId: number;
};

@Component({
  selector: 'lib-district-supgroup-filter',
  templateUrl: './district-supgroup-filter.html',
  styleUrls: ['./district-supgroup-filter.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DISTRICT_SUPGROUP_FILTER_CONTROL_VALUE_ACCESSOR],
  imports: [
    NgClass,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    MatTooltip,
    MatIcon,
    MatSuffix,
    AsyncPipe
  ]
})
export class DistrictSupgroupFilterComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() includeSupgroups = true;
  @Input() includeEmployees = true;
  @Input() includeEmployeeSearch = true;
  @Input() districtRequired = false;
  @Input() supgroupRequired = false;
  @Input() employeeRequired = false;
  @Input() persist = true;
  @Input() readOnly = false;
  @Input() readOnlyRegions = false;
  @Input() readOnlyDistricts = false;
  @Input() readOnlySupgroups = false;
  @Input() readOnlyEmployees = false;
  @Input() employeesStatus = 'Active';
  @Input() indicateEmployeeStatus: string[] = ['Leave', 'Rescided', 'Terminated'];
  @Input() allowScoping = true;
  @Input() defaultFlexWidth = 100;
  @Output() changeFilter = new EventEmitter<DistrictSupgroupFilterValue & { loading?: boolean }>();

  // current form control input. helpful in validating and accessing form control
  @Input() formControl: AbstractControl = new FormControl();

  private employeeSearchInput: NgModel;
  private employeeNameSub: Subscription;
  @ViewChild('employeeSearchInput') set employeeNameModel(model: NgModel) {
    if (model) {
      // if model is being set initially
      if (!this.employeeSearchInput) {
        this.employeeNameSub = model.valueChanges
          .pipe(
            debounceTime(750),
            distinctUntilChanged()
          )
          .subscribe(res => {
            this.findEmployee(res);
          });
      }
    } else {
      // If model was removed, remove the subscription to it
      this.employeeNameSub?.unsubscribe();
    }
    this.employeeSearchInput = model;
  }
  employeeSearch: string;

  private employeeIdSearchInput: NgModel;
  private employeeIdSub: Subscription;
  @ViewChild('employeeIdSearchInput') set employeeIdModel(model: NgModel) {
    if (model) {
      // if model is being set initially
      if (!this.employeeIdSearchInput) {
        this.employeeIdSub = model.valueChanges
          .pipe(
            debounceTime(750),
            distinctUntilChanged()
          )
          .subscribe(res => {
            this.findEmployeeId(res);
          });
      }
    } else {
      // If model was removed, remove the subscription to it
      this.employeeIdSub?.unsubscribe();
    }
    this.employeeIdSearchInput = model;
  }
  employeeIdSearch: string;

  searchEmployees: Observable<Employee[]>;

  errors = signal<string[]>([]);

  protected subscriptions: Subscription = new Subscription();

  regions: string[];
  districts: District[] | { workdayId: number; districtName: string; region: string }[];
  supgroupCodes: string[];
  employees: Employee[];

  // for scoped users
  userDataAccessViews: UserDataAccessView[];
  userScope: Scope;
  allowNoRegion = true;
  allowNoDistrict = true;
  allowNoSupgroup = true;
  allowNoEmployee = true;

  // The internal data model for form control value access (reactive forms)
  private innerValue: DistrictSupgroupFilterValue = {
    regionName: undefined,
    districtNo: undefined,
    districtName: undefined,
    supGroupCode: undefined,
    employeeId: undefined,
  };

  // for ControlValueAccessor
  private onChange: (value: DistrictSupgroupFilterValue) => void;
  public onTouched: () => void;

  get regionName(): string {
    return this.innerValue.regionName;
  }
  @Input() set regionName(value: string) {
    this.innerValue.regionName = value;
  }

  get districtNo(): number | string {
    return this.innerValue.districtNo;
  }
  @Input() set districtNo(value: number | string) {
    this.innerValue.districtNo = value;
  }

  get districtName(): string {
    return this.innerValue.districtName;
  }
  @Input() set districtName(value: string) {
    this.innerValue.districtName = value;
  }

  get supGroupCode(): string {
    return this.innerValue.supGroupCode;
  }
  @Input() set supGroupCode(value: string) {
    this.innerValue.supGroupCode = value;
  }

  get employeeId(): number {
    return this.innerValue.employeeId;
  }
  @Input() set employeeId(value: number) {
    this.innerValue.employeeId = value;
  }

  OPTION_ALL_REGIONS = 'All Regions';
  OPTION_ALL_DISTRICTS = 'All Districts';
  OPTION_ALL_SUPGROUPS = 'All Supgroups';


  constructor(
    protected sharedData: SharedDataService,
    protected authentication: AuthenticationService,
    protected persistentSettings: PersistentSettingsService,
    private route: ActivatedRoute,
    public breakpoints: BreakpointsService,
  ) {
  }

  ngOnInit() {
    // we subscribe to the route to ensure that the filtering and scoping is correctly
    // set even if this is a changed view of the same component
    this.subscriptions.add(this.route.params.subscribe(() => {
      let changedFilter = false;

      if (this.persist) {
        // restore any saved settings if the values weren't explicitly set
        if (!this.regionName && this.persistentSettings.getSetting('filter-regionName')) {
          this.regionName = this.persistentSettings.getSetting('filter-regionName');
          changedFilter = true;
        }
        if (!this.districtNo && this.persistentSettings.getSetting('filter-districtNo')) {
          this.districtNo = Number(this.persistentSettings.getSetting('filter-districtNo'));
          this.districtName = this.persistentSettings.getSetting('filter-districtName');
          changedFilter = true;
        }
        if (this.includeSupgroups && !this.supGroupCode && this.persistentSettings.getSetting('filter-supGroupCode')) {
          this.supGroupCode = this.persistentSettings.getSetting('filter-supGroupCode');
          changedFilter = true;
        }
        if (this.includeEmployees && !this.employeeId && this.persistentSettings.getSetting('filter-employeeId')) {
          changedFilter = true;
          this.setEmployeeId(Number(this.persistentSettings.getSetting('filter-employeeId')));
        }
      }

      if (changedFilter) {
        this.changeFilter.emit({ ...this.innerValue, loading: true });
      }
      if (this.onChange) {
        this.onChange(this.innerValue);
      }

      // Do user role based data scoping
      //
      // Note: if additional roles are added here, please update the list of roles in the
      // AssetManager/common/base-list/base-list.component

      if (this.allowScoping && this.authentication.currentUserHasRole('ROLE_REGIONAL DIRECTORS')) {
        // •	RD should have access to a region
        //
        // find an load their region
        this.loadUserScopedData(Scope.REGION);

      } else if (this.allowScoping && this.authentication.currentUserHasRole('ROLE_DISTRICT MANAGERS')) {
        // •	DM should have access to the entire district
        //
        // find an load their specific district
        this.loadUserScopedData(Scope.DISTRICT);

      } else if (this.allowScoping && this.authentication.currentUserHasAnyRole([
        'ROLE_ASSET MANAGER - PRIVILEGED USERS',
        'ROLE_OPS CLAIMS MANAGERS',
        'ROLE_OPS COORDINATORS - ALL',
        'ROLE_OPS MANAGERS',
        //        'ROLE_OPSCOORD',
      ])) {
        // •	OM (Field Managers), OC have access to their district
        //
        // find an load their specific district
        this.loadUserScopedData(Scope.DISTRICT);

      } else if (this.allowScoping && this.authentication.currentUserHasAnyRole([
        'ROLE_FIELD SUPERVISORS - ALL',
        'ROLE_SUPERVISORS - BHUG',
      ])) {
        // •	Supervisors should only have access to their sup group
        //
        // find and load their specific supgroup
        this.loadUserScopedData(Scope.SUPGROUP);

      } else {
        // get the list of all regions from districts
        this.loadRegions();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // get accessor
  get value(): DistrictSupgroupFilterValue {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: DistrictSupgroupFilterValue) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

  // From ControlValueAccessor interface
  writeValue(value: DistrictSupgroupFilterValue) {
    this.innerValue = value;
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: (value: DistrictSupgroupFilterValue) => void): void {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }


  loadRegions() {
    // get the list of all regions from districts
    this.subscriptions.add(this.sharedData.findAll(District,
      {
        fields: { districts: 'region' },
        sort: 'region',
        page: { limit: MAX_PAGE_LIMIT },
      })
      .subscribe({
        next: (districts: JsonApiQueryData<District>) => {
          const districtModels = districts.getModels();

          // Construct the region list from the districts
          this.regions = districtModels.filter(d => d.region).map(e => e.region);
          // filter out duplicates
          this.regions = [...new Set(this.regions)];

          // if only 1, auto select the first one
          if (this.regions.length === 1) {
            this.regionName = this.regions[0];
          } else if (this.allowNoRegion === false && this.regions.length > 1) {
            this.regionName = this.OPTION_ALL_REGIONS;
            if (this.persist) {
              this.persistentSettings.clearSetting('filter-regionName');
            }
          }

          // if a region has been selected, load the districts
          if (this.regionName) {
            this.loadDistricts();
          }

        },
        error: (errorResponse) => {
          if (errorResponse instanceof ErrorResponse) {
            this.errors.set(errorResponse.errors.map(e => (e.detail || e.title)));
          }
        }
      }));
  }

  loadDistricts() {
    // get the full list of districts for the region for the select list
    if (this.regionName) {
      this.errors.set([]);

      const filter: any = { region: this.regionName };

      this.subscriptions.add(this.sharedData.findAll(District,
        {
          filter,
          sort: 'district_name',
          page: { limit: MAX_PAGE_LIMIT },
        })
        .subscribe({
          next: (districts: JsonApiQueryData<District>) => {
            this.districts = districts.getModels();

            // if only 1, auto select the first one
            if (this.districts?.length === 1) {
              this.regionName = this.districts[0].region;
              this.districtNo = Number(this.districts[0].workdayId);
              this.districtName = this.districts[0].districtName;
            } else if (this.allowNoDistrict === false && this.districts?.length > 1) {
              // Allow district scoped users with multiple districts to see all their districts at once
              this.districtNo = this.districts.map((d: District) => d.workdayId).join(',');
              this.districtName = this.OPTION_ALL_DISTRICTS;
              if (this.persist) {
                this.persistentSettings.clearSetting('filter-districtNo');
                this.persistentSettings.clearSetting('filter-districtName');
              }
            }

            if (this.districts?.length > 1) {
              this.readOnlyDistricts = false;
            }

            if (this.regionName && this.districtNo) {
              if (this.includeSupgroups) {
                this.loadSupgroups();
              } else if (this.includeEmployees) {
                this.loadEmployees();
              }
            }

            this.changeFilter.emit({ ...this.innerValue, loading: true });
            if (this.onChange) {
              this.onChange(this.innerValue);
            }

          },
          error: (errorResponse) => {
            if (errorResponse instanceof ErrorResponse) {
              this.errors.set(errorResponse.errors.map(e => (e.detail || e.title)));
            }
          }
        }));
    }
  }

  loadSupgroups() {
    // get the full list of supgroups for the region for the select list
    if (this.districtNo) {
      this.errors.set([]);

      const filter: any = {
        district_code: this.districtNo,
        status: 'Active',
      };

      this.subscriptions.add(this.sharedData.findAll(SupervisorGroup,
        {
          filter,
          sort: 'sup_group_code',
          page: { limit: MAX_PAGE_LIMIT },
        })
        .subscribe({
          next: (supgroups: JsonApiQueryData<SupervisorGroup>) => {
            const supgroupModels: SupervisorGroup[] = supgroups.getModels();
            this.supgroupCodes = supgroupModels.map(e => e.supervisorGroupCode);
            // filter out duplicates
            this.supgroupCodes = [...new Set(this.supgroupCodes)];

            // if only 1, auto select the first one
            if (this.supgroupCodes.length === 1 ||
              (this.allowNoSupgroup === false && this.supgroupCodes.length > 1)) {
              this.supGroupCode = this.supgroupCodes[0];

              this.changeFilter.emit({ ...this.innerValue, loading: true });
              if (this.onChange) {
                this.onChange(this.innerValue);
              }
            }
            if (this.supGroupCode && this.includeEmployees) {
              this.loadEmployees();
            }

          },
          error: (errorResponse) => {
            if (errorResponse instanceof ErrorResponse) {
              this.errors.set(errorResponse.errors.map(e => (e.detail || e.title)));
            }
          }
        }));

    }
  }

  loadEmployees() {
    let filter: any;

    if (this.supGroupCode) {
      // get the full list of employees for the supgroup for the select list
      filter = {
        sup_group_code: this.supGroupCode,
        emp_type: 'WD',
        active_status: this.employeesStatus,
      };
    } else if (!this.includeSupgroups && this.districtNo) {
      // we're not showing supgroups
      filter = {
        district_id: this.districtNo,
        emp_type: 'WD',
        active_status: this.employeesStatus,
      };
    }

    if (filter) {
      this.errors.set([]);

      this.subscriptions.add(this.sharedData.findAll(Employee,
        {
          filter,
          sort: 'legal_name',
          include: 'dist',
          page: { limit: MAX_PAGE_LIMIT },
        })
        .subscribe({
          next: (employees: JsonApiQueryData<Employee>) => {
            this.employees = employees.getModels();

            // // if only 1, auto select the first one
            // if (this.employees.length === 1) {
            //   this.setEmployeeId(this.employees[0].empId);
            // }

            this.changeFilter.emit({ ...this.innerValue, loading: true });
            if (this.onChange) {
              this.onChange(this.innerValue);
            }

          },
          error: (errorResponse) => {
            if (errorResponse instanceof ErrorResponse) {
              this.errors.set(errorResponse.errors.map(e => (e.detail || e.title)));
            }
          }
        }));
    }
  }

  loadUserScopedData(scope: Scope) {
    const userId = this.authentication.currentUserId;
    this.userScope = scope;

    let filter = {};
    switch (scope) {
      case Scope.SUPGROUP:
        this.allowNoSupgroup = false;
        this.readOnlySupgroups = true;
      // eslint-disable-next-line no-fallthrough
      case Scope.DISTRICT:
        this.allowNoDistrict = false;
        this.readOnlyDistricts = true;
      // eslint-disable-next-line no-fallthrough
      case Scope.REGION:
        this.allowNoRegion = false;
        this.readOnlyRegions = true;
        break;
    }

    filter = { emp_id: userId };
    this.loadUserScopedDataWithFilter(filter);

  }

  loadUserScopedDataWithFilter(filter: any) {
    // get the list of districts (aka cost centers) for the user
    this.subscriptions.add(this.sharedData.findAll(UserDataAccessView,
      {
        filter,
        sort: 'sup_group_code',
        page: { limit: MAX_PAGE_LIMIT },
      })
      .subscribe({
        next: (userDataAccessViewResult: JsonApiQueryData<UserDataAccessView>) => {
          this.userDataAccessViews = userDataAccessViewResult.getModels();

          this.buildDatasetsFromScopedData();

          if (this.regions.length > 1) {
            // This is a regional director of multiple regions
            this.readOnlyRegions = false;
            if (!this.regions.includes(this.regionName)) {
              this.regionName = this.OPTION_ALL_REGIONS;
              if (this.persist) {
                this.persistentSettings.clearSetting('filter-regionName');
              }
            }
          }

          if (this.supGroupCode && this.includeEmployees) {
            this.loadEmployees();
          }

        },
        error: (errorResponse) => {
          if (errorResponse instanceof ErrorResponse) {
            this.errors.set(errorResponse.errors.map(e => (e.detail || e.title)));
          }
        }
      }));

  }

  buildDatasetsFromScopedData() {
    // construct the district list from the userDataAccessViews
    this.districts = this.userDataAccessViews.map(u => ({
      workdayId: u.districtId,
      districtName: u.districtName,
      region: u.region
    }));
    // ensure the district list is unique
    this.districts = this.districts
      .filter((u, i) => this.districts.findIndex(a => a.workdayId === u.workdayId) === i)
      .sort((a, b) => a.districtName.localeCompare(b.districtName));

    // Construct the region list from the user's districts
    // Note the trailing filter removes unnamed regions
    this.regions = this.districts.map(d => d.region).filter(r => r);
    // filter out duplicates (just in case)
    this.regions = [...new Set(this.regions)];

    if (this.regions.length > 1) {
      // This is a manager of multiple districts
      this.readOnlyRegions = false;
    }

    if (this.readOnlyRegions || this.regions.length === 1) {
      // auto select the first one
      // If we don't do this, users with multiple districts get global scope by default
      this.regionName = this.regions[0];
    }

    // now set the districts to just the ones for the selected region
    if (this.regionName && this.regionName !== this.OPTION_ALL_REGIONS) {
      this.districts = this.districts.filter(d => d.region === this.regionName);
    }

    if ((this.readOnlyDistricts && this.districts?.length > 0) ||
      this.districts?.length === 1) {
      // auto select the first one
      this.districtNo = Number(this.districts[0].workdayId);
      this.districtName = this.districts[0].districtName;
    }

    if (this.readOnlyDistricts && this.districts?.length > 1) {
      // This is a district manager of multiple districts
      this.readOnlyDistricts = false;
      this.districtName = this.OPTION_ALL_DISTRICTS;
    }

    if (this.districtName === this.OPTION_ALL_DISTRICTS) {
      // Allow district scoped users with multiple districts to see all their districts at once
      this.districtNo = this.districts.map((d: District) => d.workdayId).join(',');
      if (this.persist) {
        this.persistentSettings.clearSetting('filter-districtNo');
        this.persistentSettings.clearSetting('filter-districtName');
      }
    }

    // If a district is selected, get the supgroup codes for the selected district
    if (this.districtNo) {
      if (this.districtName === this.OPTION_ALL_DISTRICTS) {

        // set up the supgroup list for all districts
        const districtIds = this.districts.map((d: District) => d.workdayId);
        this.supgroupCodes = this.userDataAccessViews
          .filter(u => districtIds.includes(u.districtId))
          .map(e => e.supGroupCode);

      } else {

        // set up the supgroup list for selected district
        this.supgroupCodes = this.userDataAccessViews
          .filter(u => u.districtId === this.districtNo)
          .map(e => e.supGroupCode);

      }
      // filter out duplicates
      this.supgroupCodes = [...new Set(this.supgroupCodes)];
    }

    if ((this.readOnlySupgroups && this.supgroupCodes.length > 0) ||
      this.supgroupCodes?.length === 1) {
      // auto select the first one
      this.supGroupCode = this.supgroupCodes[0];

      if (this.includeEmployees) {
        this.loadEmployees();
      }
    }

    if (this.supgroupCodes?.length > 1) {
      // ensure selections can be made if its a supervisor of multiple supgroups
      this.readOnlySupgroups = false;
    }

    // Defaults may have been selected, so emit current selections
    this.changeFilter.emit({ ...this.innerValue, loading: true });
    if (this.onChange) {
      this.onChange(this.innerValue);
    }

  }

  selectRegion(event: Event) {
    const element = event.target as HTMLSelectElement;
    const regionName = element.value;
    this.districtNo = undefined;
    this.districtName = undefined;
    this.supGroupCode = undefined;
    this.districts = undefined;
    this.supgroupCodes = undefined;
    this.employees = undefined;
    this.setEmployeeId(undefined);

    if (this.persist) {
      this.persistentSettings.clearSetting('filter-districtNo');
      this.persistentSettings.clearSetting('filter-districtName');
      this.persistentSettings.clearSetting('filter-supGroupCode');
    }

    if (regionName === this.OPTION_ALL_REGIONS) {
      this.regionName = regionName;
      if (this.persist) {
        this.persistentSettings.clearSetting('filter-regionName');
      }
      this.districtName = this.OPTION_ALL_DISTRICTS;
      if (this.persist) {
        this.persistentSettings.clearSetting('filter-districtNo');
        this.persistentSettings.clearSetting('filter-districtName');
      }

      // Only scoped users have this option
      this.buildDatasetsFromScopedData();

      this.districtNo = this.districts.map((d: District) => d.workdayId).join(',');

    } else {

      const region = this.regions.find(attr => attr === regionName);
      if (region) {
        this.regionName = regionName;
        if (this.persist) {
          this.persistentSettings.setSetting('filter-regionName', this.regionName);
        }
        if (this.userScope) {
          this.buildDatasetsFromScopedData();
        } else {
          this.loadDistricts();
        }
      } else {
        this.regionName = undefined;
        if (this.persist) {
          this.persistentSettings.clearSetting('filter-regionName');
        }
        this.changeFilter.emit(this.innerValue);
        if (this.onChange) {
          this.onChange(this.innerValue);
        }

      }

    }
  }

  selectDistrict(event: Event) {
    const element = event.target as HTMLSelectElement;

    this.supGroupCode = undefined;
    if (this.persist) {
      this.persistentSettings.clearSetting('filter-supGroupCode');
    }

    this.employees = undefined;
    this.setEmployeeId(undefined);

    if (element.value === this.OPTION_ALL_DISTRICTS) {
      // Allow district scoped users with multiple districts to see all their districts at once
      this.districtNo = this.districts.map((d: District) => d.workdayId).join(',');
      this.districtName = this.OPTION_ALL_DISTRICTS;
      if (this.persist) {
        this.persistentSettings.clearSetting('filter-districtNo');
        this.persistentSettings.clearSetting('filter-districtName');
      }

      if (this.includeSupgroups) {
        if (this.userScope) {
          this.buildDatasetsFromScopedData();
        } else {
          this.loadSupgroups();
        }
      } else if (this.includeEmployees) {
        this.loadEmployees();
      }

    } else {
      this.districtNo = Number(element.value);
      const district = this.districts.find((attr: District) => attr.workdayId === this.districtNo);
      if (district) {
        this.districtName = district.districtName;
        if (this.persist) {
          this.persistentSettings.setSetting('filter-districtNo', String(this.districtNo));
          this.persistentSettings.setSetting('filter-districtName', this.districtName);
        }
        if (this.includeSupgroups) {
          if (this.userScope) {
            this.buildDatasetsFromScopedData();
          } else {
            this.loadSupgroups();
          }
        } else if (this.includeEmployees) {
          this.loadEmployees();
        }
      } else {
        this.districtNo = undefined;
        this.districtName = undefined;
        this.supgroupCodes = undefined;
        this.employees = undefined;
        if (this.persist) {
          this.persistentSettings.clearSetting('filter-districtNo');
          this.persistentSettings.clearSetting('filter-districtName');
        }
      }

    }

    this.changeFilter.emit(this.innerValue);
    if (this.onChange) {
      this.onChange(this.innerValue);
    }

  }

  selectSupgroup(event: Event) {
    const element = event.target as HTMLSelectElement;

    if (element.value === this.OPTION_ALL_SUPGROUPS) {
      // Allow supgroup scoped users with multiple supgroups to see all their supgroups at once
      this.supGroupCode = this.supgroupCodes.join(',');

      if (this.includeEmployees) {
        this.loadEmployees();
      }

    } else {
      this.supGroupCode = element.value === 'Select a supgroup' ? undefined : element.value;

      if (this.supGroupCode) {
        if (this.persist) {
          this.persistentSettings.setSetting('filter-supGroupCode', this.supGroupCode);
        }
        if (this.includeEmployees) {
          this.loadEmployees();
        }
      } else {
        this.employees = undefined;
        if (this.persist) {
          this.persistentSettings.clearSetting('filter-supGroupCode');
        }
      }
    }

    this.setEmployeeId(undefined);

    this.changeFilter.emit(this.innerValue);
    if (this.onChange) {
      this.onChange(this.innerValue);
    }

  }

  /**
   * setEmployeeId
   * When the employeeID is set, also update employee search and employee ID search
   */
  setEmployeeId(value: number) {
    this.employeeId = value;

    if (this.persist) {
      if (value) {
        this.persistentSettings.setSetting('filter-employeeId', String(this.employeeId));
      } else {
        this.persistentSettings.clearSetting('filter-employeeId');
      }
    }

    const employee = this.employees?.find(e => e.empId === this.employeeId);
    if (employee) {
      this.regionName = employee.district?.region;
      this.districtNo = employee.districtId;
      this.districtName = employee.districtName;
      this.supGroupCode = employee.supGroupCode;
    }

    if (this.employeeIdSearchInput) {
      if (value) {
        this.employeeIdSearch = String(this.employeeId);
      } else {
        this.employeeIdSearch = undefined;
      }
    }

    if (this.employeeSearchInput) {
      if (value) {
        if (employee) {
          this.employeeSearch = employee.legalName;
        } else {
          this.employeeSearch = undefined;
        }
      } else {
        this.employeeSearch = undefined;
      }
    }

  }

  selectEmployee(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.setEmployeeId(element.value === 'Select an employee' ? undefined : Number(element.value));

    this.changeFilter.emit(this.innerValue);
    if (this.onChange) {
      this.onChange(this.innerValue);
    }

  }


  findEmployee(employeeName: string) {
    if (employeeName) {
      if (this.allowScoping && this.userScope) {
        this.searchEmployees = this.sharedData.findAll(UserEmployeeAccess, {
          filter: {
            accessing_emp_id: this.authentication.currentUserId,
            legal_name: { LIKE: `%${employeeName?.trim()?.replace(',', '%')}%` },
            active_status: this.employeesStatus,
          },
          sort: 'legal_name',
        }).pipe(map(result => result.getModels()));
      } else {
        this.searchEmployees = this.sharedData.findAll(Employee, {
          filter: {
            legal_name: { LIKE: `%${employeeName?.trim()?.replace(',', '%')}%` },
            emp_type: 'WD',
            active_status: this.employeesStatus,
          },
          sort: 'legal_name',
        }).pipe(map(result => result.getModels()));
      }
    }
  }

  findEmployeeId(employeeId: string) {
    if (employeeId) {
      if (this.allowScoping && this.userScope) {
        this.searchEmployees = this.sharedData.findAll(UserEmployeeAccess, {
          filter: {
            accessing_emp_id: this.authentication.currentUserId,
            emp_id: { LIKE: `%${String(employeeId)?.trim()}%` },
            active_status: this.employeesStatus,
          },
          sort: 'emp_id',
        }).pipe(map(result => result.getModels()));
      } else {
        this.searchEmployees = this.sharedData.findAll(Employee, {
          filter: {
            emp_id: { LIKE: `%${String(employeeId)?.trim()}%` },
            emp_type: 'WD',
            active_status: this.employeesStatus,
          },
          sort: 'emp_id',
        }).pipe(map(result => result.getModels()));
      }
    }
  }

  searchEmployee(employee: Employee) {
    this.regionName = employee.region;
    this.districtNo = employee.districtId;
    this.districtName = employee.districtName;
    this.supGroupCode = employee.supGroupCode;
    this.employeeId = employee.empId;
    this.employeeSearch = employee.legalName;
    this.employeeIdSearch = String(employee.empId);

    // Scoped users will already have their available regions/distrists loaded and
    // but global users won't, so we need to load the values in order to select
    // them in the select lists based on the employee selected (ATS-273)
    if (!this.userScope) {
      this.loadRegions();
    } else {
      this.loadEmployees();
    }

  }


}


