import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { LegalCase } from './legal-case';
import { LawFirm } from './law-firm';

/*****************************************
 * LegalParty
 *****************************************/
@JsonApiModelConfig({
  type: 'legalParties',
})
export class LegalParty extends JsonApiModel {

  @Attribute({ serializedName: 'legal_party_type_id', converter })
    legalPartyTypeId: number;

  @Attribute({ serializedName: 'law_firm_id', converter })
    lawFirmId: number;

  @Attribute({ serializedName: 'first_name', converter })
    firstName: string;

  @Attribute({ serializedName: 'last_name', converter })
    lastName: string;

  @Attribute({ serializedName: 'middle_name', converter })
    middleName: string;

  @Attribute({ serializedName: 'preferred_name', converter })
    preferredName: string;

  @Attribute({ serializedName: 'address1', converter })
    address1: string;

  @Attribute({ serializedName: 'address2', converter })
    address2: string;

  @Attribute({ serializedName: 'city', converter })
    city: string;

  @Attribute({ serializedName: 'state', converter })
    state: string;

  @Attribute({ serializedName: 'zip', converter })
    zip: string;

  @Attribute({ serializedName: 'country', converter })
    country: string;

  @Attribute({ serializedName: 'phone', converter })
    phone: string;

  @Attribute({ serializedName: 'mobile', converter })
    mobile: string;

  @Attribute({ serializedName: 'fax', converter })
    fax: string;

  @Attribute({ serializedName: 'email', converter })
    email: string;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'attribute1', converter })
    attribute1: string;

  @Attribute({ serializedName: 'attribute2', converter })
    attribute2: string;

  @Attribute({ serializedName: 'attribute3', converter })
    attribute3: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'countryid', converter })
    countryId: string;

  @Attribute({ serializedName: 'stateid', converter })
    stateId: string;

  @HasMany()
    legalCases: LegalCase[];

  @BelongsTo()
    lawFirm: LawFirm;

}
