import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { CmsCustomer } from './cms-customer';
import { State } from '../shareddata';
import { Facility } from './facility';

/*****************************************
 * FlatRateCustomer
 *****************************************/
@JsonApiModelConfig({
  type: 'flatRateCustomers',
})
export class FlatRateCustomer extends JsonApiModel {

  @Attribute({ serializedName: 'customer_id', converter })
    customerId: number;

  @Attribute({ serializedName: 'utility_type_id', converter })
    utilityTypeId: string;

  @Attribute({ serializedName: 'facility_type_id', converter })
    facilityTypeId: number;

  @Attribute({ serializedName: 'facility_id', converter })
    facilityId: number;

  @Attribute({ serializedName: 'state_id', converter })
    stateId: number;

  @Attribute({ serializedName: 'count_against_quality_guarantee', converter })
    countAgainstQualityGuarantee: boolean;

  @Attribute({ serializedName: 'auto_approve_claims', converter })
    autoApproveClaims: boolean;

  @Attribute({ serializedName: 'approval_limit', converter })
    approvalLimit: number;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'start_date', converter: dateConverter })
    startDate: Date;

  @Attribute({ serializedName: 'end_date', converter: dateConverter })
    endDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @BelongsTo()
    cmsCustomer: CmsCustomer;

  @BelongsTo()
    facility: Facility;

  @BelongsTo()
    state: State;

}
