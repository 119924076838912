import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import {
  LatLon,
  Locate,
  MapPhoto,
  UserRequest,
} from '@usic/public-api';
import { BreakpointsService } from '../../services/breakpoints.service';
import { Observable } from 'rxjs';
import { NgStyle } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { HereMapComponent } from '../here-map/here-map.component';

export type ModalData = {
  locate?: Locate;
  title?: string;
  photos?: MapPhoto[];
  videos?: MapPhoto[];
  userRequests?: Observable<UserRequest[]>;
  locates?: Observable<Locate[]>;
  clickMarker?: any;
};


@Component({
  selector: 'lib-ticket-map',
  templateUrl: './ticket-map.component.html',
  styleUrls: ['./ticket-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [MatDialogTitle, NgStyle, MatIconButton, MatDialogClose, MatIcon, CdkScrollable, MatDialogContent, HereMapComponent]
})
export class TicketMapComponent implements OnInit {
  center: LatLon;
  ticketLocation: LatLon;
  driver: LatLon;
  area: LatLon[];
  locate: Locate;
  photos: MapPhoto[];
  videos: MapPhoto[];
  album: Array<any>;
  locates: Observable<Locate[]>;
  userRequests: Observable<UserRequest[]>;
  address: string;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<TicketMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public breakpoints: BreakpointsService,
  ) {
    if (this.data.locate) {
      this.locate = this.data.locate;

      this.address = this.locate.address;
      this.center = { lat: this.locate.lat, lon: this.locate.lon };

      // if we have a location for the locator, place a marker for the locator
      if (this.locate.visitLocationLat && this.locate.visitLocationLat !== 0 &&
        this.locate.visitLocationLon && this.locate.visitLocationLon !== 0) {
        this.driver = {
          lat: this.locate.visitLocationLat,
          lon: this.locate.visitLocationLon
        };
      }

      if (this.locate.area && this.locate.area.length > 0) {
        // if we have the polygon for the ticket, draw it
        this.area = this.locate.area;
      } else {
        // otherwise, just put a pin for the ticket center
        this.ticketLocation = { lat: this.locate.lat, lon: this.locate.lon };
      }

    }

    if (this.data.photos) {
      this.photos = this.data.photos;
      this.album = this.photos.map(p => ({ src: p.url, caption: p.fileName }));
    }

    if (this.data.videos) {
      this.videos = this.data.videos;
    }

    // CP-228
    // Show associated locates
    if (this.data.locates) {
      this.locates = this.data.locates;
      // For associated locates, don't show the driver
      this.driver = undefined;
    }

    if (this.data.userRequests) {
      this.userRequests = this.data.userRequests;
    }

    this.title = this.data.title || (this.locate ? this.locate.address : 'Photos');
  }

  ngOnInit(): void {
  }

  hideMap() {
    this.dialogRef.close();
  }

  clickMarker(event) {
    if (this.data.clickMarker) {
      this.data.clickMarker(event);
    }
  }

}
