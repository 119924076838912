import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
} from '../../helpers/jsonapi.convertors';


/*****************************************
 * DigArea
 *****************************************/
@JsonApiModelConfig({
  type: 'digAreas',
})
export class DigArea extends JsonApiModel {

  @Attribute({ converter })
    lat: number;

  @Attribute({ converter })
    lon: number;

  @Attribute({ serializedName: 'order_number', converter })
    orderNumber: number;

  @Attribute({ serializedName: 'group_index', converter })
    groupIndex: number;

  // Circular dependency
  // @BelongsTo()
  // transmissionDetail: TransmissionDetail;

}
