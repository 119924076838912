import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { ClaimDetail } from './claim-detail';
import { LegalDamage } from './legal-damage';
import { LegalRisk } from './legal-risk';
import { CasesSubtype } from './cases-subtype';
import { LawFirm } from './law-firm';
import { LegalParty } from './legal-party';

/*****************************************
 * LegalCase
 *****************************************/
@JsonApiModelConfig({
  type: 'legalCases',
})
export class LegalCase extends JsonApiModel {

  @Attribute({ serializedName: 'cases_type_id', converter })
    casesTypeId: number;

  @Attribute({ serializedName: 'cases_sub_type_id', converter })
    casesSubTypeId: number;

  @Attribute({ serializedName: 'case_manager_id', converter })
    caseManagerId: number;

  @Attribute({ serializedName: 'insurance_adjuster_id', converter })
    insuranceAdjusterId: number;

  @Attribute({ serializedName: 'law_firm_id', converter })
    lawFirmId: number;

  @Attribute({ serializedName: 'legal_party_id', converter })
    legalPartyId: number;

  @Attribute({ serializedName: 'sued_by_type_id', converter })
    suedByTypeId: number;

  @Attribute({ serializedName: 'law_firm_site_id', converter })
    lawFirmSiteId: number;

  @Attribute({ serializedName: 'case_name', converter })
    caseName: string;

  @Attribute({ serializedName: 'litigation_status_id', converter })
    litigationStatusId: number;

  @Attribute({ serializedName: 'date_served', converter: dateConverter })
    dateServed: Date;

  @Attribute({ serializedName: 'date_sent_to_attorney', converter: dateConverter })
    dateSentToAttorney: Date;

  @Attribute({ serializedName: 'matter_number', converter })
    matterNumber: string;

  @Attribute({ serializedName: 'court_case_number', converter })
    courtCaseNumber: string;

  @Attribute({ serializedName: 'damage_agreed_date', converter: dateConverter })
    damageAgreedDate: Date;

  @Attribute({ serializedName: 'insurance_claim', converter })
    insuranceClaim: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @Attribute({ serializedName: 'search_text', converter })
    searchText: string;

  @Attribute({ serializedName: 'case_status', converter })
    caseStatus: number;

  @Attribute({ serializedName: 'closed_date', converter: dateConverter })
    closedDate: Date;

  @Attribute({ serializedName: 'trial_date', converter: dateConverter })
    trialDate: Date;

  @Attribute({ serializedName: 'mediation_date', converter: dateConverter })
    mediationDate: Date;

  @BelongsTo()
    lawFirm: LawFirm;

  @BelongsTo()
    legalParty: LegalParty;

  @BelongsTo()
    casesSubtype: CasesSubtype;

  @HasMany()
    legalDamages: LegalDamage[];

  @HasMany()
    legalRisks: LegalRisk[];

  @HasMany()
    claimDetails: ClaimDetail[];


}
