import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigModule } from './app-config.module';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';

import {
  ErrorInterceptor,
  JwtInterceptor,
  AzureLoginModule,
  PipesModule,
  PublicFooterModule,
  PublicHeaderModule,
  UsicErrorHandler,
  UsicFooterModule,
  UsicHeaderModule,
} from '@usic/public-api';

import { LoginComponent as AppLoginComponent } from './login/login.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';

import { environment } from '@usic/environments/environment';
import { DigcheckPrivacyPolicyComponent } from './digcheck-privacy-policy/digcheck-privacy-policy.component';

@NgModule({ declarations: [AppComponent],
  bootstrap: [AppComponent], imports: [
    // Angular
    BrowserModule,
    AppRoutingModule,
    AppConfigModule,
    FormsModule,
    ReactiveFormsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.production ? 'G-DXZB9V2FZL' : ''),
    NgxGoogleAnalyticsRouterModule,
    // Material
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    // USIC LIB
    AzureLoginModule,
    PipesModule,
    PublicFooterModule,
    PublicHeaderModule,
    UsicFooterModule,
    UsicHeaderModule,
    AppLoginComponent,
    HelpComponent,
    HomeComponent,
    DigcheckPrivacyPolicyComponent
  ], providers: [
    { provide: ErrorHandler, useClass: UsicErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
        disableClose: false,
        hasBackdrop: true,
        height: '100%',
        panelClass: 'usic-dialog',
        maxWidth: '100vw',
        width: '100%',
      }
    },
    {
      provide: 'externalUrlRedirectResolver',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    },
    provideHttpClient(withInterceptorsFromDi())
  ] })
export class AppModule { }
