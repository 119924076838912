import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import { EffectiveDates } from '../../components/effective-dates/effective-dates.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import {
  Employee, EmployeeOnLoan,
} from '../shareddata';
// import { Asset } from './asset';

/*****************************************
 * AssetAssignment
 *****************************************/
@JsonApiModelConfig({
  type: 'assetAssignments'
})
export class AssetAssignment extends JsonApiModel {

  @Attribute({ converter })
    notes: string;

  //    @BelongsTo()
  //    asset: Asset;

  @Attribute({ serializedName: 'asset_id', converter })
    assetId: number;

  @Attribute({ converter })
    active: boolean;

  @Attribute({ serializedName: 'primary_flag', converter })
    primary: boolean;

  @Attribute({ serializedName: 'assigned_on', converter: dateConverter })
    assignedOn: Date;

  @Attribute({ serializedName: 'assigned_by', converter })
    assignedBy: number;

  @BelongsTo()
    assignedByEmployee: Employee;

  @BelongsTo()
    employee: Employee;

  @BelongsTo()
    employeeOnLoan: EmployeeOnLoan;

  @Attribute({ serializedName: 'employee_id', converter })
    employeeId: number;

  @Attribute({ converter })
    attribute1: string;

  @Attribute({ converter })
    attribute2: string;

  @Attribute({ converter })
    attribute3: string;

  @Attribute({ converter })
    attribute4: string;

  @Attribute({ converter })
    attribute5: string;

  @Attribute({ serializedName: 'effective_start_date', converter: dateConverter })
    effectiveStartDate: Date;

  @Attribute({ serializedName: 'effective_end_date', converter: dateConverter })
    effectiveEndDate: Date;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  // get effectiveDates
  get effectiveDates(): EffectiveDates {
    return {
      effectiveStartDate: this.effectiveStartDate,
      effectiveEndDate: this.effectiveEndDate
    };
  }

  // set effectiveDates
  set effectiveDates(v: EffectiveDates) {
    this.effectiveStartDate = v.effectiveStartDate;
    this.effectiveEndDate = v.effectiveEndDate;
  }

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attribute1,
      attribute2: this.attribute2,
      attribute3: this.attribute3,
      attribute4: this.attribute4,
      attribute5: this.attribute5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attribute1 = v.attribute1;
    this.attribute2 = v.attribute2;
    this.attribute3 = v.attribute3;
    this.attribute4 = v.attribute5;
    this.attribute5 = v.attribute4;
  }

}
