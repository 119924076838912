@if (showAsFieldset) {

  <fieldset>
      <legend>Attributes</legend>

      <div class="layout layout-gap" [ngClass]="{'layout-column' : breakpoints.screen('xs'), 'layout-row layout-wrap layout-align-flex-start' : breakpoints.screen('gt-xs') }">

          @for (num of attributeNumbers; track $index) {
            <ng-container *ngTemplateOutlet="attributeField; context: {$implicit: { attributeNum: num } }">
            </ng-container>
          }

      </div>

  </fieldset>

} @else {

  <div class="layout layout-gap" [ngClass]="{'layout-column' : breakpoints.screen('xs'), 'layout-row layout-wrap layout-align-flex-start' : breakpoints.screen('gt-xs') }" >

    @for (num of attributeNumbers; track num; let idx = $index) {
      <ng-container
        *ngTemplateOutlet="attributeField; context: {$implicit: { attributeNum: num, idx } }">
      </ng-container>
    }

  </div>

}

<ng-template #attributeField let-params>
  @if (labels[params.idx]) {
    <mat-form-field appearance="fill" class="flex-33-1">
        <mat-label>{{labels[params.idx]}}</mat-label>
        <input matInput type="text" name="attribute{{params.attributeNum}}"
            [placeholder]="labels[params.idx]" 
            [(ngModel)]="currentModel['attribute'+params.attributeNum]"
            (change)="changeAttribute('attribute'+params.attributeNum, $event)"
            (blur)="onTouched()" [required]="required">
    </mat-form-field> <!-- attribute {{params.attributeNum}} -->
  }
</ng-template>

