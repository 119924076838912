@if (showAsFieldset) {

  <fieldset>
    <legend>Effective Dates</legend>

    <div class="layout" [ngClass]="{'layout-column' : breakpoints.screen('xs'), 'layout-align-space-between-center layout-gap' : breakpoints.screen('gt-xs')}">

      <mat-form-field appearance="fill" class="flex">
        <mat-label> Effective Start Date</mat-label>
        <input matInput type="date" name="effectiveStartDate" placeholder=" Effective Start Date"
          [ngModel]="currentModel?.effectiveStartDate | date:'yyyy-MM-dd'"
          (ngModelChange)="currentModel.effectiveStartDate=$event" #effectiveStartDate="ngModel"
          (change)="changeEffectiveStartDate($event)" (blur)="onTouched()">
      </mat-form-field> <!-- effectiveStartDate -->

      <mat-form-field appearance="fill" class="flex">
        <mat-label> Effective End Date</mat-label>
        <input matInput type="date" name="effectiveEndDate" placeholder=" Effective End Date"
          [ngModel]="currentModel?.effectiveEndDate | date:'yyyy-MM-dd'"
          (ngModelChange)="currentModel.effectiveEndDate=$event" #effectiveEndDate="ngModel"
          (change)="changeEffectiveEndDate($event)" (blur)="onTouched()">
      </mat-form-field> <!-- effectiveEndDate -->

    </div>

  </fieldset>

} @else {

  <div class="layout" [ngClass]="{'layout-column' : breakpoints.screen('xs'), 'layout-align-space-between-center layout-gap' : breakpoints.screen('gt-xs')}">

    <mat-form-field appearance="fill" class="flex">
      <mat-label> Effective Start Date</mat-label>
      <input matInput type="date" name="effectiveStartDate" placeholder=" Effective Start Date"
             [ngModel]="currentModel?.effectiveStartDate | date:'yyyy-MM-dd'"
             (ngModelChange)="currentModel.effectiveStartDate=$event" #effectiveStartDate="ngModel"
             (change)="changeEffectiveStartDate($event)" (blur)="onTouched()">
    </mat-form-field> <!-- effectiveStartDate -->

    <mat-form-field appearance="fill" class="flex">
      <mat-label> Effective End Date</mat-label>
      <input matInput type="date" name="effectiveEndDate" placeholder=" Effective End Date"
             [ngModel]="currentModel?.effectiveEndDate | date:'yyyy-MM-dd'"
             (ngModelChange)="currentModel.effectiveEndDate=$event" #effectiveEndDate="ngModel"
             (change)="changeEffectiveEndDate($event)" (blur)="onTouched()">
    </mat-form-field> <!-- effectiveEndDate -->

  </div>

}

