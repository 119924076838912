import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
  BelongsTo,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { Bucket } from './bucket';
import { DueDateExtension } from './due-date-extension';
import { TkpDistrict } from './tkp-district';


/*****************************************
 * Usr
 *****************************************/
@JsonApiModelConfig({
  type: 'usrs',
})
export class Usr extends JsonApiModel {

  @Attribute({ serializedName: 'prin_type', converter })
    printType: string;

  @Attribute({ serializedName: 'displayname', converter })
    displayName: string;

  get sanitizedDisplayName() {
    return (this.displayName.startsWith('[DL ') || this.displayName === '[Digital Locating]') ? '' : this.displayName;
  }

  @Attribute({ serializedName: 'billing_id', converter })
    billingId: string;

  @Attribute({ serializedName: 'loginname', converter })
    loginName: string;

  @Attribute({ serializedName: 'firstname', converter })
    firstName: string;

  @Attribute({ serializedName: 'lastname', converter })
    lastName: string;

  @Attribute({ serializedName: 'reportingnode', converter })
    reportingNode: boolean;

  @Attribute({ serializedName: 'activeuser', converter })
    activeUser: boolean;

  @Attribute({ serializedName: 'password_expires', converter: dateConverter })
    passwordExpires: Date;

  @Attribute({ serializedName: 'last_sync_date', converter: dateConverter })
    lastSyncDate: Date;

  @Attribute({ serializedName: 'employee_number', converter })
    employeeNumber: number;

  @Attribute({ serializedName: 'timesheet_start_date', converter: dateConverter })
    timesheetStartDate: Date;

  @Attribute({ serializedName: 'timesheet_end_date', converter: dateConverter })
    timesheetEndDate: Date;

  @Attribute({ serializedName: 'default_project_id', converter })
    defaultProjectId: number;

  @Attribute({ serializedName: 'reports_to_id', converter })
    reportsToId: number;

  @BelongsTo()
    reportsTo: Usr;

  @Attribute({ serializedName: 'login_for_customer_id', converter })
    loginForCustomerId: number;

  @Attribute({ serializedName: 'email', converter })
    email: string;

  @Attribute({ serializedName: 'department_number', converter })
    department_number: string;

  @Attribute({ serializedName: 'storage_id', converter })
    storageId: number;

  @Attribute({ serializedName: 'phone', converter })
    phone: string;

  @Attribute({ serializedName: 'title', converter })
    title: string;

  @Attribute({ serializedName: 'home_phone', converter })
    home_phone: string;

  @Attribute({ serializedName: 'password_lockout', converter: dateConverter })
    passwordLockout: Date;

  @Attribute({ serializedName: 'password_crypt', converter })
    password_crypt: string;

  @Attribute({ serializedName: 'failed_logins', converter })
    failedLogins: number;

  @Attribute({ serializedName: 'district_number', converter })
    districtNumber: string;

  @Attribute({ serializedName: 'work_area', converter })
    workArea: string;

  @Attribute({ serializedName: 'peoplesoft_id', converter })
    peoplesoftId: number;

  @Attribute({ serializedName: 'supervisor_group_id', converter })
    supervisorGroupId: number;

  @Attribute({ serializedName: 'effective_district_id', converter })
    effectiveDistrictId: number;

  @BelongsTo()
    tkpDistrict: TkpDistrict;

  @Attribute({ serializedName: 'effective_supervisor_group_id', converter })
    effectiveSupervisorGroupId: number;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @Attribute({ serializedName: 'truck_number', converter })
    truckNumber: string;

  @Attribute({ serializedName: 'at_risk', converter })
    atRisk: boolean;

  @Attribute({ serializedName: 'type', converter })
    type: string;

  @Attribute({ serializedName: 'user_status', converter })
    userStatus: string;

  @Attribute({ serializedName: 'certified', converter })
    certified: boolean;

  @Attribute({ serializedName: 'callout_format', converter })
    calloutFormat: string;

  @Attribute({ serializedName: 'last_six_ssn', converter })
    lastSixSsn: string;

  @Attribute({ serializedName: 'org_super_region_id', converter })
    orgSuperRegionId: number;

  @Attribute({ serializedName: 'org_region_id', converter })
    orgRegionId: number;

  @Attribute({ serializedName: 'communication_preference', converter })
    communicationPreference: string;

  @Attribute({ serializedName: 'hr_manager', converter })
    hrManager: string;

  @Attribute({ serializedName: 'continous_service_date', converter: dateConverter })
    continousServiceDate: Date;

  @Attribute({ serializedName: 'recent_hire_date', converter: dateConverter })
    recentHireDate: Date;

  @Attribute({ serializedName: 'workday_district_code', converter })
    workdayDistrictCode: number;

  @Attribute({ serializedName: 'workday_region', converter })
    workdayRegion: string;

  @Attribute({ serializedName: 'workday_super_region', converter })
    workdaySuperRegion: string;

  @HasMany()
    dueDateExtensions: DueDateExtension[];

  @HasMany()
    buckets: Bucket[];

}
