import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany
} from '@michalkotas/angular2-jsonapi';
import { Attributes } from '../../components/additional-attributes/additional-attributes.component';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { Customer } from '../shareddata/customer';
import { Employee } from '../shareddata/employee';
import { DamagePhoto } from './damage-photo';
import { Estimate } from './estimate';
import { InvestigationDetailLine } from './investigation-detail-line';
import { UtilitiesProfilesView } from './utilities-profiles-view';
import { Investigation } from './investigation';

/*****************************************
 * CMSInvestigationDetail
 *****************************************/
@JsonApiModelConfig({
  type: 'cmsInvestigationDetails'
})
export class CMSInvestigationDetail extends JsonApiModel {

  @Attribute({ serializedName: 'investigation_id', converter })
    investigation_id: number;

  @Attribute({ serializedName: 'customer_id', converter })
    customer_id: number;

  @Attribute({ serializedName: 'utility_type_id', converter })
    utility_type_id: number;

  @Attribute({ serializedName: 'facility_type_id', converter })
    facility_type_id: number;

  @Attribute({ serializedName: 'facility_id', converter })
    facility_id: number;

  @Attribute({ serializedName: 'locator_id', converter })
    locator_id: number;

  @Attribute({ serializedName: 'wf_item_id', converter })
    wf_item_id: number;

  @Attribute({ serializedName: 'active', converter })
    active: boolean;

  @Attribute({ serializedName: 'investigation_root_cause_id', converter })
    investigation_root_cause_id: number;

  @Attribute({ serializedName: 'completed', converter })
    completed: boolean;

  @Attribute({ serializedName: 'completed_by', converter })
    completed_by: number;

  @Attribute({ serializedName: 'completed_date', converter: dateConverter })
    completed_date: Date;

  @Attribute({ serializedName: 'search_text', converter })
    search_text: string;

  @Attribute({ serializedName: 'att1', converter })
    att1: string;

  @Attribute({ serializedName: 'att2', converter })
    att2: string;

  @Attribute({ serializedName: 'att3', converter })
    att3: string;

  @Attribute({ serializedName: 'att4', converter })
    att4: string;

  @Attribute({ serializedName: 'att5', converter })
    att5: string;

  @Attribute({ serializedName: 'supgroup', converter })
    supgroup: string;

  @Attribute({ serializedName: 'supervisor_id', converter })
    supervisor_id: number;

  @Attribute({ serializedName: 'event_summary', converter })
    event_summary: string;

  @Attribute({ serializedName: 'video_url', converter })
    video_url: string;

  @Attribute({ serializedName: 'inv_report_attachment_id', converter })
    invReportAttachmentId: number;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  // get attributes
  get attributes(): Attributes {
    return {
      attribute1: this.attr1,
      attribute2: this.attr2,
      attribute3: this.attr3,
      attribute4: this.attr4,
      attribute5: this.attr5,
    };
  }

  // set attributes
  set attributes(v: Attributes) {
    this.attr1 = v.attribute1;
    this.attr2 = v.attribute2;
    this.attr3 = v.attribute3;
    this.attr4 = v.attribute5;
    this.attr5 = v.attribute4;
  }

  @BelongsTo()
    customer: Customer;

  @BelongsTo()
    investigation: Investigation;

  @BelongsTo()
    employee: Employee;

  @HasMany()
    estimates: Estimate[];

  @HasMany()
    investigationDetailLines: InvestigationDetailLine[];

  @BelongsTo()
    utilitiesProfilesView: UtilitiesProfilesView;

  @BelongsTo()
    investigationReport: DamagePhoto;

}
