import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter,
  dateConverter
} from '../../helpers/jsonapi.convertors';
import { CasesSubtype } from './cases-subtype';
import { LegalCase } from './legal-case';

/*****************************************
 * CasesType
 *****************************************/
@JsonApiModelConfig({
  type: 'casesTypes',
})
export class CasesType extends JsonApiModel {

  @Attribute({ serializedName: 'cases_type_description', converter })
    casesTypeDescription: string;

  @Attribute({ serializedName: 'created_on', converter: dateConverter })
    createdOn: Date;

  @Attribute({ serializedName: 'created_by', converter })
    createdBy: string;

  @Attribute({ serializedName: 'last_updated_on', converter: dateConverter })
    lastUpdatedOn: Date;

  @Attribute({ serializedName: 'last_updated_by', converter })
    lastUpdatedBy: string;

  @HasMany()
    legalCases: LegalCase[];

  @HasMany()
    casesSubtypes: CasesSubtype[];

}
