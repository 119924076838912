import {
  JsonApiModelConfig,
  JsonApiModel,
  Attribute,
  BelongsTo,
  HasMany,
} from '@michalkotas/angular2-jsonapi';
import {
  converter, dateConverter,
} from '../../helpers/jsonapi.convertors';
import { Activity } from './activity';
import { DueDateExtension } from './due-date-extension';
import { Ticket } from './ticket';
import { TkpDistrict } from './tkp-district';
import { VisitLocation } from './visit-location';


/*****************************************
 * Visit
 *****************************************/
@JsonApiModelConfig({
  type: 'visits',
})
export class Visit extends JsonApiModel {

  @Attribute({ serializedName: 'ticket_id', converter })
    ticketId: number;

  @Attribute({ serializedName: 'arrived', converter: dateConverter })
    arrived: Date;

  @Attribute({ serializedName: 'departed', converter: dateConverter })
    departed: Date;

  @Attribute({ serializedName: 'synced_up', converter: dateConverter })
    syncedUp: Date;

  @Attribute({ serializedName: 'when_saved_local', converter: dateConverter })
    whenSavedLocal: Date;

  @Attribute({ serializedName: 'photo_ident', converter })
    photoIdent: string;

  @Attribute({ serializedName: 'actor_id', converter })
    actorId: number;

  @Attribute({ serializedName: 'opened', converter: dateConverter })
    opened: Date;

  @Attribute({ serializedName: 'district_id', converter })
    districtId: number;

  @BelongsTo()
    tkpDistrict: TkpDistrict;

  @HasMany()
    dueDateExtensions: DueDateExtension[];

  @BelongsTo()
    ticket: Ticket;

  @HasMany()
    activities: Activity[];

  @BelongsTo()
    visitLocation: VisitLocation;

}
