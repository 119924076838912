<footer class="bg-usic-black border-top border-accent layout"
   [ngClass]="{ 'layout-column' : breakpoints.screen('xs'), 'layout-align-space-between-center': breakpoints.screen('gt-xs') }">

   <div class="flex layout"
      [ngClass]="{ 'layout-column' : breakpoints.screen('xs'), 'layout-align-space-between-center': breakpoints.screen('gt-xs') }">

      <p class="mat-caption" [ngStyle]="breakpoints.screen('xs') && { marginBottom: '.25em' }">
         © 2020-{{ currentDate.getFullYear() }} USIC. All Rights Reserved.
      </p>

      <p class="mat-caption flex layout layout-gap links"
         [ngClass]="{ 'layout-align-start-center' : breakpoints.screen('xs'), 'layout-align-center-center': breakpoints.screen('gt-xs') }"
         [ngStyle]="breakpoints.screen('xs') && { margin: '0 0 .25em' }">
         <strong>For Employees:</strong>

         <span>

            <a href="https://my.usicinc.com/" target="_blank" rel="noopener noreferrer nofollow"> My USIC </a> |
            <a href="https://login.microsoftonline.com" target="_blank" rel="noopener noreferrer nofollow"> Email </a> |
            <a href="https://fleetweb.donlen.com/driver_point_login.html" target="_blank"
               rel="noopener noreferrer nofollow"> GPS </a>

         </span>
      </p>

   </div>

   <div class="social-icons layout-row"
      [ngClass]="{ 'hide' : breakpoints.screen('xs'), 'layout-align-center-center': breakpoints.screen('gt-xs') }">

      <a class="facebook" title="Facebook" href="https://www.facebook.com/USICLLC" target="_blank"
         rel="noopener noreferrer nofollow">
         <fa-icon [icon]="['fab', 'facebook-f']" size="1x"></fa-icon> </a>

      <a class="twitter" title="Twitter" href="https://twitter.com/USICLLC" target="_blank"
         rel="noopener noreferrer nofollow">
         <fa-icon [icon]="['fab', 'twitter']" size="1x"></fa-icon> </a>

      <a class="linkedin" title="LinkedIn"
         href="https://www.linkedin.com/company/1082026?trk=vsrp_companies_res_name&amp;trkInfo=VSRPsearchId%3A132506311393536579689%2CVSRPtargetId%3A1082026%2CVSRPcmpt%3Aprimary"
         target="_blank" rel="noopener noreferrer nofollow">
         <fa-icon [icon]="['fab', 'linkedin-in']" size="1x"></fa-icon> </a>

   </div>

</footer>
