/* eslint-disable no-underscore-dangle */
import { Component, Input } from '@angular/core';
import { UsicDialogComponent } from '../usic-dialog.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDialogContent } from '@angular/material/dialog';
import { UsicErrorPanelComponent } from '../../usic-error-panel/usic-error-panel.component';

@Component({
  selector: 'usic-dialog-content',
  template: `
    <mat-dialog-content class="scroll">

      <!-- Errors -->
      <lib-usic-error-panel [errors]="parent.errors ">
        <!--** source: dialog-content.component **--> </lib-usic-error-panel>

      <ng-content></ng-content>

    </mat-dialog-content>
  `,
  imports: [CdkScrollable, MatDialogContent, UsicErrorPanelComponent]
})
export class DialogContentComponent {

  private _parent: UsicDialogComponent;
  @Input({ required: true }) set parent(value: UsicDialogComponent ) {
    this._parent = value;
  }
  get parent(): UsicDialogComponent {
    return this._parent;
  }

  constructor() { }

}
