/********************************************************************************
 * AuthGuard (implements CanActivate)
 *
 * Implementation of CanActivate used in the Angular Routing to ensure a user is
 * logged in (Authenticated) before accessing secured routes.
 *
 * author: Steven Pothoven (stevenpothoven@usicllc.com)
 ********************************************************************************/

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private router: Router,
    private authentication: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authentication.currentUserValue;
    if (currentUser && currentUser.token) {
      // auto-renew the JWT token if getting close to expiration (< 10 minutes)
      const tenMinutesFromNow = (new Date()).getTime() + 600000;
      if (this.authentication.currentUserExpire.getTime() < tenMinutesFromNow) {
        return this.authentication.renewToken();
      } else {
        // authorized so return true
        return true;
      }

    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
